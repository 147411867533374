const verification = (form) => {
    const verifyArray = {
        name: false,
        ltd: false,
        phonenum: false,
        email: false,
        request: false
    };
    if (
        form.phonenum &&
        form.phonenum.length > 8 &&
        form.phonenum.match(/^[0-9+\-()]*$/)
    ) {
        verifyArray.phonenum = true;
    }
    if (form.email && form.email.match(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/)) {
        verifyArray.email = true;
    }
    if (form.name && form.name.length > 1) {
        verifyArray.name = true;
    }
    if (form.ltd && form.ltd.length > 1) {
        verifyArray.ltd = true;
    }
    if (form.center || form.licence || form.learning || form.analitycs) {
        verifyArray.request = true;
    }
    return verifyArray;
};

export { verification };
// /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
