import React from "react";

const LargeImageTablet = ({ imageUrl }) => {
    return (
        <>
        
            <img
                src={imageUrl}
                alt="error"
                
                className="imageLargeBlockTablet mt-40 mb-40"
            />
        </>
    );
};

export default LargeImageTablet;
