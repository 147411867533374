import React, { useState } from "react";
import { Link } from "react-router-dom";

const HorizontalBlockDropdown = ({ blockContent }) => {
    const [hideContent, setHideContent] = useState(true);
    const [screenW, setScreenW] = useState(window.innerWidth);

    window.addEventListener("resize", () => {
        setScreenW(window.innerWidth);
    });

    const handleContent = () => {
        setHideContent(!hideContent);
    };

    const setHigh = () => {
        if (screenW > 1700) {
            return hideContent
                ? { height: 150 + "px" }
                : { height: 400 + "px" };
        } else if (screenW < 1700) {
            return hideContent
                ? { height: 150 + "px" }
                : { height: 400 + "px" };
        }
    };
    return (
        <div
            className="horizontalBlockDropdown pt-60 pb-60"
            style={setHigh()}
        >
            <div className="horizontalBlockDropdown-name fs-40          ">
                <div className="horizontalBlockDropdown-left-arrow imgBack "></div>
                <span className="horizontalBlockDropdown-name-name cp fs-40">
                    {blockContent.name}
                </span>
            </div>
            <div
                className="horizontalBlockDropdown-textBlock fs-16 cp"
                style={
                    hideContent
                        ? { right: -25 + "%", height: 40 + "px" }
                        : { right: 0 + "px", height: 100 + "%" }
                }
                onClick={() => setHideContent(false)}
            >
                {blockContent.textBlock1}
            </div>
            <div
                className="horizontalBlockDropdown-textBlock fs-16 "
                style={hideContent ? { right: -25 + "%" } : { right: 0 + "px" }}
            >
                <span>{blockContent.textBlock2}</span>
                <Link
                    to={blockContent.link}
                    className={`horizontalBlockDropdown-textBlock-link fs-32 cp`}
                >
                    <span className="horizontalBlockDropdown-textBlock-link-name ">
                        {blockContent.linkName}
                    </span>
                    <div className="horizontalBlockDropdown-textBlock-link-arrow imgBack "></div>
                </Link>
            </div>
            <div
                className="horizontalBlockDropdown-gradientBlock"
                style={
                    !hideContent ? { right: -200 + "px" } : { right: 0 + "px" }
                }
            ></div>
            <div
                style={
                    !hideContent ? { right: -20 + "px" } : { right: 0 + "px" }
                }
                className="horizontalBlockDropdown-open imgBack cp"
                onClick={() => handleContent()}
            ></div>
            <div
                className="horizontalBlockDropdown-close-back"
                style={
                    hideContent ? { right: -90 + "px" } : { right: 0 + "px" }
                }
            >
                <div
                    className="horizontalBlockDropdown-close imgBack cp"
                    onClick={() => handleContent()}
                ></div>
            </div>
        </div>
    );
};

export default HorizontalBlockDropdown;
