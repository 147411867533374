import React from "react";
import { useLocation } from "react-router";
// import keyboardPageName from "../../utils/keyboardPageName.js";

const PageHeadMobile = ({ pageName }) => {
    const { pathname } = useLocation();
    return (
        <div className="pageHeadMobile">
            <div
                className={`bborder ${
                    pathname === "/confidentionalPoliticPage"
                        ? window.innerWidth < 360
                            ? "fs-32"
                            : "fs-38"
                        : "fs-48"
                } pb-40`}
                style={
                    pathname === "/cookiesPage" ||
                    pathname === "/forBusinessPage"
                        ? { paddingRight: "10%" }
                        : pathname === "/personalDataAgreementPage"
                        ? {
                              paddingRight: "10%",
                              fontSize: 32 + "px",
                              lineHeight: 32 + "px"
                          }
                        : { paddingRight: "20%" }
                }
            >
                {pageName}
            </div>
        </div>
    );
};

export default PageHeadMobile;
