import React from "react";
import CardsWithArrowsAdaptive from "../pageBlocks/cardsBlocks/cardsWithArrowsAdaptive";
import { useLanguage } from "../hooks/useLang";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const AudiencePage = () => {
    const { content } = useLanguage();
    const pageName = content.audience.pageName;
    const subMenuList = content.audience.subMenuList;
    const imageUrls = content.audience.imageUrls;
    const blocks = content.audience.blocks;
    const cards = content.audience.cards;

    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.helper} />
            <HorizontalBlockAdaptive blockContent={blocks.tools} />
            <CardsWithArrowsAdaptive
                blockContent={cards.block1}
                arrowsDirection="left"
                topBorderbBlock={true}
            />
            <HorizontalBlockAdaptive blockContent={blocks.possibilities} />
        </div>
    );
};

export default AudiencePage;
