import React, { useState } from "react";
import { useLanguage } from "../../hooks/useLang";
const TariffsBlockMobile = () => {
    const [currentTariff, setTariff] = useState("standartValue");
    const { content } = useLanguage();
    const tableInfo = content.tariffs.table.info;
    const tabletop = content.tariffs.table.top;
    const handleTariff = (tariff) => {
        setTariff(tariff);
    };

    return (
        <>
            <div className="tariffsBlockMobile-back bborder">
                <div className="tariffsBlockMobile-top-back bborder">
                    <div
                        className="TariffsBlockMobile-top-item  pt-10 pb-10 "
                        onClick={() => handleTariff("standartValue")}
                    >
                        <div
                            className="fs-16"
                            style={
                                currentTariff === "standartValue"
                                    ? { color: "var(--hrLineColor)" }
                                    : { color: "#838383" }
                            }
                        >
                            {tabletop.column1.name}
                        </div>
                        <div
                            className="fs-12"
                            style={
                                currentTariff === "standartValue"
                                    ? {
                                          color: "var(--hrLineColor)"
                                      }
                                    : {
                                          color: "var(--bgColor)"
                                      }
                            }
                        >
                            {tabletop.column1.price}
                        </div>
                    </div>
                    <div
                        className="TariffsBlockMobile-top-item  pt-10 pb-10"
                        onClick={() => handleTariff("optimumValue")}
                    >
                        <div
                            className="fs-16"
                            style={
                                currentTariff === "optimumValue"
                                    ? { color: "var(--hrLineColor)" }
                                    : { color: "#838383" }
                            }
                        >
                            {tabletop.column2.name}
                        </div>
                        <div
                            className="fs-12"
                            style={
                                currentTariff === "optimumValue"
                                    ? { color: "var(--hrLineColor)" }
                                    : { color: "var(--bgColor)" }
                            }
                        >
                            {tabletop.column2.price}
                        </div>
                    </div>
                    <div
                        className="TariffsBlockMobile-top-item  pt-10 pb-10"
                        onClick={() => handleTariff("premiumValue")}
                    >
                        <div
                            className="fs-16"
                            style={
                                currentTariff === "premiumValue"
                                    ? { color: "var(--hrLineColor)" }
                                    : { color: "#838383" }
                            }
                        >
                            {tabletop.column3.name}
                        </div>
                        <div
                            className="fs-12"
                            style={
                                currentTariff === "premiumValue"
                                    ? { color: "var(--hrLineColor)" }
                                    : { color: "var(--bgColor)" }
                            }
                        >
                            {tabletop.column3.price}
                        </div>
                    </div>
                </div>
                {tableInfo.map((item, index) => (
                    <div
                        key={index}
                        className={`tariffsBlock-columnline-back  ${
                            index < tableInfo.length - 1 ? "" : ""
                        }`}
                    >
                        <div
                            className={`tariffsBlockMobile-columnline-item pt-5 pb-5 fs-12  ${
                                index < tableInfo.length - 1
                                    ? "graybborder"
                                    : ""
                            }`}
                        >
                            <span style={{ width: "70%" }}>{item.name}</span>
                            <div>
                                {item[currentTariff] ? (
                                    <div className="tariffsBlockMobile-columnline-item-true imgBack"></div>
                                ) : (
                                    <div className="tariffsBlockMobile-columnline-item-false imgBack"></div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default TariffsBlockMobile;
