import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";
import HorizontalBlockUnnamedMobile from "../pageBlocksMobile/horizontalBlockUnnamedMobile";

const AudiencePageMobile = () => {
    const { content, currentLang } = useLanguage();
    console.log("currentLang", currentLang);
    console.log("current width", window.innerWidth);

    const pageName = content.audience.pageName;
    const imageUrls = content.audience.imageUrls;
    const blocks = content.audience.blocks;
    const cards = content.audience.cards;

    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.helper}
                noLink={true}
                smallHeight={true}
                doubleLineName={
                    currentLang !== "ru"
                        ? true
                        : window.innerWidth < 360
                        ? true
                        : false
                }
                customHeight={270}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.tools}
                alternativeType={true}
                rightArrow={false}
                nameFontSize="32"
                leftIndent={false}
            />
            <CardsSwipeMobile
                blockContent={cards.block1}
                customMinTextHeight="200"
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.possibilities}
                noLink={true}
                smallHeight={true}
                customHeight={240}
            />
        </div>
    );
};

export default AudiencePageMobile;
