const interfaceRu = {
    menubar: {
        news: "Главные новости часа"
    },
    dropdownmenu: {
        menu: {
            main: "Главная",
            platform: "Платформа",
            forBusiness: "Для бизнеса",
            safety: "Безопасность",
            demonstration: "Демонстрация",
            contacts: "Контакты"
        },
        menumap: {
            audience: "Анализ аудитории",
            analytics: "Аналитика",
            courses: "Курсы",
            scanner: "Сканер уязвимостей",
            center: "Ситуационный центр",
            communications: "Коммуникации",
            aboutUs: "О нас",
            publicAvtivity: "Публичная деятельность",
            publications: "Публикации"
        }
    },
    question: {
        block: { name: "Задать вопрос", linkname: "Подробнее" },
        page: {
            name: "ФИО",
            llc: "Организация",
            phonenum: "Номер телефона",
            textarea: "Вопрос (не более 1000 символов)",
            requiered: "поля,обязательные для заполнения",
            agreement: {
                beforeLink: "С условиями",
                link: "Соглашения",
                afterLink: "ознакомился и согласен"
            },
            send: "Отправить",
            back: "Назад",
            success: "Ваш запрос успешно отправлен",
            error: {
                line1: "Произошла ошибка отправки запроса.",
                line2: "Попробуйте позднее или напишите нам на                                 info@avl.team",
                line3: "Приносим извинения за неудобства"
            },
            tryAgain: "Попробовать снова",
            returnLink: "На главную"
        }
    },
    request: {
        block: { name: "Отправить запрос", linkname: "Подробнее" },
        page: {
            name: "ФИО",
            llc: "Организация",
            phonenum: "Номер телефона",
            requiered: "поля,обязательные для заполнения",
            agreement: {
                beforeLink: "С условиями",
                link: "Соглашения",
                afterLink: "ознакомился и согласен"
            },
            send: "Отправить",
            back: "Назад",
            success: "Ваш запрос успешно отправлен",
            error: {
                line1: "Произошла ошибка отправки запроса.",
                line2: "Попробуйте позднее или напишите нам на                                 info@avl.team",
                line3: "Приносим извинения за неудобства"
            },
            tryAgain: "Попробовать снова",
            returnLink: "На главную",
            checkboxes: {
                center: "Виртуальный ситуационный центр",
                licence: "Лицензии и внедрение",
                analytics: "Аналитика",
                courses: "Курсы"
            },
            imageLinks: {
                center: "Виртуальный ситуационный центр",
                licence: "Лицензии и внедрение",
                analytics: "Аналитика",
                courses: "Курсы"
            }
        }
    },
    captcha: "Чтобы отправить запрос, выберите правильный ответ",
    footer: {
        sitemap: {
            aboutUs: {
                aboutus: "О нас",
                creator: "Основатель",
                mission: "Наша миссия",
                activity: "Направления деятельности",
                contacts: "Контакты"
            },
            forBusiness: {
                forbusiness: "Для бизнеса",
                center: "Виртуальный ситуационный центр",
                communications: "Коммуникации",
                analytics: "Услуги и аналитика",
                licence: "Лицензии и внедрение"
            },
            platform: {
                platform: "Платформа",
                demonstaration: "Демонстрация",
                situationCenter: "Ситуационный центр",
                audience: "Анализ аудитории",
                scanner: "Сканер уязвимостей"
            },
            courses: {
                courses: "Курсы",
                intelligence: "Конкурентная разведка",
                trainings: "Корпоративные тренинги"
            },
            safety: {
                safety: "Безопасность",
                economy: "Экономическая безопасность",
                support: "Правовая поддержка",
                cyber: "Кибербезопасность"
            },
            public: {
                publicActivity: "Публичная деятельность",
                publications: "Публикации",
                cyberDad: "КиберДед",
                tgChannels: "Телеграм-каналы",
                social: "Социальные сети"
            }
        },
        horizontalBlock: {
            rights: "Все права защищены",
            name: `ООО "ЛАВИНА ПУЛЬС"`,
            registry:
                "Программное обеспечение зарегистрировано в Едином реестре                     российских программ для электронных вычислительных машин и баз данных",
            news: "Главные новости часа",
            forBusiness: "Для бизнеса",
            platform: "Платформа",
            safety: "Безопасность",
            publicActivity: "Публичная деятельность",
            aboutus: "О нас",
            learning: "Обучение",
            contacts: "Контакты",
            confidential: "Политика конфиденциальности",
            agreement: "Соглашение об обработке персональных данных",
            useConditions: "Условия использования",
            oferta: "Публичная оферта",
            documantation: ""
        }
    }
};

export default interfaceRu;
