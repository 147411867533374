import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ChangeLang from "../pageBlocks/changeLang";

const MenuBarMobile = ({
    handeDropDownMenu,
    dropdownMenuOpen,
    handleTheme
}) => {
    const location = useLocation();
    const { pathname } = useLocation();
    useEffect(() => {
        if (!location.hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    return (
        <>
            {pathname !== `/documentation-avl-3.0/` && (
                <menu className="ma menuBarMobile">
                    <Link to="/">
                        <div className="logoMobile ml-5 imgBack"></div>
                    </Link>
                    <div className="menuItemsBack">
                        <div
                            className="changeTheme imgBack cp mr-20 mt-5"
                            onClick={() => handleTheme()}
                        ></div>
                        <div
                            className=" cp  "
                            style={{ height: 10 + "px" }}
                        >
                            <ChangeLang />
                        </div>
                        <div
                            className={
                                !dropdownMenuOpen
                                    ? "menuBurger imgBack cp"
                                    : "menuBurgerOpen imgBack cp"
                            }
                            onClick={() => handeDropDownMenu()}
                        ></div>
                    </div>
                </menu>
            )}
        </>
    );
};

export default MenuBarMobile;
