import React from "react";
import { useLanguage } from "../hooks/useLang";

import CardsWithArrowsAdaptive from "../pageBlocks/cardsBlocks/cardsWithArrowsAdaptive";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const SituationCenterPage = () => {
    const { content } = useLanguage();
    const pageName = content.situationCenter.pageName;
    const subMenuList = content.situationCenter.subMenuList;
    const imageUrls = content.situationCenter.imageUrls;
    const blocks = content.situationCenter.blocks;
    const cards = content.situationCenter.cards;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.main} />
            <HorizontalBlockAdaptive blockContent={blocks.about} />
            <CardsWithArrowsAdaptive
                arrowsDirection="left"
                blockContent={cards.block1}
                topBorderbBlock={true}
            />
            <HorizontalBlockAdaptive blockContent={blocks.benifits} />
            <CardsWithArrowsAdaptive
                arrowsDirection="right"
                blockContent={cards.block2}
                topBorderbBlock={true}
            />
            <HorizontalBlockAdaptive blockContent={blocks.outsourse} />
            <CardsWithArrowsAdaptive
                arrowsDirection="left"
                blockContent={cards.block3}
                topBorderbBlock={true}
            />
        </div>
    );
};

export default SituationCenterPage;
