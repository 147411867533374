import React from "react";
import HorizontalBlockDropdown from "../pageBlocks/horizontalBlockDropdown";
import LargeImage from "../pageBlocks/largeImage";

// import content from "../textContent";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import HorizontalBlockUnnamed from "../pageBlocks/horizontalBlockUnnamed";
import CardsAdaptiveBlock from "../pageBlocks/cardsBlocks/cardsAdaptiveBlock";
import { useLanguage } from "../hooks/useLang";
import PageHead from "../pageBlocks/pageHead/pageHead";

const MainPage = () => {
    const { content } = useLanguage();
    const pageName = content.mainPage.pageName;
    const subMenuList = content.mainPage.subMenuList;
    const imageUrls = content.mainPage.imageUrls;

    const forBusiness = content.mainPage.blocks.forBusiness;
    const safety = content.mainPage.blocks.safety;
    const demonstration = content.mainPage.blocks.demonstration;
    const sixCardsBlock = content.mainPage.blocks.sixCardsBlock;
    const platform = content.mainPage.blocks.platform;
    const situationCenter = content.mainPage.blocks.situationCenter;
    const analysis = content.mainPage.blocks.analysis;
    const vulnerabilitiesScan = content.mainPage.blocks.vulnerabilitiesScan;
    const analytics = content.mainPage.blocks.analytics;
    const unnamedBlock = content.mainPage.blocks.unnamedBlock;
    const aboutUs = content.mainPage.blocks.aboutUs;
    const shortBlock = content.mainPage.blocks.shortBlock;
    const cardsWithImg = content.mainPage.blocks.cardsWithImg;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />

            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={forBusiness} />
            <HorizontalBlockAdaptive blockContent={safety} />
            <HorizontalBlockAdaptive
                blockContent={demonstration}
                bottomBorder={true}
            />
            <CardsAdaptiveBlock
                blockContent={sixCardsBlock}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                bottomBorderCard={true}
            />
            <LargeImage imageUrl={imageUrls.img2} />
            <HorizontalBlockAdaptive blockContent={platform} />
            <HorizontalBlockDropdown blockContent={situationCenter} />
            <HorizontalBlockDropdown blockContent={analysis} />
            <HorizontalBlockDropdown blockContent={vulnerabilitiesScan} />
            <HorizontalBlockDropdown blockContent={analytics} />
            <HorizontalBlockUnnamed blockContent={unnamedBlock} />
            <LargeImage imageUrl={imageUrls.img3} />
            <HorizontalBlockAdaptive blockContent={aboutUs} />
            <HorizontalBlockAdaptive
                blockContent={shortBlock}
                // bottomPadding={true}
            />
            <CardsAdaptiveBlock
                blockContent={cardsWithImg}
                marginTopNum={false}
                marginTopText={true}
                marginTopImg={true}
                paddingBottomBlock={true}
            />
        </div>
    );
};

export default MainPage;
