import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";

const LearningPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.learning.pageName;
    const imageUrls = content.learning.imageUrls;
    const blocks = content.learning.blocks;
    const cards = content.learning.cards.block1;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.service}
                noLink={true}
                tripleLineName={true}
                customHeight={window.innerWidth < 360 ? 380 : 350}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.corporate}
                noLink={true}
                doubleLineName={true}
                customHeight={200}
            />
            <CardsSwipeMobile
                blockContent={cards}
                topBorderbBlock={true}
                bottomBorderBlock={true}
            />
        </div>
    );
};

export default LearningPageMobile;
