const interfaceRu = {
    menubar: {
        news: "Noticias destacadas"
    },
    dropdownmenu: {
        menu: {
            main: "Inicio",
            platform: "Plataforma",
            forBusiness: "Para negocios",
            safety: "Seguridad",
            demonstration: "Demostración",
            contacts: "Contactos"
        },
        menumap: {
            audience: "Análisis de la audiencia",
            analytics: "Analítica",
            courses: "Cursos",
            scanner: "Escáner de vulnerabilidades",
            center: "Centro operativo",
            communications: "Comunicaciones",
            aboutUs: "Quienes somos",
            publicAvtivity: "Sala de prensa",
            publications: "Publicaciones"
        }
    },
    question: {
        block: { name: "Hacer una pregunta", linkname: "Más" },
        page: {
            name: "Nombre",
            llc: "Entidad",
            phonenum: "Número de teléfono",
            textarea: "Pregunta (máx. 1000 caracteres)",
            requiered: "campos obligatorios",
            agreement: {
                beforeLink: "He leido y acepto",
                link: "los términos",
                afterLink: "del Acuerdo"
            },
            send: "Enviar",
            back: "Atrás",
            success: "Su solicitud ha sido enviada",
            error: {
                line1: "Se ha producido un error al enviar la solicitud.",
                line2: "Inténtelo más tarde o envíenos un correo electrónico a                                info@avl.team",
                line3: "Disculpe las molestias"
            },
            tryAgain: "Intentar otra vez",
            returnLink: "Inicio"
        }
    },
    request: {
        block: { name: "Enviar una solicitud", linkname: "Más" },
        page: {
            name: "Nombre",
            llc: "Entidad",
            phonenum: "Número de teléfono",
            requiered: "campos obligatorios",
            agreement: {
                beforeLink: "He leido y acepto",
                link: "los términos",
                afterLink: "del Acuerdo"
            },
            send: "Enviar",
            back: "Atrás",
            success: "Su solicitud ha sido enviada",
            error: {
                line1: "Se ha producido un error al enviar la solicitud.",
                line2: "Inténtelo más tarde o envíenos un correo electrónico a                                info@avl.team",
                line3: "Disculpe las molestias"
            },
            tryAgain: "Intentar otra vez",
            returnLink: "Inicio",
            checkboxes: {
                center: "Centro operativo virtual",
                licence: "Licencias e implementación",
                analytics: "Analítica",
                courses: "Cursos"
            },
            imageLinks: {
                center: "Centro operativo virtual",
                licence: "Licencias e implementación",
                analytics: "Analítica",
                courses: "Cursos"
            }
        }
    },
    captcha: "Para enviar la solicitud, seleccione la respuesta correcta",
    footer: {
        sitemap: {
            aboutUs: {
                aboutus: "Quienes somos",
                creator: "Fundador",
                mission: "Nuestra misión",
                activity: "Esferas de actividad",
                contacts: "Contactos"
            },
            forBusiness: {
                forbusiness: "Para negocios",
                center: "Centro operativo virtual",
                communications: "Comunicaciones",
                analytics: "Servicios y analítica",
                licence: "Licencias e implementación"
            },
            platform: {
                platform: "Plataforma",
                demonstaration: "Demostración",
                situationCenter: "Centro operativo",
                audience: "Análisis de la audiencia",
                scanner: "Escáner de vulnerabilidades"
            },
            courses: {
                courses: "Cursos",
                intelligence: "Inteligencia competitiva",
                trainings: "Capacitación empresarial"
            },
            safety: {
                safety: "Seguridad",
                economy: "Seguridad económica",
                support: "Apoyo legal",
                cyber: "Ciberseguridad"
            },
            public: {
                publicActivity: "Sala de prensa",
                publications: "Publicaciones",
                cyberDad: "CiberDed",
                tgChannels: "Canales de Telegram",
                social: "Redes sociales"
            }
        },
        horizontalBlock: {
            rights: "Todos los derechos reservados",
            name: `"THE AVALANCHE PULSE" LLC`,
            registry:
                "El software está registrado en el Registro Unificado de Programas Rusos para Computadoras Electrónicas y Bases de Datos",
            news: "Noticias destacadas",
            forBusiness: "Para negocios",
            platform: "Plataforma",
            safety: "Seguridad",
            publicActivity: "Sala de prensa",
            aboutus: "Quienes somos",
            learning: "Formación",
            contacts: "Contactos",
            confidential: "Política de Privacidad",
            agreement: "Acuerdo de procesamiento de datos personales",
            useConditions: "Términos de uso",
            oferta: "Oferta pública"
        }
    }
};

export default interfaceRu;
