import React, { useEffect } from "react";
import { useLanguage } from "../../../hooks/useLang";

// import SixCardsWithoutLink from "../pageBlocks/cardsBlocks/sixCardsWithoutLink";
import LargeImageTablet from "../LargeImageTablet";
// import LargeImageWithText from "../pageBlocks/largeImageWithText";
// import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const SubscribePageTablet = () => {
    const { content } = useLanguage();
    const image = content.aboutUs.imageUrls;
    // const pageName = content.platform.pageName;
    // const subMenuList = content.platform.subMenuList;
    // const cards = content.platform.sixCardsBlock;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://telegram.org/js/telegram-widget.js?21";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="mainPageTablet">
            <LargeImageTablet imageUrl={image.img1Mobile} />

            <h1>SUBSCRIBE</h1>
            {/* <script
                async
                // src="https://telegram.org/js/telegram-widget.js?21"
                data-telegram-post="react_tg/124"
                data-width="100%"
            ></script> */}
            <div
                style={{
                    height: 500 + "px",
                    overflow: "scroll"
                }}
            >
                <script data-telegram-post="maspartners/418"></script>
            </div>

            <br />
            <br />
        </div>
    );
};

export default SubscribePageTablet;
