import React, { useEffect, useState } from "react";
import { useLanguage } from "../hooks/useLang";

import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";
import axios from "axios";
import moment from "moment";

const URL_PUBS = "https://web.avl.team/api/site/publs/";
// const URL_SOCIAL = "https://web.avl.team/api/site/by_source/dz/";

const PublicationsPage = () => {
    const { content } = useLanguage();
    const pageName = content.publications.pageName;
    const subMenuList = content.publications.subMenuList;
    const imageUrls = content.publications.imageUrls;

    const [pubs, setPubs] = useState();
    const [filtredPubs, setFiltredPubs] = useState();
    const [isSearch, setSearch] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pubId, setPubId] = useState();

    useEffect(() => {
        axios
            .get(URL_PUBS, { params: { page: 0, per_page: 100 } })
            .then((res) =>
                setPubs(
                    res.data.items.sort((a, b) => {
                        return a.published_at > b.published_at ? -1 : 1;
                    })
                )
            )
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (pubs) {
            // console.log("pubs", pubs);
            setPubId(pubs[0].id);
            setFiltredPubs(pubs);
            // console.log(pubs);
        }
    }, [pubs]);

    const paginate = (action) => {
        if (action === "-") {
            setPageNum((prev) => (pageNum !== 1 ? prev - 1 : pageNum));
        }
        if (action === "+") {
            setPageNum((prev) =>
                pageNum !==
                Math.ceil(!isSearch ? pubs.length / 9 : filtredPubs.length / 9)
                    ? prev + 1
                    : pageNum
            );
        }
    };

    const prepareDate = (date) => {
        // console.log("date in Prepare Date", date);
        return moment.unix(date).format("L");
    };

    const prepareText = (text) => {
        const stage1 = text.replaceAll("<div>", "");
        const stage2 = stage1.replaceAll("</div>", ".");
        const stage3 = stage2.replaceAll("<br>", "\r\n\r\n");
        const stage4 = stage3.replaceAll("\r\n\r\n.", "\r\n\r\n");
        const result = stage4.replaceAll("..", ".").replaceAll("&nbsp;", "");
        return result;
    };

    const changePubId = (id) => {
        setPubId(id);
    };

    const handleChange = ({ target }) => {
        if (target.value) {
            setSearch(true);
        } else {
            setSearch(false);
        }
        const filtredPubs = pubs.filter((pub) =>
            pub.text.toLowerCase().includes(target.value.toLowerCase())
        );
        setFiltredPubs(filtredPubs);
    };
    const clearSearch = () => {
        const el = document.querySelector("#publicationSearch");
        el.value = "";
        setFiltredPubs(pubs);
        setSearch(false);
    };

    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <div className="publicationsPage-publications-back bborder tborder pb-60">
                <div className="publicationsPage-publications-search bborder">
                    <input
                        onChange={handleChange}
                        className="publicationsPage-publications-search-input bborder"
                        placeholder="Поиск..."
                        type="text"
                        name="publicationSearch"
                        id="publicationSearch"
                    />
                    {isSearch && (
                        <div
                            className="publicationsPage-publications-search-cancel cp imgBack"
                            onClick={() => clearSearch()}
                        ></div>
                    )}
                    <div className="publicationsPage-publications-search-loupe imgBack"></div>
                </div>

                <div className="publicationsPage-publications-headers-back">
                    {filtredPubs &&
                        filtredPubs
                            .slice(
                                pageNum !== 1 ? pageNum * 9 - 9 : pageNum - 1,
                                pageNum !== 1 ? pageNum * 9 : pageNum + 8
                            )
                            .map((header, index) => (
                                <div
                                    className={`publicationsPage-publications-headers-header ${
                                        index !== 8 && "bborder"
                                    }`}
                                    key={index}
                                >
                                    {pubId === header.id && (
                                        <div className="publicationsPage-publications-headers-header-arrow"></div>
                                    )}
                                    <div
                                        onClick={() => changePubId(header.id)}
                                        className="cp"
                                    >
                                        <div className="publicationsPage-publications-headers-header-title fs-16 ">
                                            {header.title}
                                        </div>
                                        <div className="publicationsPage-publications-headers-header-text fs-16 ">
                                            {prepareText(header.text).slice(
                                                0,
                                                100
                                            ) + "..."}
                                        </div>
                                    </div>
                                    <div className="publicationsPage-publications-headers-header-sourse">
                                        <a
                                            href={header.url}
                                            rel="noreferrer"
                                            className="publicationsPage-publications-headers-header-sourse-link imgBack"
                                            target="_blank"
                                        >
                                            {""}
                                        </a>
                                        <div className="publicationsPage-publications-headers-header-sourse-date">
                                            {prepareDate(header.published_at)}
                                        </div>
                                        <div className="publicationsPage-publications-headers-header-sourse-name">
                                            {header.domain}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    <div className="publicationsPage-publications-headers-pagination-back bborder tborder">
                        <div className="publicationsPage-publications-headers-pagination-total">
                            {`Всего: ${
                                filtredPubs &&
                                (!isSearch ? pubs.length : filtredPubs.length)
                            }`}
                        </div>
                        {filtredPubs && (
                            <div className="publicationsPage-publications-headers-pagination-change-back">
                                <div
                                    className="publicationsPage-publications-headers-pagination-leftarrow imgBack"
                                    onClick={() => paginate("-")}
                                    style={
                                        pageNum === 1
                                            ? { cursor: "default" }
                                            : { cursor: "pointer" }
                                    }
                                ></div>
                                <div className="publicationsPage-publications-headers-pagination-counter">
                                    <span
                                        style={{ fontWeight: "bold" }}
                                    >{`${pageNum} `}</span>
                                    {`/ ${Math.ceil(
                                        !isSearch
                                            ? pubs.length / 9
                                            : filtredPubs.length / 9
                                    )}`}
                                </div>
                                <div
                                    className="publicationsPage-publications-headers-pagination-rightarrow imgBack"
                                    onClick={() => paginate("+")}
                                    style={
                                        pageNum ===
                                        Math.ceil(
                                            !isSearch
                                                ? pubs.length / 9
                                                : filtredPubs.length / 9
                                        )
                                            ? { cursor: "default" }
                                            : { cursor: "pointer" }
                                    }
                                ></div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="publicationsPage-publications-publication-back">
                    <div className="publicationsPage-publications-publication">
                        {filtredPubs &&
                            filtredPubs.map(
                                (pub, index) =>
                                    pub.id === pubId && (
                                        <div
                                            className="publicationsPage-publications-publication-all "
                                            key={index * 15}
                                        >
                                            <div className="publicationsPage-publications-publication-sourse">
                                                <div className="publicationsPage-publications-publication-sourse-date">
                                                    {prepareDate(
                                                        pub.published_at
                                                    )}
                                                </div>

                                                <div className="publicationsPage-publications-publication-sourse-name">
                                                    {pub.domain}
                                                </div>
                                                <a
                                                    href={pub.url}
                                                    rel="noreferrer"
                                                    className="publicationsPage-publications-publication-sourse-link imgBack"
                                                    target="_blank"
                                                >
                                                    {""}
                                                </a>
                                            </div>
                                            <div className="publicationsPage-publications-publication-scroll">
                                                <div className="publicationsPage-publications-publication-title fs-16 cp">
                                                    {pub.title}
                                                </div>
                                                <div className="publicationsPage-publications-publication-text fs-16 cp">
                                                    {prepareText(pub.text)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicationsPage;
