import React from "react";
import { useLanguage } from "../hooks/useLang";

import CardsAdaptiveBlock from "../pageBlocks/cardsBlocks/cardsAdaptiveBlock";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";
document.querySelector("#id-75 > g > g:nth-child(2) > g > g:nth-child(2)");

const DemonstrationPage = () => {
    const { content } = useLanguage();
    const pageName = content.demonstration.pageName;
    const subMenuList = content.demonstration.subMenuList;
    const image = content.demonstration.imageUrls;
    const blocks = content.demonstration.blocks;
    const cards = content.demonstration.cards;

    const prepareFrame = () => {
        const frame = document.querySelector("#covidFrame");
        const frameEl = frame.contentWindow.document.querySelector(
            "#id-75>g>g:nth-child(2)>g>g:nth-child(2)"
        );
        console.log("frameEl", frameEl);
        frameEl.setAttribute("hidden", "");
    };
    setTimeout(() => {
        prepareFrame();
    }, 300);

    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={image.img1} />
            <CardsAdaptiveBlock
                blockContent={cards.block1}
                topBorderbBlock={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                marginTopLink={true}
                bottomBorderCard={true}
                paddingBottomBlock={true}
            />
            <HorizontalBlockAdaptive blockContent={blocks.PublicProjects} />
            <div
                className="iframe-description-back tborder pt-60 pb-40"
                style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <iframe
                    id="covidFrame"
                    title="demonstration"
                    src="https://covid19.avl.team/"
                    style={{
                        width: 70 + "%",
                        minHeight: 900 + "px"
                    }}
                    // scrolling="no"
                ></iframe>
                <div
                    className="description"
                    style={{
                        width: 30 + "%",
                        display: "flex",
                        paddingLeft: 85 + "px"
                    }}
                >
                    {blocks.iframe.textBlock}
                </div>
            </div>

            <br />
            <br />
        </div>
    );
};

export default DemonstrationPage;
