import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import CardsWithArrowsAdaptiveTablet from "../cardsBlocks/cardsWithArrowsAdaptiveTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"


const CommunicationsPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.communications.pageName;
    const subMenuList = content.communications.subMenuList;
    const imageUrls = content.communications.imageUrls;
    const blocks = content.communications.blocks;
    const cards = content.communications.cards;

    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet 
            blockContent={blocks.main} 
            
            bottomBorder={true}
            
            />
            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block1}
                arrowsDirection="left"
                topBorderbBlock={true}
                customHeightCommunicationsPageA={true}
                customWidth={true}
                topBorderCard={true}
               
                

            />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.defence} />
            <HorizontalBlockAdaptiveTablet blockContent=
            {blocks.reputation} 
            
            bottomBorder={true}
            />
            <CardsWithArrowsAdaptiveTablet
                // withImage={true}
                blockContent={cards.block2}
                arrowsDirection="right"
                // topBorderbBlock={true}
                topBorderCard={true}
                customHeightCommunicationsPageA={true}
                customWidth={true}
              
            />
            <HorizontalBlockAdaptiveTablet blockContent=
            {blocks.background} 
            
            bottomBorder={true}
            />
            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block3}
                arrowsDirection="left"
                // topBorderbBlock={true}
                customHeightCommunicationsPageB={true}
                customWidth={true}
                topBorderCard={true}
                communicationsHeightSizeForName={true}
            />
            <ContactUsTablet/>
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default CommunicationsPageTablet;
