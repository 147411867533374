import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/useLang";

const AskQuestion = () => {
    const [screenW, setScreenW] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setScreenW(window.innerWidth);
    });
    const { interfaceNames } = useLanguage();
    const blockNames = interfaceNames.question.block;
    return (
        <div
            id="askUsQuestion"
            className="askQuestion bborder pt-60 pb-60 "
        >
            <div className="askQuestion-name fs-40 pb-40">
                <div className="askQuestion-left-arrow imgBack "></div>
                <span className="askQuestion-name-name cp fs-64 ">
                    <Link to="/questionPage">{blockNames.name}</Link>
                </span>
            </div>
            <Link
                to="/questionPage"
                className="askQuestion-textBlock-link bborder fs-32 "
                style={
                    screenW > 1700
                        ? { width: 20 + "%", top: 50 + "px" }
                        : {
                              width: 20 + "%",
                              position: "absolute",
                              top: 30 + "px",
                              paddingBottom: 1 + "px"
                          }
                }
            >
                <div className="askQuestion-textBlock-link-name cp fs-32">
                    {blockNames.linkname}
                </div>
                <div className="askQuestion-textBlock-link-arrow imgBack cp"></div>
            </Link>
        </div>
    );
};

export default AskQuestion;
