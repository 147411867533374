import AllBack from "./allBack";
import CookiesPopup from "./pageBlocks/cookiesPopup";
import CookiesPopupTablet from "./tablet/pageBlocksTablet/pages/cookiesPopupTablet";
import { useEffect, useState } from "react";
import { LanguageProvider } from "./hooks/useLang";
import device from "current-device";
import AllBackMobile from "./allBackMobile";
import AllBackTablet from "./allBackTablet";

function App() {
    const [cookie, setHideCookie] = useState(false);
    // const [isMobile,setMobile]=useState(false)
    // console.log("device.mobile", device.mobile());
    useEffect(() => {
        const isCookie = localStorage.getItem("isCookie");
        if (isCookie) {
            setHideCookie(true);
        }
        // console.log("isCookie", isCookie);
    }, []);
    const handleCookie = () => {
        setHideCookie(true);
        localStorage.setItem("isCookie", true);
    }; // useEffect(() => {
    //     console.log("App war rendered!!!");
    // }, []);
    return (
        <>
            <LanguageProvider>
                {device.mobile() ? (
                    <AllBackMobile />
                ) : device.tablet() ? (
                    <AllBackTablet />
                ) : (
                    <AllBack />
                )}
                {!cookie && !device.tablet() && (
                    <CookiesPopup handleCookie={handleCookie} />
                )}
                {!cookie && device.tablet() && (
                    <CookiesPopupTablet handleCookie={handleCookie} />
                )}
            </LanguageProvider>
        </>
    );
}

export default App;
