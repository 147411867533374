import React from "react";
import { useLanguage } from "../hooks/useLang";
import CardsAdaptiveBlock from "../pageBlocks/cardsBlocks/cardsAdaptiveBlock";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
import SendRequest from "../pageBlocks/sendRequest";
// import content from "../textContent";

const LearningPage = () => {
    const { content } = useLanguage();
    const pageName = content.learning.pageName;
    const subMenuList = content.learning.subMenuList;
    const imageUrls = content.learning.imageUrls;
    const blocks = content.learning.blocks;
    const cards = content.learning.cards.block1;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            {/* <HorizontalBlockAdaptive blockContent={blocks.courses} />
            <HorizontalBlockAdaptive blockContent={blocks.academy} /> */}
            <HorizontalBlockAdaptive blockContent={blocks.service} />
            <HorizontalBlockAdaptive blockContent={blocks.corporate} />
            <CardsAdaptiveBlock
                blockContent={cards.cards}
                topBorderCard={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                paddingBottomBlock={true}
            />
            <SendRequest
                center={false}
                licence={false}
                learning={true}
            />
        </div>
    );
};

export default LearningPage;
