import React from "react";
import { useLocation } from "react-router";
import AskQuestion from "./footer/askQuestion";
import FooterHorizontalBlock from "./footer/footerHorizontalBlock";
import SiteMap from "./footer/siteMap";

const Footer = ({ handleTheme, handleLanguage }) => {
    const { pathname } = useLocation();
    return (
        <>
            {pathname !== `/documentation-avl-3.0/` && (
                <footer>
                    {pathname !== "/cookiesPage" &&
                        pathname !== "/useConditionsPage" &&
                        pathname !== "/personalDataAgreementPage" &&
                        pathname !== "/confidentionalPoliticPage" &&
                        pathname !== "/publicOfertaPage" &&
                        pathname !== "/learningPage" &&
                        pathname !== "/analyticsPage" &&
                        pathname !== "/tariffsPage" &&
                        pathname !== "/questionPage" &&
                        pathname !== "/requestPage" &&
                        pathname !== "/publicationsPage" && <AskQuestion />}
                    <SiteMap />
                    <FooterHorizontalBlock
                        handleTheme={handleTheme}
                        handleLanguage={handleLanguage}
                    />
                </footer>
            )}
        </>
    );
};

export default Footer;
