import React from "react";
import { Link } from "react-router-dom";

const ContactUsTablet = (blockContent,topBorder = false, bottomBorder = true) => {
    return (
        <div
            className={`contactUsTablet ${topBorder ? "tborder" : ""} ${
                bottomBorder ? "bborder" : ""
            } pt-40 pb-40`}
        >
            <div className="contactUsTablet-name-back fs-44">
             {/* <div
                            className="contactUsMobile-left-arrow imgBack "
                            style={
                                !blockContent.num 
                                    ? { top: 25 + "%" }
                                    : {}
                            }
                        ></div>  */}
         <div className="contactUsTabletBlockDropdown-left-arrow imgBack ">
         </div>

                <div className="contactUsTablet-name-name">
                    <Link to="/contactsPageMobile">Связаться с нами</Link>
                </div>
                {/* <div className="contactUsMobile-name-arrow imgBack"></div> */}
            </div>
             <div className="contactTablet-wrapper ml-10pers">
            <div className="contactUsTablet-link-back  w-295 ml-75pers  pl-570 fs-20">
                   
                    <Link className="horizontalBlockAdaptiveTablet-textBlock-link" 
                    to="/contactsPageMobile">Подробнее 
                    <div className="horizontalBlockAdaptiveTablet-textBlock-link-arrow imgBack">

                </div>
            
                </Link>
                
            </div>
            </div>
        </div>
    );
};

export default ContactUsTablet;
