import React, { useState } from "react";

const AgreementsBlock = ({
    blockContent,
    search = false,
    pagination = false
}) => {
    const [currentTextVersion, setTextVersion] = useState(
        blockContent.agreementDate[0].textVersion
    );
    return (
        <div className="agreementsBlock tborder bborder">
            <div className="agreementsBlock-itemsList-back pt-40 pb-60">
                <div className="agreementsBlock-itemsList-itemsBack rborder">
                    {blockContent.agreementDate.map((item, index) => (
                        <div
                            key={index}
                            className={`agreementsBlock-itemsList-item fs-16 cp ${
                                item.textVersion === currentTextVersion
                                    ? " bold "
                                    : " "
                            } ${
                                index === 0 ? "tborder bborder" : " bborder"
                            } mr-85`}
                            onClick={() => setTextVersion(item.textVersion)}
                        >
                            <span>{`${item.pointText} ${item.date}`}</span>
                            {item.textVersion === currentTextVersion && (
                                <div className="agreementsBlock-item-arrow imgBack"></div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="agreementsBlock-itemText-back pt-40 pb-60">
                <div className="agreementsBlock-itemText ">
                    <div className="agreementsBlock-itemText-innerText pl-85 pr-40">
                        {blockContent.agreementText[currentTextVersion]}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgreementsBlock;
