import React from "react";

const HorizontalBlockUnnamedTablet = ({ blockContent, bottomBorder = true}) => {
    return (
        <div
            className={`horizontalBlockUnnamedTablet  pt-40 pb-40 ${
                bottomBorder ? "bborder" : ""
            }`}
        >
            <div className="horizontalBlockUnnamedTablet-textBlock fs-30-30">
               {blockContent.textBlock}
            </div>
        </div>
    );
};

export default HorizontalBlockUnnamedTablet;
