import React from "react";

const HorizontalBlockUnnamed = ({ blockContent, bottomBorder = true }) => {
    return (
        <div
            className={`horizontalBlockUnnamed  pt-60 pb-60 ${
                bottomBorder ? "bborder" : ""
            }`}
        >
            <div className="horizontalBlockUnnamed-textBlock ">
                {blockContent.textBlock}
            </div>
        </div>
    );
};

export default HorizontalBlockUnnamed;
