import React from "react";
import { useLocation } from "react-router";

const PageHeadTablet = ({ pageName,heihtForName,publicActivityName,withoutHeight, withoutHeightSituationCenter}) => {
    const { pathname } = useLocation();

    // const[hideContent]=useState(true)
    // const [screenW, setScreenW] = useState(window.innerWidth);
    // window.addEventListener("resize", () => {
    //     setScreenW(window.innerWidth);
    // });
    // const setHigh = () => {
    //     if (screenW <1090 pathname === "/personalDataAgreementPage" ||
    //         pathname ==="/confidentionalPoliticPage") 
    //{ return hideContent
    // 
    //        ? {height:"199px"}:{height:"auto"}
    //     }
    // };

    return (
        <div className="pageHeadTablet">
            <div
                className={`pageHeadTablet-name fs-84 cd ${heihtForName ? "height-name":""}
                ${publicActivityName ? "name-cyberded":""}
                ${withoutHeight ? "withoutHeight":""}
                ${withoutHeightSituationCenter ? "withoutHeightForSmallSize" :""}
                `
               
            }
                style={
                    pathname === "/cookiesPage" ||
                    pathname === "/forBusinessPage"
                     
                        ? { paddingRight: "10%" }
                        : pathname === "/personalDataAgreementPage" ||
                        pathname ==="/confidentionalPoliticPage"
                        ? {
                              paddingRight: "10%",
                              fontSize: 84 + "px",
                              lineHeight: 84 + "px",
                              height:219+"px",
                              marginTop:40+"px"
                          }
                        : { paddingRight: "20%" }
                        //  ?{paddingLeft:"20px", paddingRight:"20px"}:""
                }
                 
                
            >
                {pageName}
            </div>
        </div>
    );
};

export default PageHeadTablet;
