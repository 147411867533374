import React from "react";
import { useLanguage } from "../../hooks/useLang";

import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";

const ContactsPageMobile = ({ darkTheme }) => {
    const { content } = useLanguage();

    const pageName = content.contacts.pageName;
    const contacts = content.contacts.blocks.contacts;
    return (
        <div
            className="mainPage"
            id="ContactsPageMap"
        >
            <PageHeadMobile pageName={pageName} />
            <YMaps query={{ lang: "en_RU" }}>
                <div
                    className="mt-20 mb-20 "
                    style={
                        darkTheme
                            ? {
                                  filter: "invert(1) hue-rotate(200deg)",
                                  //   filter: "grayscale(1) invert(1)",
                                  width: "100%",
                                  height: "409px"
                              }
                            : {
                                  filter: "invert(0) hue-rotate(0deg)",
                                  width: "100%",
                                  height: "409px"
                              }
                    }
                >
                    <Map
                        id="yandexMaps"
                        defaultState={{
                            center: [55.825482, 37.625513],
                            zoom: 13.5
                        }}
                        width="100%"
                        height="409px"
                        // style={
                        //     darkTheme
                        //         ? {
                        //               filter: "invert(1) hue-rotate(180deg)",
                        //               //   filter: "grayscale(1) invert(1)",
                        //               width: "100%",
                        //               height: "1000px"
                        //           }
                        //         : {
                        //               filter: "invert(0) hue-rotate(0deg)",
                        //               width: "100%",
                        //               height: "1000px"
                        //           }
                        // }
                    >
                        <Placemark
                            geometry={{
                                type: "Point",
                                coordinates: [55.825482, 37.625513]
                            }}
                            options={{
                                preset: darkTheme
                                    ? "islands#redDotIcon"
                                    : "islands#darkBlueDotIcon",
                                iconImageSize: [100, 100]
                            }}
                        />

                        {/* <Button
                            options={{
                                maxWidth: 528,
                                size: "auto",
                                position: { top: "60px", right: "60px" }
                            }}
                            data={{
                                content: (
                                    <span style={{ fontSize: "24px" }}>
                                        Москва, ул.Электродная, 11, к.203
                                    </span>
                                )
                            }}
                            defaultState={{
                                selected: false
                            }}
                        /> */}
                        <ZoomControl options={{ float: "right" }} />
                        {/* <div
                            style={{
                                color: "black",
                                fontSize: "12px",
                                fontWeight: "bold",
                                position: "absolute",
                                top: 40 + "px",
                                right: 40 + "px",
                                backgroundColor: "white",
                                padding: "10px 40px 10px 40px",
                                zIndex: 10
                            }}
                        >
                            55.825482, 37.625513
                        </div> */}
                    </Map>
                </div>
            </YMaps>
            <div className="contactsPageMobile-howToFind-back pb-40 pt-20 bborder tborder">
                <div className="contactsPageMobile-howToFind-name fs-32 mb-20">
                    {contacts.name}{" "}
                    <div className="contactsPageMobile-howToFind-arrow imgBack"></div>
                </div>
                <div className="contactsPageMobile-howToFind-data fs-14">
                    {contacts.textBlock2}
                </div>
            </div>
        </div>
    );
};

export default ContactsPageMobile;
