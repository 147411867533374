import React from "react";
import HorizontalBlockDropdownTablet from "../pageBlocksTablet/horizontalBlockDropdownTablet";
import LargeImageTablet from "../pageBlocksTablet/LargeImageTablet";

import HorizontalBlockAdaptiveTablet from "../pageBlocksTablet/horizontalBlockAdaptiveTablet";
//import content from "../../text/content/textContent"
import HorizontalBlockUnnamedTablet from "../pageBlocksTablet/horizontalBlockUnnamedTablet";
import CardsAdaptiveBlockTablet from "../pageBlocksTablet/cardsBlocks/cardsAdaptiveBlockTablet";
import { useLanguage } from "../../hooks/useLang";
import PageHeadTablet from "../pageHeadTablet/pageHeadTablet";
// import ContactUsPageMobile from "../../mobile/pagesMobile/ContactUsPageMobile";

// import FooterHorizontalBlockTablet from "../footerTablet/footerHorizontalBlockTablet";
// добавил из footer

import ContactUsTablet from "../footerTablet/contactUsTablet";

// import AskQuestion from "../../footer/askQuestion";
import SiteMapTablet from "../footerTablet/siteMapTablet";
const MainPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.mainPage.pageName;

    const imageUrls = content.mainPage.imageUrls;

    const forBusiness = content.mainPage.blocks.forBusiness;
    const safety = content.mainPage.blocks.safety;
    // const demonstration = content.mainPage.blocks.demonstration;
    // const sixCardsBlock = content.mainPage.blocks.sixCardsBlock;
    const platform = content.mainPage.blocks.platform;
    const situationCenter = content.mainPage.blocks.situationCenter;
    const analysis = content.mainPage.blocks.analysis;
    const vulnerabilitiesScan = content.mainPage.blocks.vulnerabilitiesScan;
    const analytics = content.mainPage.blocks.analytics;
    const unnamedBlock = content.mainPage.blocks.unnamedBlock;
    const aboutUs = content.mainPage.blocks.aboutUs;
    const shortBlock = content.mainPage.blocks.shortBlock;
    const cards = content.mainPage.blocks.cardsBlock.cards;
    return (
        <div
            className="mainPageTablet"
            style={{ maxWidth: "2000px", width: "100%" }}
        >
            <PageHeadTablet
                pageName={pageName}
                withoutHeightSituationCenter={true}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Tablet} />
            <HorizontalBlockAdaptiveTablet blockContent={forBusiness} />
            <HorizontalBlockAdaptiveTablet
                blockContent={safety}
                bottomBorder={true}
            />
            {/* <HorizontalBlockAdaptiveTablet
                blockContent={demonstration}
                bottomBorder={true}
            />    */}
            {/* <CardsAdaptiveBlockTablet
                blockContent={sixCardsBlock}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                bottomBorderCard={true}
            />   */}
            <LargeImageTablet imageUrl={imageUrls.img2Tablet} />
            <HorizontalBlockAdaptiveTablet blockContent={platform} />
            <HorizontalBlockDropdownTablet blockContent={situationCenter} />
            <HorizontalBlockDropdownTablet blockContent={analysis} />
            <HorizontalBlockDropdownTablet blockContent={vulnerabilitiesScan} />
            <HorizontalBlockDropdownTablet
                blockContent={analytics}
                customHeight={true}
            />
            <HorizontalBlockUnnamedTablet blockContent={unnamedBlock} />
            <LargeImageTablet imageUrl={imageUrls.img3Tablet} />
            <HorizontalBlockAdaptiveTablet blockContent={aboutUs} />
            <HorizontalBlockAdaptiveTablet
                blockContent={shortBlock}
                customName={true}
                bottomPadding={true}
                customNameBottom={true}
                topAuto={true}
                titileTabletForwidth={true}
            />
            <CardsAdaptiveBlockTablet /* картинки imgMobile*/
                blockContent={cards}
                marginTopNum={false}
                marginTopText={true}
                marginTopImg={true}
                heightMainPageForCardtext={true}
            />
            <ContactUsTablet />
            {/* <AskQuestion/> */}
            {/* <ContactUsPageMobile/>  */}
            <SiteMapTablet />
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default MainPageTablet;
