import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import CardsAdaptiveBlockTablet from "../cardsBlocks/cardsAdaptiveBlockTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import HorizontalBlockDropdownTablet from "../horizontalBlockDropdownTablet";
import LargeImageTablet from "../LargeImageTablet";

import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"


const PlatformPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.platform.pageName;
    const subMenuList = content.platform.subMenuList;
    const imageUrls = content.platform.imageUrls;
    const cards = content.platform.sixCardsBlock;
    const blocks = content.platform.blocks;
    // console.log("Lucky9",cards)
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} /> 
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.imageBlock}
                notAnyPoints={true}
                bottomBorder={true}
            />
            {/* <div id="PlatformPagePlatform"></div> */}
            <CardsAdaptiveBlockTablet blockContent={cards}
            paddingTopBlock={true}
            marginTopNum={true}
            marginTopName={true}
            marginTopText={true}
            topBorderCard={true}
            customHeight={true}
            customHeightPlatformCards={true}
            />
            <HorizontalBlockAdaptiveTablet 
            blockContent={blocks.ourProducts}
            customWidthPlatformAboutProducts={true} />
            <HorizontalBlockDropdownTablet blockContent={blocks.situationCenter} />
            <HorizontalBlockDropdownTablet blockContent={blocks.analysis} />
            <HorizontalBlockDropdownTablet blockContent={blocks.vulnerabilitiesScan} />
            <HorizontalBlockDropdownTablet 
             blockContent={blocks.analytics} 
             customHeight={true}
            />
            <ContactUsTablet/>
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default PlatformPageTablet;
