import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../hooks/useLang";

// import { HashLink } from "react-router-hash-link";

const DropdownMenuMobile = ({ dropdownMenuOpen, handeDropDownMenu }) => {
    const [currentPage, setCurrentPage] = useState();
    const location = useLocation();
    useEffect(() => {
        setCurrentPage(location.pathname);
    }, [location]);

    const colorPageName = (page) => {
        if (currentPage === page) {
            return { color: "#838383" };
        }
    };
    const { interfaceNames } = useLanguage();
    const buttons = interfaceNames.dropdownmenu.menu;
    const menumap = interfaceNames.dropdownmenu.menumap;

    return (
        <div
            className="dropdownMenuMobile ma open-close"
            style={
                !dropdownMenuOpen
                    ? {
                          transform: "scaleY(0)"
                      }
                    : {
                          transform: "scaleY(1)"
                      }
            }
            onMouseLeave={({ relatedTarget }) => {
                const menuEl = document.querySelector(".ma");
                if (relatedTarget !== menuEl && dropdownMenuOpen) {
                    handeDropDownMenu(false);
                }
            }}
        >
            <hr />
            <div className="dropdownMenuMobile-contacts">
                {/* <div className=" fs-12">
                    <a href="tel:+74993930015">+7 (499) 393-00-15</a>
                </div> */}
                <div className=" fs-12">
                    <a href="mailto:info@avl.team?subject=Email from mobile site">
                        info@avl.team
                    </a>
                </div>
            </div>
            <hr />
            <div className="dropdownMenuMobile-point">
                <div className="dropdownMenuMobile-point-left-arrow imgBack"></div>
                <div
                    className="dropdownMenuMobile-point-menu-point fs-20 mr-20"
                    onClick={() => handeDropDownMenu()}
                >
                    <Link
                        to="/"
                        style={colorPageName("/")}
                    >
                        {buttons.main}
                    </Link>
                </div>
                <div className="dropdownMenu-point-right-arrow imgBack"></div>
            </div>
            <hr />
            <div className="dropdownMenuMobile-point">
                <div className="dropdownMenuMobile-point-left-arrow imgBack"></div>
                <div
                    className="dropdownMenuMobile-point-menu-point fs-20 mr-20"
                    onClick={() => handeDropDownMenu()}
                >
                    <Link
                        to="/platformPage"
                        style={colorPageName("/platformPage")}
                    >
                        {buttons.platform}
                    </Link>
                </div>
                <div className="dropdownMenu-point-right-arrow imgBack"></div>
            </div>
            <hr />
            <div className="dropdownMenuMobile-point">
                <div className="dropdownMenuMobile-point-left-arrow imgBack"></div>
                <div
                    className="dropdownMenuMobile-point-menu-point fs-20 mr-20"
                    onClick={() => handeDropDownMenu()}
                >
                    <Link
                        to="/forBusinessPage"
                        style={colorPageName("/forBusinessPage")}
                    >
                        {buttons.forBusiness}
                    </Link>
                </div>
                <div className="dropdownMenu-point-right-arrow imgBack"></div>
            </div>
            <hr />
            <div className="dropdownMenuMobile-point">
                <div className="dropdownMenuMobile-point-left-arrow imgBack"></div>
                <div
                    className="dropdownMenuMobile-point-menu-point fs-20 mr-20"
                    onClick={() => handeDropDownMenu()}
                >
                    <Link
                        to="/safetyPage"
                        style={colorPageName("/safetyPage")}
                    >
                        {buttons.safety}
                    </Link>
                </div>
                <div className="dropdownMenu-point-right-arrow imgBack"></div>
            </div>
            <hr />
            <div className="dropdownMenuMobile-point">
                <div className="dropdownMenuMobile-point-left-arrow imgBack"></div>
                <div
                    className="dropdownMenuMobile-point-menu-point fs-20 mr-20"
                    onClick={() => handeDropDownMenu()}
                >
                    <Link
                        to="/contactsPage"
                        style={colorPageName("/contactsPage")}
                    >
                        {buttons.contacts}
                    </Link>
                </div>
                <div className="dropdownMenu-point-right-arrow imgBack"></div>
            </div>
            <hr />
            <div className="dropdownMenu-siteMap">
                <div className="dropdownMenuMobile-siteMap-leftBlock">
                    <div className="dropdownMenu-siteMap-blockBack mt-40">
                        <div className="dropdownMenu-siteMap-blockName">
                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt">
                                <Link
                                    to="/audiencePage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.audience}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />
                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt">
                                <Link
                                    to="/analyticsPage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.analytics}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />

                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt">
                                <Link
                                    to="/scannerPage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.scanner}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />
                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt">
                                <Link
                                    to="/situationCenterPage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.center}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />
                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt">
                                <Link
                                    to="/communicationsPage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.communications}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />
                        </div>
                    </div>
                </div>

                <div className="dropdownMenuMobile-siteMap-rightBlock">
                    <div className="dropdownMenu-siteMap-blockBack mt-40">
                        <div className="dropdownMenu-siteMap-blockName">
                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt">
                                <Link
                                    to="/learningPage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.courses}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />
                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt cp">
                                <Link
                                    to="/aboutUsPage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.aboutUs}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />
                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt cp">
                                <Link
                                    to="/publicActivityPage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.publicAvtivity}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />
                            <div className="dropdownMenuMobile-siteMap-blockPoint fs-12 pt cls cp">
                                <Link
                                    to="/publicationsPage"
                                    onClick={() => handeDropDownMenu()}
                                >
                                    {menumap.publications}
                                </Link>
                            </div>
                            <hr className="dropdownMenuMobile-siteMap-blockPoint-hr" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="dropdownMenu-bottom-hr mt-40" />
        </div>
    );
};

export default DropdownMenuMobile;
