import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";
import HorizontalBlockUnnamedMobile from "../pageBlocksMobile/horizontalBlockUnnamedMobile";

const PlatformPageMobile = () => {
    const { content, currentLang } = useLanguage();
    const pageName = content.platform.pageName;
    const imageUrls = content.platform.imageUrls;
    const cards = content.platform.cardsBlock;
    const blocks = content.platform.blocks;
    return (
        <div className="mainPageMobile">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile
                imageUrl={imageUrls.img1Mobile}
                bottomBorder={true}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.imageBlock}
                textBlockWidth={100}
                leftIndent={false}
                classicType={true}
                topBorder={false}
            />
            <CardsSwipeMobile
                blockContent={cards}
                customMinTextHeight={
                    (currentLang === "fr" && window.innerWidth < 360) ||
                    (currentLang === "es" && window.innerWidth < 360)
                        ? "250"
                        : currentLang === "fr" || currentLang === "es"
                        ? "210"
                        : window.innerWidth < 360
                        ? "230"
                        : "180"
                }
                customMinNameHeight={currentLang === "fr" ? "120" : ""}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.ourProducts}
                bottomBorder={false}
                alternativeType={true}
                altTypeFontSize="32"
                leftIndent={false}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.situationCenter}
                lowName={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.analysis}
                lowName={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.vulnerabilitiesScan}
                lowName={true}
                doubleLineName={true}
                customHeight={window.innerWidth < 360 ? 380 : 350}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.analytics}
                lowName={true}
            />
        </div>
    );
};

export default PlatformPageMobile;
