import React, { useState } from "react";
import { useLanguage } from "../../hooks/useLang"

const ChangeLangTablet = () => {
    const { currentLang, langList, handleCurrentLang } = useLanguage();
    const [langListOpen, setListOpen] = useState(false);
    // console.log("currentLang in lang component", currentLang);
    // console.log("langList in lang component", langList);
    const handleListOpen = () => {
        setListOpen(!langListOpen);
    };
    const handleMenuItemClick = (item) => {
        handleListOpen();
        handleCurrentLang(item.toLowerCase());
    };
    return (
        <div
            className="changeLangTablet-back fs-12"
            style={{ zIndex: 3000}}
        >
            <span style={{ fontWeight: "bold" ,width:17+"px"}}>
                {currentLang && currentLang.toUpperCase()}
            </span>
            <span>|</span>
          
            <div  
                className="changeLangTablet-image imgBack "
              
                onClick={() => handleListOpen()}
            ></div>
            <div
                className="changeLangTablet-dropdownList fs-14 open-close"
                style={
                    langListOpen
                        ? { transform: "scaleY(1)" }
                        : { transform: "scaleY(0)" }
                }
            >
                {langList &&
                    langList
                        .filter((item) => item !== currentLang)
                        .map((item, index) => (
                            <div
                                key={index}
                                className={`changeLangTablet-dropdownList-item ${
                                    index !== langList.length - 2
                                        ? "bborder"
                                        : ""
                                }`}
                                onClick={() => handleMenuItemClick(item)}
                            >
                                {item.toUpperCase()}
                            </div>
                        ))}
            </div>
        </div>
    );
};

export default ChangeLangTablet;
