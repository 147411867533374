import { setCookie } from "./cookieHandler";
const body = document.querySelector("body");

export const setToDarkTheme = () => {
    setCookie("avl4site_theme", "dark", {
        expires: new Date("2025-01-01 00:00:01"),
        domain: "avl.team",
        samesite: "lax"
    });
    body.style.color = "white";
    body.style.setProperty("--hrLineColor", "white");
    body.style.setProperty("--logoUrl", "url(../img/menu/logoDark.svg)");
    body.style.setProperty(
        "--logoMobile",
        "url(../img/menu/logoMobileDark.svg)"
    );
    body.style.setProperty(
        "--changeTheme",
        "url(../img/menu/changeThemeDark.svg)"
    );
    body.style.setProperty(
        "--changeLang",
        "url(../img/menu/changeLangDark.svg)"
    );
    body.style.setProperty(
        "--menuBurger",
        "url(../img/menu/menuBurgerDark.svg)"
    );
    body.style.setProperty(
        "--menuBurgerOpen",
        "url(../img/menu/menuBurgerCloseDark.svg)"
    );
    body.style.setProperty(
        "--menuRightArrow",
        "url(../img/menu/dropdownMenu-point-right-arrowDark.svg)"
    );
    body.style.setProperty(
        "--menuLeftArrow",
        "url(../img/menu/dropdownMenu-point-left-arrowDark.svg)"
    );
    body.style.setProperty(
        "--subMenuOpen",
        "url(../img/pageHead/pageHead-sideSlideMenuBack-opened-arrowDark.svg)"
    );
    body.style.setProperty(
        "--HorizontalBlockOpen",
        "url(../img/horizontalBlock/horizontalBlockDropdown-openDark.svg)"
    );
    body.style.setProperty(
        "--HorizontalBlockClose",
        "url(../img/horizontalBlock/horizontalBlockDropdown-closeDark.svg)"
    );
    body.style.setProperty(
        "--gradientColor",
        "linear-gradient(to right, transparent, #1E1E1E)"
    );
    body.style.setProperty(
        "--cardsRightArrow",
        "url(../img/cardsWithArrowsRight/fourCardsArrowsRight-arrows-arrow-rightDark.svg)"
    );
    body.style.setProperty("--Ok", "url(../Ok-dark.svg)");
    body.style.setProperty("--ExternalLink", "url(../External_Link-dark.svg)");

    body.style.setProperty("--NotOk", "url(../NotOk-dark.svg)");

    body.style.setProperty(
        "--paginationLeftArrow",
        "url(../paginate-leftarrow-dark.svg)"
    );
    body.style.setProperty(
        "--paginationRightArrow",
        "url(../paginate-rightarrow-dark.svg)"
    );
    body.style.setProperty("--changeLang", "url(../ChangeLang-dark.svg)");

    body.style.setProperty("--bgColor", "#1E1E1E");

    body.style.backgroundColor = "#1E1E1E";
};
export const setToLightTheme = () => {
    setCookie("avl4site_theme", "light", {
        expires: new Date("2025-01-01 00:00:01"),
        domain: "avl.team",
        samesite: "lax"
    });
    body.style.color = "black";
    body.style.setProperty("--hrLineColor", "black");
    body.style.setProperty("--logoUrl", "url(../img/menu/logo.svg)");
    body.style.setProperty("--logoMobile", "url(../img/menu/logoMobile.svg)");
    body.style.setProperty("--changeTheme", "url(../img/menu/changeTheme.svg)");
    body.style.setProperty("--changeLang", "url(../img/menu/changeLang.svg)");
    body.style.setProperty("--menuBurger", "url(../img/menu/menuBurger.svg)");
    body.style.setProperty(
        "--menuBurgerOpen",
        "url(../img/menu/menuBurgerClose.svg)"
    );
    body.style.setProperty(
        "--menuRightArrow",
        "url(../img/menu/dropdownMenu-point-right-arrow.svg)"
    );
    body.style.setProperty(
        "--menuLeftArrow",
        "url(../img/menu/dropdownMenu-point-left-arrow.svg)"
    );
    body.style.setProperty(
        "--subMenuOpen",
        "url(../img/pageHead/pageHead-sideSlideMenuBack-opened-arrow.svg)"
    );
    body.style.setProperty(
        "--HorizontalBlockOpen",
        "url(../img/horizontalBlock/horizontalBlockDropdown-open.svg)"
    );
    body.style.setProperty(
        "--HorizontalBlockClose",
        "url(../img/horizontalBlock/horizontalBlockDropdown-close.svg)"
    );
    body.style.setProperty(
        "--gradientColor",
        "linear-gradient(to right, transparent, white)"
    );
    body.style.setProperty(
        "--cardsRightArrow",
        "url(../img/cardsWithArrowsRight/fourCardsArrowsRight-arrows-arrow-right.svg)"
    );
    body.style.setProperty("--Ok", "url(../Ok.svg)");
    body.style.setProperty("--NotOk", "url(../NotOk.svg)");
    body.style.setProperty(
        "--paginationLeftArrow",
        "url(../paginate-leftarrow.svg)"
    );
    body.style.setProperty(
        "--paginationRightArrow",
        "url(../paginate-rightarrow.svg)"
    );
    body.style.setProperty("--changeLang", "url(../ChangeLang.svg)");

    body.style.setProperty("--ExternalLink", "url(../External_Link.svg)");

    body.style.setProperty("--bgColor", "white");
    body.style.backgroundColor = "white";
};
