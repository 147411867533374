const interfaceRu = {
    menubar: {
        news: "Top news of the moment"
    },
    dropdownmenu: {
        menu: {
            main: "Main",
            platform: "Platform",
            forBusiness: "For Business",
            safety: "Security",
            demonstration: "Demo",
            contacts: "Contacts"
        },
        menumap: {
            audience: "Audience Analysis",
            analytics: "Analytics",
            courses: "Courses",
            scanner: "Vulnerability Scan",
            center: "Situation Center",
            communications: "Communications",
            aboutUs: "About Us",
            publicAvtivity: "Public Activities",
            publications: "Publications"
        }
    },
    question: {
        block: { name: "Ask a question", linkname: "More" },
        page: {
            name: "Full name",
            llc: "Enterprise",
            phonenum: "Telephone",
            textarea: "Question (max. 1000 letters)",
            requiered: "mandatory fields",
            agreement: {
                beforeLink: "I have read and accept",
                link: "the Terms",
                afterLink: "of the Agreement"
            },
            send: "Send",
            back: "Back",
            success: "Your request has been sent successfully",
            error: {
                line1: "There was an error sending the request.",
                line2: "Please try later or email us at info@avl.team",
                line3: "Sorry for the inconvenience"
            },
            tryAgain: "Try again",
            returnLink: "Main"
        }
    },
    request: {
        block: { name: "Send a request", linkname: "More" },
        page: {
            name: "Full name",
            llc: "Enterprise",
            phonenum: "Telephone",
            requiered: "mandatory fields",
            agreement: {
                beforeLink: "I have read and accept",
                link: "the Terms",
                afterLink: "of the Agreement"
            },
            send: "Send",
            back: "Back",
            success: "Your request has been sent successfully",
            error: {
                line1: "There was an error sending the request.",
                line2: "Please try later or email us at info@avl.team",
                line3: "Sorry for the inconvenience"
            },
            tryAgain: "Try again",
            returnLink: "Main",
            checkboxes: {
                center: "Virtual Situation Center",
                licence: "Licenses and Implementation",
                analytics: "Analytics",
                courses: "Courses"
            },
            imageLinks: {
                center: "Virtual Situation Center",
                licence: "Licenses and Implementation",
                analytics: "Analytics",
                courses: "Courses"
            }
        }
    },
    captcha: "To send a request, choose the right answer",
    footer: {
        sitemap: {
            aboutUs: {
                aboutus: "About Us",
                creator: "Founder",
                mission: "Our Mission",
                activity: "Activities",
                contacts: "Contacts"
            },
            forBusiness: {
                forbusiness: "For Business",
                center: "Virtual Situation Center",
                communications: "Communications",
                analytics: "Services and Analytics",
                licence: "Licenses and Implementation"
            },
            platform: {
                platform: "Platform",
                demonstaration: "Demo",
                situationCenter: "Situation Center",
                audience: "Audience Analysis",
                scanner: "Vulnerability Scan"
            },
            courses: {
                courses: "Courses",
                intelligence: "Competitive Intelligence",
                trainings: "Corporate Trainings"
            },
            safety: {
                safety: "Security",
                economy: "Economic Security",
                support: "Corporate Trainings",
                cyber: "Cybersecurity"
            },
            public: {
                publicActivity: "Public Activities",
                publications: "Publications",
                cyberDad: "CyberDed",
                tgChannels: "Telegram Channels",
                social: "Social"
            }
        },
        horizontalBlock: {
            rights: "All Rights Reserved",
            name: `"THE AVALANCHE PULSE" LLC`,
            registry:
                "The software is registered in the Unified Register of Russian Programs for Electronic Computers and Databases",
            news: "Top news of the moment",
            forBusiness: "For Business",
            platform: "Platform",
            safety: "Security",
            publicActivity: "Public Activities",
            aboutus: "About Us",
            learning: "Training",
            contacts: "Contacts",
            confidential: "Privacy Policy",
            agreement: "Personal Data Processing Agreement",
            useConditions: "Terms of Use",
            oferta: "Public Offer"
        }
    }
};

export default interfaceRu;
