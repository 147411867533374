import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../hooks/useLang";

import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet";

import axios from "axios";

// import content from "../textContent";
import CardsWithArrowsAdaptiveTablet from "../cardsBlocks/cardsWithArrowsAdaptiveTablet";
// import { Link } from "react-router-dom";

const URL_PUBS = "https://web.avl.team/api/site/publs/";
// const URL_SOCIAL = "https://web.avl.team/api/site/by_source/";

// const regExp = /[а-яА-Я0-9]/g;

const PublicActivityPageTablet = () => {
    const { content } = useLanguage();
    const linkNameLang = content.mainPage.blocks.sixCardsBlock[0].linkName;
    const pageName = content.publicActivity.pageName;
    const subMenuList = content.publicActivity.subMenuList;
    const imageUrls = content.publicActivity.imageUrls;
    const blocks = content.publicActivity.blocks;

    const [pubs, setPubs] = useState({ cards: [] });
    // const [mp, setMp] = useState();
    // const [mi, setMi] = useState();
    // const [vk, setVk] = useState();
    // const [dz, setDz] = useState();
    // const [dz2, setDz2] = useState();

    // const getSocialData = (sourse) => {
    //     const sourses = {
    //         mp: "mp/",
    //         mi: "mi/",
    //         vk: "vk/",
    //         dz: "dz/",
    //         dz2: "dz2/"
    //     };
    //     const setFunctions = {
    //         mp: setMp,
    //         mi: setMi,
    //         vk: setVk,
    //         dz: setDz,
    //         dz2: setDz2
    //     };

    //     const URL = URL_SOCIAL + sourses[sourse];
    //     axios
    //         // для ВК выгружаем последние 50 постов для проверки
    //         .get(URL, {
    //             params: { page: 0, per_page: sourse === "vk" ? 50 : 5 }
    //         })
    //         // если в тексте поста присутствует слово размещен,такой пост обрезается
    //         .then((res) =>
    //             setFunctions[sourse](
    //                 sourse !== "vk"
    //                     ? res.data.items
    //                     : res.data.items
    //                           .filter((item) => !item.text.includes("Размещен"))
    //                           .slice(0, 5)
    //             )
    //         )
    //         .catch((err) => console.log(err));
    // };

    useEffect(() => {
        axios
            .get(URL_PUBS, { params: { page: 0, per_page: 4 } })
            .then((res) =>
                setPubs((prev) => ({
                    ...prev,
                    cards: res.data.items
                        .sort((a, b) => {
                            return a.published_at > b.published_at ? -1 : 1;
                        })
                        .map((item, index) => ({
                            num: index + 1,
                            name: item.title,
                            // text: item.text.slice(5, 100),
                            linkName: linkNameLang,
                            externalLink: item.url
                        }))
                }))
            )
            .catch((err) => console.log(err));
        // getSocialData("mp");
        // getSocialData("mi");
        // getSocialData("vk");
        // getSocialData("dz");
        // getSocialData("dz2");
    }, [linkNameLang]);
    // useEffect(() => {
    //     console.log("mi", mi);
    //     console.log("vk", vk);
    //     console.log("dz", dz);
    //     console.log("dz2", dz2);
    // }, [mp, mi]);
    // useEffect(() => {
    //     if (pubs) {
    //         console.log(pubs);
    //     }
    // }, [pubs]);
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
                publicActivityName={true}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.publications}
                bottomBorder={true}
            />
            {pubs.cards[0] && (
                <CardsWithArrowsAdaptiveTablet
                    blockContent={pubs}
                    arrowsDirection="left"
                    topBorderbBlock={true}
                    topBorderCard={true}
                    customLinkArrowMarginTop={true}
                    publicHeightSizeForAdaptive={true}
                />
            )}
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.cyberDad}
                customNameWidthPuublicActivityPage={true}
                titileTabletForwidth={true}
            />

            {/* BLOCK IMAGES WITH ARROWS LEFT */}
            <div className="publicActivityTablet-images-with-arrows-back pt-40 pb-40 tborder">
                {window.innerWidth > 800 ? (
                    <div className="publicActivityTablet-arrows-back ">
                        <div className="publicActivityTablet-arrows arr1">
                            <div className="publicActivityTablet-arrows-arrow imgBack"></div>
                            <div className="publicActivityTablet-arrows-arrow imgBack mt-10"></div>
                            <div className="publicActivityTablet-arrows-arrow imgBack mt-10"></div>
                        </div>
                        <div className="publicActivityTablet-arrows arr2">
                            <div className="publicActivityTablet-arrows-arrow imgBack"></div>
                            <div className="publicActivityTablet-arrows-arrow imgBack mt-10"></div>
                            <div className="publicActivityTablet-arrows-arrow imgBack mt-10"></div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="publicActivityTablet-arrows-back ">
                            <div className="publicActivityTablet-arrows-arrow imgBack"></div>
                            <div className="publicActivityTablet-arrows-arrow imgBack mt-10"></div>
                            <div className="publicActivityTablet-arrows-arrow imgBack mt-10"></div>
                        </div>
                    </div>
                )}
                <div className="publicActivityTablet-image-back  pr-63 pl-62">
                    <img
                        className="publicActivityTablet-image-image"
                        src={imageUrls.middle1}
                        alt="error"
                    />
                </div>

                <div className="publicActivityTablet-image-back lborder pl-62 pr-63">
                    <img
                        className="publicActivityTablet-image-image"
                        src={imageUrls.middle2}
                        alt="error"
                    />
                </div>
                <div className="publicActivityTablet-image-back  lborder pl-62">
                    <img
                        className="publicActivityTablet-image-image"
                        src={imageUrls.middle3}
                        alt="error"
                    />
                </div>
            </div>
            {/* <HorizontalBlockAdaptiveTablet
                blockContent={blocks.tg}
                onlyName={true}
                customNameWidthPuublicActivityPage={true}
            /> */}
            {/* BLOCK WITH TG CHANNELS */}
            {/* <div className="publicActivityTablet-telegram-with-arrows-back  pb-40 ">
                <div className="publicActivityTablet-telegram-channel-back pr-63 ">
                    <div className="publicActivity-telegram-channel">
                        <div className="publicActivityTablet-telegram-channel-name tborder bborder">
                            <span className="fs-20">{tg.channel1}</span>
                            <Link
                                to={{ pathname: tg.channel1Link }}
                                target="_blank"
                                className="publicActivityTablet-telegram-channel-name-link"
                            ></Link>
                        </div>
                        {mp &&
                            mp.map((item) => (
                                <div
                                    className="publicActivityTablet-telegram-channel-post bborder"
                                    key={Math.random() * 40}
                                >
                                    <div className="publicActivityTablet-telegram-channel-post-text">
                                        {/* обрезаем эмоджи в начале поста в ТГ */}
            {/* {item.text[0].match(regExp)
                                            ? item.text.slice(0, 100)
                                            : item.text.slice(2, 101)}
                                        {"..."}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivityTablet-telegram-channel-post-arrow imgBack"
                                    ></Link>
                                </div>
                            ))}
                    </div>
                </div> */}
            {/* // <div className="publicActivityTablet-telegram-channel-back pr-63 pl-62 ">
                //     <div className="publicActivity-telegram-channel">
                //         <div className="publicActivityTablet-telegram-channel-name tborder bborder">
                //             <span className="fs-20">{tg.channel2}</span>
                //             <Link */}
            {/* to={{ pathname: tg.channel2Link }}
                             target="_blank"
                               className="publicActivityTablet-telegram-channel-name-link"
                           ></Link> */}
            {/* //         </div> */}
            {/* {mi &&
                            mi.map((item) => (
                                <div
                                    className="publicActivityTablet-telegram-channel-post bborder"
                                    key={Math.random() * 50}
                                >
                                    <div className="publicActivityTablet-telegram-channel-post-text">
                                        {/* обрезаем эмоджи в начале поста в ТГ */}
            {/* {item.text[0].match(regExp)
                                            ? item.text.slice(0, 100)
                                            : item.text.slice(2, 101)}
                                        {"..."}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivityTablet-telegram-channel-post-arrow imgBack"
                                    ></Link>
                                </div>
                            )
                    </div>
                </div>  */}

            {/* <div className="publicActivityTablet-telegram-arrows-back ">
                    <div className="publicActivity-telegram-arrows">
                        <div className="publicActivityTablet-telegram-arrows-arrow imgBack"></div>
                        <div className="publicActivityTablet-telegram-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivityTablet-telegram-arrows-arrow imgBack mt-10"></div>
                    </div>
                    <div className="publicActivityTablet-telegram-arrows">
                        <div className="publicActivityTablet-telegram-arrows-arrow imgBack"></div>
                        <div className="publicActivityTablet-telegram-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivityTablet-telegram-arrows-arrow imgBack mt-10"></div>
                    </div>
                </div>
            </div> */}
            {/* <HorizontalBlockAdaptiveTablet
                blockContent={blocks.social}
                onlyName={true}
                customNameWidthPuublicActivityPage={true}
            /> */}
            {/* BLOCK WITH SOCIAL NETWORKS CHANNELS */}
            {/* <div className="publicActivityTablet-social-with-arrows-back  pb-40 ">
                <div className="publicActivityTablet-social-arrows-back "> */}
            {/* <div className="publicActivity-social-arrows">
                        <div className="publicActivityTablet-social-arrows-arrow imgBack"></div>
                        <div className="publicActivityTablet-social-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivityTablet-social-arrows-arrow imgBack mt-10"></div>
                    </div> */}
            {/* <div className="publicActivity-social-arrows">
                        <div className="publicActivityTablet-social-arrows-arrow imgBack"></div>
                        <div className="publicActivityTablet-social-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivityTablet-social-arrows-arrow imgBack mt-10"></div>
                    </div> */}
            {/* </div>
                <div className="publicActivityTablet-social-channel-back pr-63 pl-62">
                    <div className="publicActivity-social-channel">
                        <div className="publicActivityTablet-social-channel-name tborder bborder">
                            <span className="fs-20">{social.channel1}</span>
                            <Link
                                to={{ pathname: social.channel1Link }}
                                target="_blank"
                                className="publicActivityTablet-social-channel-name-link"
                            ></Link>
                        </div> */}
            {/* {dz &&
                            dz.map((item, index) => (
                                <div
                                    className="publicActivityTablet-social-channel-post bborder"
                                    key={Math.random() * 20}
                                >
                                    <div className="publicActivityTablet-social-channel-post-text">
                                        {item.title.slice(0, 100)}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivityTablet-social-channel-post-arrow imgBack"
                                    ></Link>
                                </div>
                            ))}
                    </div> */}
            {/* </div>
                <div className="publicActivityTablet-social-channel-back pr-63 pl-62 ">
                    <div className="publicActivity-social-channel"> */}
            {/* <div className="publicActivityTablet-social-channel-name tborder bborder">
                            <span className="fs-20">{social.channel2}</span>
                            <Link
                                to={{ pathname: social.channel2Link }}
                                target="_blank"
                                className="publicActivityTablet-social-channel-name-link"
                            ></Link>
                        </div> */}
            {/* {vk &&
                            vk.map((item) => (
                                <div
                                    className="publicActivityTablet-social-channel-post bborder"
                                    key={Math.random() * 10}
                                >
                                    <div className="publicActivityTablet-social-channel-post-text">
                                        {/* обрезаем эмоджи в начале поста в ТГ */}
            {/* {item.text[0].match(regExp)
                                            ? item.text.slice(0, 100)
                                            : item.text.slice(2, 101)}
                                        {"..."} */}
            {/* </div> */}
            {/* <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivityTablet-social-channel-post-arrow imgBack"
                                    ></Link> */}
            {/* </div>
                            ))}
                    </div>
                </div> */}

            {/* <div className="publicActivityTablet-social-channel-back pl-62 ">
                    <div className="publicActivity-social-channel">
                        <div className="publicActivityTablet-social-channel-name tborder bborder">
                            <span className="fs-20">{social.channel3}</span>
                            <Link
                                to={{ pathname: social.channel3Link }}
                                target="_blank"
                                className="publicActivityTablet-social-channel-name-link"
                            ></Link>
                        </div>
                        {dz2 &&
                            dz2.map((item) => (
                                <div
                                    className="publicActivityTablet-social-channel-post bborder"
                                    key={Math.random() * 30}
                                >
                                    <div className="publicActivityTablet-social-channel-post-text">
                                        {item.title.slice(0, 100)}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivityTablet-social-channel-post-arrow imgBack"
                                    ></Link>
                                </div>
                            ))}
                    </div>
                </div> */}
            {/* </div> */}
            <ContactUsTablet />
            <SiteMapTablet />
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default PublicActivityPageTablet;
