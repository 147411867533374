import React, { useState } from "react";

const AgreementsBlockTablet = ({
    blockContent,
    search = false,
    pagination = false,
    topBorderCard=false
}) => {
    const [currentTextVersion, setTextVersion] = useState(
        blockContent.agreementDate[0].textVersion
        
    );


 console.log("Lucky",  blockContent.agreementDate)

    return (
        <div className="agreementsBlockTablet tborder bborder">
            <div className={`agreementsBlockTablet-itemsList-back pt-40 pb-40`}>    
                <div className="agreementsBlockTablet-itemsList-itemsBack pr-63 rborder">
                    <div className="bborder h-full ">
                    {blockContent.agreementDate.map((item, index) => (
                        <div
                            key={index}
                            className={`agreementsBlockTablet-itemsList-item fs-14 cp ${
                                item.textVersion === currentTextVersion
                                    ? " bold "
                                    : " "
                            } ${
                                index === 0 ? "tborder bborder" : " bborder"
                            } `}
                            onClick={() => setTextVersion(item.textVersion)}
                        >
                            <span>{`${item.pointText} ${item.date}`}</span>
                            {item.textVersion === currentTextVersion && (
                                <div className="agreementsBlockTablet-item-arrow imgBack"></div>
                            )}
                        </div>
                    ))}
                    </div>
                </div>

            </div>
            <div className="agreementsBlockTablet-itemText-back f-14 pt-40 pb-40">
                <div className="agreementsBlockTablet-itemText fs-14 pl-62 pr ">
                    <div className="agreementsBlockTablet-itemText-innerText bborder">
                        {blockContent.agreementText[currentTextVersion]}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgreementsBlockTablet;
