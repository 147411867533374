import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../hooks/useLang";
// import ChangeLang from "../../pageBlocks/changeLang";
import ChangeLangTablet from "../pageBlocksTablet/changeLangTablet";
import moment from "moment";

const FooterHorizontalBlockTablet = ({ handleTheme, handleLanguage }) => {
    const { interfaceNames } = useLanguage();
    const blockNames = interfaceNames.footer.horizontalBlock;
    // console.log("lucky",blockNames.registry );

    // console.log("currentLang in FooterHorizonBlock", currentLang);
    return (
        <div className="ma w-full pl-20 pr-20">
            <div className="footerHorizontalBlockTablet-footer pt-40 pb-40">
                <div className="footerHorizontalBlock-footer-rights pb-20 fs-14-16">
                    {`© ${moment().format("YYYY")} ${blockNames.name}. ${
                        blockNames.rights
                    }.`}
                </div>
                <div className="footerHorizontalBlockTablet-footer-govlist  fs-12">
                    <Link
                        to={{
                            pathname:
                                "https://reestr.digital.gov.ru/reestr/305133/?sphrase_id=2597706"
                        }}
                        target="_blank"
                    >
                        <div className="w-400"> {blockNames.registry}</div>
                    </Link>{" "}
                </div>
                <div className="footerHorizontalBlockTablet-footer-columnsBlock">
                    <div className="footerHorizontalBlockTablet-footer-columnsBlock-column">
                        <div className="df gap-20">
                            <div
                                className="changeThemeTablet-footer imgBack cp mb-10"
                                onClick={() => handleTheme()}
                            ></div>

                            <div
                                className=" cp mb-10"
                                style={{ height: 10 + "px" }}
                                onClick={() => handleLanguage()}
                            >
                                {/* <span
                            style={
                                currentLang === "en"
                                    ? {}
                                    : { fontWeight: "bold" }
                            }
                        >
                            RU
                        </span>{" "}
                        <span>|</span>{" "}
                        <span
                            style={
                                currentLang === "en"
                                    ? { fontWeight: "bold" }
                                    : {}
                            }
                        >
                            EN
                        </span> */}
                                <ChangeLangTablet />
                            </div>
                        </div>
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link
                                to={{ pathname: "https://main.avl.team/" }}
                                target="_blank"
                            >
                                {blockNames.news}
                            </Link>
                        </div>
                        {/* <div className="phoneNumTablet mb-10 cd fs-12" >
                        +7(499)393-00-15
                    </div> */}
                        <div className="emailTablet mb-10 fs-12">
                            {/* <Link
                            // to={{ pathname: ":mailto:info@avl.team" }}
                            to="mailto:info@avl.team"
                            // target="_blank"
                        >
                            info@avl.team
                        </Link> */}
                            info@avl.team
                        </div>
                    </div>
                    <div className="footerHorizontalBlockTablet-footer-columnsBlock-column">
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/forBusinessPage">
                                {blockNames.forBusiness}
                            </Link>
                        </div>
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/platformPage">
                                {blockNames.platform}
                            </Link>
                        </div>
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/safetyPage">{blockNames.safety}</Link>
                        </div>
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/publicActivityPage">
                                {blockNames.publicActivity}{" "}
                            </Link>
                        </div>
                    </div>
                    <div className="footerHorizontalBlockTablet-footer-columnsBlock-column">
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/aboutUsPage">{blockNames.aboutus}</Link>
                        </div>
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/learningPage">
                                {blockNames.learning}
                            </Link>
                        </div>
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/contactsPage">
                                {blockNames.contacts}
                            </Link>
                        </div>
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/cookiesPage">Cookies</Link>
                        </div>
                    </div>
                    <div className="footerHorizontalBlockTablet-footer-columnsBlock-column">
                        <div className="newsLinkTablet cd mb-10 fs-12">
                            <Link to="/confidentionalPoliticPage">
                                {blockNames.confidential}
                            </Link>
                        </div>
                        <div className="newsLinkTablet cd mb-10 wn fs-12 ">
                            <Link to="/personalDataAgreementPage">
                                {blockNames.agreement}
                            </Link>
                        </div>
                        <div className="newsLinkTablet cp mb-10 fs-12">
                            <Link to="/useConditionsPage">
                                {blockNames.useConditions}
                            </Link>
                        </div>

                        <div className="newsLink cd mb-10 fs-12">
                            <Link to="/publicOfertaPage">
                                {blockNames.oferta}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterHorizontalBlockTablet;
