import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../hooks/useLang";
// import device from "current-device";

const languagesText = {
    ru: {
        line1: "Мы используем файлы",
        line2: "для улучшения работы сайта",
        line3: "Принять и закрыть"
    },
    en: {
        line1: "We use",
        line2: "to improve the site",
        line3: "Accept and close"
    },
    es: {
        line1: "Usamos",
        line2: "para mejorar el sitio",
        line3: "Aceptar y cerrar"
    },
    fr: {
        line1: "Nous utilisons des",
        line2: "pour améliorer le site",
        line3: "Accepter et fermer"
    }
};

const CookiesPopupTablet = ({ handleCookie }) => {
    // const [popupStep, setPopupStep] = useState(433);
    const { currentLang } = useLanguage();
    // useEffect(() => {
    //     const width = document.documentElement.clientWidth;
    //     if (width < 1800) { //1800
    //         setPopupStep(width / 2-458);//458
    //     }
    //     // eslint-disable-next-line
    // }, []);

    return (
        <>
            <div
                className={
                    // device.mobile() ? 'popupMobile'
                    // : device.desktop() ? 'popup'
                    //  device.tablet() ? -
                    "popupTablet"
                }
                style={
                    // !device.mobile()
                    //     ? { left: `calc(50% + ${popupStep}px)` }
                    //     : { left: "15px" }
                    //  { left: `calc(50% + ${popupStep}px)`}
                    { right: "19px" } //25px
                }
            >
                <div
                    className={
                        // !device.mobile() ? `popup-back` : ` popup-back fs-14`
                        //         device.mobile() ? 'popup-back fs-14'
                        // : device.desktop() ? 'popup-back'
                        //   device.tablet() ?
                        "popupTablet-back"
                    }
                >
                    <p>
                        {currentLang && languagesText[currentLang].line1}{" "}
                        <Link
                            to="/cookiesPage"
                            style={{ textDecoration: "underline" }}
                        >
                            cookies
                        </Link>{" "}
                        {currentLang && languagesText[currentLang].line2}
                    </p>
                    <div
                        className={
                            // !device.mobile()
                            //     ? `popup-link cp mt-30`
                            //     : `popupMobile-link cp mt-20`
                            // device.mobile() ? 'popupMobile-link'
                            // : device.desktop() ? 'popup-link'
                            //  device.tablet() ?
                            "popupTablet-link cp mt-30"
                        }
                        onClick={() => handleCookie()}
                    >
                        <span>
                            {currentLang && languagesText[currentLang].line3}
                        </span>
                        {/* {!device.mobile() ? `` : `fs-16`} */}
                        <div className="popupTablet-link-arrow imgBack"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CookiesPopupTablet;
