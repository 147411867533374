import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import CardsAdaptiveBlockTablet from "../cardsBlocks/cardsAdaptiveBlockTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import HorizontalBlockUnnamedTablet from "../horizontalBlockUnnamedTablet";
import LargeImageTablet from "../LargeImageTablet";

import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"


const ScannerPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.scanner.pageName;
    const subMenuList = content.scanner.subMenuList;
    const imageUrls = content.scanner.imageUrls;
    const blocks = content.scanner.blocks;
    const cards = content.scanner.cards;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
                withoutHeightSituationCenter={true}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet 
             blockContent={blocks.main} 
             customWrapLine={true}/>
            <HorizontalBlockAdaptiveTablet blockContent={blocks.control} />
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.possibilities}
                bottomBorder={false}
              
              
            />
            <CardsAdaptiveBlockTablet
                blockContent={cards.block1}
                topBorderCard={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                customHeightScannerPageTablet={true}
                titleScannerColumn={true}
            />
            <HorizontalBlockUnnamedTablet
                blockContent={blocks.unnamed}
                bottomBorder={false}
              
            />
            <HorizontalBlockAdaptiveTablet 
            blockContent={blocks.benifits}
            bottomBorder={true} // линия под блоком
             />
            <CardsAdaptiveBlockTablet
                blockContent={cards.block2}
                // topBorderCard={true}// линия над карточкой
                marginTopNum={true}
                marginTopImg={true}
                marginTopText={true}
                customMarginBottom={true}
            />
            <ContactUsTablet/>
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default ScannerPageTablet;
