import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";

const AboutUsPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.aboutUs.pageName;
    const imageUrls = content.aboutUs.imageUrls;
    const blocks = content.aboutUs.blocks;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                tripleLineName={true}
                customHeight={window.innerWidth < 360 ? 320 : 270}
                dropdownDisable={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.education}
                noLink={true}
                rightArrow={true}
                lowName={true}
                customNameLine="0"
                customHeight={210}
                dropdownDisable={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.expert}
                noLink={true}
                rightArrow={true}
                lowName={true}
                doubleLineName={true}
                customHeight={180}
                dropdownDisable={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.unnamed}
                noLink={true}
                noName={true}
                customHideHeight="170"
                doubleLineName={true}
                customHeight={window.innerWidth < 360 ? 760 : 720}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.mission}
                noLink={true}
                customNameLine="0"
                customHeight={window.innerWidth < 360 ? 360 : 320}
                dropdownDisable={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.direction}
                noLink={true}
                customNameLine="40"
                customHeight={190}
                dropdownDisable={true}
            />
        </div>
    );
};

export default AboutUsPageMobile;
