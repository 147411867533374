import React from "react";
import { useLanguage } from "../../../hooks/useLang";
const TariffsBlockTablet = () => {
    const { content } = useLanguage();
    const tableInfo = content.tariffs.table.info;
    const tabletop = content.tariffs.table.top;


    console.log("Lucky", content.tariffs.table.info);
    return (
        <>
            <div className="tariffsBlockTablet-back bborder">
                <div className="tariffsBlockTablet-columnline-top-back">
                    <div className="tariffsBlockTablet-columnline-top-item bborder"></div>
                    <div className="tariffsBlockTablet-columnline-top-item bborder ml-20">
                        <span className="fs-20 pt-10">{tabletop.column1.name}</span>
                        <span className="fs-14 pt-10 price-el">{tabletop.column1.price}</span>
                    </div>
                    <div className="tariffsBlockTablet-columnline-top-item bborder ml-20">{/*ml-45*/}
                        <span className="fs-20 pt-10">{tabletop.column2.name}</span>
                        <span className="fs-14 pt-10 price-el">
                            {tabletop.column2.price}
                        </span>
                    </div>
                    <div className="tariffsBlockTablet-columnline-top-item bborder ml-20 ">
                        <span className="fs-20 pt-10">{tabletop.column3.name}</span>
                        <span className="fs-14 pt-10 price-el">
                            {tabletop.column3.price}
                        </span>
                    </div>
                </div>
                {tableInfo.map((item, index) => (
                    <div
                        key={index}
                        className={`tariffsBlockTablet-columnline-back  ${
                            index < tableInfo.length - 1 ? "" : ""
                        }`}
                    >
                        <div
                            className={`tariffsBlockTablet-columnline-item-name ${
                                index < tableInfo.length - 1
                                    ? "graybborder"
                                    : ""
                            }`}
                        >
                            {item.name}
                        </div>
                        <div
                            className={`tariffsBlockTablet-columnline-item ${
                                index < tableInfo.length - 1
                                    ? "graybborder"
                                    : ""
                            } ml-20 `}
                        >
                            {item.standartValue ? (
                                <div className="tariffsBlockTablet-columnline-item-true"></div>
                            ) : (
                                <div className="tariffsBlockTablet-columnline-item-false"></div>
                            )}
                        </div>
                        <div
                            className={`tariffsBlockTablet-columnline-item ${
                                index < tableInfo.length - 1
                                    ? "graybborder"
                                    : ""
                            } ml-20`}
                        >
                            {item.optimumValue ? (
                                <div className="tariffsBlockTablet-columnline-item-true"></div>
                            ) : (
                                <div className="tariffsBlockTablet-columnline-item-false"></div>
                            )}
                            {item.optimumValueExtrainfo ? (
                                <span className="tariffsBlockTablet-columnline-item-extrainfo fs-12">
                                    {item.optimumValueExtrainfo}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                        <div
                            className={`tariffsBlockTablet-columnline-item ${
                                index < tableInfo.length - 1
                                    ? "graybborder"
                                    : ""
                            } ml-20 `}
                        >
                            {item.premiumValue ? (
                                <div className="tariffsBlockTablet-columnline-item-true"></div>
                            ) : (
                                <div className="tariffsBlockTablet-columnline-item-false"></div>
                            )}
                            {item.premiumValueExtrainfo ? (
                                <span className="tariffsBlockTablet-columnline-item-extrainfo fs-12">
                                    {item.premiumValueExtrainfo}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default TariffsBlockTablet;
