import { Link } from "react-router-dom";
const contentEs = {
    mainPage: {
        pageName: "Vía principal",
        subMenuList: [
            { num: 1, label: "Para empresas", anchor: "MainPageForBusiness" },
            { num: 2, label: "Seguridad", anchor: "MainPageSafety" },
            { num: 3, label: "Plataforma", anchor: "MainPagePlatform" },
            { num: 4, label: "Quienes somos", anchor: "MainPageAboutUs" },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/mainPage/img1.jpg",
            img1Mobile: "./img/mainPage/img1Mobile.jpg",
            img1Tablet: "./img/mainPage/img1Tablet.jpg", // добавил главную картинку
            img2: "./img/mainPage/img2.jpg",
            img2Mobile: "./img/mainPage/img2Mobile.jpg",
            img2Tablet: "./img/mainPage/img2Tablet.jpg",
            img3: "./img/mainPage/img3.jpg",
            img3Mobile: "./img/mainPage/img3Mobile.jpg",
            img3Tablet: "./img/mainPage/img3Tablet.jpg"
        },
        blocks: {
            forBusiness: {
                num: 1,
                name: "Para empresas",
                textBlock1:
                    "Desde 2016, nuestro centro digital operativo lleva a cabo un análisis integral del espacio informativo: seguimos la agenda de los medios de comunicación, las redes sociales, las aplicaciones de mensajería, así como la información en la darkweb. Garantizamos una interacción efectiva con las audiencias ad hoc, protegemos las empresas de los ataques cibernéticos, proporcionamos alertas tempranas y eliminamos diversos riesgos y amenazas en tiempo real.",
                textBlock2:
                    "Ofrecemos servicios de outsourcing de seguridad económica, auditoría de reputación y detección temprana de amenazas para un desarrollo sostenible. Realizamos monitoreo de competidores y del entorno externo, así como el análisis de los medios personalizados.",
                linkName1: "Leer más",
                link1: "/forBusinessPage",
                anchor: "MainPageForBusiness"
            },
            safety: {
                num: 2,
                name: "Seguridad",
                textBlock1:
                    "La seguridad informática para las empresas es uno de los aspectos clave para el éxito, ya que protege los datos confidenciales y la propiedad intelectual. La integración de las soluciones modernas en la seguridad informática es un elemento obligatorio en la estrategia de desarrollo sostenible de los negocios, ya que permite prevenir pérdidas económicas causadas por ciberataques y fugas de datos.",
                textBlock2:
                    "Las empresas y los organismos reguladores en todo el mundo llevan implementando los requisitos cada vez más estrictos para la protección de la información, y el incumplimiento de dichas medidas puede producir graves consecuencias legales y financieras.",
                linkName1: "Leer más",
                link1: "/safetyPage",
                anchor: "MainPageSafety"
            },
            platform: {
                num: 3,
                name: "Plataforma",
                textBlock1:
                    "Las soluciones basadas en la plataforma tecnológica Avalanche proporcionan al cliente capacidades de un Centro operativo moderno en la recopilación, el procesamiento, el almacenamiento y el análisis de datos masivos provenientes de las fuentes abiertas.",
                textBlock2:
                    "El uso de productos Avalanche aumenta significativamente el conocimiento de los gerentes acerca de los eventos importantes mediante la implementación de nuevas soluciones de alta tecnología que garantizan el análisis automatizado continuo del flujo de datos de las fuentes abiertas.",
                linkName1: "Leer más",
                link1: "/platformPage",
                anchor: "MainPagePlatform"
            },
            situationCenter: {
                name: "Centro operativo",
                textBlock1:
                    "Outsourcing del Centro operativo. Proporcionamos monitoreo las 24 horas de los medios intormativos alrededor del objeto de interés y detectamos riesgos y amenazas emergentes sin necesidad de crear un Centro operativo propio por haber alquilado a nuestros profesionales y soluciones técnicas.",
                textBlock2:
                    "Los programas automatizados seleccionan la información más importante y, mediante el procesamiento previo y el análisis lógico-sintáctico del texto, la presentan de la manera más conveniente posible, además generan los  informes estadísticos y analíticos sobre los eventos más importantes.",
                linkName: "Leer más",
                link: "/situationCenterPage"
            },
            analysis: {
                name: "Análisis de audiencia",
                textBlock1:
                    "El producto Avalanche es útil para recopilar y procesar grandes cantidades de datos de las redes sociales de manera rápida. Es una herramienta imprescindible para profesionales de diferentes perfiles, desde relaciones públicas y marketing hasta inteligencia corporativa. Permite analizar la audiencia objetivo, el crecimiento de seguidores, su actividad y mucho más.",
                textBlock2:
                    "Se realiza un análisis de datos abiertos en las redes sociales sin violar la privacidad de los usuarios. Permite detectar oleadas de información y ataques de cuentas falsas. Se garantiza la rapidez en la obtención de datos, transparencia y objetividad de la información.",
                linkName: "Leer más",
                link: "/audiencePage"
            },
            vulnerabilitiesScan: {
                name: "Análisis de vulnerabilidades",
                textBlock1:
                    "Los delincuentes modernos descubren y explotan nuevas vulnerabilidades en los sitios web que contienen los datos sensibles. Los errores de implementación de software, la falta de actualización de software, las contraseñas obsoletas y mucho más, todo esto conduce a la aparición de graves vulnerabilidades en los proyectos de Internet.",
                textBlock2:
                    "Un escáner web es un conjunto de soluciones dirigidas a proporcionar funciones de control de seguridad de los sistemas de información mediante tecnologías web. El escáner web itegra las funciones de detección, prevención y respuesta a intentos de una actividad no autorizada de usuarios remotos e internos en las redes informáticas.",
                linkName: "Leer más",
                link: "/scannerPage"
            },
            analytics: {
                name: "Análisis",
                textBlock1:
                    "El control de la agenda de información y las opciones para resolver los problemas emergentes, responder a riesgos y amenazas, y combatir ataques informáticos. Evaluación de la eficacia de las comunicaciones estratégicas, campañas publicitarias y otras interacciones públicas con la audiencia objetivo y reposicionamiento en cuestiones vulnerables.",
                textBlock2:
                    "Los estudios de gabinete en beneficio de los servicios de mercadotecnia, las relaciones públicas y la seguridad financiera. Los analistas trabajan según el principio de la oficina de referencia y ayudan a encontrar respuestas a las preguntas más complejas.",
                linkName: "Leer más",
                link: "/analyticsPage"
            },
            unnamedBlock: {
                textBlock:
                    "La sustitución de los productos importados de fabricación extranjera y el desarrollo de la competitividad rusa con el fin de contrarrestar los riesgos de las medidas restrictivas en el área de alta tecnología."
            },
            aboutUs: {
                num: 4,
                name: "Quienes somos",
                textBlock1:
                    "Por primera vez el producto Avalanche apareció en el mercado ruso en el año 2003. Su creador es el pionero en el mercado de inteligencia competitiva, el Teniente Coronel retirado de la FAPSI, Andrei Masalóvich. Durante casi 20 años, el sistema se ha utilizado para vigilar la opinión pública y enfrentar las amenazas del terrorismo por parte de los organismos de seguridad, las autoridades federales y regionales.",
                textBlock2:
                    "Hoy en día, los sistemas analíticos funcionando con base en la plataforma tecnológica Avalanche se utilizan activamente por las empresas comerciales para monitorear los mercados, los socios y competidores rusos y extranjeros.",
                linkName1: "Leer más",
                link1: "/aboutUsPage",
                anchor: "MainPageAboutUs"
            },
            shortBlock: {
                name: "Sinergias entre las tecnologías de análisis de big data"
            },
            demonstration: {
                num: "",
                name: "Demo",
                textBlock2: `Para demostrar el funcionamiento de Avalanche, hemos preparado varios proyectos:\n\r\u2022 líderes políticos en los medios extranjeros \n\r\u2022 ministerios y departamentos en los medios rusos\r\n\u2022 COVID-19 en regiones de Rusia \r\n Para más información, contáctenos.`
            },
            sixCardsBlock: [
                {
                    num: 1,
                    name: "Líderes políticos",
                    text: "Referencias a los principales líderes políticos del mundo en los medios de comunicación extranjeros",
                    linkName: "Ver todo",
                    externalLink: "https://leaders.avl.team/"
                },
                {
                    num: 2,
                    name: "Ministerios",
                    text: "La agenda de los ministerios y departamentos rusos en los medios de comunicación nacionales.",
                    linkName: "Ver todo",
                    externalLink: "https://minister.avl.team/"
                },
                {
                    num: 3,
                    name: "Covid-19",
                    text: "Las noticias sobre el contagio de coronavirus en los medios regionales de Rusia con desglose por distritos federales.",
                    linkName: "Ver todo",
                    externalLink: "https://covid19.avl.team/"
                },
                {
                    num: 4,
                    name: "Búsqueda de vuelos",
                    text: "Recopilación de información sobre vuelos baratos y promociones de varias aerolíneas del mundo.",
                    linkName: "Ver todo",
                    externalLink: "https://lowcost.avl.team/"
                },
                {
                    num: 5,
                    name: "Movilización: noticias",
                    text: "Todo lo más importante sobre la campaña de movilización y las misiones de voluntariado.",
                    linkName: "Ver todo",
                    externalLink: "https://vstavai.avl.team/"
                },
                {
                    num: 6,
                    name: "OSINT&Cybersec",
                    text: "Noticias del mundo de la inteligencia y la ciberseguridad, monitoreo de filtraciones y fugas, búsqueda de vulnerabilidades.",
                    linkName: "Ver todo",
                    externalLink: "https://osint.avl.team/"
                }
            ],
            cardsWithImg: [
                {
                    num: 1,
                    text: "Gente. El éxito se logra con la ardua labor de nuestro equipo profesional. Avalanche es un sistema operado por personal técnico profesional de operadores, analistas y administradores de sistemas.",
                    imgLink: "./img/mainPage/small1.jpg"
                },
                {
                    num: 2,
                    text: "Procesos. Creación y análisis de un flujograma de riesgos y amenazas a la reputación. Desarrollo de escenarios de posibles ciberataques. Generación de los mecanismos de controlar la audiencia en el ciberespacio y la optimización del trabajo con los medios de comunicación.",
                    imgLink: "./img/mainPage/small2.jpg"
                },
                {
                    num: 3,
                    text: "Tecnologías. Un conjunto avanzado de algoritmos de última generación para analizar los macrodatos, que incluyen el análisis rápido, la evaluación de riesgos, la predicción entre otros.",
                    imgLink: "./img/mainPage/small3.jpg"
                }
            ],
            cardsBlock: {
                cards: [
                    {
                        num: 1,
                        text: "Gente. El éxito se logra con la ardua labor de nuestro equipo profesional.",
                        imgLink: "./img/mainPage/small1Mobile.jpg"
                    },
                    {
                        num: 2,
                        text: "Procesos. Creación y análisis de un flujograma de riesgos y amenazas a la reputación.",
                        imgLink: "./img/mainPage/small2Mobile.jpg"
                    },
                    {
                        num: 3,
                        text: "Tecnologías. El juego aumentado de los algoritmos modernos para el procesamiento de datos grandes.",
                        imgLink: "./img/mainPage/small3Mobile.jpg"
                    }
                ]
            }
        }
    },
    platform: {
        pageName: "Plataforma",
        subMenuList: [
            { num: 1, label: "Plataforma", anchor: "PlatformPagePlatform" },
            {
                num: 2,
                label: "Nuestros productos",
                anchor: "PlatformPageProducts"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/platformPage/img1.jpg",
            img1Mobile: "./img/platformPage/img1Mobile.jpg"
        },
        blocks: {
            imageBlock: {
                name: "",
                textBlock1: "",
                textBlock2:
                    "Avalanche («Avalancha») es la tecnología de inteligencia en Internet más una familia de los sistemas derivados automatizados de monitoreo de Internet de nueva generación, que reune amplias capacidades de recopilación y procesamiento analítico de la información, así como la planificación de las medidas urgentes.",
                linkName1: "",
                link1: "",
                anchor: "PlatformPagePlatform"
            },
            ourProducts: {
                num: 2,
                name: "Nuestros productos",
                anchor: "PlatformPageProducts"
            },
            situationCenter: {
                name: "Centro operativo",
                textBlock1:
                    "Outsourcing del Centro operativo. Proporcionamos el monitoreo de 24 horas de la información del objeto de interés y detectamos los riesgos y las amenazas emergentes sin necesidad de crear un Centro operativo propio mediante el uso de nuestros profesionales y soluciones técnicas.",
                textBlock2:
                    "Los programas automatizados seleccionan la información más importante y al procesar previamente y realizar el análisis lógico-sintáctico del texto la presentan de manera más congruente, además generan los informes estadísticos y analíticos sobre los eventos más importantes.",
                linkName: "Leer más",
                link: "/situationCenterPage"
            },
            analysis: {
                name: "Análisis de audiencia",
                textBlock1:
                    "El producto Avalanche es útil para recopilar y procesar grandes cantidades de datos de las redes sociales de manera rápida. Es una herramienta imprescindible para varios profesionales: desde las relaciones públicas y mercadotecnia hasta la inteligencia corporativa. Permite analizar la audiencia ad hoc, el crecimiento de seguidores, la actividad de los mismos y mucho más.",
                textBlock2:
                    "Se realiza un análisis de datos abiertos en las redes sociales sin violar la privacidad de los usuarios. Permite detectar oleadas de información y ataques de cuentas falsas. Se garantiza la rapidez en la obtención de datos, la transparencia y objetividad de la información.",
                linkName: "Leer más",
                link: "/audiencePage"
            },
            vulnerabilitiesScan: {
                name: "Análisis de vulnerabilidades",
                textBlock1:
                    "Los delincuentes modernos descubren y explotan nuevas vulnerabilidades en los sitios web los cuales contienen datos sensibles. Los errores en la implementación de software, el software no actualizado, las claves y contraseñas obsoletas entre otros, todo esto conduce a la aparición de graves vulnerabilidades en los proyectos de Internet..",
                textBlock2:
                    "Escáner web es un conjunto de soluciones para garantizar la función de control de seguridad en los sistemas informáticos basados ne la web. El escáner web permite implementar las funciones de detección, prevención y respuesta a la actividad no autorizada de usuarios remotos e internos en las redes informáticas.",
                linkName: "Leer más",
                link: "/scannerPage"
            },
            analytics: {
                name: "Análisis",
                textBlock1:
                    "Control de la agenda de información y las opciones para resolver los problemas emergentes, responder a los riesgos y las amenazas, hacer frente a los ataques cibern+eticos. Evaluación de la eficacia de las comunicaciones estratégicas, campañas publicitarias y otras actividades públicas con la audiencia objetivo y reposicionamiento en cuestiones vulnerables.",
                textBlock2:
                    "Estudios para los servicios de mercadotecnia, las relaciones públicas y la seguridad económica. El análisis funciona como una oficina de información y ayuda a encontrar respuestas a las preguntas más complejas",
                linkName: "Leer más",
                link: "/analyticsPage"
            }
        },
        sixCardsBlock: [
            {
                num: 1,
                name: "Ajuste ad hoc",
                text: "Ajuste fino de los recursos de información, con la capacidad de filtrar la información importante en el flujo del contenido no esencial, de los banners, etc. Recopilación de la información principal, evitando la información irrelevante."
            },
            {
                num: 2,
                name: "En línea 24/7",
                text: "Monitoreo y aviso 24 horas al día. Incluso por la noche. Incluso en vacaciones. Siempre protegiendo sus intereses. Si se detecta una amenaza, Ud. recibirá un aviso en Telegram."
            },
            {
                num: 3,
                name: "Integración",
                text: "Mecanismos flexibles para la integración y el intercambio de datos con otros sistemas de información. Avalanche puede funcionar lo mismo como fuente de datos que como herramienta de análisis."
            },
            {
                num: 4,
                name: "Análisis y visualización",
                text: "Análisis de duplicidad y coloración emocional. Visualización de vínculos y diagramas de distribución de información. Cualquier dato puede ser visualizado."
            },
            {
                num: 5,
                name: "Revisión de 360 grados",
                text: "La cinta de eventos no solo abarca los medios tradicionales y canales de Telegram, sino también los eventos corporativos, tribunales, licitaciones, sitios gubernamentales entre otros. Ud. obtenrá una visión integral de la situación."
            },
            {
                num: 6,
                name: "Destacar lo más importante",
                text: "Las oportunidades serias para informar, los artículos en los principales medios de comunicación y canales de Telegram se resaltan con el color verde, las informaciones comprometedoras el compromiso y negativas con el amarillo, las oportunidades críticas con el rojo. El sistema «semáforo» se basa en las técnicas estadísticas y las redes neuronales entrenables."
            }
        ],
        cardsBlock: {
            cards: [
                {
                    num: 1,
                    name: "Ajuste ad hoc",
                    text: "Ajuste fino de los recursos de información, con la capacidad de filtrar la información importante en el flujo del contenido no esencial, de los banners, etc. Recopilación de la información principal, evitando la información irrelevante."
                },
                {
                    num: 2,
                    name: "En línea 24/7",
                    text: "Monitoreo y aviso 24 horas al día. Incluso por la noche. Incluso en vacaciones. Siempre protegiendo sus intereses. Si se detecta una amenaza, Ud. recibirá un aviso en Telegram."
                },
                {
                    num: 3,
                    name: "Integración",
                    text: "Mecanismos flexibles para la integración y el intercambio de datos con otros sistemas de información. Avalanche puede funcionar lo mismo como fuente de datos que como herramienta de análisis."
                },
                {
                    num: 4,
                    name: "Análisis y visualización",
                    text: "Análisis de duplicidad y coloración emocional. Visualización de vínculos y diagramas de distribución de información. Cualquier dato puede ser visualizado."
                },
                {
                    num: 5,
                    name: "Revisión de 360 grados",
                    text: "La cinta de eventos no solo abarca los medios tradicionales y canales de Telegram, sino también los eventos corporativos, tribunales, licitaciones, sitios gubernamentales entre otros. Ud. obtenrá una visión integral de la situación."
                },
                {
                    num: 6,
                    name: "Destacar lo más importante",
                    text: "Las oportunidades serias para informar, los artículos en los principales medios de comunicación y canales de Telegram se resaltan con el color verde, las informaciones comprometedoras el compromiso y negativas con el amarillo, las oportunidades críticas con el rojo. El sistema «semáforo» se basa en las técnicas estadísticas y las redes neuronales entrenables."
                }
            ]
        }
    },
    forBusiness: {
        pageName: "Para empresas",
        subMenuList: [
            {
                num: 1,
                label: "Inteligencia competitiva para su negocio",
                anchor: "ForBusinessMain"
            },
            {
                num: 2,
                label: "Centro operativo virtual",
                anchor: "ForBusinessCenter"
            },
            {
                num: 3,
                label: "Licencias e implementación",
                anchor: "ForBusinessLicence"
            },
            {
                num: 4,
                label: "Servicios y análisis",
                anchor: "ForBusinessService"
            },
            {
                num: 5,
                label: "Comunicaciones",
                anchor: "ForBusinessCommunications"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/forBusinessPage/img1.jpg",
            img1Mobile: "./img/forBusinessPage/img1Mobile.jpg",
            img2: "./img/forBusinessPage/img2.jpg",
            img2Mobile: "./img/forBusinessPage/img2Mobile.jpg",
            img3: "./img/forBusinessPage/img3.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Inteligencia competitiva para su negocio",
                textBlock2:
                    "Los proyectos en Avalanche proporcionan un control total del espacio informativo, mediante los cuales Ud. podrá monitorear la situación en el mercado, la actividad de los competidores, las modificaciones en la política nacional y la regulación legal. Cada proyecto es único y se adapta al temario del respectivo sector.",

                anchor: "ForBusinessMain"
            },
            center: {
                num: 2,
                name: "Centro operativo virtual",
                textBlock1: "",
                textBlock2:
                    "El servicio de supervisión de Internet presta al Cliente las capacidades del Centro operativo moderno para vigilar la situación corriente y detectar tempranamente las amenazas de Internet. Existen 3 planes de tarifas para los Clientes privados.",
                linkName1: "Planes",
                link1: "/tariffsPage",
                anchor: "ForBusinessCenter"
            },
            communications: {
                num: 5,
                name: "Comunicaciones",
                textBlock1: "",
                textBlock2:
                    "Se necesitan años para construir la reputación corporativa y la confianza de los clientes, no obstante pueden ser arruinadas instantáneamente por el ataque informativo de los competidores. Toda acción abierta de la empresa puede ser blanco de un ataque de la desinformación; es una parte real y difícil para negocios rusos.",
                linkName1: "Leer más",
                link1: "/communicationsPage",
                anchor: "ForBusinessCommunications"
            },
            service: {
                num: 4,
                name: "Servicios y análisis",
                textBlock1: "",
                textBlock2:
                    "El Avalanche funciona de modo más efectivo en conjunto con nuestro servicio de consultoría. No sólo haremos ajustes del proyecto para los requerimientos de información, sino prepararemos los reportes analíticos periódicos, estimaremos los posibles riesgos y resaltaremos los puntos de crecimiento.",
                linkName1: "Leer más",
                link1: "/analyticsPage",
                anchor: "ForBusinessService"
            },
            licence: {
                num: 3,
                name: "Licencias e implementación",
                textBlock1: "",
                textBlock2:
                    "La implementación del sistema informático Avalanche en el hardware del cliente, incluyendo la configuración inicial y la capacitación del usuario. El control de vigilancia en la empresa, sin la limitación del número de usuarios y del número de ogjetos de vigilancia.",
                linkName1: "Tarifas",
                link1: "/tariffsPage",
                anchor: "ForBusinessLicence"
            }
        }
    },
    situationCenter: {
        pageName: "Centro operativo",
        subMenuList: [
            {
                num: 1,
                label: "Centro operativo",
                anchor: "SituationCenterMain"
            },
            {
                num: 2,
                label: "Sobre los centros operativos",
                anchor: "SituationCenterAbout"
            },
            {
                num: 3,
                label: "Beneficios de los centros operativos",
                anchor: "SituationCenterBenifits"
            },
            {
                num: 4,
                label: "Outsourcing del centro operativo",
                anchor: "SituationCenterOutsourse"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/situationCenter/img1.jpg",
            img1Mobile: "./img/situationCenter/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Centro operativo",
                textBlock2:
                    "A partir del año 2016 nuestro Centro operativo lleva realizando el monitoreo integral del espacio informativo: controlamos la agenda de los medios de comunicación, las redes sociales, los mensajeros, la información en darkweb. El Centro operativo del Grupo cuenta con más de 50 operadores y analistas que aseguran la detección temprana y la prevención de riesgos y amenazas en beneficio de las autoridades estatales federales, los coonsorcios nacionales, las empresas grandes, las instituciones bancarias.",

                anchor: "SituationCenterMain"
            },
            about: {
                num: 2,
                name: "Sobre los centros operativos",
                textBlock2:
                    "El Centro operativo está destinado a la supervión operativa del espacio informativo y la consolidación de la información para tomar buenas decisiones de gestión. Normalmente, un centro operativo consta de tres componentes: un equipo de vigilancia, un equipo de analistas y un equipo de reacción rápida.",

                anchor: "SituationCenterAbout"
            },
            benifits: {
                num: 3,
                name: "Beneficios de los centros operativoc",
                textBlock2:
                    "En caso de emergencia o un ataque informativo, la reputación de toda la empresa puede depender de la rapidez de la toma de decisión. El objetivo clave del centro operativo es alertar de antemano sobre el peligro inminente, presentar toda la información respectiva, así como proporcionar los recursos informáticos e informativos para una reacción adecuada.",

                anchor: "SituationCenterBenifits"
            },
            outsourse: {
                num: 4,
                name: "Outsourcing del centro operativo",
                textBlock2:
                    "La creación y el mantenimiento de un centro operativo íntegro es muy costoso, incluso para las más grandes empresas. El costo del equipo del centro operativo comienza del mínimo de 10 millones de rublos, y el costo de mantenimiento es de 1 millón de rublos al mes como mínimo. Somos propietarios del centro operativo con más de 50 empleados y ofrecemos un servicio nuevo y único en el mercado ruso: outsourcing del centro operativo.",

                anchor: "SituationCenterOutsourse"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Equipo de supervisión",
                        text: "Vigila el espacio de información, busca riesgos y amenazas a la empresa, realiza vigilancia continua del entorno y de los competidores."
                    },
                    {
                        num: 2,
                        name: "Equipo de análisis",
                        text: "Realiza la evaluación de los riesgos y las amenazas detectados, formula una comprensión de la situación y propoone una estrategia de reacción."
                    },
                    {
                        num: 3,
                        name: "Equipo de reacción rápida",
                        text: "Asegura la neutralización de los riesgos y las amenazas que han aparecido en los medios de comunicación, las redes sociales o los mensajeros."
                    },
                    {
                        num: 4,
                        name: "Coordinación de los esfuerzos",
                        text: "Los esfuerzos conjuntos de los equipos garantizan la seguridad integral en todos los segmentos del espacio informatiov."
                    }
                ]
            },
            block2: {
                cards: [
                    {
                        num: 1,
                        name: "Seguimiento permanente informativo",
                        text: "A diferencia de un departamento de RR.PP. orgánico, el centro operativo del Grupo proporcionará el monitoreo permanente de 24 horas del espacio informativo, incluso por la noche y los días festivos."
                    },
                    {
                        num: 2,
                        name: "Alerta temprana",
                        text: "Los operadores del Centro operativo son los primeros quienes reciben la información después de la aparición de la misma en el espacio público y antes de la difusión amplia de dicha información. Por lo tanto, nuestros clientes siempre tienen un período suficiente para responder de forma preventiva a la amenaza. La alerta sobre las amenazas detectadas se efectúa en tiempo real."
                    },
                    {
                        num: 3,
                        name: "Desarrollo de contramedidas",
                        text: "La evaluación de riesgos, recopilación y análisis de datos para la toma de una solución gerencial óptima y la elaboración de estrategia de protección contra las amenazas."
                    },
                    {
                        num: 4,
                        name: "Reacciones",
                        text: "Los esfuerzos conjuntos del equipo garantizan la seguridad integral de todos los segmentos del espacio informático."
                    }
                ]
            },
            block3: {
                cards: [
                    {
                        num: 1,
                        name: "Costo óptimo",
                        text: "La empresa recibirá un juego de servicios completo por la parte del centro operativo sin los gastos para la creación del mismo y los gastos de mantenimiento."
                    },
                    {
                        num: 2,
                        name: "Escalabilidad",
                        text: "El Centro operativo sirve tanto para las empresas pequeñas como para grandes que operan a nivel internacional."
                    },
                    {
                        num: 3,
                        name: "Flexibilidad",
                        text: "Los empleados del centro operativo se concentrarán en los procesos y cuestiones ad hoc, y pueden trabajar en diferentes áreas."
                    },
                    {
                        num: 4,
                        name: "Despliegue rápido",
                        text: "El Centro operativo se despliega y se ajusta hacia un nuevo tema dentro de una semana. Da flexibilidad y adaptabilidad en la toma de decisiones."
                    }
                ]
            }
        }
    },
    communications: {
        pageName: "Comunicaciones",
        subMenuList: [
            {
                num: 1,
                label: "Comunicaciones",
                anchor: "CommunicationsMain"
            },
            {
                num: 2,
                label: "Protegerse contra la agresión informativa",
                anchor: "CommunicationsDefence"
            },
            {
                num: 3,
                label: "Gontrolar la reputación",
                anchor: "CommunicationsReputation"
            },
            {
                num: 4,
                label: "Crear un espacio informativo",
                anchor: "CommunicationsBackground"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/communicationsPage/img1.jpg",
            img1Mobile: "./img/communicationsPage/img1Mobile.jpg",
            img2Mobile: "./img/communicationsPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Comunicaciones",
                textBlock2:
                    "La reputación empresarial y la confianza de los clientes se construye durante años, pero pueden ser arruinados en un segundo por el ataque informativo de los adversarios. Cualquier acción pública de la empresa puede convertirse en el blanco para el ataque informativo: es una parte dura del negocio actual.",

                anchor: "CommunicationsMain"
            },
            defence: {
                num: 2,
                name: "Protegerse contra la agresión informativa",
                textBlock1:
                    "Las empresas compiten por el consumidor no solo en el mercado, sino también en el espacio informativo. Cualquier cambio en el mercado o una actividad pública de la empresa puede provocar un ataque informativo devastador por parte de los adversarios.",
                textBlock2:
                    "Garamtizamos la protección y prevención de las agresiones informativas y además no solo controlamos el espacio informativo 24 horas, sino también ayudamos a evitar las pérdidas por la imagen afectada. Desarrollamos un mapa único de riesgos y amenazas para cada cliente a fin de comprender los probables objetivos y las fuentes de ataque, así como ayudamos a oponerse de manera adecuada a la agresión informativa en los medios de comunicación, las redes sociales y los mensajeros.",

                anchor: "CommunicationsDefence"
            },
            reputation: {
                num: 3,
                name: "Controlar la reputación",
                textBlock2:
                    "Ayuda en la recuperación de la reputación empresarial en los medios de comunicación masiva, la eliminación o el bloqueo de una información ilegal, así como la asistencia en la defensa de los intereses de la empresa.",

                anchor: "CommunicationsReputation"
            },
            background: {
                num: 4,
                name: "Crear un espacio informativo",
                textBlock2:
                    "Evitar es más fácil y más barato que sanar. La creación de un marco informativo positivo para la empresa reducirá drásticamente los costes asociados a la guerra informativa. Promover una agenda de información positiva es la condición importante para crear de un espacio ifnormativo favorable.",

                anchor: "CommunicationsBackground"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Tiempo",
                        text: "El recurso principal durante la crisis es el tiempo. Las unidades de RP y los contratistas no ven muy a menudo el inicio de un ataque informático y pierden tiempo."
                    },
                    {
                        num: 2,
                        name: "Outsourcing",
                        text: "Nuestro Grupo es el primero en Rusia en proporcionar servicios de externalización de los centros operativos. Esto significa que el espacio informativo de la empresa estará bajo la supervisión permanente 24 horas y sin días festivos. La supervisión está a cargo de analistas profesionales que evalúan los riesgos y amenazas para la empresa incluso antes de que éstos entren en la fase de implementación."
                    },
                    {
                        num: 3,
                        name: "Reacción",
                        text: "No solo garantizamos la detección temprana de los ataques informativos, sino también ofrecemos una estrategia de solución de problemas y una respuesta adecuada a los riesgos y amenazas apareciendo en los medios de comunicación."
                    },
                    {
                        num: 4,
                        name: "Carácter único",
                        text: "No hay espacio para los patrones en repeler la agresión informativa. Bajo cada caso, desarrollamos una metodología única de protección contra amenazas de información."
                    }
                ]
            },
            block2: {
                imageUrl: "../img/communicationsPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Auditoría de la reputación",
                        text: "El producto principal de la auditoría de reputación es un mapa de riesgos y amenazas que muestra todas las debilidades en la estrategia de comunicación y posicionamiento de la empresa. El mapa analiza tanto los riesgos actuales como los potenciales que pueden derivarse de los cambios dentro de la empresa o en el entorno externo."
                    },
                    {
                        num: 2,
                        name: "Contramedidas",
                        text: "Se desarrolla un sistema de respuesta a la agresión informativa con base en el mapa de riesgos y amenazas. Se preparan los materiales a publicar de antemano, se generan los escenarios de respuesta, se elabora una estrategia de respuesta y se crean los respectivos materiales que luego se publican en los medios de comunicación, las redes sociales y los mensajeros."
                    }
                ]
            },
            block3: {
                cards: [
                    {
                        num: 1,
                        name: "Estrategia y posicionamiento",
                        text: "Elaboración de una estrategia efectiva de posicionamiento en el espacio informativo que garantice las posiciones más ventajosas para protegerse contra los riesgos y las amenazas informativos."
                    },
                    {
                        num: 2,
                        name: "Creación de sentidos",
                        text: "Trabajo con la audiencia ad hoc. Creación de sentidos y pensamiento asociativo para formar la opinión pública deseada."
                    },
                    {
                        num: 3,
                        name: "Protección proactiva",
                        text: "Previsión de riesgos potenciales. Preparación de las posiciones de comunicación efectivas que mitiguen el primer y el más doloroso golpe."
                    },
                    {
                        num: 4,
                        name: "Gestión de agenda",
                        text: "Ayudamos a las empresas a convertirse en centros periciales, fuentes de citación y opiniones, en vez de repetir el discurso actual. Nuestros ponentes serán los guías de conocimientos únicos y el centro de atracción para la audiencia ad hoc."
                    }
                ]
            }
        }
    },
    analytics: {
        pageName: "Análisis",
        subMenuList: [
            {
                num: 1,
                label: "Análisis",
                anchor: "AnalyticsMain"
            },
            {
                num: 2,
                label: "Inteligencia de fuentes abiertas",
                anchor: "AnalyticsScouting"
            },
            {
                num: 3,
                label: "Investigaciones",
                anchor: "AnalyticsResearch"
            },
            { label: "Enviar consulta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/analyticsPage/img1.jpg",
            img1Mobile: "./img/analyticsPage/img1Mobile.jpg",
            img2Mobile: "./img/analyticsPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Análisis",
                textBlock1:
                    "La causa de la mayoría de las pérdidas financieras empresariales comprende las decisiones erróneas por la escasez de la información y la incertidumbre. Nuestro Grupo garantizará a las empresas el apoyo análitico y de la inteligencia. Estos servicios serán especialmente útiles en las situaciones complejas y ambiguas del negocio internacional actuales.",
                textBlock2:
                    "Nuestra misión es proporcionar la información suficiente para tomar varias decisiones de gestión, incluso en las condiciones más complicadas y multifactoriales. Nuestro Departamento de Análisis e Investigación es un laboratorio de ideas, lo que permite resolver de manera eficiente cualquier problema del cliente en el ámbito informativo.",
                anchor: "AnalyticsMain"
            },
            scouting: {
                num: 2,
                name: "Inteligencia de fuentes abiertas",
                textBlock1:
                    "Open source intelligence es la inteligencia de fuentes abiertas u OSINT proporciona la información para el negocio incluso en el ámbito cerrado y la escasez de fuentes de datos. OSINT trabaja por concepto de la información de fuentes abiertas y con base se ésta se genera una imagen completa y fiable de los acontecimientos sobre cualquier asunto, incluyendo el más complejo.",
                textBlock2:
                    "Nuestros analistas poseen una tecnología universal para procesar la información, lo que les permite recopilar los datos y aclarar la situación en los casos más complejos. Se realiza el análisis del ámbito informativo para comprender la situación y generar un pronóstico fidedigno de su desarrollo.",
                anchor: "AnalyticsScouting"
            },
            research: {
                num: 3,
                name: "Investigaciones",
                textBlock2:
                    "Nuestro equipo no solo realiza estudios de gabinete, sino también de campo. Realizamos estudios cuantitativos y cualitativos en todas las regiones del país, tanto en el ámbito de economía y comercio como en el sociopolítico.",

                anchor: "AnalyticsResearch"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Fuentes",
                        text: "Acumulamos gran número de fuentes de información durante más de 15 años de trabajo: somos suscritores de todas las bases de datos y fuentes de información más grandes."
                    },
                    {
                        num: 2,
                        name: "Habilidades únicas",
                        text: "Nuestros analistas pasan los cursos de formación especializados de Andréi Masalóvich, por eso podemos conseguir la información exclusiva de las fuentes abiertas."
                    },
                    {
                        num: 3,
                        name: "Peritaje",
                        text: "Colaboramos con más de doscientos expertos en diversas áreas de conocimiento, desde las relaciones públicas, el derecho y la economía hasta la robótica y la aviación espacial. Nuestros clientes pueden estar seguros de que la información a recibir será confirmada por los expertos de altísimo nivel."
                    },
                    {
                        num: 4,
                        name: "Equipo",
                        text: "Nuestro equipo de trabajo está conformado por los técnicos políticos profesionales únicos, los expertos en las relaciones públicas, los periodistas y analistas. Cada uno de ellos no sólo recorrió su camino de formación profesional, sino también adquirió los conocimientos especializados trabajando en el Grupo. La síntesis de experiencias y habilidades únicas nos permite emprender las tareas de cualquier complejidad."
                    }
                ]
            },
            block2: {
                imageUrl: "../img/analyticsPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Investigaciones cualitativas",
                        text: "Especializamos en los etudios en grupos focales y en las entrevistas de expertos. Eso permite realizar a nuestros clientes una política efectiva de comunicación y marketing, así como corregitla a tiempo."
                    },
                    {
                        num: 2,
                        name: "Investigaciones cuantitativas",
                        text: "Sobre la base de un mapa de riesgos y amenazas, se desarrolla un sistema de respuesta a la agresión informativa. Los escenarios de respuesta se configuran de antemano, se elabora una estrategia de respuesta comunicativa."
                    }
                ]
            }
        }
    },
    safety: {
        pageName: "Seguridad",
        subMenuList: [
            {
                num: 1,
                label: "Seguridad",
                anchor: "SafetyMain"
            },
            {
                num: 2,
                label: "Seguridad económica",
                anchor: "SafetyEconomic"
            },
            {
                num: 3,
                label: "Apoyo jurídico",
                anchor: "SafetySupport"
            },
            {
                num: 4,
                label: "Ciberseguridad",
                anchor: "SafetyCyber"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/safetyPage/img1.jpg",
            img1Mobile: "./img/safetyPage/img1Mobile.jpg",
            img2Mobile: "./img/safetyPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Seguridad",
                textBlock1:
                    "La razón de la mayoría de las pérdidas financieras empresariales consiste en las decisiones erróneas tomadas por escasez de la información y la incertidumbre. Prestamos a las empresas servicio de inteligencia y apoyo analítico. Estos servicios serán especialmente útiles en las complejas y ambiguas realidades del negocio internacional actual.",
                textBlock2:
                    "La misión del Grupo es proporcionar suficiente información para tomar una decisión de gestión de cualquier complejidad, incluso en las condiciones más difíciles y ambiguas. Nuestro Departamento de Análisis e Investigación está organizado por concepto de laboratorio de ideas, lo que permite resolver de manera eficiente cualquier problema informativo del cliente.",
                anchor: "SafetyMain"
            },
            economic: {
                num: 2,
                name: "Seguridad económica",
                textBlock2:
                    "Nuestro Grupo garantiza la seguridad económica de una empresa en el marco legal de Rusia mediante la Diligencia Debina integral con respecto a las transacciones, los socios, empleados y candidatos de la empresa.",
                anchor: "SafetyEconomic"
            },
            cyber: {
                num: 4,
                name: "Ciberseguridad",
                textBlock2:
                    "Desde hace más de 15 años elaboramos e implementamos soluciones garantizando la seguridad en los organismos del poder federal, las corporaciones estatales, grandes empresas comerciales, así como en el sector bancario.",

                anchor: "SafetyCyber"
            },
            support: {
                num: 3,
                name: "Apoyo jurídico",
                textBlock2:
                    "Un sólido apoyo legal en una amplia gama de cuestines económicas a partir de los conflictos corporativos hasta las quiebras y gestión arbitral. Dicho apoyo permite no sólo proporcionar a los clientes la información para la toma de decisiones de gestión competente, sino también proteger sus intereses en litigios y procesos judiciales en todas las etapas.",
                anchor: "SafetySupport"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Chequeo de contrapartes",
                        text: "La información exhaustiva sobre las probables contrapartes de la empresa para tomar una decisión óptima. El trabajo se efectúa tanto para las contrapartes rusas como extranjeras, incluyendo la información de las RRSS y la de otras fuentes."
                    },
                    {
                        num: 2,
                        name: "Chequeo de candidato",
                        text: "Se chequea la reputación de candidatos, se determinan las amenazas y los riesgos que puede llevar el empleado disponible."
                    },
                    {
                        num: 3,
                        name: "Protección proactiva",
                        text: "Entre los analistas del Grupo tenemos ex oficiales de los órganos de la seguridad ciudadana y de los organismos especiales con amplia experiencia en la investigación de delitos económicos y otros actos ilegales."
                    },
                    {
                        num: 4,
                        name: "Invesigaciones",
                        text: "La solución para casi cualquier cuestión: desde la búsqueda de activos en el extranjero hasta el análisis de las actividades de las compañías offshore. Sabemos dónde encontrar la información sobre una sociedad china, sobre las últimas actividades de un empresario latinoamericano o como evaluar los riesgos empresariales en el proceso de entrada en el mercado del sudeste asiático."
                    }
                ]
            },
            block2: {
                cards: [
                    {
                        num: 1,
                        name: "Búsqueda de vulnerabilidades",
                        text: "Las investigación exclusivas con base en nuestro propio soporte lógico el cual detecta vulnerabilidades de los recursos informáticos, los sitios web, las aplicaciones entre otros. Es no sólo la búsqueda de problemas, sino también las soluciones para la eliminación de los mismos."
                    },
                    {
                        num: 2,
                        name: "Desarrollo de software",
                        text: "Elaboración de las soluciones espaciales lógicas para garantizar la seguridad de infraestructura."
                    },
                    {
                        num: 3,
                        name: "Desarrollo de servicios analíticos",
                        text: "El desarrollo de herramientas para el análisis profundo de las RRSS: publicaciones, dinámicas y actividades de la audiencia. El análisis del tráfico de los sitios web y del tráfico de búsqueda."
                    },
                    {
                        num: 4,
                        name: "Procesamiento de datos",
                        text: "La recopilación y el procesamiento de grandes de datos y el desarrollo de las soluciones propias para optimizar el trabajo con grandes volúmenes de información."
                    }
                ]
            },
            block3: {
                imageUrl: "../img/safetyPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Protección de la reputación empresarial",
                        text: `Los abogados de "Masalóvich y asociados" ayudarán a restaurar la reputación comercial en caso de difamación en los medios de comunicación, eliminarán o bloquearán la información ilícita, y ayudarán a proteger los intereses del cliente en caso de un ataque informativo.`
                    },
                    {
                        num: 2,
                        name: "Litigios comerciales",
                        text: "No sólo proporcionaremos la información actual sobre el conflicto comercial, sino también podremos proporcionar asesoramiento legal y, de ser necesario, representar los intereses del cliente ante los tribunales de Rusia."
                    }
                ]
            }
        }
    },
    learning: {
        pageName: "Cursos",
        subMenuList: [
            {
                num: 1,
                label: "Inteligencia competitiva",
                anchor: "LearningService"
            },
            {
                num: 2,
                label: "Entrenamientos corporativos",
                anchor: "LearningCorporate"
            },
            { label: "Enviar consulta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/learningPage/img1.jpg",
            img1Mobile: "./img/learningPage/img1Mobile.jpg"
        },
        blocks: {
            service: {
                num: 1,
                name: "Inteligencia competitiva",
                textBlock1:
                    "En el mundo actual, la información es un recurso clave para el desarrollo exitoso de cualquier empresa. La inteligencia de fuentes abiertas (OSINT) juega un papel crucial en la recopilación y análisis de datos diversos. Gracias a la disponibilidad de la información abierta, la inteligencia de fuentes abiertas facilita el análisis de datos y la predicción del avance del proceso.",
                textBlock2:
                    "Le contaremos cómo OSINT puede ayudar al negocio a aprovechar la información de manera eficaz, mejorar la toma de decisiones estratégicas y guardar la confidencialidad, así como detectar amenazas y riesgos asociados con las actividades de competidores. Nuestras técnicas le permitirán revelar rápidamente las amenazas y oportunidades para su empresa, fortalecer su posición en el mercado y alcanzar nuevos picos.",
                linkName1: "",
                linkName2: "",
                anchor: "LearningService"
            },
            corporate: {
                num: 2,
                name: "Entrenamientos corporativos",
                textBlock1:
                    "El desarrollo cualitativo del personal es uno de los factores clave para el éxito de cualquier empresa. Es por eso los entrenamientos corporativos constituyen una parte inalienable de la estrategia empresarial efectiva.",
                textBlock2:
                    "Nuestros entrenamientos le ayudarán a aplicar sus conocimientos de OSINT y a proporcionar liderazgo en la esfera informativa para el desarrollo empresarial continuo.",
                linkName1: "",
                linkName2: "",
                anchor: "LearningCorporate"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Nivel básico",
                        text: "Fundamentos de la Inteligencia de Fuentes Abiertas (OSINT) y búsquedas en Internet."
                    },
                    {
                        num: 2,
                        name: "Nivel medio",
                        text: "Herramientas OSINT eficaces para trabajar en Internet y RRSS, garantía de la seguridad própia."
                    },
                    {
                        num: 3,
                        name: "Nivel avanzado",
                        text: "Búsqueda en IoT, trabajo en WireShark, la detección de Honeypot y otras herramientas avanzadas OSINT."
                    }
                ]
            }
        }
    },
    audience: {
        pageName: "Análisis de audiencia",
        subMenuList: [
            {
                num: 1,
                label: "Recopilación de estadísticas",
                anchor: "AudienceStatistic"
            },
            {
                num: 2,
                label: "Herramientas",
                anchor: "AudienceTools"
            },
            {
                num: 3,
                label: "Capacidades",
                anchor: "AudiencePossible"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/audiencePage/img1.jpg",
            img1Mobile: "./img/audiencePage/img1Mobile.jpg"
        },
        blocks: {
            helper: {
                num: 1,
                name: "Recopilación de estadísticas",
                textBlock1:
                    "Avalanche ayuda a recopilar y procesar rápidamente gran número de datos de las RRSS. Es una herramienta indispensable para varios sectores profesionales: desde las RRPP y marketing hasta la inteligencia corporativa. El análisis del público objetivo, aumento de seguidores, la actividad de ellos entre otros.",
                textBlock2:
                    "Se realiza un análisis de los datos abiertos en los medios sociales. Permite revelar las ondas de desinformación y los ataques de cuentas falsas (botnets). Se garantiza la rapidez en la obtención de datos, la transparencia y la objetividad de la información.",
                anchor: "AudienceStatistic"
            },
            tools: {
                num: 2,
                name: "Herramientas",
                textBlock1: "",
                textBlock2: "",
                anchor: "AudienceTools"
            },
            possibilities: {
                num: 3,
                name: "Capacidades",
                textBlock1:
                    "El usuario informa al sistema cuales son los grupos y las páginas de interés, y al pasar cierto período de recojida y procesamiento de datos, obtiene los primeros resultados. El análisis se efectúa para los datos del último año. El sistema procesa varios parámetros clave: el número de usuarios, de publicaciones, de 'me gusta', de comentarios y de vistas.",
                textBlock2:
                    "El sistema consolida la información y la presenta en forma interactiva .\n\n Para conocer las redes a ser procesaras, envíenos un correo electrónico..",
                linkName1: "",
                linkName2: "",
                anchor: "AudiencePossible"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "Vigilancia oculta de la actividad de fuentes de amenazas ad hoc."
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Análisis del interés y la evaluación de la respuesta del público objetivo en las redes globales hacia unos u otros problemas y temas."
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Análisis de las tensiones sociales."
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Supervisión de la actividad de las comunidades en los medios sociales."
                    }
                ]
            }
        }
    },
    scanner: {
        pageName: "Escáner de vulnerabilidades",
        subMenuList: [
            {
                num: 1,
                label: "Amenazas actuales",
                anchor: "ScannerPageDanger"
            },
            {
                num: 2,
                label: "Monitoreo de protección",
                anchor: "ScannerPageControl"
            },
            {
                num: 3,
                label: "Capacidades",
                anchor: "ScannerPagePossibilities"
            },
            {
                num: 4,
                label: "Ventajas",
                anchor: "ScannerPageBenifits"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/scannerPage/img1.jpg",
            img1Mobile: "./img/scannerPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Amenazas actuales",
                textBlock1:
                    "Hoy en día, los recursos Web son elementos inalienables de los procesos empresariales. Numerosos estudios muestran que más del 40% de los recursos tienen vulnerabilidades críticas. Los problemas de seguridad de los recursos Web son problemas en los procesos empresariales de toda compañía. Pueden provocar:",
                textBlock2:
                    "• revelación de la información confidencial ;\n • reducción de beneficios a causa de violación de los procesos empresariales ;\n • impacto negativo en la reputación de la empresa ;\n • acceso no autorizado a los datos personales de los clientes ;\n • intrusiones en los sistemas informáticos intrenos corporativos.",
                anchor: "ScannerPageDanger"
            },
            control: {
                num: 2,
                name: "Monitoreo de protección",
                textBlock1:
                    "Los malhechores detectan y utilizan todas las vulnerabilidades nuevas de los recursos web que contienen los datos sensibles. Los errores del soporte lógico, no actualización del software, viejas contraseñas entre otros, todo lo mencionado lleva a las vulnerabilidades serias en los proyectos de Internet.",
                textBlock2:
                    "Un escáner web es un conjunto de soluciones para garantizar el monitoreo de vulnerabilidades de un sistema informático con base en las tecnologías web. El escáner web permite implementar las funciones de detección, prevención y respuesta a intrusiones de los usuarios externos e internos de las redes informáticas.",
                anchor: "ScannerPageControl"
            },
            possibilities: {
                num: 3,
                name: "Capacidades",
                textBlock1: "",
                textBlock2: "",
                anchor: "ScannerPagePossibilities"
            },
            unnamed: {
                textBlock:
                    "Los métodos de monitoreo de la seguridad de los sitios web tienen en cuenta las amenazas actuales, los riesgos empresariales de las compañías y los requisitos legales."
            },
            benifits: {
                num: 4,
                name: "Beneficios",
                textBlock1: "",
                textBlock2: "",
                anchor: "ScannerPageBenifits"
            }
        },
        cards: {
            block1: [
                {
                    num: 1,
                    name: `Vulnerabilidades en la arquitectura`,
                    text: "Este tipo incluye las vulnerabilidades por errores de elaboración de las aplicaciones Web. Se realiza el chequeo integral por concepto de implementación de un código malicioso, acceso no autorizado y presencia de virus. El chequeo se efectúa en diferentes niveles desde la interfaz (vulnerabilidades XSS/CS- RF) hasta la base de datos (inyección SQL/NoSQL, de comando, etc.), el carácter correcto de procesamiento de los datos de usuarios, la existencia de protección contra bots."
                },
                {
                    num: 2,
                    name: "Vulnerabilidades de archivos y carpetas",
                    text: "Se chequea la existencia de los archivos y carpetas abiertos de acceso libre, incluyendo los sin enlaces directos. Se detectan las fuentes potenciales de filtraciones y fugas de información confidencial, se puede monitorear la aparición de los datos prohibidos en los índices de los motores de búsqueda. Se chequea la indexación por los motores de búsqueda de datos sensibles."
                },
                {
                    num: 3,
                    name: "Escáneres de configuración",
                    text: "Se analiza la configuración del sistema informático. Se determina la existencia de errores en la configuración del soporte físico y lógico (DNS, configuración del servidor web, etc.)."
                },
                {
                    num: 4,
                    name: "Errores de administración",
                    text: "Durante la instalación y el uso de la aplicación web se cometen muchos errores típicos que pueden provocar las consecuencias muy tristes. Es uso de contraseñas tipo, herramientas administrativas de terceros, así como código fuente abierto."
                },
                {
                    num: 5,
                    name: "Fenómenos sociales negativos",
                    text: "La búsqueda de varias informaciones negativas sobre el sitio web: publicaciones en foros de seguridad sobre la vulnerabilidad detectada, las solicitudes de jaquear el sitio web o hacer ataque DoS, la revelación de información sobre los mecanismos de seguridad utilizados. Si el sitio Web se basa en un modelo tipo de gestor de contenidos, el usuario recibe avisos de los problemas detectados en el gestor. "
                },
                {
                    num: 6,
                    name: "Generación de informes",
                    text: "Los resultados de trabajo de escáneres se organizan en un informe consolidado visualizado."
                }
            ],
            block1Mobile: {
                cards: [
                    {
                        num: 1,
                        name: "Vulnerabilidades en la arquitectura",
                        text: "Este tipo incluye las vulnerabilidades por errores de elaboración de las aplicaciones Web. Se realiza el chequeo integral por concepto de implementación de un código malicioso, acceso no autorizado y presencia de virus."
                    },
                    {
                        num: 2,
                        name: "Vulnerabilidades de archivos y carpetas",
                        text: "Se chequea la existencia de los archivos y carpetas abiertos de acceso libre, incluyendo los sin enlaces directos."
                    },
                    {
                        num: 3,
                        name: "Escáneres de configuración",
                        text: "Se analiza la configuración del sistema informático. Se determina la existencia de errores en la configuración del soporte físico y lógico (DNS, configuración del servidor web, etc.)."
                    },
                    {
                        num: 4,
                        name: "Errores de administración",
                        text: "Durante la instalación y el uso de la aplicación web se cometen muchos errores típicos que pueden provocar las consecuencias muy tristes. Es uso de contraseñas tipo, herramientas administrativas de terceros, así como código fuente abierto."
                    },
                    {
                        num: 5,
                        name: "Fenómenos sociales negativos",
                        text: "La búsqueda de varias informaciones negativas sobre el sitio web: publicaciones en foros de seguridad sobre la vulnerabilidad detectada, las solicitudes de jaquear el sitio web o hacer ataque DoS, la revelación de información sobre los mecanismos de seguridad utilizados."
                    },
                    {
                        num: 6,
                        name: "Generación de informes",
                        text: "Los resultados de trabajo de escáneres se organizan en un informe consolidado visualizado."
                    }
                ]
            },
            block2: [
                {
                    num: 1,
                    name: "",
                    text: "La supervisión continua del grado de seguridad de los sitios web de la compañía 24/7/365.",
                    imgLink: "./img/scannerPage/small1.jpg"
                },
                {
                    num: 2,
                    name: "",
                    text: "Los avisos a los encargados de ser detectadas unas vulnerabilidades críticas.",
                    imgLink: "./img/scannerPage/small2.jpg"
                },
                {
                    num: 3,
                    name: "",
                    text: "Los informes semanales sobre el grado actual de protección",
                    imgLink: "./img/scannerPage/small3.jpg"
                },
                {
                    num: 4,
                    name: "",
                    text: "Las recomendaciones para neutralizar las vulnerabilidades detectadas y el apoyo en materialización de las recomendaciones.",
                    imgLink: "./img/scannerPage/small4.jpg"
                },
                {
                    num: 5,
                    name: "",
                    text: "La reducción de los costos de implementación de la seguridad informática.",
                    imgLink: "./img/scannerPage/small5.jpg"
                },
                {
                    num: 6,
                    name: "",
                    text: "No aumenta la carga computacional en los recursos informáticos de la compañía.",
                    imgLink: "./img/scannerPage/small6.jpg"
                }
            ],
            block2Mobile: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "La supervisión continua del grado de seguridad de los sitios web de la compañía 24/7/365.",
                        imgLink: "./img/scannerPage/small1.jpg"
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Los avisos a los encargados de ser detectadas unas vulnerabilidades críticas.",
                        imgLink: "./img/scannerPage/small2.jpg"
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Los informes semanales sobre el grado actual de protección",
                        imgLink: "./img/scannerPage/small3.jpg"
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Las recomendaciones para neutralizar las vulnerabilidades detectadas y el apoyo en materialización de las recomendaciones.",
                        imgLink: "./img/scannerPage/small4.jpg"
                    },
                    {
                        num: 5,
                        name: "",
                        text: "La reducción de los costos de implementación de la seguridad informática.",
                        imgLink: "./img/scannerPage/small5.jpg"
                    },
                    {
                        num: 6,
                        name: "",
                        text: "No aumenta la carga computacional en los recursos informáticos de la compañía.",
                        imgLink: "./img/scannerPage/small6.jpg"
                    }
                ]
            }
        }
    },
    aboutUs: {
        pageName: "Quienes somos",
        subMenuList: [
            {
                num: 1,
                label: "Tte.Cnel. Andréi MASALÓVICH",
                anchor: "AboutUsPageMain"
            },
            {
                num: 2,
                label: "Misión",
                anchor: "AboutUsPageMission"
            },
            {
                num: 3,
                label: "Líneas de negocio",
                anchor: "AboutUsPageDirection"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/aboutUsPage/img1.jpg",
            img1Mobile: "./img/aboutUsPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Tte. Cnel. Andréi MASALÓVICH",
                textBlock1:
                    "El producto Avalanche apareció por primera vez en el mercado ruso en 2003. Su creador es el pionero en el mercado de inteligencia competitiva Tte. Cnel. del Servicio Federal de Comunicación Secreta Andréi MASALÓVICH. Durante más de 20 años, el sistema se utiliza para vigilar los sentimientos sociales y hacer frente a la amenaza terrorista por parte de los organismos de seguridad, las autoridades federales y regionales.",
                textBlock2:
                    "Hoy en día los sistemas analíticos con la plataforma tecnológica de Avalanche se utilizan ampliamente por las compañías comerciales para el monitoreo de los mercados, socios y competidores rusos y extranjeros.",
                linkName1: "",
                linkName2: "",
                anchor: "AboutUsPageMain"
            },
            education: {
                name: "Educación",
                textBlock1: "",
                textBlock2:
                    "2005 – curso “Gestión Corporativa”, York University (Toronto, Canadá)\n1994 – curso “Conversión & Privatización”, Universidad de Los Ángeles (EE.UU.)\n1992 – curso especial King’s School of English, (Londres, Reino Unido)\n1989 – curso de postgrado de MAI, Doctor en ciencias físicas y matemáticas\n1984 – Instituto Aeronáutico de Moscú, Facultad de Matemáticas aplicadas."
            },
            expert: {
                name: "Perito en Inteligencia Competitiva",
                textBlock1: "",
                textBlock2:
                    "Presidente del Grupo Inforus\nDoctor en ciencias físicas y matemáticas\nGanador de la beca RAN «Científico distinguido de Rusia» – 1993\nPremio IPCC «El mejor trabajo en el periodismo informático de Rusia» -1995\nPremio «TOP-profesional» (MKK, categorías «Negocio» и «Persona») – 2002"
            },
            unnamed: {
                name: "",
                textBlock1: `Autor de más de 100 obras impresas, los principales resultados de su trabajo tienen patentes norteamericanos. Tiene múltiples publicaciones en las revistas «Cuestiones de Radioelectrónica», PC Week, Mundo de PC, «Intercomputador», «Softmarket», «Mercado de valores» entre otras. En 1995 recibió el premio de la Asociación Internacional de periodistas IPCC «Mejor trabajo en periodismo informático de Rusia» por un ciclo de artículos sobre el tema de lógica difusa, . Participante y miembro de los CCOO de muchas conferencias internacionales. En 1995 recibió el agradecimiento de EuroPar Steering Committee por su contribución personal a la organización de la conferencia EuroPar-95 (Estocolmo). Participante y dirigente de más de 20 proyectos internacionales exitosos. A partir de 1998 ha organizado el desarrollo de un Sistema de programas analíticos para las compañías mercantiles y los consorcios. El programa "Gestión de Supermercado" ganó en el concurso "Software Empresarial 98 ". En 2000 constituyó la compañía InterRusSoft para diseñar los proyectos de Internet de encargo individual en el mercado mundial.`,
                textBlock2:
                    "Realizó varios proyectos exitosos en Internet, entre ellos www.trubka.ru, www.rusbiotech.ru, www.procurezone.com. Los proyectos recibieron unos premios de concursos online: «Reconocimiento del jurado» (Internit-2001), diploma «Sitio de oro - 2001» y etc..En 2001 se convirtió en presidente del Consorcio Inforus, que reunió a más de 80 empresas de TI líderes en Rusia. Experto RFFI (1995-2000), INTAS (1998) MTC OON (desde 2002), ATES (desde 2003). Miembro del jurado de concursos BIT (desde 2004 ) и «Premio del Runet (Internet ruso)» (desde 2005).Desarrollador y constructor principal del análisis tecnológico de Big Data – Avalanche.\n\nLas tecnologías de análisis de datos grandes son de gran relevancia, tanto en el ámbito empresarial como en el sector público."
            },
            mission: {
                num: 2,
                name: "Nuestra misión",
                textBlock1: "",
                textBlock2: `El Grupo "Masalóvich y asociados" fue constituido en el año 2020 por el reconocido perito en la seguridad informática Andrei Masalóvich, no obstante la historia de "Masalóvich y asociados" se remonta a 2003, cuando fue constituido el Grupo "Inforús" y fue creada la primera versión de la plataforma de monitoreo Avalanche. Desde hace más de 17 años, nuestro peritaje y desarrollos ayudamos a las empresas rusas y al poder público a hacer frente exitosamente a las amenazas informativas e informáticas. Tenemos cumplidos muchos proyectos de TI importantes y la experiencia en el aseguramiento informativo y analítico del poder federal y regional, los altos funcionarios, los consorcios de capital estatal, las empresas privadas, las industrias militares rusas.`,
                anchor: "AboutUsPageMission"
            },
            direction: {
                num: 3,
                name: "Líneas de negocio",
                textBlock1: "",
                textBlock2:
                    "Nuestro equipo se dedica a la seguridad integral que se basa en la recopilación y el análisis de los datos de las fuentes abiertas, incluyendo las comunicaciones, la seguridad económica e informática.\n\nAdemás, una de las principales líneas de negocio es el desarrollo de software corporativo.",
                anchor: "AboutUsPageDirection"
            }
        }
    },
    demonstration: {
        pageName: "Demo",
        subMenuList: [
            {
                num: 1,
                label: "Demo",
                anchor: "DemonstrationPageMain"
            },
            {
                num: 2,
                label: "Proyectos abiertos",
                anchor: "DemonstrationPublicProjects"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/demonstrationPage/img1.jpg"
        },
        blocks: {
            PublicProjects: {
                num: 2,
                name: "Proyectos abiertos",
                textBlock1:
                    "Para demostrar el trabajo de Avalanche usamos varios proyectos abiertos. El sistema consolida la información de miles de fuentes abiertas, la filtra por concepto de temas y la presenta en forma de listas de noticias. Es gratis.",
                textBlock2:
                    "Partiendo de la información procesada, el sistema analiza los temas de interés del público objetivo en las redes globales y avisa sobre las amenazas y los riesgos emergentes.",
                linkName1: "",
                linkName2: "",
                anchor: "DemonstrationPublicProjects"
            },
            iframe: {
                textBlock:
                    "Pantalla del gerente (presentación predefenida). Hay 8 temas principales para seguir la información sobre Covid 19. La vigilancia de la situación actual en Internet en cada uno de los distritos federales de Rusia.",

                anchor: "DemonstrationPublicProjects"
            }
        },
        cards: {
            block1: [
                {
                    num: 1,
                    name: "Líderes políticos",
                    text: "Las referencias a los principales líderes políticos del mundo en los medios extranjeros.",
                    linkName: "Ver todo",
                    externalLink: "https://leaders.avl.team/"
                },
                {
                    num: 2,
                    name: "Ministerios",
                    text: "La agenda de los ministerios y departamentos rusos en los medios nacionales.",
                    linkName: "Ver todo",
                    externalLink: "https://minister.avl.team/"
                },
                {
                    num: 3,
                    name: "Covid-19",
                    text: "Las noticias acerca del coronavirus en los medios regionales de Rusia, desglosadas por distritos federales.",
                    linkName: "Ver todo",
                    externalLink: "https://covid19.avl.team/"
                },
                {
                    num: 4,
                    name: "Búsqueda de vuelos",
                    text: "La recopilación de información sobre los vuelos baratos y las promociones de las aerolíneas del mundo.",
                    linkName: "Ver todo",
                    externalLink: "https://lowcost.avl.team/"
                },
                {
                    num: 5,
                    name: "Noticias de la movilización",
                    text: "Todo lo más importante sobre la campaña de movilización y las misiones de voluntariado.",
                    linkName: "Ver todo",
                    externalLink: "https://vstavai.avl.team/"
                },
                {
                    num: 6,
                    name: "OSINT&Cybersec",
                    text: "Las noticias del mundo de la inteligencia y ciberseguridad, el monitoreo de las filtraciones y fugas, la búsqueda de vulnerabilidades.",
                    linkName: "Ver todo",
                    externalLink: "https://osint.avl.team/"
                }
            ]
        }
    },
    contacts: {
        pageName: "Contacto",
        subMenuList: "",
        blocks: {
            contacts: {
                num: "",
                name: "Dirección:",
                textBlock1: "",
                textBlock2: (
                    <>
                        <div className="contacts-line-back bborder">
                            <span>1.</span>
                            <span>
                                Moscú, calle 2 Ostánkinskaya, 6, oficina 103
                            </span>
                        </div>
                        {/* <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span>+7 (499) 393-00-15</span>
                        </div> */}
                        <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span>
                                Para cuestiones generales: info@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>3.</span>
                            <span>Departamento de ventas: sales@avl.team</span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>4.</span>
                            <span>Servicio de soporte support@avl.team</span>
                        </div>
                    </>
                )
            },
            contactsTablet: {
                num: "",
                name: "Dirección:",
                textBlock1: "",
                textBlock2: (
                    <>
                        <div className="contacts-line-back bborder">
                            <span>1.</span>
                            <span className="text-right pl-15">
                                Moscú, calle 2 Ostánkinskaya, 6, oficina 103
                            </span>
                        </div>
                        {/* <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span className="text-right">
                                +7 (499) 393-00-15
                            </span>
                        </div> */}
                        <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span className="text-right">
                                Para cuestiones generales: info@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>3.</span>
                            <span className="text-right">
                                Departamento de ventas: sales@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>4.</span>
                            <span className="text-right">
                                Servicio de soporte support@avl.team
                            </span>
                        </div>
                    </>
                )
            }
        }
    },
    cookies: {
        pageName: "Cómo funcionan las cookies",
        subMenuList: [
            {
                num: 1,
                label: "Política de cookies",
                anchor: "CookiesPagePolitics"
            },
            {
                num: 2,
                label: "¿Qué son las cookies?",
                anchor: "CookiesPageWhatItIs"
            },
            {
                num: 3,
                label: "¿Cómo utilizamos las cookies?",
                anchor: "CookiesPageWhatWeUse"
            },
            {
                num: 4,
                label: "¿Qué tipos de cookies utilizamos?",
                anchor: "CookiesPageWhatTypes"
            },
            {
                num: 4,
                label: "¿Puedo cambiar la configuración de las cookies?",
                anchor: "CookiesPageCanChange"
            }
        ],
        imageUrls: {
            img1: "./img/cookiesPage/img1.jpg",
            img1Mobile: "./img/cookiesPage/img1Mobile.jpg"
        },
        blocks: {
            politics: {
                num: 1,
                name: "Política de cookies",
                textBlock1:
                    "Esta Política de cookies explica qué son las cookies, cómo y qué tipos de cookies utilizamos, así como cómo gestionar la configuración de las cookies. Para obtener más información sobre cómo utilizamos, almacenamos y protegemos sus datos personales, consulte nuestra Política de privacidad.",
                textBlock2:
                    "Puede modificar o revocar su consentimiento para el uso de cookies en nuestro sitio web en cualquier momento.",
                anchor: "CookiesPagePolitics"
            },
            whatItIs: {
                num: 2,
                name: "¿Qué son las cookies?",
                textBlock2:
                    "Las cookies son pequeños archivos de texto que se utilizan para almacenar pequeños fragmentos de información. Se guardan en su dispositivo cuando el sitio web se descarga en su navegador. Las cookies nos ayudan a garantizar que el sitio web funcione correctamente, que sea más seguro, que mejore la interacción con el usuario, que comprenda cómo funciona el sitio web y que analice qué funciona mal y dónde se necesita mejorar.",
                anchor: "CookiesPageWhatItIs"
            },
            whatWeUse: {
                num: 3,
                name: "¿Cómo utilizamos las cookies?",
                textBlock2:
                    "Al igual que la mayoría de los servicios en línea, nuestro sitio web utiliza cookies propias y de terceros para varios fines. Algunas de ellas son esenciales para el correcto funcionamiento del sitio web y no recogen datos personales. Las cookies de terceros utilizadas en nuestro sitio web están diseñadas para comprender cómo funciona el sitio web, cómo usted interactúa con nuestro sitio web, para garantizar la seguridad de nuestros servicios, proporcionar publicidad relevante, etc.",

                anchor: "CookiesPageWhatWeUse"
            },
            whatTypes: {
                num: 4,
                name: "Qué tipos de cookies utilizamos?",

                anchor: "CookiesPageWhatTypes"
            },
            canChange: {
                num: 5,
                name: "¿Puedo cambiar la configuración de las cookies?",
                textBlock1:
                    "Si decide cambiar la configuración más adelante, puede hacer clic en la pestaña «Política de privacidad y uso de cookies» en su pantalla. Esto mostrará de nuevo la notificación de consentimiento, lo que le permitirá cambiar sus preferencias o retirar completamente su consentimiento.",
                textBlock2:
                    "Además, los diferentes navegadores proporcionan diferentes formas de bloquear y eliminar las cookies utilizadas por los sitios web. Puede cambiar la configuración de su navegador para bloquear/eliminar cookies.",
                anchor: "CookiesPageCanChange"
            },
            cookies: {
                analytics: {
                    num: "",
                    name: "cookielawinfo-checkbox-analytics",
                    textBlock2:
                        "Esta cookie está instalada por el complemento GDPR Cookie Consent. La cookie se utiliza para almacenar el consentimiento del usuario para el uso de cookies en la categoría «Análisis»."
                },
                functional: {
                    num: "",
                    name: "cookielawinfo-checkbox-functional",
                    textBlock2:
                        "La cookie se establece mediante el consentimiento para el uso de cookies GDPR para registrar el consentimiento del usuario para las cookies en la categoría «Funcional»."
                },
                necessary: {
                    num: "",
                    name: "cookielawinfo-checkbox-necessary",
                    textBlock2:
                        "Estas cookies se establecen mediante el plugin GDPR Cookie Consent. Las cookies se utilizan para almacenar el consentimiento del usuario para el uso de cookies en la categoría «Necesario»."
                },
                others: {
                    num: "",
                    name: "cookielawinfo-checkbox-others",
                    textBlock2:
                        "Esta cookie está instalada por el complemento GDPR Cookie Consent. La cookie se utiliza para almacenar el consentimiento del usuario para el uso de cookies en la categoría «Otros»."
                },
                performance: {
                    num: "",
                    name: "cookielawinfo-checkbox-performance",
                    textBlock2:
                        "Esta cookie está instalada por el complemento GDPR Cookie Consent. La cookie se utiliza para almacenar el consentimiento del usuario para el uso de cookies en la categoría «Rendimiento»."
                },
                policy: {
                    num: "",
                    name: "viewed_cookie_policy",
                    textBlock2:
                        "La cookie se establece mediante el plugin GDPR Cookie Consent y se utiliza para almacenar información sobre si el usuario ha consentido el uso de cookies. No almacena ningún dato personal."
                }
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Básicas",
                        text: "Algunas cookies son necesarias para que pueda usar todas las funciones de nuestro sitio web. Nos permiten mantener sesiones personalizadas y prevenir cualquier amenaza a la seguridad y no recopilar ni almacenar ninguna información personal."
                    },
                    {
                        num: 2,
                        name: "Estadísticas",
                        text: "Estas cookies almacenan información como el número de visitantes del sitio web, el número de visitantes únicos, así como información sobre las páginas del sitio web visitadas, la fuente de la visita, etc. Estos datos nos ayudan a entender y analizar si funciona bien un sitio web y dónde necesita mejoras."
                    },
                    {
                        num: 3,
                        name: "Funcional",
                        text: "Son cookies que ayudan a algunas funciones no esenciales en nuestro sitio web. Estas características incluyen incrustar contenido como videos o compartir contenido de sitios web en plataformas de redes sociales."
                    },
                    {
                        num: 4,
                        name: "Preferencias",
                        text: "Estas cookies nos ayudan a guardar sus configuraciones y preferencias de navegación, como las preferencias de idioma, para que sus futuras visitas al sitio web sean cómodas."
                    }
                ]
            }
        }
    },
    conditions: {
        pageName: "Condiciones de uso",
        subMenuList: "",
        imageUrls: {
            img1: "./img/useConditionsPage/img1.jpg",
            img1Mobile: "./img/useConditionsPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "",
                textBlock1: `El servicio y sus componentes, desarrollados por el grupo de compañías "Masalóvich y asociados", están diseñados para tareas de monitoreo de Internet, así como el procesamiento y análisis de datos abiertos de Internet. Enfatizamos, solo utilizamos datos abiertos que están en libre acceso en Internet.`,
                textBlock2: (
                    <div
                        style={{ display: "inline-block", marginBottom: "2px" }}
                    >
                        {`Si utiliza nuestro Servicio de cualquier manera, por lo tanto, confirma su consentimiento para cumplir con las siguientes condiciones (todas las condiciones estipuladas a continuación se aplican tanto al Servicio en general como a todos sus componentes individualmente, pero no excluye los términos adicionales de la`}
                        <Link
                            to="/publicOfertaPage"
                            className="ml-5 "
                        >
                            <span className="bborder">oferta pública</span>
                        </Link>
                        {` ):`}
                    </div>
                ),
                anchor: ""
            },
            support: {
                name: "Términos de soporte técnico",
                line1: "Como parte del Soporte técnico, el Titular de los derechos de autor y/o sus Socios se comprometen a proporcionar los siguientes servicios:\n\r\u0020\u0020\u0020\u2022 actualización de nuevas versiones tanto del Servicio en su totalidad como de todos sus componentes a medida que estén disponibles; \n\r\u0020\u0020\u0020\u2022organización de consultas y resolución de incidencias (por correo electrónico – support@avl.team y a través de un formulario especial de comentarios)",
                line2_1:
                    "Modo temporal de registro de solicitudes de servicio en el sistema de soporte técnico (y a la dirección de correo electrónico oficial)",
                line2_2: "Las 24 horas del día (24х7)",
                line3_1: "Modo temporal de eliminación de incidentes críticos",
                line3_2: "De 10:00 a 20:00 huso horario de Moscú ",
                line4_1:
                    "Modo temporal para resolver otras solicitudes y tramitar solicitudes de información",
                line4_2:
                    "De 10:00 a 20:00 huso horario de Moscú (excepto fines de semana y días festivos)",
                line5_1: "Tiempo de respuesta a la solicitud",
                line5_2:
                    "4 horas (el tiempo de respuesta a la solicitud incluye solo el horario de trabajo)"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "En caso de compra de acceso al Servicio, usted tiene derecho a utilizar el Servicio en el número de ordenadores/dispositivos móviles especificados en el pedido."
                    },
                    {
                        num: 2,
                        name: "",
                        text: `El grupo de compañías "Masalóvich y asociados" garantiza el funcionamiento del Servicio de acuerdo con las condiciones descritas en el manual de instrucciones.`
                    },
                    {
                        num: 3,
                        name: "",
                        text: `El grupo de compañías "Masalóvich y asociados" no garantiza el funcionamiento del Servicio en caso de violación de las condiciones descritas en el manual de instrucciones, así como en caso de incumplimiento por el Usuario de las condiciones de uso del Servicio.`
                    },
                    {
                        num: 4,
                        name: "",
                        text: `El grupo de compañías "Masalóvich y asociados"» no se hace responsable de las interrupciones relacionadas con el uso del Servicio o la imposibilidad de utilizar el Servicio en caso de que la computadora del Usuario, los sistemas operativos y el software asociado no cumplan los requisitos del sistema.`
                    },
                    {
                        num: 5,
                        name: "",
                        text: `El grupo de compañías "Masalóvich y asociados" no se hace responsable del uso del Servicio por parte del Usuario con fines malintencionados. Al mismo tiempo, El grupo de compañías "Masalóvich y asociados" se reserva el derecho de monitorear y analizar la actividad del Usuario (de forma impersonal) con el fin de impedir el uso del Servicio con fines ilícitos que infrinjan la legislación de la Federación Rusa.`
                    },
                    {
                        num: 6,
                        name: "",
                        text: "El Usuario se compromete a no utilizar el Servicio para verificar la seguridad de los sitios sin el consentimiento de sus propietarios. El usuario es responsable, según lo establecido por la legislación de la Federación Rusa, de los controles de seguridad realizados y de todas las posibles consecuencias de dichos controles."
                    },
                    {
                        num: 7,
                        name: "",
                        text: "Está prohibido hacer la elaboración de vuelta (el diseñado de vuelta, la ingeniería de vuelta, el reverso-ingeniería), también la decompilación y/o la modificación del código inicial de cualesquiera nuestros productos."
                    },
                    {
                        num: 8,
                        name: "",
                        text: "El infractor incurre en responsabilidad civil, administrativa o penal por la violación de los derechos de autor de conformidad con la legislación de la Federación de Rusia."
                    },
                    {
                        num: 9,
                        name: "",
                        text: `Una vez activada la cuenta (excepto las versiones destinadas a fines de orientación), el Usuario adquiere el derecho de recibir Asistencia Técnica deд grupo de compañías "Masalóvich y asociados" durante el período de validez de la licencia.`
                    }
                ]
            }
        }
    },
    personalData: {
        pageName: "Acuerdo sobre el tratamiento de datos personales",
        subMenuList: "",
        imageUrls: {
            img1: "./img/personalDataAgreementPage/img1.jpg",
            img1Mobile: "./img/personalDataAgreementPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Acuerdo sobre el tratamiento de datos personales",
                textBlock1: "",
                textBlock2: "",
                anchor: ""
            }
        }
    },
    confidentionalPolitic: {
        pageName: "Política de privacidad",
        subMenuList: "",
        imageUrls: {
            img1: "./img/confidentionalPoliticPage/img1.jpg",
            img1Mobile: "./img/confidentionalPoliticPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Política de privacidad en relación con el tratamiento de datos personales",
                textBlock1: "",
                textBlock2: "",
                anchor: ""
            }
        }
    },
    publicOferta: {
        pageName: "Oferta pública",
        subMenuList: "",
        imageUrls: {
            img1: "./img/publicOfertaPage/img1.jpg",
            img1Mobile: "./img/publicOfertaPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Acuerdo de prestación de servicios (oferta pública)",
                textBlock1: "",
                textBlock2: (
                    <>
                        <span style={{ fontWeight: "bold" }}>
                            "Tiempo de publicación en el sitio web «01» de enero
                            de 2023.
                        </span>
                        <br />
                        <p>
                            ООО «ЛАВИНА ПУЛЬС» propone concluir el presente
                            Acuerdo para la prestación de Servicio de Suministro
                            derecho uso simple (no exclusivo) Licencia de
                            producto. La propuesta está dirigida a un grupo
                            indeterminado de personas físicas, jurídicas,
                            empresarios individuales y es una oferta pública de
                            conformidad con el artículo 437, párrafo 2, del
                            Código Civil de la Federación de Rusia.
                        </p>
                    </>
                ),
                anchor: ""
            },
            mainTablet: {
                num: "",
                name: "Acuerdo de prestación de servicios (oferta pública)",
                textBlock1: "",
                textBlock2: (
                    <>
                        <span style={{ fontWeight: "bold", width: "20vw" }}>
                            "Tiempo de publicación en el sitio web «01» de enero
                            de 2023.
                        </span>
                        <br />
                        <p>
                            ООО «ЛАВИНА ПУЛЬС» propone concluir el presente
                            Acuerdo para la prestación de Servicio de Suministro
                            derecho uso simple (no exclusivo) Licencia de
                            producto. La propuesta está dirigida a un grupo
                            indeterminado de personas físicas, jurídicas,
                            empresarios individuales y es una oferta pública de
                            conformidad con el artículo 437, párrafo 2, del
                            Código Civil de la Federación de Rusia.
                        </p>
                    </>
                ),
                anchor: ""
            }
        }
    },
    tariffs: {
        pageName: "Tarifas",
        subMenuList: [
            {
                num: 1,
                label: "Centro operativo virtual",
                anchor: "TariffsPageCenter"
            },
            {
                num: 2,
                label: "Licencias e implementación",
                anchor: "TariffsPageLicence"
            },
            { label: "Enviar consulta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/tariffsPage/img1.jpg",
            img1Mobile: "./img/tariffsPage/img1Mobile.jpg",
            img2: "./img/tariffsPage/img2.jpg",
            img2Mobile: "./img/tariffsPage/img2Mobile.jpg"
        },
        blocks: {
            center: {
                num: 1,
                name: "Centro operativo virtual",
                textBlock1: "",
                textBlock2:
                    "El servicio de monitoreo del espacio de Internet proporciona al cliente la capacidad de un Centro operativo moderno para monitorear el entorno operativo y la detección temprana de amenazas de Internet. Для коммерческих Заказчиков предусмотрено 3 тарифных плана.",
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageCenter"
            },
            licence: {
                num: 2,
                name: "Licencias e implementación",
                textBlock1:
                    "Al implementar el sistema en el hardware del Cliente, es posible realizar revisiones personalizadas del software de acuerdo con los requisitos del Cliente.",
                textBlock2: (
                    <span
                        style={{
                            fontSize: 30 + "px",
                            paddingLeft: 20 + "px",
                            lineHeight: 1.2,
                            position: "relative",
                            top: -5 + "px"
                        }}
                    >
                        Costo por licencia de servidor sin personalización – 15
                        millones de rublos ₽.
                    </span>
                ),
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageLicence"
            },
            licenceTablet: {
                num: 2,
                name: "Licencias e implementación",
                textBlock1:
                    "Al implementar el sistema en el hardware del Cliente, es posible realizar revisiones personalizadas del software de acuerdo con los requisitos del Cliente.",
                textBlock2: (
                    <span
                        className="fs-20-20"
                        // style={{
                        //     fontSize: 30 + "px",
                        //     paddingLeft: 20 + "px",
                        //     lineHeight: 1.2,
                        //     position: "relative",
                        //     top: -5 + "px"
                        // }}
                    >
                        Costo por licencia de servidor sin personalización – 15
                        millones de rublos ₽.
                    </span>
                ),
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageLicence"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Desarrollo del autor",
                        text: "El sistema tiene como base la tecnología original nacional de rastreo de internet y la construcción de bases de datos distribuidos."
                    },
                    {
                        num: 2,
                        name: "Análisis profundo de datos",
                        text: "El juego aumentado de los algoritmos modernos para el procesamiento de datos grandes, incluyendo el análisis rápido, pronóstico, modelado entre otros."
                    },
                    {
                        num: 3,
                        name: "Flexibilidad y adaptabilidad",
                        text: "Apoyo de la integración con bases de datos estatales, departamentales y especializadas. Capacidad de integración de fuentes de datos externas."
                    },
                    {
                        num: 4,
                        name: "Código abierto",
                        text: "Todos los componentes del complejo están desarrollados en Rusia y se basan en productos de software de código abierto."
                    }
                ]
            }
        },
        table: {
            top: {
                column1: {
                    name: "estándar",
                    price: "de 150 mil rublos ₽ / por mes"
                },
                column2: {
                    name: "óptimo",
                    price: "de 300 mil rublos ₽ / por mes"
                },
                column3: {
                    name: "premium",
                    price: "de 500 mil rublos ₽ / por mes"
                }
            },
            info: [
                {
                    name: "Proyecto en el sistema Avalanche",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Canal privado en Telegram",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Informe analítico mensual",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Soporte analítico",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Informe analítico semanal",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Complicaciones semanales de noticias",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Informes analíticos",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "(hasta 3)",
                    premiumValueExtrainfo: "(hasta 5)"
                },
                {
                    name: "Canal privado en Telegram con transmisión en línea de noticias clave",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Informes diarios",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Monitoreo de la actividad de los competidores\n\r(no más de 5 compañías)",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Desarrollo y actualización de un mapa de riesgos y amenazas",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Búsqueda de vulnerabilidades de reputación",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Trabajos adicionales de armonización",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                }
            ]
        }
    },
    question: {
        pageName: "Hacer pregunta",
        subMenuList: ""
    },
    request: {
        pageName: "Enviar consulta",
        subMenuList: ""
    },
    publications: {
        pageName: "Publicaciones",
        subMenuList: "",
        imageUrls: {
            img1: "./img/publicationsPage/img1.jpg",
            img1Mobile: "./img/publicationsPage/img1Mobile.jpg"
        }
    },
    publicActivity: {
        pageName: "Actividades públicas",
        subMenuList: [
            {
                num: 1,
                label: "Publicaciones",
                anchor: "publicActivityPageMain"
            },
            {
                num: 2,
                label: `Libro "Ciberabuelo sabe"`,
                anchor: "publicActivityPageBook"
            },
            {
                num: 3,
                label: "Canales de Telegram",
                anchor: "publicActivityPageTG"
            },
            {
                num: 4,
                label: "Redes sociales",
                anchor: "publicActivityPageSocial"
            },
            { label: "Hacer pregunta", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/publicActivityPage/img1.jpg",
            img1Mobile: "./img/publicActivityPage/img1Mobile.jpg",
            img2Mobile: "./img/publicActivityPage/img2Mobile.jpg",
            middle1: "./img/publicActivityPage/middle1.jpg",
            middle2: "./img/publicActivityPage/middle2.jpg",
            middle3: "./img/publicActivityPage/middle3.jpg"
        },
        blocks: {
            publications: {
                num: 1,
                name: "Publicaciones",
                textBlock1: "",
                textBlock2: "",
                linkName1: "Ver todo",
                link1: "/publicationsPage",
                linkName2: "",
                anchor: "publicActivityPageMain"
            },
            cyberDad: {
                num: 2,
                name: `Libro\r\n«Ciberabuelo sabe»`,
                textBlock1: "",
                textBlock1Img: "./img/publicActivityPage/small1.jpg",
                imgLink: "./img/publicActivityPage/small1.jpg",
                textBlock2: `Andrei Masalóvich, empresario, explorador de Internet y autor del canal en Youtube "Ciberabuelo", está convencido: cualquier fracaso es siempre un golpe cósmico para buscar oportunidades. Lo más importante es dejar de ser el cuento de nunca acabar y no rendirse. Cuanto más bajo sea el punto desde el que empiezas, más aplastante será la victoria. Pasó los años -90, -00, ganó dinero real con las crisis, el autor le enseñará las reglas contra la fragilidad en la realidad rusa.`,
                linkName1: "Comprar",
                link1: "https://eksmo.ru/book/belyy-pisets-10-sovetov-ot-kiberdeda-kak-priyti-k-uspekhu-v-rossii-ITD1193720/",
                linkName2: "",
                anchor: "publicActivityPageBook"
            },
            tg: {
                num: 3,
                name: "Canales de Telegram",
                textBlock1: "",
                textBlock2: "",
                linkName1: "",
                link1: "",
                linkName2: "",
                anchor: "publicActivityPageTG"
            },
            social: {
                num: 4,
                name: "Redes sociales",
                textBlock1: "",
                textBlock2: "",
                linkName1: "",
                link1: "",
                linkName2: "",
                anchor: "publicActivityPageSocial"
            }
        },
        cardsBlock: {
            cards: [
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle1.jpg"
                },
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle2.jpg"
                },
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle3.jpg"
                }
            ]
        },
        telegram: {
            channel1: "Masalóvich y asociados",
            channel1Link: "https://t.me/maspartners",
            channel2: "Mycroft Intelligence",
            channel2Link: "https://t.me/mycroftintel"
        },
        social: {
            channel1: "Dzen Ciberabuelo sabe",
            channel1Link: "https://dzen.ru/kiberded",
            channel2: "Masalóvich y asociados",
            channel2Link: "https://vk.com/@mycroftintel-bspltn",
            channel3: "Dzen Mycroft Intelligence",
            channel3Link: "https://dzen.ru/mycroftintel"
        }
    }
};
export default contentEs;
