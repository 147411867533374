import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import AgreementsBlockMobile from "../pageBlocksMobile/agreementsBlockMobile";

const ConfidentialPoliticPageMobile = () => {
    const { content, agreements } = useLanguage();
    const pageName = content.confidentionalPolitic.pageName;
    const imageUrls = content.confidentionalPolitic.imageUrls;
    const blocks = content.confidentionalPolitic.blocks;
    const confidentialAgreement = agreements.confidential;
    console.log(
        "confidentialAgreement in confPoliticPage",
        confidentialAgreement
    );
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                bottomBorder={true}
                customNameLine="90"
                customNameWidth="85"
                customHeight={130}
                dropdownDisable={true}
                rightArrow={true}
            />
            <AgreementsBlockMobile blockContent={confidentialAgreement} />
        </div>
    );
};

export default ConfidentialPoliticPageMobile;
