import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import SendRequest from "../pageBlocks/sendRequest";
// import content from "../textContent";
import CardsAdaptiveBlockTablet from "../cardsBlocks/cardsAdaptiveBlockTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"

import LargeImageTablet from "../LargeImageTablet";
const LearningPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.learning.pageName;
    const subMenuList = content.learning.subMenuList;
    const imageUrls = content.learning.imageUrls;
    const blocks = content.learning.blocks;
    const cards = content.learning.cards.block1;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            {/* <HorizontalBlockAdaptive blockContent={blocks.courses} />
            <HorizontalBlockAdaptive blockContent={blocks.academy} /> */}
            <HorizontalBlockAdaptiveTablet blockContent={blocks.service} />
            <HorizontalBlockAdaptiveTablet 
            blockContent={blocks.corporate}
            bottomBorder = {true}/>
            <CardsAdaptiveBlockTablet
                blockContent={cards.cards}
               
                topBorderCard={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                paddingTopBlock = {true}
            />
            {/* <SendRequest
                center={false}
                licence={false}
                learning={true}
            /> */}
               <ContactUsTablet/>
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default LearningPageTablet;
