import React from "react";

import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import HorizontalBlockUnnamedMobile from "../pageBlocksMobile/horizontalBlockUnnamedMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";

const MainPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.mainPage.pageName;
    const imageUrls = content.mainPage.imageUrls;

    const forBusiness = content.mainPage.blocks.forBusiness;
    const safety = content.mainPage.blocks.safety;
    const platform = content.mainPage.blocks.platform;
    const situationCenter = content.mainPage.blocks.situationCenter;
    const analysis = content.mainPage.blocks.analysis;
    const vulnerabilitiesScan = content.mainPage.blocks.vulnerabilitiesScan;
    const analytics = content.mainPage.blocks.analytics;
    const unnamedBlock = content.mainPage.blocks.unnamedBlock;
    const aboutUs = content.mainPage.blocks.aboutUs;
    const shortBlock = content.mainPage.blocks.shortBlock;
    const cardsWithImg = content.mainPage.blocks.cardsBlock;
    return (
        <div className="mainPageMobile">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile blockContent={forBusiness} />
            <HorizontalBlockDropdownMobile
                blockContent={safety}
                bottomBorder={true}
            />
            <LargeImageMobile imageUrl={imageUrls.img2Mobile} />

            <HorizontalBlockDropdownMobile
                blockContent={platform}
                customHeight={280}
            />
            <HorizontalBlockDropdownMobile
                blockContent={situationCenter}
                smallHeight={true}
                lowName={true}
                customHeight={330}
            />
            <HorizontalBlockDropdownMobile
                blockContent={analysis}
                lowName={true}
                smallHeight={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={vulnerabilitiesScan}
                lowName={true}
                doubleLineName={true}
                smallHeight={true}
                customHeight={380}
            />
            <HorizontalBlockDropdownMobile
                blockContent={analytics}
                lowName={true}
                smallHeight={true}
                customHeight={330}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={unnamedBlock}
                alternativeType={true}
                textBlockWidth={82}
                nameFontSize="22"
                rightArrow={false}
            />
            <LargeImageMobile imageUrl={imageUrls.img3Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={aboutUs}
                customHeight={330}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={shortBlock}
                alternativeType={true}
            />
            <CardsSwipeMobile
                blockContent={cardsWithImg}
                bottomBorderBlock={false}
            />
        </div>
    );
};

export default MainPageMobile;
