import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import HorizontalBlockUnnamedMobile from "../pageBlocksMobile/horizontalBlockUnnamedMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";

const CookiesPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.cookies.pageName;
    const imageUrls = content.cookies.imageUrls;
    const blocks = content.cookies.blocks;
    const cookiesBlocks = content.cookies.blocks.cookies;
    const cards = content.cookies.cards;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.politics}
                tripleLineName={true}
                customHeight={240}
                dropdownDisable={true}
                noLink={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.whatItIs}
                doubleLineName={true}
                customHeight={240}
                dropdownDisable={true}
                noLink={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.whatWeUse}
                tripleLineName={true}
                customHeight={240}
                dropdownDisable={true}
                noLink={true}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.whatTypes}
                alternativeType={true}
                rightArrow={true}
                leftIndent={false}
            />
            <CardsSwipeMobile
                blockContent={cards.block1}
                topBorderCard={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={cookiesBlocks.analytics}
                customHeight={window.innerWidth < 360 ? 140 : 100}
                dropdownDisable={true}
                noLink={true}
                rightArrow={true}
                lowName={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={cookiesBlocks.functional}
                lowName={true}
                customHeight={window.innerWidth < 360 ? 140 : 100}
                dropdownDisable={true}
                noLink={true}
                rightArrow={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={cookiesBlocks.necessary}
                lowName={true}
                customHeight={window.innerWidth < 360 ? 140 : 100}
                dropdownDisable={true}
                noLink={true}
                rightArrow={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={cookiesBlocks.others}
                lowName={true}
                customHeight={window.innerWidth < 360 ? 140 : 100}
                dropdownDisable={true}
                noLink={true}
                rightArrow={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={cookiesBlocks.performance}
                lowName={true}
                customHeight={window.innerWidth < 360 ? 140 : 100}
                dropdownDisable={true}
                noLink={true}
                rightArrow={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={cookiesBlocks.policy}
                lowName={true}
                customNameLine="0"
                customHeight={window.innerWidth < 360 ? 140 : 100}
                dropdownDisable={true}
                noLink={true}
                rightArrow={true}
            />

            <HorizontalBlockDropdownMobile
                blockContent={blocks.canChange}
                customNameLine={window.innerWidth < 360 ? "140" : "120"}
                customHeight={250}
                dropdownDisable={true}
                noLink={true}
                rightArrow={true}
                bottomBorder={true}
            />
        </div>
    );
};

export default CookiesPageMobile;
