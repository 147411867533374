import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const random = () => Math.floor(Math.random() * 1000);

const CardsWithArrowsAdaptive = ({
    blockContent,
    arrowsDirection,
    bottomBorderBlock = false,
    topBorderbBlock = false,
    // bottomBorderCard = false,
    // topBorderCard = false,
    // marginTopNum = false,
    // marginTopName = false,
    marginBottomName = false
    // marginTopText = false,
    // marginTopImg = false,
    // marginTopLink = false
}) => {
    const [tempClass, setTempClass] = useState();
    const [nameHeight, setNameHeight] = useState();
    // const [cardHeight, setCardHeight] = useState();
    const [textHeight, setTextHeight] = useState();
    useEffect(() => {
        setTempClass(`cardsWithArrowsAdaptive${random()}`);
    }, []);
    const getHeightName = () => {
        let maxHeightName = Object.values(
            document.querySelectorAll(`.${tempClass}name`)
        ).map((el) => el.offsetHeight);
        setNameHeight(Math.max.apply(null, maxHeightName));
    };
    const getHeightText = () => {
        let maxHeightText = Object.values(
            document.querySelectorAll(`.${tempClass}text`)
        ).map((el) => el.offsetHeight);
        setTextHeight(Math.max.apply(null, maxHeightText));
    };
    const setHeight = () => {
        const maxHeightNameSet = Object.values(
            document.querySelectorAll(`.${tempClass}name`)
        );
        maxHeightNameSet.map((el) => (el.style.height = nameHeight + "px"));
        const maxHeightTextSet = Object.values(
            document.querySelectorAll(`.${tempClass}text`)
        );
        maxHeightTextSet.map((el) => (el.style.height = textHeight + "px"));
    };

    setTimeout(() => {
        getHeightName();
        getHeightText();
        setHeight();
    }, 200);

    const arrowDirection = (direction) => {
        if (direction === "right") {
            return "cardsWithArrowsAdaptive-arrows-rightDirection imgBack";
        } else if (direction === "left") {
            return "cardsWithArrowsAdaptive-arrows-leftDirection imgBack";
        }
    };
    const blockProps = (direction, index) => {
        if (direction === "right") {
            return index !== blockContent.cards.length - 1
                ? "cardsWithArrowsAdaptive-cards-card-back ml-45 pr-45 pl-85 lborder"
                : "cardsWithArrowsAdaptive-cards-card-back  pl-85 lborder";
        } else if (direction === "left") {
            return index !== blockContent.cards.length - 1
                ? "cardsWithArrowsAdaptive-cards-card-back mr-85 pr-85  rborder"
                : "cardsWithArrowsAdaptive-cards-card-back mr-45 pr-85 rborder";
        }
    };
    const imgProps = (direction) => {
        if (direction === "right") {
            return "cardsWithArrowsAdaptive-image mt-60 mb-60 ml-85 pl-85 lborder";
        } else if (direction === "left") {
            return "cardsWithArrowsAdaptive-image mt-60 mb-60 ml-45 mr-45 pr-85 rborder";
        }
    };
    return (
        <div
            className={`cardsWithArrowsAdaptive  ${
                topBorderbBlock ? " tborder" : ""
            } ${bottomBorderBlock ? " bborder" : ""} `}
        >
            {arrowsDirection === "right" && (
                <div className="cardsWithArrowsAdaptive-arrows-back pt-60 pb-60">
                    {blockContent.cards[0].name ? (
                        <>
                            <div className="cardsWithArrowsAdaptive-arrows">
                                <div
                                    className={arrowDirection(arrowsDirection)}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-30`}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-30`}
                                ></div>
                            </div>
                            <div className="cardsWithArrowsAdaptive-arrows">
                                <div
                                    className={arrowDirection(arrowsDirection)}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-30`}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-30`}
                                ></div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={arrowDirection(arrowsDirection)}
                            ></div>
                            <div
                                className={`${arrowDirection(
                                    arrowsDirection
                                )} mt-30`}
                            ></div>
                            <div
                                className={`${arrowDirection(
                                    arrowsDirection
                                )} mt-30`}
                            ></div>
                        </>
                    )}
                </div>
            )}
            <div className={`cardsWithArrowsAdaptive-cards-back pt-60 pb-60 `}>
                {blockContent.cards.map((card, index) => (
                    <div
                        className={blockProps(arrowsDirection, index)}
                        key={index}
                    >
                        <div className="cardsWithArrowsAdaptive-cards-card tborder">
                            {card.num && (
                                <div className="cardsWithArrowsAdaptive-cards-card-num fs-64 mt-60">
                                    0{card.num}
                                </div>
                            )}
                            {card.name && (
                                <div
                                    className={`cardsWithArrowsAdaptive-cards-card-name fs-40 mt-60 ${tempClass}name ${
                                        marginBottomName ? "mb-60" : ""
                                    }`}
                                >
                                    {card.name}
                                </div>
                            )}

                            <div
                                className={`cardsWithArrowsAdaptive-cards-card-text fs16 mt-60 ${tempClass}text `}
                            >
                                {card.text}
                            </div>
                            {card.linkName && (
                                <Link
                                    to={{
                                        pathname: card.externalLink
                                            ? card.externalLink
                                            : "/"
                                    }}
                                    target="_blank"
                                    className="cardsWithArrowsAdaptive-textBlock-link  fs-32 cp "
                                >
                                    <span className="cardsWithArrowsAdaptive-textBlock-link-name ">
                                        {card.linkName}
                                    </span>
                                    <div className="cardsWithArrowsAdaptive-textBlock-link-arrow imgBack "></div>
                                </Link>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {blockContent.imageUrl && (
                <img
                    src={blockContent.imageUrl}
                    alt="Loading Error"
                    className={imgProps(arrowsDirection)}
                    // align="left"
                />
            )}
            {arrowsDirection === "left" && (
                <div className="cardsWithArrowsAdaptive-arrows-back pt-60 pb-60">
                    {blockContent.cards[0].name ? (
                        <>
                            <div className="cardsWithArrowsAdaptive-arrows">
                                <div
                                    className={arrowDirection(arrowsDirection)}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-30`}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-30`}
                                ></div>
                            </div>
                            <div className="cardsWithArrowsAdaptive-arrows">
                                <div
                                    className={arrowDirection(arrowsDirection)}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-30`}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-30`}
                                ></div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={arrowDirection(arrowsDirection)}
                            ></div>
                            <div
                                className={`${arrowDirection(
                                    arrowsDirection
                                )} mt-30`}
                            ></div>
                            <div
                                className={`${arrowDirection(
                                    arrowsDirection
                                )} mt-30`}
                            ></div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CardsWithArrowsAdaptive;
