import React from "react";
import { useLanguage } from "../../hooks/useLang";

import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";

const SituationCenterPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.situationCenter.pageName;
    const imageUrls = content.situationCenter.imageUrls;
    const blocks = content.situationCenter.blocks;
    const cards = content.situationCenter.cards;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                doubleLineName={true}
                customHeight={window.innerWidth < 360 ? 240 : 210}
                dropdownDisable={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.about}
                noLink={true}
                doubleLineName={true}
                customHeight={210}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block1}
                topBorderbBlock={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.benifits}
                noLink={true}
                tripleLineName={true}
                customHeight={210}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block2}
                topBorderbBlock={true}
                customNameFontSize="30"
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.outsourse}
                noLink={true}
                tripleLineName={true}
                customHeight={210}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block3}
                topBorderbBlock={true}
            />
        </div>
    );
};

export default SituationCenterPageMobile;
