import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";

import SiteMapTablet from "../../footerTablet/siteMapTablet"


const ContactsPageTablet = ({ darkTheme }) => {
    const { content } = useLanguage();

    const pageName = content.contacts.pageName;
    const subMenuList = content.contacts.subMenuList;
    const contacts = content.contacts.blocks.contactsTablet;
    return (
        <div
            className="mainPageTablet"
            id="ContactsPageMap"
        >
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <YMaps query={{ lang: "en_RU" }}>
                <div
                    className="mb-150 prel  t-105"// mb-40
                    style={
                        darkTheme
                            ? {
                                  filter: "invert(1) hue-rotate(200deg)",
                                  //   filter: "grayscale(1) invert(1)",
                                  width: "100%",
                                  height: "700px"
                              }
                            : {
                                  filter: "invert(0) hue-rotate(0deg)",
                                  width: "100%",
                                  height: "700px"
                              }
                    }
                >
                    <Map
                        id="yandexMaps"
                        defaultState={{
                            center: [55.825482, 37.625513],
                            zoom: 13.5
                        }}
                        width="100%"
                        height="700px"
                      
                        // style={
                        //     darkTheme
                        //         ? {
                        //               filter: "invert(1) hue-rotate(180deg)",
                        //               //   filter: "grayscale(1) invert(1)",
                        //               width: "100%",
                        //               height: "1000px"
                        //           }
                        //         : {
                        //               filter: "invert(0) hue-rotate(0deg)",
                        //               width: "100%",
                        //               height: "1000px"
                        //           }
                        // }
                    >
                        <Placemark
                            geometry={{
                                type: "Point",
                                coordinates: [55.825482, 37.625513]
                            }}
                            options={{
                                preset: darkTheme
                                    ? "islands#redDotIcon"
                                    : "islands#darkBlueDotIcon",
                                iconImageSize: [100, 100]
                            }}
                        />

                        {/* <Button
                            options={{
                                maxWidth: 528,
                                size: "auto",
                                position: { top: "60px", right: "60px" }
                            }}
                            data={{
                                content: (
                                    <span style={{ fontSize: "24px" }}>
                                        Москва, ул.Электродная, 11, к.203
                                    </span>
                                )
                            }}
                            defaultState={{
                                selected: false
                            }}
                        /> */}
                        <ZoomControl options={{ float: "right" }} />
                      
                        <div
                            style={{
                                color: "black",
                                fontSize: "20px",
                                fontWeight: "bold",
                                position: "absolute",
                                top: 40 + "px",
                                right: 40 + "px",
                                backgroundColor: "white",
                                 padding: "10px 40px 10px 40px",
                                zIndex: 10
                            }}
                        >
                            55.825482, 37.625513
                        </div>
                        
                    </Map>
                </div>
            </YMaps>
            <HorizontalBlockAdaptiveTablet
                blockContent={contacts}
                nameSize={"fs-44"}
                bottomBorder={true}
                contactsWidth={true}
                customNameWidthContacts={true}
                titileTabletForwidth={true}
                contactsTopforName={true}
            />

            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default ContactsPageTablet;
