import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";
import HorizontalBlockUnnamedMobile from "../pageBlocksMobile/horizontalBlockUnnamedMobile";

const ScannerPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.scanner.pageName;
    const imageUrls = content.scanner.imageUrls;
    const blocks = content.scanner.blocks;
    const cards = content.scanner.cards;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                smallHeight={true}
                doubleLineName={true}
                customHeight={window.innerWidth < 360 ? 300 : 250}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.control}
                noLink={true}
                smallHeight={true}
                doubleLineName={true}
                customHeight={window.innerWidth < 360 ? 350 : 320}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.possibilities}
                alternativeType={true}
                rightArrow={false}
                nameFontSize="32"
                leftIndent={false}
            />
            <CardsSwipeMobile
                blockContent={cards.block1Mobile}
                topBorderCard={true}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.unnamed}
                alternativeType={true}
                textBlockWidth={80}
                nameFontSize="20"
                rightArrow={false}
                leftIndent={true}
                bottomBorder={false}
            />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.benifits}
                alternativeType={true}
                rightArrow={false}
                nameFontSize="32"
                leftIndent={false}
            />
            <CardsSwipeMobile
                blockContent={cards.block2Mobile}
                topBorderCard={true}
            />
        </div>
    );
};

export default ScannerPageMobile;
