import React from "react";
import { useLanguage } from "../hooks/useLang";

import CardsAdaptiveBlock from "../pageBlocks/cardsBlocks/cardsAdaptiveBlock";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import HorizontalBlockDropdown from "../pageBlocks/horizontalBlockDropdown";
import LargeImage from "../pageBlocks/largeImage";

import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const PlatformPage = () => {
    const { content } = useLanguage();
    const pageName = content.platform.pageName;
    const subMenuList = content.platform.subMenuList;
    const imageUrls = content.platform.imageUrls;
    const cards = content.platform.sixCardsBlock;
    const blocks = content.platform.blocks;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive
                blockContent={blocks.imageBlock}
                notAnyPoints={true}
            />
            {/* <div id="PlatformPagePlatform"></div> */}
            <CardsAdaptiveBlock
                blockContent={cards}
                topBorderCard={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                paddingBottomBlock={true}
            />
            <HorizontalBlockAdaptive blockContent={blocks.ourProducts} />
            <HorizontalBlockDropdown blockContent={blocks.situationCenter} />
            <HorizontalBlockDropdown blockContent={blocks.analysis} />
            <HorizontalBlockDropdown
                blockContent={blocks.vulnerabilitiesScan}
            />
            <HorizontalBlockDropdown blockContent={blocks.analytics} />
        </div>
    );
};

export default PlatformPage;
