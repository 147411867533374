import React from "react";
import { useLanguage } from "../hooks/useLang";

import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";
// import agreements from "../agreementsTextEn";
import AgreementsBlock from "../pageBlocks/agreementsBlock";

const PublicOfertaPage = () => {
    const { content, agreements } = useLanguage();
    const pageName = content.confidentionalPolitic.pageName;
    const subMenuList = content.confidentionalPolitic.subMenuList;
    const imageUrls = content.confidentionalPolitic.imageUrls;
    const blocks = content.confidentionalPolitic.blocks;
    const confidentialAgreement = agreements.confidential;
    console.log(
        "confidentialAgreement in confPoliticPage",
        confidentialAgreement
    );
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive
                blockContent={blocks.main}
                nameSize={"fs-64"}
            />
            <AgreementsBlock blockContent={confidentialAgreement} />
        </div>
    );
};

export default PublicOfertaPage;
