import React from "react";
import { Link } from "react-router-dom";

const HorizontalBlockUnnamedMobile = ({
    blockContent,
    bottomBorder = true,
    topBorder = false,
    alternativeType = false,
    classicType = false,
    fulltype = false,
    onlyName = false,
    nameFontSize = "24",
    textBlockFontSize = "14",
    textBlockWidth = 70,
    secondTextBlockFontSize = "14",
    secondTextBlockWidth = 70,
    leftIndent = true,
    rightArrow = true,
    noLink = true
}) => {
    return (
        <>
            {alternativeType && (
                <div
                    className={`horizontalBlockUnnamedMobile-alternativeType  pt-40 pb-40 ${
                        bottomBorder ? "bborder" : ""
                    }`}
                >
                    <div
                        className={`horizontalBlockUnnamedMobile-alternativeType-text   fs-${nameFontSize}`}
                        style={
                            leftIndent
                                ? {
                                      width: `${textBlockWidth}%`,
                                      marginLeft: `Calc(${
                                          100 - textBlockWidth
                                      }% + 15px)`
                                  }
                                : { width: `${textBlockWidth}%` }
                        }
                    >
                        {blockContent.textBlock
                            ? blockContent.textBlock
                            : blockContent.textBlock2
                            ? blockContent.textBlock
                            : blockContent.name}
                    </div>
                    <div
                        className="horizontalBlockUnnamedMobile-alternativeType-leftArrow imgBack"
                        style={
                            rightArrow
                                ? { visibility: "visible" }
                                : { visibility: "hidden" }
                        }
                    ></div>
                </div>
            )}
            {classicType && (
                <div
                    className={`horizontalBlockUnnamedMobile-classicType  pt-40 pb-40 ${
                        bottomBorder ? "bborder" : ""
                    } ${topBorder ? "tborder" : ""}`}
                >
                    <div
                        style={
                            leftIndent
                                ? {
                                      width: `${textBlockWidth}%`,
                                      marginLeft: `Calc(25% + 15px)`
                                  }
                                : { width: `${textBlockWidth}%` }
                        }
                        className={`horizontalBlockUnnamedMobile-textBlock fs-${textBlockFontSize}`}
                    >
                        {blockContent.textBlock
                            ? blockContent.textBlock
                            : blockContent.textBlock2
                            ? blockContent.textBlock2
                            : ""}
                    </div>
                </div>
            )}
            {fulltype && (
                <>
                    <div
                        className={`horizontalBlockUnnamedMobile-fullType-back ${
                            bottomBorder ? "bborder" : ""
                        } ${topBorder ? "tborder" : ""}`}
                    >
                        <div
                            className={`horizontalBlockUnnamedMobile-fullType-name-back fs-${nameFontSize} mt-40`}
                        >
                            <div
                                className={`horizontalBlockUnnamedMobile-fullType-name-name `}
                            >
                                {blockContent.name ? blockContent.name : ""}
                            </div>
                            <div
                                className="horizontalBlockUnnamedMobile-alternativeType-leftArrow imgBack"
                                style={
                                    rightArrow
                                        ? { visibility: "visible" }
                                        : { visibility: "hidden" }
                                }
                            ></div>
                        </div>
                    </div>
                    <div
                        className={`horizontalBlockUnnamedMobile-fullType-textBlock mt-40 fs-${textBlockFontSize}`}
                    >
                        {blockContent.textBlock ? blockContent.textBlock : ""}
                        {blockContent.textBlock1 ? blockContent.textBlock1 : ""}
                    </div>
                    <div
                        className={`horizontalBlockUnnamedMobile-fullType-textBlock mt-40 mb-40 fs-${secondTextBlockFontSize}`}
                        style={
                            secondTextBlockWidth
                                ? {
                                      width: `${secondTextBlockWidth}%`,
                                      marginLeft: `calc(100% - ${secondTextBlockWidth}%)`
                                  }
                                : { width: "100%" }
                        }
                    >
                        {blockContent.textBlock2.props.children
                            ? blockContent.textBlock2.props.children
                            : blockContent.textBlock2}
                    </div>
                </>
            )}
            {onlyName && (
                <div
                    className={`horizontalBlockUnnamedMobile-onlyName pt-40 pb-40 ${
                        bottomBorder ? "bborder" : ""
                    } ${topBorder ? "tborder" : ""}`}
                >
                    <div
                        className={`horizontalBlockUnnamedMobile-fullType-name-name fs-${nameFontSize}`}
                    >
                        {blockContent.name ? blockContent.name : ""}
                    </div>
                    <Link
                        style={
                            noLink
                                ? { visibility: "hidden" }
                                : {
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      bottom: 0 + "px",
                                      width: 50 + "%",
                                      left: 50 + "%",
                                      fontSize: 20 + "px"
                                  }
                        }
                        to={
                            blockContent.link1
                                ? blockContent.link1
                                : blockContent.link
                                ? blockContent.link
                                : ""
                        }
                        className={`horizontalBlockDropdownMobile-link bborder  fs-20 pb-10 pt-40`}
                    >
                        <span className="horizontalBlockDropdown-link-name mr-20">
                            {blockContent.linkName1
                                ? blockContent.linkName1
                                : blockContent.linkName}
                        </span>
                        <div className="horizontalBlockDropdownMobile-link-arrow imgBack "></div>
                    </Link>
                </div>
            )}
        </>
    );
};

export default HorizontalBlockUnnamedMobile;
