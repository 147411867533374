import React, { useState } from "react";
import { Link } from "react-router-dom";

const HorizontalBlockDropdownMobile = ({
    blockContent,
    bottomBorder = false,
    lowName = false,
    doubleLineName = false,
    tripleLineName = false,
    customNameLine = "",
    customNameWidth = "70",
    customHideHeight = "",
    customHeight = 350,
    customHeightTextBlock = 55,
    smallHeight = false,
    noLink = false,
    dropdownDisable = false,
    rightArrow = false,
    noName = false
}) => {
    const [hideContent, setHideContent] = useState(
        dropdownDisable ? false : true
    );

    const handleContent = () => {
        setHideContent(!hideContent);
    };

    return (
        <div
            className={`horizontalBlockDropdownMobile pt-40 pb-40 ${
                bottomBorder ? "bborder" : ""
            }`}
            style={
                customHideHeight
                    ? { minHeight: customHideHeight + "px" }
                    : smallHeight
                    ? { minHeight: 200 + "px" }
                    : { minHeight: 260 + "px" }
            }
        >
            {!noName && (
                <>
                    <div
                        className="horizontalBlockDropdownMobile-name mb-30"
                        style={
                            doubleLineName
                                ? { height: 60 + "px" }
                                : tripleLineName
                                ? { height: 100 + "px" }
                                : customNameLine
                                ? {
                                      height: customNameLine + "px",
                                      width: customNameWidth + "%"
                                  }
                                : { height: 30 + "px" }
                        }
                    >
                        <span
                            className={`horizontalBlockDropdown-name-name cp ${
                                lowName ? "fs-24" : "fs-32"
                            }`}
                        >
                            {blockContent.name}
                        </span>
                    </div>
                    <div
                        className="horizontalBlockDropdown-name-leftArrow imgBack"
                        style={
                            rightArrow
                                ? lowName
                                    ? { visibility: "visible", top: "40px" }
                                    : { visibility: "visible", top: "45px" }
                                : { visibility: "hidden" }
                        }
                    ></div>
                </>
            )}
            {blockContent.imgLink && (
                <div className="horizontalBlockDropdown-img">
                    <img
                        src={blockContent.imgLink}
                        alt="Loading Error"
                        className="mt-40"
                    />
                </div>
            )}
            <div
                className={`horizontalBlockDropdownMobile-textBlocks-back  ${
                    hideContent ? "mb-40" : ""
                }`}
                style={
                    hideContent
                        ? { height: customHeightTextBlock + "px" }
                        : { height: customHeight + "px" }
                }
            >
                {blockContent.textBlock1 && (
                    <div
                        className="horizontalBlockDropdownMobile-textBlock fs-14  cp"
                        onClick={() => setHideContent(false)}
                    >
                        {hideContent
                            ? blockContent.textBlock1.slice(0, 140) + "..."
                            : blockContent.textBlock1}
                    </div>
                )}
                <div
                    className="horizontalBlockDropdownMobile-textBlock mt-40 fs-14 "
                    style={
                        typeof blockContent.textBlock2 !== String
                            ? {}
                            : blockContent.textBlock2.includes("\n")
                            ? { whiteSpace: "pre-wrap" }
                            : {}
                    }
                >
                    <span>
                        {hideContent
                            ? blockContent.textBlock2.slice(0, 140) + "..."
                            : blockContent.textBlock2}
                    </span>
                </div>
                {lowName && (
                    <Link
                        to={
                            blockContent.link1
                                ? blockContent.link1
                                : blockContent.link
                                ? blockContent.link
                                : ""
                        }
                        target="_self"
                        className={`horizontalBlockDropdownMobile-link bborder mt-40 fs-20 cp`}
                        style={
                            noLink
                                ? { visibility: "hidden" }
                                : hideContent
                                ? {
                                      position: "relative",
                                      bottom: 0 + "px",
                                      width: 40 + "%",
                                      left: 60 + "%"
                                  }
                                : {
                                      position: "absolute",
                                      bottom: 0 + "px",
                                      width: 40 + "%",
                                      left: 60 + "%"
                                  }
                        }
                    >
                        <span className="horizontalBlockDropdown-link-name mr-20">
                            {blockContent.linkName1
                                ? blockContent.linkName1
                                : blockContent.linkName}
                        </span>
                        <div className="horizontalBlockDropdownMobile-link-arrow imgBack "></div>
                    </Link>
                )}
                <div
                    className="horizontalBlockDropdownMobile-gradientBlock"
                    style={
                        !hideContent
                            ? { right: -200 + "px" }
                            : { right: 0 + "px" }
                    }
                ></div>
            </div>
            {!dropdownDisable && (
                <div
                    style={
                        !hideContent
                            ? { transform: "scaleY(0)" }
                            : { transform: "scaleY(1)" }
                    }
                    className={`horizontalBlockDropdownMobile-open  imgBack ${
                        lowName ? "t-30" : "t-40"
                    }  cp`}
                    onClick={() => handleContent()}
                ></div>
            )}
            {!dropdownDisable && (
                <div
                    style={lowName ? { top: 30 + "px" } : { top: 40 + "px" }}
                    className={`horizontalBlockDropdownMobile-close  imgBack cp`}
                    onClick={() => handleContent()}
                ></div>
            )}
            {!lowName && (
                <Link
                    style={
                        noLink
                            ? { visibility: "hidden" }
                            : {
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  bottom: 0 + "px",
                                  width: 40 + "%",
                                  left: 60 + "%"
                              }
                    }
                    to={{
                        pathname: `${
                            blockContent.link1
                                ? blockContent.link1
                                : blockContent.link
                                ? blockContent.link
                                : ""
                        }`
                    }}
                    target="_self"
                    className={`horizontalBlockDropdownMobile-link bborder  fs-20 pb-10`}
                >
                    <span className="horizontalBlockDropdown-link-name mr-20">
                        {blockContent.linkName1
                            ? blockContent.linkName1
                            : blockContent.linkName}
                    </span>
                    <div className="horizontalBlockDropdownMobile-link-arrow imgBack "></div>
                </Link>
            )}
        </div>
    );
};

export default HorizontalBlockDropdownMobile;
