import { Link } from "react-router-dom";

const DocumentationMobile = () => {
    return (
        <div className="DocumentationMobile">
            <div className="Documentation-back">
                <div className="DocumentationMobile-name fs-38">
                    Документация
                </div>
                <div className="Documentation-links">
                    <a
                        className="Documentation-links-link"
                        href="./AVL_Technology.pdf"
                        target="_blank"
                        download
                    >
                        Описание ПО
                    </a>
                </div>
            </div>
            <h3>
                <Link to={`/`}>На главную</Link>
            </h3>
        </div>
    );
};

export default DocumentationMobile;
