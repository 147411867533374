import React from "react";
import { useLanguage } from "../../hooks/useLang";

import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";

const CommunicationsPageMobile = () => {
    const { content, currentLang } = useLanguage();
    const pageName = content.communications.pageName;
    const imageUrls = content.communications.imageUrls;
    const blocks = content.communications.blocks;
    const cards = content.communications.cards;

    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                customHeight={210}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block1}
                topBorderbBlock={true}
                customMinTextHeight={
                    currentLang === "fr" || currentLang === "es" ? "220" : ""
                }
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.defence}
                noLink={true}
                customNameLine="110"
                customHeight={
                    currentLang === "fr"
                        ? 340
                        : window.innerWidth < 360
                        ? 340
                        : 210
                }
                dropdownDisable={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.reputation}
                noLink={true}
                doubleLineName={true}
                customHeight={100}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block2}
                topBorderbBlock={true}
                bottomBorderBlock={true}
            />
            <LargeImageMobile imageUrl={imageUrls.img2Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.background}
                noLink={true}
                tripleLineName={true}
                customHeight={210}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block3}
                topBorderbBlock={true}
            />
        </div>
    );
};

export default CommunicationsPageMobile;
