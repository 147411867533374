import React from "react";
import { useLanguage } from "../hooks/useLang";

import CardsWithArrowsAdaptive from "../pageBlocks/cardsBlocks/cardsWithArrowsAdaptive";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";

import PageHead from "../pageBlocks/pageHead/pageHead";
import SendRequest from "../pageBlocks/sendRequest";
import TariffsBlock from "../pageBlocks/tariffsBlock";
// import content from "../textContent";

const TariffsPage = () => {
    const { content } = useLanguage();
    const pageName = content.tariffs.pageName;
    const subMenuList = content.tariffs.subMenuList;
    const imageUrls = content.tariffs.imageUrls;
    const blocks = content.tariffs.blocks;
    const cards = content.tariffs.cards;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.center} />
            <TariffsBlock />
            <LargeImage imageUrl={imageUrls.img2} />
            <HorizontalBlockAdaptive
                blockContent={blocks.licence}
                bottomBorder={true}
            />
            <CardsWithArrowsAdaptive
                blockContent={cards.block1}
                arrowsDirection="right"
            />
            <SendRequest />
        </div>
    );
};

export default TariffsPage;
