import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import CardsWithArrowsAdaptiveTablet from "../cardsBlocks/cardsWithArrowsAdaptiveTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";

import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import SendRequest from "../pageBlocks/sendRequest";
import TariffsBlockTablet from "../pages/tariffsBlockTablet";
// import content from "../textContent";

import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet";

const TariffsPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.tariffs.pageName;
    const subMenuList = content.tariffs.subMenuList;
    const imageUrls = content.tariffs.imageUrls;
    const blocks = content.tariffs.blocks;
    const cards = content.tariffs.cards;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.center}
                customWidth={true}
                customNameWidthTariffsPage={true}
            />
            <TariffsBlockTablet />
            <LargeImageTablet imageUrl={imageUrls.img2Mobile} />
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.licenceTablet}
                bottomBorder={true}
                customFontSize={true}
            
            />
            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block1}
                arrowsDirection="right"
                topBorderCard={true}
                tariffsHeightForColumn={true}
                tariffsHeightText={true}
            />
            {/* <SendRequest /> */}

            <ContactUsTablet />
            <SiteMapTablet />
            {/* <FooterHorizontalBlockTablet /> */}
        </div>
    );
};

export default TariffsPageTablet;
