import React, { useEffect, useState } from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
// import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";
import axios from "axios";
import moment from "moment";

const URL_PUBS = "https://web.avl.team/api/site/publs/";
// const URL_SOCIAL = "https://web.avl.team/api/site/by_source/dz/";

const PublicationsPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.publications.pageName;
    const imageUrls = content.publications.imageUrls;

    const [pubs, setPubs] = useState();
    const [filtredPubs, setFiltredPubs] = useState();
    const [isSearch, setSearch] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pubId, setPubId] = useState();
    const [hidePub, setHide] = useState(true);

    useEffect(() => {
        axios
            .get(URL_PUBS, { params: { page: 0, per_page: 100 } })
            .then((res) =>
                setPubs(
                    res.data.items.sort((a, b) => {
                        return a.published_at > b.published_at ? -1 : 1;
                    })
                )
            )
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (pubs) {
            // console.log("pubs", pubs);
            setPubId(pubs[0].id);
            setFiltredPubs(pubs);
            // console.log(pubs);
        }
    }, [pubs]);

    const paginate = (action) => {
        if (action === "-") {
            setPageNum((prev) => (pageNum !== 1 ? prev - 1 : pageNum));
        }
        if (action === "+") {
            setPageNum((prev) =>
                pageNum !==
                Math.ceil(!isSearch ? pubs.length / 4 : filtredPubs.length / 4)
                    ? prev + 1
                    : pageNum
            );
        }
    };

    const prepareDate = (date) => {
        // console.log("date in Prepare Date", date);
        return moment.unix(date).format("L");
    };

    const prepareText = (text) => {
        const stage1 = text.replaceAll("<div>", "");
        const stage2 = stage1.replaceAll("</div>", ".");
        const stage3 = stage2.replaceAll("<br>", "\r\n\r\n");
        const stage4 = stage3.replaceAll("\r\n\r\n.", "\r\n\r\n");
        const result = stage4.replaceAll("..", ".").replaceAll("&nbsp;", "");
        return result;
    };

    const changePubId = (id) => {
        setPubId(id);
    };

    const handleChange = ({ target }) => {
        if (target.value) {
            setSearch(true);
        } else {
            setSearch(false);
        }
        const filtredPubs = pubs.filter((pub) =>
            pub.text.toLowerCase().includes(target.value.toLowerCase())
        );
        setFiltredPubs(filtredPubs);
    };
    const handlePubHide = (status) => {
        setHide(status);
    };
    const clearSearch = () => {
        const el = document.querySelector("#publicationSearch");
        el.value = "";
        setFiltredPubs(pubs);
        setSearch(false);
    };

    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <div className="publicationsPageMobile-publications-back bborder tborder pb-40">
                <div
                    className={`publicationsPageMobile-publications-search ${
                        hidePub ? "bborder" : ""
                    }`}
                >
                    <input
                        onChange={handleChange}
                        className="publicationsPage-publications-search-input bborder"
                        placeholder="Поиск..."
                        type="text"
                        name="publicationSearch"
                        id="publicationSearch"
                    />
                    {isSearch && (
                        <div
                            className="publicationsPage-publications-search-cancel cp imgBack"
                            onClick={() => clearSearch()}
                        ></div>
                    )}
                    <div className="publicationsPage-publications-search-loupe imgBack"></div>
                    <div
                        className="publicationsPageMobile-publications-search-moveback bborder"
                        style={hidePub ? { right: "-100%" } : { right: "0px" }}
                    >
                        <div
                            className="publicationsPageMobile-publications-search-moveback-arrow imgBack"
                            onClick={() => handlePubHide(true)}
                        ></div>
                    </div>
                </div>

                <div className="publicationsPageMobile-publications-headers-back">
                    {filtredPubs &&
                        filtredPubs
                            .slice(
                                pageNum !== 1 ? pageNum * 4 - 4 : pageNum - 1,
                                pageNum !== 1 ? pageNum * 4 : pageNum + 3
                            )
                            .map((header, index) => (
                                <div
                                    className={`publicationsPageMobile-publications-headers-header ${
                                        index !== 8 && "bborder"
                                    }`}
                                    key={index}
                                >
                                    {pubId === header.id && (
                                        <div
                                            className="publicationsPage-publications-headers-header-arrow"
                                            onClick={() => {
                                                changePubId(header.id);
                                                handlePubHide(false);
                                            }}
                                        ></div>
                                    )}
                                    <div
                                        onClick={() => {
                                            changePubId(header.id);
                                            handlePubHide(false);
                                        }}
                                        className="cd"
                                    >
                                        <div className="publicationsPageMobile-publications-headers-header-title fs-14 ">
                                            {header.title}
                                        </div>
                                        <div className="publicationsPage-publications-headers-header-text fs-14 ">
                                            {prepareText(header.text).slice(
                                                0,
                                                60
                                            ) + "..."}
                                        </div>
                                    </div>
                                    <div className="publicationsPage-publications-headers-header-sourse">
                                        <a
                                            href={header.url}
                                            rel="noreferrer"
                                            className="publicationsPage-publications-headers-header-sourse-link imgBack"
                                            target="_blank"
                                        >
                                            {""}
                                        </a>
                                        <div className="publicationsPage-publications-headers-header-sourse-date fs-12">
                                            {prepareDate(header.published_at)}
                                        </div>
                                        <div className="publicationsPage-publications-headers-header-sourse-name fs-12">
                                            {header.domain}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    <div className="publicationsPageMobile-publications-headers-pagination-back bborder tborder">
                        <div className="publicationsPage-publications-headers-pagination-total">
                            {`Всего: ${
                                filtredPubs &&
                                (!isSearch ? pubs.length : filtredPubs.length)
                            }`}
                        </div>
                        {filtredPubs && (
                            <div className="publicationsPage-publications-headers-pagination-change-back">
                                <div
                                    className="publicationsPage-publications-headers-pagination-leftarrow imgBack"
                                    onClick={() => paginate("-")}
                                ></div>
                                <div className="publicationsPage-publications-headers-pagination-counter">
                                    <span
                                        style={{ fontWeight: "bold" }}
                                    >{`${pageNum} `}</span>
                                    {`/ ${Math.ceil(
                                        !isSearch
                                            ? pubs.length / 4
                                            : filtredPubs.length / 4
                                    )}`}
                                </div>
                                <div
                                    className="publicationsPage-publications-headers-pagination-rightarrow imgBack"
                                    onClick={() => paginate("+")}
                                    style={
                                        pageNum ===
                                        Math.ceil(
                                            !isSearch
                                                ? pubs.length / 4
                                                : filtredPubs.length / 4
                                        )
                                            ? { cursor: "default" }
                                            : { cursor: "pointer" }
                                    }
                                ></div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="publicationsPageMobile-publications-publication-back"
                    style={hidePub ? { right: "-100%" } : { right: "0px" }}
                >
                    <div className="publicationsPage-publications-publication">
                        {filtredPubs &&
                            filtredPubs.map(
                                (pub, index) =>
                                    pub.id === pubId && (
                                        <div
                                            className="publicationsPageMobile-publications-publication-all "
                                            key={index * 15}
                                        >
                                            <div className="publicationsPageMobile-publications-publication-sourse">
                                                <div className="publicationsPageMobile-publications-publication-sourse-info mt-10">
                                                    <div className="publicationsPageMobile-publications-publication-title fs-16 cp bold">
                                                        {pub.title}
                                                    </div>
                                                    <div className="publicationsPageMobile-publications-publication-sourse-back mt-10">
                                                        <div className="publicationsPage-publications-publication-sourse-date">
                                                            {prepareDate(
                                                                pub.published_at
                                                            )}
                                                        </div>
                                                        <div className="publicationsPage-publications-publication-sourse-name ml-10">
                                                            {pub.domain}
                                                        </div>
                                                    </div>
                                                </div>
                                                <a
                                                    href={pub.url}
                                                    rel="noreferrer"
                                                    className="publicationsPageMobile-publications-publication-sourse-link imgBack"
                                                    target="_blank"
                                                >
                                                    {""}
                                                </a>
                                            </div>
                                            <div className="publicationsPageMobile-publications-publication-scroll">
                                                <div className="publicationsPageMobile-publications-publication-text fs-16 cp">
                                                    {prepareText(pub.text)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicationsPageMobile;
