import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import CardsWithArrowsAdaptiveTablet from "../cardsBlocks/cardsWithArrowsAdaptiveTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"

// import content from "../textContent";

const SafetyPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.safety.pageName;
    const subMenuList = content.safety.subMenuList;
    const imageUrls = content.safety.imageUrls;
    const blocks = content.safety.blocks;
    const cards = content.safety.cards;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet 
            blockContent={blocks.main} />
            <HorizontalBlockAdaptiveTablet
             blockContent={blocks.economic}
             bottomBorder={true}
             />
            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block1}
                arrowsDirection="left"
                topBorderCard={true}
                safetyHeight={true}
            />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.support} 
             bottomBorder={true}
            />
            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block3}
                arrowsDirection="right"
                // topBorderbBlock={true}
                topBorderCard={true}
                safetyHeight={true}
            />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.cyber} 
            
            bottomBorder={true}
            />
            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block2}
                arrowsDirection="left"
                // topBorderbBlock={true}
                topBorderCard={true}
                safetyHeight={true}
            />
            <ContactUsTablet/>
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default SafetyPageTablet;
