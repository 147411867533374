import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const random = () => Math.floor(Math.random() * 1000);

const CardsAdaptiveBlock = ({
    blockContent,
    twoCardsInLine = false,
    bottomBorderBlock = false,
    topBorderbBlock = false,
    bottomBorderCard = false,
    topBorderCard = false,
    paddingTopBlock = false,
    paddingBottomBlock = false,
    marginTopNum = false,
    marginTopName = false,
    marginTopText = false,
    marginTopImg = false,
    marginTopLink = true
}) => {
    const [tempClass, setTempClass] = useState();
    const [nameHeight, setNameHeight] = useState();
    // const [cardHeight, setCardHeight] = useState();
    const [textHeight, setTextHeight] = useState();
    useEffect(() => {
        setTempClass(`cardsAdaptive${random()}`);
        // console.log("-------------------------------");
    }, []);
    const getHeightName = () => {
        let maxHeightName = Object.values(
            document.querySelectorAll(`.${tempClass}name`)
        ).map((el) => el.offsetHeight);
        setNameHeight(Math.max.apply(null, maxHeightName));
    };
    const getHeightText = () => {
        let maxHeightText = Object.values(
            document.querySelectorAll(`.${tempClass}text`)
        ).map((el) => el.offsetHeight);
        setTextHeight(Math.max.apply(null, maxHeightText));
    };
    const setHeight = () => {
        const maxHeightNameSet = Object.values(
            document.querySelectorAll(`.${tempClass}name`)
        );
        maxHeightNameSet.map((el) => (el.style.height = nameHeight + "px"));
        const maxHeightTextSet = Object.values(
            document.querySelectorAll(`.${tempClass}text`)
        );
        maxHeightTextSet.map((el) => (el.style.height = textHeight + "px"));
    };
    setTimeout(() => {
        getHeightName();
        getHeightText();
        setHeight();
    }, 200);
    // useEffect(() => {
    //     getHeightName();
    //     getHeightText();
    //     setHeight();
    // });

    return (
        <>
            <div
                className={`cardsAdaptiveBlock   ${
                    bottomBorderBlock ? "bborder" : ""
                } ${topBorderbBlock ? "tborder" : ""} ${
                    paddingTopBlock ? "pt-60" : ""
                } ${paddingBottomBlock ? "pb-60" : ""}`}
            >
                {blockContent.map((card, index) => (
                    <div
                        className={`cardsAdaptiveBlock-card   ${
                            bottomBorderCard ? "bborder" : ""
                        } ${topBorderCard ? "tborder" : ""} ${
                            !twoCardsInLine && topBorderCard && index > 2
                                ? "mt-60 "
                                : ""
                        } ${
                            twoCardsInLine && topBorderCard && index > 1
                                ? "mt-60 "
                                : ""
                        }
                        ${tempClass}card`}
                        style={
                            twoCardsInLine
                                ? index % 2 === 0
                                    ? {
                                          width: 40 + "%",
                                          marginLeft: 135 + "px"
                                      }
                                    : { width: 40 + "%" }
                                : { width: 27 + "%" }
                        }
                        key={Math.random()}
                    >
                        {card.num && (
                            <div
                                // className={`sixCardsAdaptive-card-num fs-64 ${
                                //     !topBorder && index < 3 ? " " : "mt-60"
                                // } ${bottomBorder ? "mt-60" : ""}`}
                                className={`cardsAdaptiveBlock-card-num fs-64 ${
                                    marginTopNum && "mt-60 "
                                } `}
                            >
                                0{card.num}
                            </div>
                        )}
                        {card.name && (
                            <div
                                className={`cardsAdaptiveBlock-card-name fs-40 ${
                                    marginTopName && "mt-60 "
                                }
                                ${tempClass}name`}
                                // style={{
                                //     height: nameHeight + "px"
                                // }}
                            >
                                {card.name}
                            </div>
                        )}
                        {card.text && (
                            <div
                                className={`cardsAdaptiveBlock-card-text ${
                                    marginTopText && " mt-60 "
                                } ${
                                    card.imgLink ? "fs-16" : "fs-16 "
                                }    ${tempClass}text`}
                                // style={{ height: textHeight + "px" }}
                            >
                                {card.text}
                            </div>
                        )}
                        {card.linkName && (
                            <Link
                                to={
                                    card.externalLink
                                        ? {
                                              pathname: card.externalLink
                                          }
                                        : card.link
                                }
                                target={card.externalLink && "_blank"}
                                className={`cardsAdaptiveBlock-textBlock-link fs-32  ${
                                    marginTopLink && "mt-60"
                                }`}
                            >
                                <span className="cardsAdaptiveBlock-textBlock-link-name cp">
                                    {card.linkName}
                                </span>
                                <div className="cardsAdaptiveBlock-textBlock-link-arrow imgBack cp"></div>
                            </Link>
                        )}
                        {card.imgLink && (
                            <img
                                src={card.imgLink}
                                alt="error"
                                className={`cardsAdaptiveBlock-img ${
                                    marginTopImg && " mt-60"
                                }`}
                            />
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default CardsAdaptiveBlock;
