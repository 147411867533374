import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const HorizontalBlockAdaptiveTablet = ({
    blockContent,
    bottomBorder = false,
    marginTopName=true,
    //bottomPadding = false,
    contactsWidth=false,
    customName=false,
    customNameWidth=false,
    customWidth=false,
    nameSize = "",
    notAnyPoints = false,
    onlyName = false,
    customHeightCookiePageTablet=false,
    customNameMaxWidth=false,
    customLeft=false,
    customNameWidthPublicOferta=false,
    customNameWidthPuublicActivityPage=false,
    customNameWidthPersonalDataAgreementPage=false,
    customNameWidthConditionalPoliticPage=false,
    customNameWidthTariffsPage=false,
    imageWith=false,
    customWidthNameAboutUs=false,
    customWidthNameAboutUsExpert=false,
    customWidthPlatformAboutProducts=false,
    customWidthNameMain=false,
    customNameWidthAbout=false,
    customNameWidthCookies=false,
    customNameWidthCookiesWhatTypes=false,
    customWrapLine=false,
    customFontSize=false,
    customNameBottom=false,
    customNameWidthContacts=false,
    topAuto=false ,
    titileTabletForwidth=false,
    contactsTopforName=false

}) => {
    const [largeName, setLargeName] = useState(false);
    const nameHeight = useRef(null);
    const text1Height = useRef(null);
    const text2Height = useRef(null);
    const compareHeight = (nameHeight, text1Height, text2Height) => {
        //console.log(blockContent.name, nameHeight, text1Height, text2Height);
        return nameHeight >= text1Height && nameHeight >= text2Height
            ? true
            : false;
    };





    useEffect(() => {
        let res = compareHeight(
            nameHeight.current.getBoundingClientRect().height,
            text1Height.current.getBoundingClientRect().height,
            text2Height.current.getBoundingClientRect().height
        );
        setLargeName(res);
        // eslint-disable-next-line
        //  console.log("lucky", largeName)
    }, [nameHeight, text1Height, text2Height]);

// console.log("Lucky", blockContent.blocks)
   const style=  !blockContent.num
   ? !blockContent.textBlock1 &&
     !blockContent.textBlock2
       ? largeName
           ? {}
            ? customName            
           : { top: -15 + "px" }
           :{top:0+ "px"}
       : { top: -15 + "px" }// -20 +px 
   : { top: -10 + "px" }
    if(topAuto){
      style.top="auto"
    }

    return (
        <div
            id={blockContent.anchor}
            className={`horizontalBlockAdaptiveTablet smt pt-40 ${ //все блоки 
                largeName ? "pb-40" : "pb-40"// pb название
            } ${bottomBorder ? "bborder" : " "} `}
        >   
            <div
                className={`horizontalBlockAdaptiveTablet-name pt-10  ${
                    !blockContent.num &&
                    !blockContent.textBlock &&
                    !blockContent.textBlock1 &&
                    !blockContent.textBlock2
                        ? "w-100" // ширина подвала
                        : ""
                }`}
            >
                {!notAnyPoints ? (
                    blockContent.num ? (//  fs num
                        <span className="horizontalBlockAdaptiveTablet-name-num fs-14"> 
                            {blockContent.num}.
                        </span>
                    ) : (
                        <div
                            className={`horizontalBlockAdaptiveTablet-left-arrow  imgBack  ${
                                !blockContent.num &&
                                blockContent.textBlock1 &&
                                blockContent.textBlock2
                                       ?  " dsn"
                                      : ""
                                    }`}

                            style={
                              !blockContent.num &&
                             !blockContent.textBlock1 &&
                             !blockContent.textBlock2
                             ? { top: 25 + "%" }
                             : {}
                             }
                           
                        ></div>
                    )
                ) : (
                    <></>
                )}

                <div
                    className={`horizontalBlockAdaptiveTablet-name-name   ${
                        blockContent.num
                            ? "fs-44"// fs название
                            : nameSize
                            ? nameSize
                            : "fs-30 w-100"//fs 
                            
                    } ${!blockContent.num && !blockContent.textBlock1&& !blockContent.textBlock2 ? "pl-95 b-2 top":""}
                    ${!blockContent.textBlock1Img ? " w-360" : ""} 
                    ${ customNameWidth&& blockContent.num && blockContent.textBlock1&& blockContent.textBlock2 ? "w-430-important":""}
                    ${ customNameMaxWidth&& !blockContent.num && blockContent.textBlock2 ? "minw-400":""}
                    ${(customNameWidthPuublicActivityPage &&blockContent.num)? "w-80p" :"" }
                    ${onlyName ? " w-360 " : ""}  
                    ${(customNameWidthPublicOferta&& !blockContent.num && blockContent.textBlock2)&&"w-505"}
                    ${(customNameWidthConditionalPoliticPage&& !blockContent.num)&&"w-605" }
                    ${(customNameWidthPersonalDataAgreementPage&& !blockContent.num)&&"w-390"}
                    ${customWidthNameAboutUs&& "w-449-important"}
                    ${customWidthNameAboutUsExpert&&  "w-338-important fs-30-30"}
                    ${customWidthPlatformAboutProducts && "w-410-important"}
                    ${customWidthNameMain&& "w-419-important"}
                    ${customNameWidthAbout&& "w-430-important"}
                    ${customNameWidthCookies&& "w-390"}
                    ${customNameWidthCookiesWhatTypes&& "w-430-important" }
                    ${(customNameBottom && !blockContent.num && !blockContent.textBlock1&& !blockContent.textBlock2)&& "b-17"}
                    ${customNameWidthContacts ? "w-full":""}
                    ${titileTabletForwidth ? "tablet-w-full":""}
                    
                   `}
                       // ${marginTopName ? "mb-30":""}}
                    style={
                      style
                    }
                    

                    ref={nameHeight}
                
                >
                    {blockContent.name && blockContent.name}
                </div>
            </div>
            <div
                className={`horizontalBlockAdaptiveTablet-textBlock fs-14 ${customWidth&& "w-295-important"}`}// lBusnesstext
                ref={text1Height}
            >
                {blockContent.textBlock1 && blockContent.textBlock1}
                {blockContent.textBlock1Img && (
                    <div className="text-right">
                    <img
                        src={blockContent.textBlock1Img}
                        alt="error"
                        style={{ maxWidth:'100%'
                        }}
                        
                         // height:'calc(100% - 40px)' 
                    />
                    </div>
                )}
            </div>

            <div
                className={`
            horizontalBlockAdaptiveTablet-textBlock-right fs-14 
            ${customWidth ? "w-295-important":""} 
            ${customNameWidthTariffsPage ? "w-318-important":""}
            ${contactsWidth ? "width-contacts" :""} 
            ${customLeft ? "prel l-15 w-295-important" :""}
            ${customWrapLine ? "ws-p-l" : ""}
            ${customFontSize ? "fs-20-20" : ""}
            `}
            //${!!blockContent.textBlock1Img ? "justify-between":""}
                ref={text2Height}
            >
                {blockContent.textBlock2 && <div className="">{blockContent.textBlock2}</div>}
                {blockContent.linkName1 && (
                    <Link
                        to={{
                            pathname: blockContent.link1
                                ? blockContent.link1
                                : "/"
                        }}
                        target={
                            blockContent.link1.includes(".")
                                ? "_blank"
                                : "_self"
                        }
                        className={`horizontalBlockAdaptiveTablet-textBlock-link ${
                            blockContent.textBlock2 && "mt-40"//mt link   m-16
                        } fs-20 cp`}// подробнее
                    >
                        <span className="horizontalBlockAdaptiveTablet-textBlock-link-name">
                            {blockContent.linkName1}
                        </span>
                        <div className="horizontalBlockAdaptiveTablet-textBlock-link-arrow imgBack "></div>
                    </Link>
                )}
                {blockContent.linkName2 && (
                    <Link
                        to={blockContent.link2 ? blockContent.link2 : "/"}
                        className="horizontalBlockAdaptiveTablet-textBlock-link mt-40  cp" //mt-60
                    >
                        <span className="horizontalBlockAdaptiveTablet-textBlock-link-name fs-20">
                            {blockContent.linkName2}
                        </span>
                        <div className="horizontalBlockAdaptiveTablet-textBlock-link-arrow imgBack "></div>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default HorizontalBlockAdaptiveTablet;
