import React, { useState } from "react";
import { Link } from "react-router-dom";

const HorizontalBlockDropdownTablet = ({
    blockContent,
    customHeight = false
}) => {
    const [hideContent, setHideContent] = useState(true);
    const [screenW, setScreenW] = useState(window.innerWidth);

    //    const [bottomPadding]=useState(false)
    window.addEventListener("resize", () => {
        setScreenW(window.innerWidth);
    });

    const handleContent = () => {
        setHideContent(!hideContent);
    };

    // console.log("Lucky", blockContent)
    const setHigh = () => {
        if (screenW > 1700) {
            return hideContent
                ? { height: 150 + "px" }
                : { height: 400 + "px" };
        } else if (screenW < 1700) {
            return hideContent ? { height: 100 + "px" } : { height: "auto" }; //(customHeight ? 265 : 300) + "px" };// 300
        }
    };
    return (
        <div
            className="horizontalBlockDropdownTablet pt-40 pb-40 "
            onClick={() => handleContent()}
            style={setHigh()}
        >
            <div className="horizontalBlockDropdownTablet-name fs-30 ">
                <div className="horizontalBlockDropdownTablet-left-arrow imgBack "></div>
                <span className="horizontalBlockDropdownTablet-name-name cp fs-30 whitespace-nowrap">
                    {blockContent.name}
                </span>
            </div>
            <div
                className="horizontalBlockDropdownTablet-textBlock fs-14 cp"
                style={
                    hideContent
                        ? { right: -25 + "%", height: 40 + "px" }
                        : { right: 0 + "px", height: 100 + "%" }
                }
                onClick={() => setHideContent(false)}
            >
                {blockContent.textBlock1}
            </div>
            <div
                className="horizontalBlockDropdownTablet-textBlock fs-14 "
                style={hideContent ? { right: -25 + "%" } : { right: 0 + "px" }}
            >
                <span>{blockContent.textBlock2}</span>
                <Link
                    to={blockContent.link}
                    className={`horizontalBlockDropdownTablet-textBlock-link fs-32 mt-40 cp ${
                        customHeight ? "mt-35" : ""
                    }`} //mb-60
                >
                    <span className="horizontalBlockDropdown-textBlock-link-name fs-20">
                        {blockContent.linkName}
                    </span>
                    <div className="horizontalBlockDropdownTablet-textBlock-link-arrow imgBack "></div>
                </Link>
            </div>
            <div
                className="horizontalBlockDropdownTablet-gradientBlock"
                style={
                    !hideContent ? { right: -200 + "px" } : { right: 0 + "px" }
                }
            ></div>
            <div
                style={
                    !hideContent ? { right: -20 + "px" } : { right: 0 + "px" }
                }
                className="horizontalBlockDropdownTablet-open imgBack cp"
                onClick={() => handleContent()}
            ></div>
            <div
                className="horizontalBlockDropdownTablet-close-back"
                style={
                    hideContent ? { right: -90 + "px" } : { right: 0 + "px" }
                }
            >
                <div
                    className="horizontalBlockDropdownTablet-close imgBack cp"
                    onClick={() => handleContent()}
                ></div>
            </div>
        </div>
    );
};

export default HorizontalBlockDropdownTablet;
