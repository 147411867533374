import React, { useEffect, useState } from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";
import HorizontalBlockUnnamedMobile from "../pageBlocksMobile/horizontalBlockUnnamedMobile";
import axios from "axios";

// import content from "../textContent";
// import { Link } from "react-router-dom";
// import TgChannelsBlockMobile from "../pageBlocksMobile/tgChannelsBlockMobile";
// import OtherChannelsBlockMobile from "../pageBlocksMobile/otherChannelsBlockMobile";

const URL_PUBS = "https://web.avl.team/api/site/publs/";
// const URL_SOCIAL = "https://web.avl.team/api/site/by_source/";

// const regExp = /[а-яА-Я0-9]/g;git pull origin main

const PublicActivityPageMobile = () => {
    const { content, currentLang } = useLanguage();
    const linkNameLang = content.mainPage.blocks.sixCardsBlock[0].linkName;
    const pageName = content.publicActivity.pageName;
    const imageUrls = content.publicActivity.imageUrls;
    const blocks = content.publicActivity.blocks;
    const cards = content.publicActivity.cardsBlock;

    const [pubs, setPubs] = useState({ cards: [] });
    // const [mp, setMp] = useState();
    // const [mi, setMi] = useState();
    // const [vk, setVk] = useState();
    // const [dz, setDz] = useState();
    // const [dz2, setDz2] = useState();

    // const getSocialData = (sourse) => {
    //     const sourses = {
    //         mp: "mp/",
    //         mi: "mi/",
    //         vk: "vk/",
    //         dz: "dz/",
    //         dz2: "dz2/"
    //     };
    //     const setFunctions = {
    //         mp: setMp,
    //         mi: setMi,
    //         vk: setVk,
    //         dz: setDz,
    //         dz2: setDz2
    //     };

    //     const URL = URL_SOCIAL + sourses[sourse];
    //     axios
    //         // для ВК выгружаем последние 50 постов для проверки
    //         .get(URL, {
    //             params: { page: 0, per_page: sourse === "vk" ? 50 : 5 }
    //         })
    //         // если в тексте поста присутствует слово размещен,такой пост обрезается
    //         .then((res) =>
    //             setFunctions[sourse](
    //                 sourse !== "vk"
    //                     ? res.data.items
    //                     : res.data.items
    //                           .filter((item) => !item.text.includes("Размещен"))
    //                           .slice(0, 5)
    //             )
    //         )
    //         .catch((err) => console.log(err));
    // };

    useEffect(() => {
        axios
            .get(URL_PUBS, { params: { page: 0, per_page: 4 } })

            .then((res) =>
                setPubs((prev) => ({
                    ...prev,
                    cards: res.data.items
                        .sort((a, b) => {
                            return a.published_at > b.published_at ? -1 : 1;
                        })
                        .map((item, index) => ({
                            num: index + 1,
                            name: item.title,
                            // text: item.text.slice(5, 100),
                            linkName: linkNameLang,
                            externalLink: item.url
                        }))
                }))
            )
            .catch((err) => console.log(err));
        // getSocialData("mp");
        // getSocialData("mi");
        // getSocialData("vk");
        // getSocialData("dz");
        // getSocialData("dz2");
    }, [linkNameLang]);
    // useEffect(() => {
    //     console.log("mi", mi);
    //     console.log("vk", vk);
    //     console.log("dz", dz);
    //     console.log("dz2", dz2);
    // }, [mp, mi]);
    // useEffect(() => {
    //     if (pubs) {
    //         console.log(pubs);
    //     }
    // }, [pubs]);
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.publications}
                // noLink={true}
                // smallHeight={true}
                // doubleLineName={true}
                // tripleLineName={true}
                // customNameLine="110"
                // customHeight={170}
                // dropdownDisable={true}
                topBorder={true}
                onlyName={true}
                noLink={false}
                nameFontSize="32"
            />
            {pubs.cards[0] && (
                <CardsSwipeMobile
                    blockContent={pubs}
                    customNameFontSize="24"
                    externalLink={true}
                    customMinNameHeight="300"
                    // arrowsDirection="left"
                    // topBorderbBlock={true}
                />
            )}
            <HorizontalBlockDropdownMobile
                blockContent={blocks.cyberDad}
                // noLink={true}
                // smallHeight={true}
                // doubleLineName={true}
                // tripleLineName={true}
                customNameLine={
                    currentLang === "en" || window.innerWidth < 360
                        ? "80"
                        : "40"
                }
                customNameWidth="80"
                customHeight={window.innerWidth < 360 ? 250 : 230}
                dropdownDisable={true}
                bottomBorder={true}
            />
            <CardsSwipeMobile
                blockContent={cards}
                topBorderCard={false}
                // customNameFontSize="24"
                // externalLink={true}
                // customMinNameHeight="300"
                // arrowsDirection="left"
                // topBorderbBlock={true}
            />
            {/* <HorizontalBlockUnnamedMobile
                blockContent={blocks.tg}
                topBorder={true}
                onlyName={true}
                nameFontSize="32"
            /> */}
            {/* <TgChannelsBlockMobile
                channel1Array={mp}
                channel2Array={mi}
            /> */}
            {/* <HorizontalBlockUnnamedMobile
                blockContent={blocks.social}
                // onlyName={true}
            /> */}
            {/* <OtherChannelsBlockMobile
                channel1Array={vk}
                channel2Array={dz}
                channel3Array={dz2}
            /> */}
        </div>
    );
};

export default PublicActivityPageMobile;
