import React from "react";
import { useLanguage } from "../hooks/useLang";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";
// import agreements from "../agreementsTextEn";
import AgreementsBlock from "../pageBlocks/agreementsBlock";

const PersonalDataAgreementPage = () => {
    const { content, agreements } = useLanguage();
    const pageName = content.personalData.pageName;
    const subMenuList = content.personalData.subMenuList;
    const imageUrls = content.personalData.imageUrls;
    const blocks = content.personalData.blocks;
    const personalDataAgreement = agreements.personalData;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive
                blockContent={blocks.main}
                nameSize={"fs-64"}
            />
            <AgreementsBlock blockContent={personalDataAgreement} />
        </div>
    );
};

export default PersonalDataAgreementPage;
