const interfaceRu = {
    menubar: {
        news: "Principales nouvelles"
    },
    dropdownmenu: {
        menu: {
            main: "Page principale",
            platform: "Plateforme",
            forBusiness: "Pour le business",
            safety: "Sécurité",
            demonstration: "Démonstration",
            contacts: "Contacts"
        },
        menumap: {
            audience: "Analyse d'audience",
            analytics: "Analytique",
            courses: "Formation",
            scanner: "Analyse de vulnérabilités",
            center: "Centre situationnel",
            communications: "Communication",
            aboutUs: "À propos de nous",
            publicAvtivity: "Activité publique",
            publications: "Publications"
        }
    },
    question: {
        block: { name: "Poser une question", linkname: "En savoir plus" },
        page: {
            name: "Nom et prénom",
            llc: "Organisation",
            phonenum: "Numéro de téléphone",
            textarea: "Question (max 1000 caractères)",
            requiered: "Champs obligatoires",
            agreement: {
                beforeLink: "Les conditions",
                link: "de l'accord",
                afterLink: "ont été lues par moi"
            },
            send: "Envoyer",
            back: "Revenir",
            success: "Votre requête a été envoyée avec succès",
            error: {
                line1: "Une erreur s'est produite lors de l'envoi de la requête",
                line2: "Réessayez plus tard ou envoyez-nous un e-mail à info@avl.team",
                line3: "Veuillez nous excuser pour la gêne occasionnée"
            },
            tryAgain: "Essayer à nouveau",
            returnLink: "Aller à la page principale"
        }
    },
    request: {
        block: { name: "Envoyer une requête", linkname: "En savoir plus" },
        page: {
            name: "Nom et prénom",
            llc: "Organisation",
            phonenum: "Numéro de téléphone",
            requiered: "Champs obligatoires",
            agreement: {
                beforeLink: "Les conditions",
                link: "de l'accord",
                afterLink: "ont été lues par moi"
            },
            send: "Envoyer",
            back: "Revenir",
            success: "Votre demande a été envoyée avec succès",
            error: {
                line1: "Une erreur s'est produite lors de l'envoi de la requête.",
                line2: "Réessayez plus tard ou envoyez-nous un e-mail à info@avl.team",
                line3: "Nous nous excusons pour la gêne occasionnée"
            },
            tryAgain: "Essayer à nouveau",
            returnLink: "Aller à la page principale",
            checkboxes: {
                center: "Centre situationnel virtuel",
                licence: "Licences et implémentation",
                analytics: "Analytique",
                courses: "Formation"
            },
            imageLinks: {
                center: "Centre situationnel virtuel",
                licence: "Licences et implémentation",
                analytics: "Analytique",
                courses: "Formation"
            }
        }
    },
    captcha:
        "Veuillez sélectionner la bonne réponse pour envoyer votre requête.",
    footer: {
        sitemap: {
            aboutUs: {
                aboutus: "À propos de nous",
                creator: "Fondateur",
                mission: "Notre mission",
                activity: "Domaines d'activité",
                contacts: "Contacts"
            },
            forBusiness: {
                forbusiness: "Pour le business",
                center: "Centre situationnel virtuel",
                communications: "Communication",
                analytics: "Services et analyses",
                licence: "Licences et implémentation"
            },
            platform: {
                platform: "Plateforme",
                demonstaration: "Démonstration",
                situationCenter: "Centre situationnel",
                audience: "Analyse d'audience",
                scanner: "Scanner de vulnérabilités"
            },
            courses: {
                courses: "Formation",
                intelligence: "Renseignement sur la concurrence",
                trainings: "Formation corporative"
            },
            safety: {
                safety: "Sécurité",
                economy: "Sécurité économique",
                support: "Assistance juridique",
                cyber: "Cybersécurité"
            },
            public: {
                publicActivity: "Activité publique",
                publications: "Publications",
                cyberDad: "CyberDed",
                tgChannels: "Chaînes Telegram",
                social: "Réseaux sociaux"
            }
        },
        horizontalBlock: {
            rights: "Tous les droits sont réservés",
            name: `"THE AVALANCHE PULSE" LLC`,
            registry:
                "Le logiciel est enregistré dans le registre unifié des programmes russes pour les ordinateurs électroniques et les bases de données",
            news: "Principales nouvelles",
            forBusiness: "Pour le business",
            platform: "Plateforme",
            safety: "Sécurité",
            publicActivity: "Activité publique",
            aboutus: "À propos de nous",
            learning: "Formation",
            contacts: "Contacts",
            confidential: "Politique de confidentialité",
            agreement: "Accord de traitement des données personnelles",
            useConditions: "Conditions d'utilisation",
            oferta: "Offre publique"
        }
    }
};

export default interfaceRu;
