import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../hooks/useLang";

const ContactUsMobile = (topBorder = false, bottomBorder = false) => {
    const { currentLang } = useLanguage();
    return (
        <div
            className={`contactUsMobile ${topBorder ? "tborder" : ""} ${
                bottomBorder ? "bborder" : ""
            } pt-40 pb-40`}
        >
            <div className="contactUsMobile-name-back fs-32">
                <div className="contactUsMobile-name-name">
                    <Link to="/contactUsPage">
                        {currentLang === "ru"
                            ? "Связаться с нами"
                            : currentLang === "en"
                            ? "Contact us"
                            : currentLang === "fr"
                            ? "Contactez-nous"
                            : currentLang === "es"
                            ? "Contacta con nosotros"
                            : "Contact us"}
                    </Link>
                </div>
                <div className="contactUsMobile-name-arrow imgBack"></div>
            </div>
            <div className="contactUsMobile-link-back mt-10 bborder  fs-20">
                <div className="contactUsMobile-link-name">
                    <Link to="/contactUsPage">
                        {currentLang === "ru"
                            ? "Подробнее"
                            : currentLang === "en"
                            ? "More"
                            : currentLang === "fr"
                            ? "En savoir plus"
                            : currentLang === "es"
                            ? "Leer más"
                            : "Contact us"}
                    </Link>
                </div>
                <div className="contactUsMobile-link-arrow imgBack"></div>
            </div>
        </div>
    );
};

export default ContactUsMobile;
