const random = (min, max) => Math.floor(Math.random() * (max - min) + min);
const firstNumRu = {
    1: [1, "oдин"],
    2: [2, "двa"],
    3: [3, "тpи"],
    4: [4, "четыре"],
    5: [5, "пять"],
    6: [6, "шесть"],
    7: [7, "семь"],
    8: [8, "восемь"],
    9: [9, "девять"],
    10: [10, "десять"],
    11: [11, "одиннадцать"],
    12: [12, "двенадцать"],
    13: [13, "тринадцать"],
    14: [14, "четырнадцать"],
    15: [15, "пятнадцать"],
    16: [16, "шестнадцать"],
    17: [17, "семнадцать"],
    18: [18, "восемнадцать"],
    19: [19, "девятнадцать"],
    20: [20, "двадцать"],
    21: [21, "двадцатьодин"],
    22: [22, "двадцатьдва"],
    23: [23, "двадцатьтри"],
    24: [24, "двадцатьчетыре"],
    25: [25, "двадцатьпять"],
    26: [26, "двадцатьшесть"],
    27: [27, "двадцатьсемь"],
    28: [28, "двадцатьвосемь"],
    29: [29, "двадцатьдевять"],
    30: [30, "тридцать"]
};
const firstNumEn = {
    1: [1, "one"],
    2: [2, "two"],
    3: [3, "three"],
    4: [4, "four"],
    5: [5, "five"],
    6: [6, "six"],
    7: [7, "seven"],
    8: [8, "eight"],
    9: [9, "nine"],
    10: [10, "ten"],
    11: [11, "eleven"],
    12: [12, "twelve"],
    13: [13, "thirteen"],
    14: [14, "fourteen"],
    15: [15, "fifteen"],
    16: [16, "sixteen"],
    17: [17, "seventeen"],
    18: [18, "eighteen"],
    19: [19, "nineteen"],
    20: [20, "twenty"],
    21: [21, "twentyone"],
    22: [22, "twentytwo"],
    23: [23, "twentythree"],
    24: [24, "twentyfour"],
    25: [25, "twentyfive"],
    26: [26, "twentysix"],
    27: [27, "twentyseven"],
    28: [28, "twentyeight"],
    29: [29, "twentynine"],
    30: [30, "thirty"]
};
const firstNumFr = {
    1: [1, "un"],
    2: [2, "deux"],
    3: [3, "trois"],
    4: [4, "quatre"],
    5: [5, "cinq"],
    6: [6, "six"],
    7: [7, "sept"],
    8: [8, "huit"],
    9: [9, "neuf"],
    10: [10, "dix"],
    11: [11, "onze"],
    12: [12, "douze"],
    13: [13, "treize"],
    14: [14, "quatorze"],
    15: [15, "quinze"],
    16: [16, "seize"],
    17: [17, "dixsept"],
    18: [18, "dixhuit"],
    19: [19, "dixneuf"],
    20: [20, "vingt"],
    21: [21, "vingtetun"],
    22: [22, "vingtdeux"],
    23: [23, "vingttrois"],
    24: [24, "vingtquatre"],
    25: [25, "vingtcinq"],
    26: [26, "vingtsix"],
    27: [27, "vingtsept"],
    28: [28, "vingthuit"],
    29: [29, "vingtneuf"],
    30: [30, "trente"]
};
const firstNumEs = {
    1: [1, "un"],
    2: [2, "dos"],
    3: [3, "tres"],
    4: [4, "cuatro"],
    5: [5, "cinco"],
    6: [6, "seis"],
    7: [7, "siete"],
    8: [8, "ocho"],
    9: [9, "nueve"],
    10: [10, "diez"],
    11: [11, "once"],
    12: [12, "doce"],
    13: [13, "trece"],
    14: [14, "catorce"],
    15: [15, "quince"],
    16: [16, "dieciséis"],
    17: [17, "diecisiete"],
    18: [18, "dieciocho"],
    19: [19, "diecinueve"],
    20: [20, "veinte"],
    21: [21, "veintiuno"],
    22: [22, "veintidós"],
    23: [23, "veintitrés"],
    24: [24, "veinticuatro"],
    25: [25, "veinticinco"],
    26: [26, "veintiseis"],
    27: [27, "veintisiete"],
    28: [28, "veintiocho"],
    29: [29, "veintinueve"],
    30: [30, "treinta"]
};
const actionRu = { 1: "плюc", 2: "минуc" };
const actionEn = { 1: "plus", 2: "minus" };
const actionFr = { 1: "plus", 2: "moins" };
const actionEs = { 1: "más", 2: "menos" };

const secondNum = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10
};

function captchaNum(currentLang) {
    const firstNum =
        currentLang === "es"
            ? firstNumEs
            : currentLang === "en"
            ? firstNumEn
            : currentLang === "fr"
            ? firstNumFr
            : firstNumRu;
    const action =
        currentLang === "es"
            ? actionEs
            : currentLang === "en"
            ? actionEn
            : currentLang === "fr"
            ? actionFr
            : actionRu;
    const randomNum1 = random(1, 31); //рандом первого числа
    const num1 = firstNum[randomNum1][0]; //число для вычисления массив 1
    const num1Name = firstNum[randomNum1][1]; //число словом для отображения массив 1

    const randomAction = random(1, 3); //рандом типа вычисления
    const actionName = action[randomAction]; //тип вычисления

    const randomNum2 = random(1, num1 < 10 ? num1 : 11); //рандом второго числа.Без отрицательного результата
    const num2 = secondNum[randomNum2]; //число для вычисления массив 2
    const task = `${num1Name}${actionName}${num2}`; //строка для отображения пользователю
    // console.log("Вычисли:  ", task);

    const result = randomAction === 1 ? num1 + num2 : num1 - num2; // числовой результат вычисления
    // console.log(result);
    return { task, result };
}
export { captchaNum, random };
