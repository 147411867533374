import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";

const AnalyticsPageMobile = () => {
    const { content, currentLang } = useLanguage();
    const pageName = content.analytics.pageName;
    const imageUrls = content.analytics.imageUrls;
    const blocks = content.analytics.blocks;
    const cards = content.analytics.cards;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                customHeight={window.innerWidth < 360 ? 340 : 280}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.scouting}
                noLink={true}
                // tripleLineName={true}
                customNameLine="110"
                customHeight={
                    currentLang === "fr"
                        ? 330
                        : window.innerWidth < 360
                        ? 330
                        : 280
                }
                bottomBorder={true}
            />

            <CardsSwipeMobile
                blockContent={cards.block1}
                // topBorderbBlock={true}
                customMinTextHeight={
                    currentLang === "es"
                        ? "210"
                        : currentLang === "fr"
                        ? "250"
                        : ""
                }
            />
            <LargeImageMobile
                imageUrl={imageUrls.img2Mobile}
                topBorder={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.research}
                noLink={true}
                customHeight={window.innerWidth < 360 ? 150 : 120}
                customNameLine="10"
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block2}
                topBorderbBlock={true}
                customNameFontSize="34"
                bottomBorderBlock={true}
            />
        </div>
    );
};

export default AnalyticsPageMobile;
