import React from "react";
import { useLanguage } from "../hooks/useLang";

import CardsAdaptiveBlock from "../pageBlocks/cardsBlocks/cardsAdaptiveBlock";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const UseConditionsPage = () => {
    const { content } = useLanguage();
    const pageName = content.conditions.pageName;
    const subMenuList = content.conditions.subMenuList;
    const imageUrls = content.conditions.imageUrls;
    const blocks = content.conditions.blocks;
    const support = blocks.support;
    const cards = content.conditions.cards;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive
                blockContent={blocks.main}
                // bottomBorder={true}
            />
            <CardsAdaptiveBlock
                blockContent={cards.block1.cards}
                topBorderbBlock={true}
                paddingTopBlock={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                topBorderCard={true}
            />
            <div className="useConditionsSupportBlock tborder bborder mb-30">
                <div className="useConditionsSupportBlock-left-arrow mt-60  imgBack "></div>
                <div className="useConditionsSupportBlock-name-back ">
                    <div className="useConditionsSupportBlock-name mt-60 fs-64">
                        {support.name}
                    </div>
                </div>
                <div className="useConditionsSupportBlock-textBlock-back pt-60 pb-60">
                    <div className="useConditionsSupportBlock-textBlock fs-16 bborder">
                        {support.line1}
                    </div>
                    <div className="useConditionsSupportBlock-textBlock-halfs-back bborder">
                        <div className="useConditionsSupportBlock-textBlock-half">
                            {support.line2_1}
                        </div>
                        <div className="useConditionsSupportBlock-textBlock-half pl-15">
                            {support.line2_2}
                        </div>
                    </div>
                    <div className="useConditionsSupportBlock-textBlock-halfs-back bborder">
                        <div className="useConditionsSupportBlock-textBlock-half">
                            {support.line3_1}
                        </div>
                        <div className="useConditionsSupportBlock-textBlock-half pl-15">
                            {support.line3_2}
                        </div>
                    </div>
                    <div className="useConditionsSupportBlock-textBlock-halfs-back bborder">
                        <div className="useConditionsSupportBlock-textBlock-half">
                            {support.line4_1}
                        </div>
                        <div className="useConditionsSupportBlock-textBlock-half pl-15">
                            {support.line4_2}
                        </div>
                    </div>
                    <div className="useConditionsSupportBlock-textBlock-halfs-back bborder">
                        <div className="useConditionsSupportBlock-textBlock-half">
                            {support.line5_1}
                        </div>
                        <div className="useConditionsSupportBlock-textBlock-half pl-15">
                            {support.line5_2}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UseConditionsPage;
