import React, { useEffect, useState } from "react";
import { useLanguage } from "../hooks/useLang";

import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import axios from "axios";

// import content from "../textContent";
import CardsWithArrowsAdaptive from "../pageBlocks/cardsBlocks/cardsWithArrowsAdaptive";
import { Link } from "react-router-dom";

const URL_PUBS = "https://web.avl.team/api/site/publs/";
const URL_SOCIAL = "https://web.avl.team/api/site/by_source/";

const regExp = /[а-яА-Я0-9]/g;

const PublicActivityPage = () => {
    const { content } = useLanguage();
    const linkNameLang = content.mainPage.blocks.sixCardsBlock[0].linkName;
    const pageName = content.publicActivity.pageName;
    const subMenuList = content.publicActivity.subMenuList;
    const imageUrls = content.publicActivity.imageUrls;
    const blocks = content.publicActivity.blocks;
    const tg = content.publicActivity.telegram;
    const social = content.publicActivity.social;

    const [pubs, setPubs] = useState({ cards: [] });
    const [mp, setMp] = useState();
    const [mi, setMi] = useState();
    const [vk, setVk] = useState();
    const [dz, setDz] = useState();
    const [dz2, setDz2] = useState();

    const getSocialData = (sourse) => {
        const sourses = {
            mp: "mp/",
            mi: "mi/",
            vk: "vk/",
            dz: "dz/",
            dz2: "dz2/"
        };
        const setFunctions = {
            mp: setMp,
            mi: setMi,
            vk: setVk,
            dz: setDz,
            dz2: setDz2
        };

        const URL = URL_SOCIAL + sourses[sourse];
        axios
            // для ВК выгружаем последние 50 постов для проверки
            .get(URL, {
                params: { page: 0, per_page: sourse === "vk" ? 50 : 5 }
            })
            // если в тексте поста присутствует слово размещен,такой пост обрезается
            .then((res) =>
                setFunctions[sourse](
                    sourse !== "vk"
                        ? res.data.items
                        : res.data.items
                              .filter((item) => !item.text.includes("Размещен"))
                              .slice(0, 5)
                )
            )
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        axios
            .get(URL_PUBS, { params: { page: 0, per_page: 4 } })
            .then((res) =>
                setPubs((prev) => ({
                    ...prev,
                    cards: res.data.items
                        .sort((a, b) => {
                            return a.published_at > b.published_at ? -1 : 1;
                        })
                        .map((item, index) => ({
                            num: index + 1,
                            name: item.title,
                            // text: item.text.slice(5, 100),
                            linkName: linkNameLang,
                            externalLink: item.url
                        }))
                }))
            )
            .catch((err) => console.log(err));
        getSocialData("mp");
        getSocialData("mi");
        getSocialData("vk");
        getSocialData("dz");
        getSocialData("dz2");
    }, [linkNameLang]);
    // useEffect(() => {
    //     console.log("mi", mi);
    //     console.log("vk", vk);
    //     console.log("dz", dz);
    //     console.log("dz2", dz2);
    // }, [mp, mi]);
    // useEffect(() => {
    //     if (pubs) {
    //         console.log(pubs);
    //     }
    // }, [pubs]);
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.publications} />
            {pubs.cards[0] && (
                <CardsWithArrowsAdaptive
                    blockContent={pubs}
                    arrowsDirection="left"
                    topBorderbBlock={true}
                    marginBottomName={true}
                />
            )}
            <HorizontalBlockAdaptive blockContent={blocks.cyberDad} />
            {/* BLOCK IMAGES WITH ARROWS LEFT */}
            <div className="publicActivity-images-with-arrows-back pt-60 pb-60 tborder">
                <div className="publicActivity-arrows-back ">
                    <div className="publicActivity-arrows">
                        <div className="publicActivity-arrows-arrow imgBack"></div>
                        <div className="publicActivity-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivity-arrows-arrow imgBack mt-10"></div>
                    </div>
                    <div className="publicActivity-arrows">
                        <div className="publicActivity-arrows-arrow imgBack"></div>
                        <div className="publicActivity-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivity-arrows-arrow imgBack mt-10"></div>
                    </div>
                </div>
                <div className="publicActivity-image-back pr-85 pl-85">
                    <img
                        className="publicActivity-image-image"
                        src={imageUrls.middle1}
                        alt="error"
                    />
                </div>
                <div className="publicActivity-image-back pr-85 pl-85">
                    <img
                        className="publicActivity-image-image"
                        src={imageUrls.middle2}
                        alt="error"
                    />
                </div>
                <div className="publicActivity-image-back pl-85">
                    <img
                        className="publicActivity-image-image"
                        src={imageUrls.middle3}
                        alt="error"
                    />
                </div>
            </div>
            {/* <HorizontalBlockAdaptive
                blockContent={blocks.tg}
                onlyName={true}
            /> */}
            {/* BLOCK WITH TG CHANNELS */}
            <div
                className="publicActivity-telegram-with-arrows-back  pb-60 "
                style={{ display: "none" }}
            >
                <div className="publicActivity-telegram-channel-back pr-85 ">
                    <div className="publicActivity-telegram-channel">
                        <div className="publicActivity-telegram-channel-name tborder bborder">
                            <span className="fs-24">{tg.channel1}</span>
                            <Link
                                to={{ pathname: tg.channel1Link }}
                                target="_blank"
                                className="publicActivity-telegram-channel-name-link"
                            ></Link>
                        </div>
                        {mp &&
                            mp.map((item) => (
                                <div
                                    className="publicActivity-telegram-channel-post bborder"
                                    key={Math.random() * 40}
                                >
                                    <div className="publicActivity-telegram-channel-post-text">
                                        {/* обрезаем эмоджи в начале поста в ТГ */}
                                        {item.text && item.text[0].match(regExp)
                                            ? item.text.slice(0, 100)
                                            : item.text.slice(2, 101)}
                                        {"..."}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivity-telegram-channel-post-arrow"
                                    ></Link>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="publicActivity-telegram-channel-back pr-85 pl-85 ">
                    <div className="publicActivity-telegram-channel">
                        <div className="publicActivity-telegram-channel-name tborder bborder">
                            <span className="fs-24">{tg.channel2}</span>
                            <Link
                                to={{ pathname: tg.channel2Link }}
                                target="_blank"
                                className="publicActivity-telegram-channel-name-link"
                            ></Link>
                        </div>
                        {mi &&
                            mi.map((item) => (
                                <div
                                    className="publicActivity-telegram-channel-post bborder"
                                    key={Math.random() * 50}
                                >
                                    <div className="publicActivity-telegram-channel-post-text">
                                        {/* обрезаем эмоджи в начале поста в ТГ */}
                                        {item.text && item.text[0].match(regExp)
                                            ? item.text.slice(0, 100)
                                            : item.text.slice(2, 101)}
                                        {"..."}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivity-telegram-channel-post-arrow"
                                    ></Link>
                                </div>
                            ))}
                    </div>
                </div>

                <div className="publicActivity-telegram-arrows-back ">
                    <div className="publicActivity-telegram-arrows">
                        <div className="publicActivity-telegram-arrows-arrow imgBack"></div>
                        <div className="publicActivity-telegram-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivity-telegram-arrows-arrow imgBack mt-10"></div>
                    </div>
                    <div className="publicActivity-telegram-arrows">
                        <div className="publicActivity-telegram-arrows-arrow imgBack"></div>
                        <div className="publicActivity-telegram-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivity-telegram-arrows-arrow imgBack mt-10"></div>
                    </div>
                </div>
            </div>
            {/* <HorizontalBlockAdaptive
                blockContent={blocks.social}
                onlyName={true}
            /> */}
            {/* BLOCK WITH SOCIAL NETWORKS CHANNELS */}
            <div
                className="publicActivity-social-with-arrows-back  pb-60 "
                style={{ display: "none" }}
            >
                <div className="publicActivity-social-arrows-back ">
                    <div className="publicActivity-social-arrows">
                        <div className="publicActivity-social-arrows-arrow imgBack"></div>
                        <div className="publicActivity-social-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivity-social-arrows-arrow imgBack mt-10"></div>
                    </div>
                    <div className="publicActivity-social-arrows">
                        <div className="publicActivity-social-arrows-arrow imgBack"></div>
                        <div className="publicActivity-social-arrows-arrow imgBack mt-10"></div>
                        <div className="publicActivity-social-arrows-arrow imgBack mt-10"></div>
                    </div>
                </div>
                <div className="publicActivity-social-channel-back pr-85 pl-85">
                    <div className="publicActivity-social-channel">
                        <div className="publicActivity-social-channel-name tborder bborder">
                            <span className="fs-24">{social.channel1}</span>
                            <Link
                                to={{ pathname: social.channel1Link }}
                                target="_blank"
                                className="publicActivity-social-channel-name-link"
                            ></Link>
                        </div>
                        {dz &&
                            dz.map((item, index) => (
                                <div
                                    className="publicActivity-social-channel-post bborder"
                                    key={Math.random() * 20}
                                >
                                    <div className="publicActivity-social-channel-post-text">
                                        {item.title.slice(0, 100)}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivity-social-channel-post-arrow"
                                    ></Link>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="publicActivity-social-channel-back pr-85 pl-85 ">
                    <div className="publicActivity-social-channel">
                        <div className="publicActivity-social-channel-name tborder bborder">
                            <span className="fs-24">{social.channel2}</span>
                            <Link
                                to={{ pathname: social.channel2Link }}
                                target="_blank"
                                className="publicActivity-social-channel-name-link"
                            ></Link>
                        </div>
                        {vk &&
                            vk.map((item) => (
                                <div
                                    className="publicActivity-social-channel-post bborder"
                                    key={Math.random() * 10}
                                >
                                    <div className="publicActivity-social-channel-post-text">
                                        {/* обрезаем эмоджи в начале поста в ТГ */}
                                        {item.text && item.text[0].match(regExp)
                                            ? item.text.slice(0, 100)
                                            : item.text.slice(2, 101)}
                                        {"..."}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivity-social-channel-post-arrow"
                                    ></Link>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="publicActivity-social-channel-back pl-85 ">
                    <div className="publicActivity-social-channel">
                        <div className="publicActivity-social-channel-name tborder bborder">
                            <span className="fs-24">{social.channel3}</span>
                            <Link
                                to={{ pathname: social.channel3Link }}
                                target="_blank"
                                className="publicActivity-social-channel-name-link"
                            ></Link>
                        </div>
                        {dz2 &&
                            dz2.map((item) => (
                                <div
                                    className="publicActivity-social-channel-post bborder"
                                    key={Math.random() * 30}
                                >
                                    <div className="publicActivity-social-channel-post-text">
                                        {item.title.slice(0, 100)}
                                    </div>
                                    <Link
                                        to={{ pathname: item.url }}
                                        target="_blank"
                                        className="publicActivity-social-channel-post-arrow"
                                    ></Link>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicActivityPage;
