import React, { useState } from "react";

const AgreementsBlockMobile = ({ blockContent }) => {
    const [currentTextVersion, setTextVersion] = useState(
        blockContent.agreementDate[0].textVersion
    );
    const [hideText, setHide] = useState(true);

    const handleHide = (status) => {
        setHide(status);
    };
    return (
        <>
            <div className="agreementsBlockMobile-back">
                <div className="agreementsBlockMobile-versions-back pt-40 pb-40 bborder">
                    {blockContent.agreementDate.map((item, index) => (
                        <div
                            key={index}
                            className={`agreementsBlockMobile-itemsList-item fs-14 pt-20 pb-20 cd ${
                                item.textVersion === currentTextVersion
                                    ? " bold "
                                    : " "
                            } ${index === 0 ? "tborder bborder" : " bborder"}`}
                            onClick={() => {
                                setTextVersion(item.textVersion);
                                handleHide(false);
                            }}
                        >
                            <span>{`${item.pointText} ${item.date}`}</span>
                            {item.textVersion === currentTextVersion && (
                                <div className="agreementsBlockMobile-item-arrow imgBack"></div>
                            )}
                        </div>
                    ))}
                </div>
                <div
                    className="agreementsBlockMobile-agreement-back bborder"
                    style={hideText ? { right: "-100%" } : { right: "0px" }}
                >
                    <div className="agreementsBlockMobile-agreement-moveback-back bborder">
                        <div
                            className="agreementsBlockMobile-agreement-moveback-arrow imgBack"
                            onClick={() => handleHide(true)}
                        ></div>
                    </div>
                    <div className="agreementsBlockMobile-agreement-text pt-40 pb-40">
                        {blockContent.agreementText[currentTextVersion]}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgreementsBlockMobile;
