import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "./hooks/useLang";
// import { useLanguage } from "./hooks/useLang";
import ChangeLang from "./pageBlocks/changeLang";

const MenuBar = ({ handeDropDownMenu, dropdownMenuOpen, handleTheme }) => {
    const location = useLocation();
    const { pathname } = useLocation();
    useEffect(() => {
        if (!location.hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    const { interfaceNames } = useLanguage();
    return (
        <>
            {pathname !== `/documentation-avl-3.0/` && (
                <menu className="ma">
                    <Link to="/">
                        <div className="logo imgBack"></div>
                    </Link>
                    <div className="menuItemsBack">
                        <div
                            className="changeTheme imgBack cp mr-menu"
                            onClick={() => handleTheme()}
                        ></div>
                        <div
                            className=" cp mr-menu "
                            style={{ height: 10 + "px" }}
                        >
                            <ChangeLang />
                        </div>
                        <Link
                            className="newsLink cp mr-menu fs-14"
                            to={{ pathname: "https://main.avl.team/" }}
                            target="_blank"
                        >
                            {interfaceNames.menubar.news}
                        </Link>
                        {/* <div className="phoneNum mr-menu cd fs-14">
                            +7(499)393-00-15
                        </div> */}
                        <div className="email mr-menu cd fs-14">
                            info@avl.team
                        </div>
                        <div
                            className={
                                !dropdownMenuOpen
                                    ? "menuBurger imgBack cp"
                                    : "menuBurgerOpen imgBack cp"
                            }
                            onClick={() => handeDropDownMenu()}
                        ></div>
                    </div>
                </menu>
            )}
        </>
    );
};

export default MenuBar;
