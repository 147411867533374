import React from "react";
import { useLanguage } from "../hooks/useLang";

import CardsAdaptiveBlock from "../pageBlocks/cardsBlocks/cardsAdaptiveBlock";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import HorizontalBlockUnnamed from "../pageBlocks/horizontalBlockUnnamed";
import LargeImage from "../pageBlocks/largeImage";

import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const ScannerPage = () => {
    const { content } = useLanguage();
    const pageName = content.scanner.pageName;
    const subMenuList = content.scanner.subMenuList;
    const imageUrls = content.scanner.imageUrls;
    const blocks = content.scanner.blocks;
    const cards = content.scanner.cards;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.main} />
            <HorizontalBlockAdaptive blockContent={blocks.control} />
            <HorizontalBlockAdaptive
                blockContent={blocks.possibilities}
                bottomBorder={false}
                bottomPadding={true}
            />
            <CardsAdaptiveBlock
                blockContent={cards.block1}
                topBorderCard={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                paddingBottomBlock={true}
            />
            <HorizontalBlockUnnamed
                blockContent={blocks.unnamed}
                bottomBorder={false}
            />
            <HorizontalBlockAdaptive blockContent={blocks.benifits} />
            <CardsAdaptiveBlock
                blockContent={cards.block2}
                topBorderCard={true}
                marginTopNum={true}
                marginTopImg={true}
                marginTopText={true}
                paddingBottomBlock={true}
            />
        </div>
    );
};

export default ScannerPage;
