import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../hooks/useLang";

const SiteMap = () => {
    const { pathname } = useLocation();
    const { interfaceNames } = useLanguage();
    const sitemap = interfaceNames.footer.sitemap;
    // console.log("currentLang in SiteMap", currentLang);
    const moveToElement = (id) => {
        setTimeout(() => {
            const el = document.querySelector(`#${id}`);
            el.scrollIntoView();
        }, 300);
    };

    return (
        <div
            className={`footer-siteMap-back pb-60 ${
                pathname === "/cookiesPage" ? " tborder pt-40 " : "pt-10"
            }`}
        >
            <div className="footer-siteMap">
                <div className="footer-siteMap-leftBlock">
                    <div className="footer-siteMap-blockBack">
                        <div className="footer-siteMap-blockName">
                            <div className="cp pt">
                                <Link to="/aboutUsPage">
                                    {sitemap.aboutUs.aboutus}
                                </Link>
                            </div>

                            <hr className="footer-siteMap-blockName-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/aboutUsPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("AboutUsPageMain")
                                    }
                                >
                                    {sitemap.aboutUs.creator}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/aboutUsPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("AboutUsPageMission")
                                    }
                                >
                                    {sitemap.aboutUs.mission}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/aboutUsPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("AboutUsPageDirection")
                                    }
                                >
                                    {sitemap.aboutUs.activity}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link to="/contactsPage">
                                    {sitemap.aboutUs.contacts}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                        </div>
                    </div>
                </div>
                <div className="footer-siteMap-middleBlock">
                    <div className="footer-siteMap-blockBack">
                        <div className="footer-siteMap-blockName">
                            <div className="cp pt">
                                <Link to="/forBusinessPage">
                                    {sitemap.forBusiness.forbusiness}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockName-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/forBusinessPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("ForBusinessCenter")
                                    }
                                >
                                    {sitemap.forBusiness.center}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link to="/communicationsPage">
                                    {sitemap.forBusiness.communications}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/forBusinessPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("ForBusinessService")
                                    }
                                >
                                    {sitemap.forBusiness.analytics}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/forBusinessPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("ForBusinessLicence")
                                    }
                                >
                                    {sitemap.forBusiness.licence}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                        </div>
                    </div>
                </div>
                <div className="footer-siteMap-rightBlock">
                    <div className="footer-siteMap-blockBack">
                        <div className="footer-siteMap-blockName">
                            <div className="cp pt">
                                <Link to="/platformPage">
                                    {sitemap.platform.platform}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockName-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cd pt">
                                <Link to="/demonstrationPage">
                                    {sitemap.platform.demonstaration}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link to="/situationCenterPage">
                                    {sitemap.platform.situationCenter}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link to="/audiencePage">
                                    {sitemap.platform.audience}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link to="/scannerPage">
                                    {sitemap.platform.scanner}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr " />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-siteMap">
                <div className="footer-siteMap-leftBlock">
                    <div className="footer-siteMap-blockBack">
                        <div className="footer-siteMap-blockName">
                            <div className="cp pt">
                                <Link to="/learningPage">
                                    {sitemap.courses.courses}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockName-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/learningPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("LearningService")
                                    }
                                >
                                    {sitemap.courses.intelligence}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/learningPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("LearningCorporate")
                                    }
                                >
                                    {sitemap.courses.trainings}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                        </div>
                    </div>
                </div>
                <div className="footer-siteMap-middleBlock">
                    <div className="footer-siteMap-blockBack">
                        <div className="footer-siteMap-blockName">
                            <div className="cp pt">
                                <Link to="/safetyPage">
                                    {sitemap.safety.safety}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockName-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/safetyPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("SafetyEconomic")
                                    }
                                >
                                    {sitemap.safety.economy}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/safetyPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("SafetySupport")
                                    }
                                >
                                    {sitemap.safety.support}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt">
                                <Link
                                    to={{
                                        pathname: "/safetyPage"
                                    }}
                                    onClick={() => moveToElement("SafetyCyber")}
                                >
                                    {sitemap.safety.cyber}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                        </div>
                    </div>
                </div>
                <div className="footer-siteMap-rightBlock">
                    <div className="footer-siteMap-blockBack">
                        <div className="footer-siteMap-blockName">
                            <div className="cp pt ">
                                <Link to="/publicActivityPage">
                                    {sitemap.public.publicActivity}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockName-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt ">
                                <Link to="/publicationsPage">
                                    {sitemap.public.publications}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt ">
                                <Link
                                    to={{
                                        pathname: "/publicActivityPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("publicActivityPageBook")
                                    }
                                >
                                    {sitemap.public.cyberDad}
                                </Link>
                            </div>
                            {/* <hr className="footer-siteMap-blockPoint-hr" />
                            <div className="footer-siteMap-blockPoint fs-16 cp pt ">
                                <Link
                                    to={{
                                        pathname: "/publicActivityPage"
                                    }}
                                    onClick={() =>
                                        moveToElement("publicActivityPageTG")
                                    }
                                >
                                    {sitemap.public.tgChannels}
                                </Link>
                            </div> */}
                            <hr className="footer-siteMap-blockPoint-hr" />
                            {/* <div className="footer-siteMap-blockPoint fs-16 cp pt ">
                                <Link
                                    to={{
                                        pathname: "/publicActivityPage"
                                    }}
                                    onClick={() =>
                                        moveToElement(
                                            "publicActivityPageSocial"
                                        )
                                    }
                                >
                                    {sitemap.public.social}
                                </Link>
                            </div>
                            <hr className="footer-siteMap-blockPoint-hr" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SiteMap;
