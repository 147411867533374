import React, { useEffect, useState } from "react";

import DropdownMenu from "./dropdownMenu";
import Footer from "./footer";
import MainPage from "./pages/mainPage";
import MenuBar from "./menuBar";
import { setToDarkTheme, setToLightTheme } from "./utils/darkTheme";
import PlatformPage from "./pages/platformPage";
import SituationCenterPage from "./pages/situationCenterPage";
import CommunicationsPage from "./pages/communicationsPage";
import AnalyticsPage from "./pages/analyticsPage";
import SafetyPage from "./pages/safetyPage";
import LearningPage from "./pages/learningPage";
import AudiencePage from "./pages/audiencePage";
import ForBusinessPage from "./pages/forBusinessPage";
import ScannerPage from "./pages/scannerPage";
import AboutUsPage from "./pages/aboutUsPage";
// import { Route, Switch, Redirect } from "react-router-dom";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

// import { Link } from "react-router-dom";
import SubscribePage from "./pages/subscribePage";
import DemonstrationPage from "./pages/demonstrationPage";
import ContactsPage from "./pages/contactsPage";
import CookiesPage from "./pages/cookiesPage";
import UseConditionsPage from "./pages/useConditionsPage";
import PersonalDataAgreementPage from "./pages/personalDataAgreementPage";
import ConfidentialPoliticPage from "./pages/confidentialPoliticPage";
import PublicOfertaPage from "./pages/publicOfertaPage";
import TariffsPage from "./pages/tariffsPage";
import QuestionPage from "./pages/questionPage";
import RequestPage from "./pages/requestPage";
import PublicationsPage from "./pages/publicationsPage";
import PublicActivityPage from "./pages/publicActivityPage";
import { useLanguage } from "./hooks/useLang";
import Docs from "./pages/docs";
import { getCookie, setCookie } from "./utils/cookieHandler";
// import CookiesPopup from "./pageBlocks/cookiesPopup";
const allowLocs = [
    `/documentation-avl-3.0/`,
    "/platformPage",
    "/questionPage",
    "/requestPage",
    "/forBusinessPage",
    "/safetyPage",
    "/publicationsPage",
    "/publicActivityPage",
    "/aboutUsPage",
    "/subscribePage",
    "/learningPage",
    "/situationCenterPage",
    "/audiencePage",
    "/scannerPage",
    "/communicationsPage",
    "/analyticsPage",
    "/demonstrationPage",
    "/useConditionsPage",
    "/cookiesPage",
    "/publicOfertaPage",
    "/personalDataAgreementPage",
    "/confidentionalPoliticPage",
    "/tariffsPage",
    "/contactsPage",
    "/"
];

const AllBack = () => {
    // const [cookie, setHideCookie] = useState(false);
    // useEffect(() => {
    //     const isCookie = localStorage.getItem("isCookie");
    //     if (isCookie) {
    //         setHideCookie(true);
    //     }
    //     console.log("isCookie", isCookie);
    // }, []);
    // const handleCookie = () => {
    //     setHideCookie(true);
    //     localStorage.setItem("isCookie", true);
    // };
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
    const [darkTheme, setDarkTheme] = useState();
    const [language, setLanguage] = useState();

    const { handleCurrentLang } = useLanguage();
    const currentLocation = useLocation();
    const history = useHistory();

    useEffect(() => {
        const returnFunc = () => {
            history.push("/");
            history.goForward();
        };
        // console.log("currentLocation", currentLocation.pathname);

        allowLocs.includes(currentLocation.pathname)
            ? console.log("")
            : returnFunc();
        // eslint-disable-next-line
    }, [currentLocation]);

    const handeDropDownMenu = (state) => {
        setDropdownMenuOpen(state || !dropdownMenuOpen);
    };
    const handleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLanguage = () => {
        const lang = localStorage.getItem("lang");
        // console.log("lang", lang);
        if (lang === null || lang === "ru") {
            localStorage.setItem("lang", "en");
            setLanguage("en");
            handleCurrentLang("en");
        }
        if (lang === "en") {
            localStorage.setItem("lang", "ru");
            setLanguage("ru");
            handleCurrentLang("ru");
        }
    };
    useEffect(() => {
        handleCurrentLang(language);
        // eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        // console.log("darkTheme", darkTheme);
        if (darkTheme !== undefined) {
            darkTheme ? setToDarkTheme() : setToLightTheme();
        }
    }, [darkTheme]);
    useEffect(() => {
        // console.log("AllBack is rerender");
        const currentTheme = getCookie(`avl4site_theme`);
        console.log("currentTheme", currentTheme);
        if (!currentTheme) {
            setCookie("avl4site_theme", "light", {
                expires: new Date("2025-01-01 00:00:01"),
                domain: "avl.team",
                samesite: "lax"
            });
        }
        if (currentTheme === "dark") {
            setToDarkTheme();
        }
        if (currentTheme === "light") {
            setToLightTheme();
        }
    }, []);
    // useEffect(() => {
    //     console.log("dropdownMenuOpen in AllBack", dropdownMenuOpen);
    // }, [dropdownMenuOpen]);
    // console.log(document.documentElement.clientWidth);
    return (
        <div
            className={`allBack ${language ? " " : " "}`}
            // style={{ transform: "scale(0.75)" }}
        >
            <MenuBar
                handeDropDownMenu={handeDropDownMenu}
                dropdownMenuOpen={dropdownMenuOpen}
                handleTheme={handleTheme}
            />
            <DropdownMenu
                dropdownMenuOpen={dropdownMenuOpen}
                handeDropDownMenu={handeDropDownMenu}
            />
            <Switch>
                <Route
                    exact
                    strict
                    path={`/documentation-avl-3.0/`}
                    component={Docs}
                />
                <Route
                    path="/platformPage"
                    component={PlatformPage}
                />

                <Route
                    path="/questionPage"
                    component={QuestionPage}
                />
                <Route
                    path="/requestPage"
                    component={RequestPage}
                />
                <Route
                    path="/forBusinessPage"
                    component={ForBusinessPage}
                />
                <Route
                    path="/safetyPage"
                    component={SafetyPage}
                />
                <Route
                    path="/publicationsPage"
                    component={PublicationsPage}
                />
                <Route
                    path="/publicActivityPage"
                    component={PublicActivityPage}
                />
                <Route
                    path="/aboutUsPage"
                    component={AboutUsPage}
                />
                <Route
                    path="/subscribePage"
                    component={SubscribePage}
                />
                <Route
                    path="/learningPage"
                    component={LearningPage}
                />
                <Route
                    path="/situationCenterPage"
                    component={SituationCenterPage}
                />
                <Route
                    path="/audiencePage"
                    component={AudiencePage}
                />
                <Route
                    path="/scannerPage"
                    component={ScannerPage}
                />
                <Route
                    path="/communicationsPage"
                    component={CommunicationsPage}
                />
                <Route
                    path="/analyticsPage"
                    component={AnalyticsPage}
                />
                <Route
                    path="/demonstrationPage"
                    component={DemonstrationPage}
                />
                <Route
                    path="/useConditionsPage"
                    component={UseConditionsPage}
                />
                <Route
                    path="/cookiesPage"
                    component={CookiesPage}
                />
                <Route
                    path="/publicOfertaPage"
                    component={PublicOfertaPage}
                />
                <Route
                    path="/PersonalDataAgreementPage"
                    component={PersonalDataAgreementPage}
                />
                <Route
                    path="/confidentionalPoliticPage"
                    component={ConfidentialPoliticPage}
                />
                <Route
                    path="/tariffsPage"
                    component={TariffsPage}
                />
                <Route path="/contactsPage">
                    <ContactsPage darkTheme={darkTheme} />
                </Route>
                <Route
                    exact
                    strict
                    path="/"
                    component={MainPage}
                />
                {/* <Redirect to="/" /> */}
                {/* <Route
                    path="/*"
                    component={<Redirect to="/contactsPage" />}
                /> */}
                {/* <Redirect path="/">
                    <MainPage />
                </Redirect> */}
                {/* <SafetyPage /> */}
            </Switch>
            <Footer
                handleTheme={handleTheme}
                handleLanguage={handleLanguage}
            />
            {/* {!cookie && <CookiesPopup handleCookie={handleCookie} />} */}
        </div>
    );
};

export default AllBack;
