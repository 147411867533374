const Loader = ({ customHeight = 700, customWidth = 100 }) => {
    return (
        <div
            style={{
                height: `${customHeight}px`,
                width: `${customWidth}%`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div
                style={{
                    height: "300px",
                    width: "300px",
                    backgroundImage: `url("../Loading_circle_start.gif")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}
            ></div>
        </div>
    );
};

export default Loader;
