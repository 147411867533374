export default function keyboardPageName(pageName, element = ".pageHead-name") {
    const nameEl = document.querySelector(element);
    setTimeout(() => {
        // const nameEl = document.querySelector(element);
        if (!nameEl.innerText) {
            for (let i = 0, j = 0; i < pageName.length; i++, j += 0.8) {
                const el = document.createElement("span");
                el.innerText = pageName[i];
                el.className = "pageNameSpan";
                el.style.animationDelay = `${i}00ms`;
                nameEl.append(el);
            }
        } else {
            nameEl.innerText = "";
            for (let i = 0, j = 0; i < pageName.length; i++, j += 0.8) {
                const el = document.createElement("span");
                el.innerText = pageName[i];
                el.className = "pageNameSpan";
                el.style.animationDelay = `${i}00ms`;
                nameEl.append(el);
            }
        }
    }, 50);
}
