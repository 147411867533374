import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import keyboardPageName from "../../utils/keyboardPageName.js";
import PageHeadSubMenu from "./pageHeadSubMenu.jsx";

const PageHead = ({ pageName, subMenuList }) => {
    const [openedSubMenu, setOpenedSubMenu] = useState(false);

    const handleSubMenu = () => {
        setOpenedSubMenu(!openedSubMenu);
    };
    useEffect(() => {
        keyboardPageName(pageName);
        // eslint-disable-next-line
    }, [pageName]);
    const { pathname } = useLocation();
    return (
        <div className="pageHead">
            <div
                className="pageHead-name fs-128 cd"
                style={
                    pathname === "/cookiesPage" ||
                    pathname === "/forBusinessPage"
                        ? { paddingRight: "20%" }
                        : pathname === "/personalDataAgreementPage"
                        ? {
                              paddingRight: "10%",
                              fontSize: 98 + "px",
                              lineHeight: 98 + "px"
                          }
                        : { paddingRight: "20%" }
                }
            >
                {}
            </div>
            {subMenuList && (
                <>
                    <div
                        className="pageHead-sideSlideMenuBack-closed-arrow  imgBack cp"
                        // style={
                        //     openedSubMenu
                        //         ? { transform: "rotate(-0.25turn)" }
                        //         : { transform: "rotate(0)" }
                        // }
                        onClick={() => handleSubMenu()}
                    ></div>

                    <PageHeadSubMenu
                        subMenuList={subMenuList}
                        openedSubMenu={openedSubMenu}
                        handleSubMenu={handleSubMenu}
                    />
                    <hr />
                </>
            )}
        </div>
    );
};

export default PageHead;
