import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
// import agreements from "../agreementsTextEn";
import AgreementsBlockTablet from "../pages/agreementsBlockTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"


const PublicOfertaPageTablet= () => {
    const { content, agreements } = useLanguage();
    const pageName = content.publicOferta.pageName;
    const subMenuList = content.publicOferta.subMenuList;
    const imageUrls = content.publicOferta.imageUrls;
    const blocks = content.publicOferta.blocks;
    const publicOfertaAgreement = agreements.publicOferta;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.mainTablet}
                nameSize={"fs-44"}
                customNameWidthPublicOferta={true}
            />
            <AgreementsBlockTablet blockContent={publicOfertaAgreement} 
            
            topBorderCard={true}
            />
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default PublicOfertaPageTablet;
