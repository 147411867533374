import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../hooks/useLang";
import moment from "moment";

const SiteMapMobile = () => {
    // const { pathname } = useLocation();
    const { interfaceNames } = useLanguage();
    const blockNames = interfaceNames.footer.horizontalBlock;
    // console.log("currentLang in SiteMap", currentLang);
    // const moveToElement = (id) => {
    //     setTimeout(() => {
    //         const el = document.querySelector(`#${id}`);
    //         el.scrollIntoView();
    //     }, 300);
    // };

    return (
        <div className="footer-SiteMapMobile-back mt-40 ">
            <div className="footer-SiteMapMobile">
                <div className="footer-SiteMapMobile-column fs-12 pr-12">
                    <div className="footer-SiteMapMobile-columnItem ">
                        <Link to="/forBusinessPage">
                            {blockNames.forBusiness}
                        </Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/platformPage">{blockNames.platform}</Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/safetyPage">{blockNames.safety}</Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/publicActivityPage">
                            {blockNames.publicActivity}
                        </Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/aboutUsPage">{blockNames.aboutus}</Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/learningPage">{blockNames.learning}</Link>
                    </div>
                </div>
                <div className="footer-SiteMapMobile-column fs-12 pr-30">
                    <div className="footer-SiteMapMobile-columnItem ">
                        <Link to="/contactsPage">{blockNames.contacts}</Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/cookiesPage">Cookies</Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/confidentionalPoliticPage">
                            {blockNames.confidential}
                        </Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/personalDataAgreementPage">
                            {blockNames.agreement}
                        </Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem mt-10">
                        <Link to="/useConditionsPage">
                            {blockNames.useConditions}
                        </Link>
                    </div>
                    <div className="footer-SiteMapMobile-columnItem pt-10">
                        <Link to="/publicOfertaPage">{blockNames.oferta}</Link>
                    </div>
                </div>
            </div>
            <div className="footer-SiteMapMobile-registry mt-40 tborder bborder pt-20 pb-20">
                <div className="footer-SiteMapMobile-registry-rights fs-14">
                    {`© ${moment().format("YYYY")} ${blockNames.name}. ${
                        blockNames.rights
                    }.`}
                </div>
                <div className="footer-SiteMapMobile-registry-govlist mt-20 fs-12">
                    <Link
                        to={{
                            pathname:
                                "https://reestr.digital.gov.ru/reestr/305133/?sphrase_id=2597706"
                        }}
                        target="_blank"
                    >
                        {blockNames.registry}
                    </Link>{" "}
                </div>
            </div>
            <div className="footer-SiteMapMobile-contacts bborder mb-20 pt-10 pb-10">
                {/* <div className="footer-SiteMapMobile-contacts-phone fs-12 cd">
                    +7 (499) 393-00-15
                </div> */}
                <div className="footer-SiteMapMobile-contacts-email fs-12 cd">
                    <Link
                        to={{ pathname: "mailto:info@avl.team" }}
                        // href="mailto:info@avl.team"
                        target="_blank"
                    ></Link>
                    info@avl.team
                </div>
            </div>
        </div>
    );
};

export default SiteMapMobile;
