import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { setToDarkTheme, setToLightTheme } from "./utils/darkTheme";

import { getCookie, setCookie } from "./utils/cookieHandler";
import MainPageMobile from "./mobile/pagesMobile/mainPageMobile";
// import MenuBar from "./menuBar";
import MenuBarMobile from "./mobile/menuBarMobile";
import DropdownMenuMobile from "./mobile/dropdownMenuMobile";
import FooterMobile from "./mobile/footerMobile/footerMobile";
import ContactUsPageMobile from "./mobile/pagesMobile/ContactUsPageMobile";
import ContactsPageMobile from "./mobile/pagesMobile/contactsPageMobile";
import PlatformPageMobile from "./mobile/pagesMobile/platformPageMobile";
import ForBusinessPageMobile from "./mobile/pagesMobile/forBusinessPageMobile";
import TariffsPageMobile from "./mobile/pagesMobile/tariffsPageMobile";
import SituationCenterPageMobile from "./mobile/pagesMobile/situationCenterPageMobile";
import CommunicationsPageMobile from "./mobile/pagesMobile/communicationsPageMobile";
import AnalyticsPageMobile from "./mobile/pagesMobile/analyticsPageMobile";
import SafetyPageMobile from "./mobile/pagesMobile/safetyPageMobile";
import LearningPageMobile from "./mobile/pagesMobile/learningPageMobile";
import AudiencePageMobile from "./mobile/pagesMobile/audiencePageMobile";
import ScannerPageMobile from "./mobile/pagesMobile/scannerPageMobile";
import AboutUsPageMobile from "./mobile/pagesMobile/aboutUsPageMobile";
import PublicActivityPageMobile from "./mobile/pagesMobile/publicActivityPageMobile";
import PublicationsPageMobile from "./mobile/pagesMobile/publicationsPageMobile";
import CookiesPageMobile from "./mobile/pagesMobile/cookiesPageMobile";
import UseConditionsPageMobile from "./mobile/pagesMobile/useConditionsPageMobile";
import PublicOfertaPageMobile from "./mobile/pagesMobile/publicOfertaPageMobile";
import ConfidentialPoliticPageMobile from "./mobile/pagesMobile/confidentialPoliticPageMobile";
import PersonalDataAgreementPageMobile from "./mobile/pagesMobile/personalDataAgreementPageMobile";
import DocumentationMobile from "./mobile/pagesMobile/docsMobile";

const allowLocs = [
    `/documentation-avl-3.0/`,
    "/platformPage",
    "/contactUsPage",
    "/forBusinessPage",
    "/safetyPage",
    "/publicationsPage",
    "/publicActivityPage",
    "/aboutUsPage",
    "/subscribePage",
    "/learningPage",
    "/situationCenterPage",
    "/audiencePage",
    "/scannerPage",
    "/communicationsPage",
    "/analyticsPage",
    "/useConditionsPage",
    "/cookiesPage",
    "/publicOfertaPage",
    "/personalDataAgreementPage",
    "/confidentionalPoliticPage",
    "/tariffsPage",
    "/contactsPage",
    "/"
];

const AllBackMobile = () => {
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
    const [darkTheme, setDarkTheme] = useState();
    const [tapToTopHide, setTapToTop] = useState();
    const currentLocation = useLocation();
    const history = useHistory();
    const allBack = useRef();
    const handeDropDownMenu = (state) => {
        setDropdownMenuOpen(state || !dropdownMenuOpen);
    };
    const handleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    useLayoutEffect(() => {
        darkTheme ? setToDarkTheme() : setToLightTheme();
    }, [darkTheme]);
    useLayoutEffect(() => {
        // console.log("AllBack is rerender");
        const currentTheme = getCookie(`avl4site_theme`);
        console.log("currentTheme", currentTheme);
        if (!currentTheme) {
            setCookie("avl4site_theme", "light", {
                expires: new Date("2025-01-01 00:00:01"),
                domain: "avl.team",
                samesite: "lax"
            });
        }
        if (currentTheme === "dark") {
            setToDarkTheme();
        }
        if (currentTheme === "light") {
            setToLightTheme();
        }
    }, []);
    useEffect(() => {
        const returnFunc = () => {
            history.push("/");
            history.goForward();
        };
        if (!allowLocs.includes(currentLocation.pathname)) returnFunc();
        // eslint-disable-next-line
    }, [currentLocation]);
    useEffect(() => {
        if (allBack.current) {
            document.addEventListener("scroll", (event) =>
                setTapToTop(allBack.current.getBoundingClientRect().top)
            );
        }
    }, [allBack]);

    return (
        <div
            className={`allBackMobile `}
            ref={allBack}
        >
            <MenuBarMobile
                handeDropDownMenu={handeDropDownMenu}
                dropdownMenuOpen={dropdownMenuOpen}
                handleTheme={handleTheme}
            />
            <DropdownMenuMobile
                dropdownMenuOpen={dropdownMenuOpen}
                handeDropDownMenu={handeDropDownMenu}
            />
            <Switch>
                <Route
                    exact
                    strict
                    path={`/documentation-avl-3.0/`}
                    component={DocumentationMobile}
                />
                <Route
                    path="/platformPage"
                    component={PlatformPageMobile}
                />

                <Route
                    path="/contactUsPage"
                    component={ContactUsPageMobile}
                />
                <Route
                    path="/forBusinessPage"
                    component={ForBusinessPageMobile}
                />
                <Route
                    path="/safetyPage"
                    component={SafetyPageMobile}
                />
                <Route
                    path="/publicationsPage"
                    component={PublicationsPageMobile}
                />
                <Route
                    path="/publicActivityPage"
                    component={PublicActivityPageMobile}
                />
                <Route
                    path="/aboutUsPage"
                    component={AboutUsPageMobile}
                />
                {/* <Route
                    path="/subscribePage"
                    component={SubscribePage}
                /> */}
                <Route
                    path="/learningPage"
                    component={LearningPageMobile}
                />
                <Route
                    path="/situationCenterPage"
                    component={SituationCenterPageMobile}
                />
                <Route
                    path="/audiencePage"
                    component={AudiencePageMobile}
                />
                <Route
                    path="/scannerPage"
                    component={ScannerPageMobile}
                />
                <Route
                    path="/communicationsPage"
                    component={CommunicationsPageMobile}
                />
                <Route
                    path="/analyticsPage"
                    component={AnalyticsPageMobile}
                />
                <Route
                    path="/useConditionsPage"
                    component={UseConditionsPageMobile}
                />
                <Route
                    path="/cookiesPage"
                    component={CookiesPageMobile}
                />
                <Route
                    path="/publicOfertaPage"
                    component={PublicOfertaPageMobile}
                />
                <Route
                    path="/personalDataAgreementPage"
                    component={PersonalDataAgreementPageMobile}
                />
                <Route
                    path="/confidentionalPoliticPage"
                    component={ConfidentialPoliticPageMobile}
                />
                <Route
                    path="/tariffsPage"
                    component={TariffsPageMobile}
                />
                <Route path="/contactsPage">
                    <ContactsPageMobile />
                </Route>
                <Route
                    exact
                    strict
                    path="/"
                    component={MainPageMobile}
                />
            </Switch>
            <FooterMobile />
            <div
                className="tapToTop imgBack"
                onClick={() => window.scrollTo({ top: 1, behavior: "smooth" })}
                style={
                    tapToTopHide < -500
                        ? {
                              height: "50px",
                              width: "50px",
                              transition: "0.3s",
                              position: "sticky",
                              right: "60px",
                              border: "1px solid var(--hrLineColor)"
                          }
                        : {
                              height: "0px",
                              width: "0px",
                              transition: "0.3s"
                          }
                }
            ></div>
        </div>
    );
};

export default AllBackMobile;
