import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/useLang";
import device from "current-device";

const languagesText = {
    ru: {
        line1: "Мы используем файлы",
        line2: "для улучшения работы сайта",
        line3: "Принять и закрыть"
    },
    en: {
        line1: "We use",
        line2: "to improve the site",
        line3: "Accept and close"
    },
    es: {
        line1: "Usamos",
        line2: "para mejorar el sitio",
        line3: "Aceptar y cerrar"
    },
    fr: {
        line1: "Nous utilisons des",
        line2: "pour améliorer le site",
        line3: "Accepter et fermer"
    }
};

const CookiesPopup = ({ handleCookie }) => {
    const [popupStep, setPopupStep] = useState(433);
    const { currentLang } = useLanguage();
    useEffect(() => {
        const width = document.documentElement.clientWidth;
        if (width < 1800) {
            setPopupStep(width / 2 - 458);
        }
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <div
                className={!device.mobile() ? `popup` : `popupMobile`}
                style={
                    !device.mobile()
                        ? { left: `calc(50% + ${popupStep}px)` }
                        : { left: "9px" }
                }
            >
                <div
                    className={
                        !device.mobile() ? `popup-back` : ` popup-back fs-14`
                    }
                >
                    <p>
                        {currentLang && languagesText[currentLang].line1}{" "}
                        <Link
                            to="/cookiesPage"
                            style={{ textDecoration: "underline" }}
                        >
                            cookies
                        </Link>{" "}
                        {currentLang && languagesText[currentLang].line2}
                    </p>
                    <div
                        className={
                            !device.mobile()
                                ? `popup-link cp mt-30`
                                : `popupMobile-link cp mt-20`
                        }
                        onClick={() => handleCookie()}
                    >
                        <span className={!device.mobile() ? `` : `fs-16`}>
                            {currentLang && languagesText[currentLang].line3}
                        </span>
                        <div className="popup-link-arrow imgBack"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CookiesPopup;
