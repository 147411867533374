import React from "react";
import { useLanguage } from "../../../hooks/useLang";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"

const AboutUsPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.aboutUs.pageName;
    const subMenuList = content.aboutUs.subMenuList;
    const imageUrls = content.aboutUs.imageUrls;
    const blocks = content.aboutUs.blocks;
    return (
        <div className="mainPage">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet 
            blockContent={blocks.main} 
            customWidthNameAboutUs={true}/>
            <HorizontalBlockAdaptiveTablet blockContent={blocks.education} />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.expert}
             customWidthNameAboutUsExpert={true} />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.unnamed} />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.mission} />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.direction} />
            <ContactUsTablet/>
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default AboutUsPageTablet;
