import React from "react";
import CardsWithArrowsAdaptiveTablet from "../cardsBlocks/cardsWithArrowsAdaptiveTablet";
import { useLanguage } from "../../../hooks/useLang";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";

import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"

const AudiencePageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.audience.pageName;
    const subMenuList = content.audience.subMenuList;
    const imageUrls = content.audience.imageUrls;
    const blocks = content.audience.blocks;
    const cards = content.audience.cards;

    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.helper} />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.tools} />
            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block1}
                arrowsDirection="left"
                 topBorderBlock={true}// topBottom
                  bottomBorderCard = {false}
                 customHeight={true}
                 customWidth={true}
    topBorderCard = {true}
    marginTopNum = {true}
    marginTopName = {true}
    marginTopText = {true}
    marginTopImg = {true}
    marginTopLink = {true}
            />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.possibilities} />
            <ContactUsTablet/>
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default AudiencePageTablet;
