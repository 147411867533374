import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import AgreementsBlockMobile from "../pageBlocksMobile/agreementsBlockMobile";

const PublicOfertaPageMobile = () => {
    const { content, agreements } = useLanguage();
    const pageName = content.publicOferta.pageName;
    const imageUrls = content.publicOferta.imageUrls;
    const blocks = content.publicOferta.blocks;
    const publicOfertaAgreement = agreements.publicOferta;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                bottomBorder={true}
                customNameLine={window.innerWidth < 360 ? "120" : "90"}
                customNameWidth="85"
                customHeight={window.innerWidth < 360 ? 260 : 230}
                dropdownDisable={true}
                rightArrow={true}
            />
            <AgreementsBlockMobile blockContent={publicOfertaAgreement} />
        </div>
    );
};

export default PublicOfertaPageMobile;
