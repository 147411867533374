import { Link } from "react-router-dom";
const content = {
    mainPage: {
        pageName: "Find the most important",
        subMenuList: [
            { num: 1, label: "For business", anchor: "MainPageForBusiness" },
            { num: 2, label: "Security", anchor: "MainPageSafety" },
            { num: 3, label: "Platform", anchor: "MainPagePlatform" },
            { num: 4, label: "About us", anchor: "MainPageAboutUs" },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/mainPage/img1.jpg",
            img1Mobile: "./img/mainPage/img1Mobile.jpg",
            img1Tablet: "./img/mainPage/img1Tablet.jpg", // добавил главную картинку
            img2: "./img/mainPage/img2.jpg",
            img2Mobile: "./img/mainPage/img2Mobile.jpg",
            img2Tablet: "./img/mainPage/img2Tablet.jpg",
            img3: "./img/mainPage/img3.jpg",
            img3Mobile: "./img/mainPage/img3Mobile.jpg",
            img3Tablet: "./img/mainPage/img3Tablet.jpg"
        },
        blocks: {
            forBusiness: {
                num: 1,
                name: "For business",
                textBlock1:
                    "Since 2016, our digital situational center has been conducting a comprehensive analysis of the information space: we control the media agenda, social networks, messengers, as well as information in the darkweb. We ensure effective interaction with target audiences, protect business from information attacks, provide early warning and elimination of various risks and threats in real time.",
                textBlock2:
                    "Outsourcing of economic security. Reputation audit and early detection of threats to sustainable development. Monitoring of competitors and the external environment, as well as personalized media analytics.",
                linkName1: "More",
                link1: "/forBusinessPage",
                anchor: "MainPageForBusiness"
            },
            safety: {
                num: 2,
                name: "Security",
                textBlock1:
                    "Information security for business is one of the key aspects of successful work, as it protects confidential data and intellectual property. Today, the integration of modern solutions in the field of information security is an indispensable element of a sustainable business development strategy, as it helps to prevent economic losses caused by cyber attacks and data leaks.",
                textBlock2:
                    "Companies and regulators around the world are imposing increasingly stringent data protection requirements, and ignoring such measures can lead to serious legal and financial consequences.",
                linkName1: "More",
                link1: "/safetyPage",
                anchor: "MainPageSafety"
            },
            platform: {
                num: 3,
                name: "Platform",
                textBlock1:
                    "Products based on the Avalanche technology platform provide the Customer with the capabilities of a modern situational center in terms of collecting, processing, accumulating and analyzing large amounts of data from open sources.",
                textBlock2:
                    "Using Avalanche products significantly increases management's awareness of important events through the introduction of new high-tech solutions that provide continuous automated analysis of a flow of data from open sources.",
                linkName1: "More",
                link1: "/platformPage",
                anchor: "MainPagePlatform"
            },
            situationCenter: {
                name: "Situation center",
                textBlock1:
                    "Outsourcing of the situation center. Ensuring round-the-clock monitoring of the information field around the object of interest and identifying emerging risks and threats without creating your own situation center, by leasing our specialists and technical solutions.",
                textBlock2:
                    "Software tools automatically select the most important information and, using pre-processing and logical-syntactic text analysis, present it in the most convenient form, as well as generate statistical and analytical reports on the most important events.",
                linkName: "More",
                link: "/situationCenterPage"
            },
            analysis: {
                name: "Audience Analysis",
                textBlock1:
                    "Avalanche helps to quickly collect and process large amounts of data from social media. It is an indispensable tool for professionals of various profiles - from PR and marketing to corporate intelligence. Target audience analysis, subscriber growth, their activity and much more.",
                textBlock2:
                    "The analysis of open data in social media is carried out, without violating the privacy of users. It allows to detect information waves and fake account attacks. Efficiency of obtaining data, transparency and objectivity of information is ensured.",
                linkName: "More",
                link: "/audiencePage"
            },
            vulnerabilitiesScan: {
                name: "Vulnerability Scan",
                textBlock1:
                    "Modern attackers discover and exploit more and more vulnerabilities on web resources that contain sensitive data. Software implementation errors, untimely software updates, outdated passwords, and much more will lead to serious vulnerabilities in Internet projects.",
                textBlock2:
                    "A web scanner is a set of solutions aimed at providing security control functions for an information system built on the basis of web technologies. A web scanner allows you to implement functions of detecting, preventing and responding to unauthorized activity attempts by remote and internal users of computer networks.",
                linkName: "More",
                link: "/scannerPage"
            },
            analytics: {
                name: "Analytics",
                textBlock1:
                    "Control of the information agenda and options for solving emerging problems, responding to risks and threats, countering information attacks. Evaluating the efficiency of strategic communications, advertising campaigns and other public interactions with the target audience and repositioning on vulnerable issues.",
                textBlock2:
                    "Desk research for marketing, PR and economic security. Analytics works like a help desk and helps to find answers to the most difficult questions.",
                linkName: "More",
                link: "/analyticsPage"
            },
            unnamedBlock: {
                textBlock:
                    "Import substitution of foreign manufacturers and the development of Russian competitiveness in order to parry sanctions risks in the field of high technologies"
            },
            aboutUs: {
                num: 4,
                name: "About us",
                textBlock1:
                    "For the first time, a product called Avalanche appeared on the Russian market in 2003. Its creator is a pioneer in the competitive intelligence market, retired FAPSI Lieutenant Colonel Andrey Masalovich. For almost 20 years, the system has been used by law enforcement agencies, federal and regional authorities to control public sentiment and combat terrorist threats. ",
                textBlock2:
                    "Today, analytical systems built on the basis of the Avalanche technology platform are actively used by commercial companies to monitor Russian and foreign markets, partners and competitors.",
                linkName1: "More",
                link1: "/aboutUsPage",
                anchor: "MainPageAboutUs"
            },
            shortBlock: {
                name: "Synergy of Big Data Analysis Technologies"
            },
            demonstration: {
                num: "",
                name: "Demo",
                textBlock2: `To demonstrate the work of Avalanche, we pre-configured several projects:\n\r\u2022  political leaders in foreign media \n\r\u2022  ministries and departments in Russian media \r\n\u2022  COVID-19 in Russian regions \r\nFor more information, please contact us.`
            },
            sixCardsBlock: [
                {
                    num: 1,
                    name: "Political leaders",
                    text: "Mentions of key world political leaders in foreign media.",
                    linkName: "Go to page",
                    externalLink: "https://leaders.avl.team/"
                },
                {
                    num: 2,
                    name: "Ministries",
                    text: "Agenda of the Russian ministries and departments in the domestic media.",
                    linkName: "Go to page",
                    externalLink: "https://minister.avl.team/"
                },
                {
                    num: 3,
                    name: "Covid-19",
                    text: "Mentions of the coronavirus infection in the regional media of Russia, divided into federal districts.",
                    linkName: "Go to page",
                    externalLink: "https://covid19.avl.team/"
                },
                {
                    num: 4,
                    name: "Search for air tickets",
                    text: "Collecting information about cheap flights and promotions of various global airlines.",
                    linkName: "Go to page",
                    externalLink: "https://lowcost.avl.team/"
                },
                {
                    num: 5,
                    name: "Mobilization news",
                    text: "Most important information about the mobilization campaign and volunteer missions.",
                    linkName: "Go to page",
                    externalLink: "https://vstavai.avl.team/"
                },
                {
                    num: 6,
                    name: "OSINT&Cybersec",
                    text: "News from the world of intelligence and cybersecurity, monitoring leaks and finding vulnerabilities.",
                    linkName: "Go to page",
                    externalLink: "https://osint.avl.team/"
                }
            ],
            cardsWithImg: [
                {
                    num: 1,
                    text: "People. Success is achieved through the hard work of our professional team. Avalanche is a complex system operated by qualified technical staff consisting of operators, analysts and system administrators.",
                    imgLink: "./img/mainPage/small1.jpg"
                },
                {
                    num: 2,
                    text: "Processes. Formation and analysis of a map of risks and threats to reputation. Determination of scenarios for possible information attacks. Development of audience control mechanisms in the information field and optimization of working with the media.",
                    imgLink: "./img/mainPage/small2.jpg"
                },
                {
                    num: 3,
                    text: "Technologies. An extended set of modern algorithms for analytical processing of big data, including express analysis, risk assessment, forecasting, and more. ",
                    imgLink: "./img/mainPage/small3.jpg"
                }
            ],
            cardsBlock: {
                cards: [
                    {
                        num: 1,
                        text: "People. Success is achieved through the hard work of our professional team. ",
                        imgLink: "./img/mainPage/small1Mobile.jpg"
                    },
                    {
                        num: 2,
                        text: "Processes. Formation and analysis of a map of risks and threats to reputation. ",
                        imgLink: "./img/mainPage/small2Mobile.jpg"
                    },
                    {
                        num: 3,
                        text: "Technologies. Expanded set of modern algorithms for big data processing. ",
                        imgLink: "./img/mainPage/small3Mobile.jpg"
                    }
                ]
            }
        }
    },
    platform: {
        pageName: "Platform",
        subMenuList: [
            { num: 1, label: "Platform", anchor: "PlatformPagePlatform" },
            { num: 2, label: "Our products", anchor: "PlatformPageProducts" },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/platformPage/img1.jpg",
            img1Mobile: "./img/platformPage/img1Mobile.jpg"
        },
        blocks: {
            imageBlock: {
                name: "",
                textBlock1: "",
                textBlock2:
                    "Avalanche is an Internet intelligence technology and a new generation of automated Internet monitoring systems built on its basis, combining advanced capabilities for collecting and analytical processing of information, as well as operational planning of events.",
                linkName1: "",
                link1: "",
                anchor: "PlatformPagePlatform"
            },
            ourProducts: {
                num: 2,
                name: "Our products",
                anchor: "PlatformPageProducts"
            },
            situationCenter: {
                name: "Situation centre",
                textBlock1:
                    "Outsourcing of the situation center. Ensuring round-the-clock monitoring of the information field around the object of interest and identifying emerging risks and threats without creating your own situation center, by leasing our specialists and technical solutions.",
                textBlock2:
                    "Software tools automatically select the most important information and, using pre-processing and logical-syntactic text analysis, present it in the most convenient form, as well as generate statistical and analytical reports on the most important events.",
                linkName: "More",
                link: "/situationCenterPage"
            },
            analysis: {
                name: "Audience Analysis",
                textBlock1:
                    "Avalanche helps to quickly collect and process large amounts of data from social media. It is an indispensable tool for professionals of various profiles - from PR and marketing to corporate intelligence. Target audience analysis, subscriber growth, their activity and much more.",
                textBlock2:
                    "The analysis of open data in social media is carried out, without violating the privacy of users. It allows to detect information waves and fake account attacks. Efficiency of obtaining data, transparency and objectivity of information is ensured.",
                linkName: "More",
                link: "/audiencePage"
            },
            vulnerabilitiesScan: {
                name: "Vulnerability Scan",
                textBlock1:
                    "Modern attackers discover and exploit more and more vulnerabilities on web resources that contain sensitive data. Software implementation errors, untimely software updates, outdated passwords, and much more will lead to serious vulnerabilities in Internet projects.",
                textBlock2:
                    "A web scanner is a set of solutions aimed at providing security control functions for an information system built on the basis of web technologies. A web scanner allows you to implement functions of detecting, preventing and responding to unauthorized activity attempts by remote and internal users of computer networks.",
                linkName: "More",
                link: "/scannerPage"
            },
            analytics: {
                name: "Analytics",
                textBlock1:
                    "Control of the information agenda and options for solving emerging problems, responding to risks and threats, countering information attacks. Evaluating the efficiency of strategic communications, advertising campaigns and other public interactions with the target audience and repositioning on vulnerable issues.",
                textBlock2:
                    "Desk research for marketing, PR and economic security. Analytics works like a help desk and helps to find answers to the most difficult questions.",
                linkName: "More",
                link: "/analyticsPage"
            }
        },
        sixCardsBlock: [
            {
                num: 1,
                name: "Target setting",
                text: "Fine tuning of information resources, with the ability to clean meaningful information from irrelevant content, banners, etc. Collecting the most important, cutting off the irrelevant information."
            },
            {
                num: 2,
                name: "Online 24/7",
                text: "Monitoring and notification around the clock. Even at night. Even on holidays. Always on guard of your interests. If a threat is detected, you will receive a notificatinon in Telegram."
            },
            {
                num: 3,
                name: "Integration",
                text: "Flexible mechanisms for integrating and exchanging data with third-party information systems. Avalanche can act both as a data source and as an analysis tool. "
            },
            {
                num: 4,
                name: "Analysis and visualization",
                text: "Analysis of duplicates and emotional tones. Visualization of connections and information distribution schemes. Any data is suitable for visualization."
            },
            {
                num: 5,
                name: "360 degree overview",
                text: "The event feed is not just traditional media and telegram channels, but also corporate events, courts, tenders, government websites and much more. You get a comprehensive view of the events."
            },
            {
                num: 6,
                name: "Hightlights the main things",
                text: "Major newsbreaks and articles in major media and telegram channels are highlighted in green, incriminating evidence and negative publications - in yellow. Critical news - in red. The 'traffic light' system works on the basis of statistical methods and trained neural networks."
            }
        ],
        cardsBlock: {
            cards: [
                {
                    num: 1,
                    name: "Target setting",
                    text: "Fine tuning of information resources, with the ability to clean meaningful information from irrelevant content, banners, etc. Collecting the most important, cutting off the irrelevant information."
                },
                {
                    num: 2,
                    name: "Online 24/7",
                    text: "Monitoring and notification around the clock. Even at night. Even on holidays. Always on guard of your interests. If a threat is detected, you will receive a notificatinon in Telegram."
                },
                {
                    num: 3,
                    name: "Integration",
                    text: "Flexible mechanisms for integrating and exchanging data with third-party information systems. Avalanche can act both as a data source and as an analysis tool. "
                },
                {
                    num: 4,
                    name: "Analysis and visualization",
                    text: "Analysis of duplicates and emotional tones. Visualization of connections and information distribution schemes. Any data is suitable for visualization."
                },
                {
                    num: 5,
                    name: "360 degree overview",
                    text: "The event feed is not just traditional media and telegram channels, but also corporate events, courts, tenders, government websites and much more. You get a comprehensive view of the events."
                },
                {
                    num: 6,
                    name: "Hightlights the main things",
                    text: "Major newsbreaks and articles in major media and telegram channels are highlighted in green, incriminating evidence and negative publications - in yellow. Critical news - in red. The 'traffic light' system works on the basis of statistical methods and trained neural networks."
                }
            ]
        }
    },
    forBusiness: {
        pageName: "For business",
        subMenuList: [
            {
                num: 1,
                label: "Competitive intelligence for your business",
                anchor: "ForBusinessMain"
            },
            {
                num: 2,
                label: "Virtual situation center",
                anchor: "ForBusinessCenter"
            },
            {
                num: 3,
                label: "Licenses and Implementation",
                anchor: "ForBusinessLicence"
            },
            {
                num: 4,
                label: "Services and Analytics",
                anchor: "ForBusinessService"
            },
            {
                num: 5,
                label: "Communications",
                anchor: "ForBusinessCommunications"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/forBusinessPage/img1.jpg",
            img1Mobile: "./img/forBusinessPage/img1Mobile.jpg",
            img2: "./img/forBusinessPage/img2.jpg",
            img2Mobile: "./img/forBusinessPage/img2Mobile.jpg",
            img3: "./img/forBusinessPage/img3.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Competitive intelligence for your business",
                textBlock2:
                    "Projects in Avalanche give you complete control over your information space. With its help, you can monitor the market situation, competitor activity, changes in government policy and regulation. Each project is unique and customizable according to the subjects of the industry. ",

                anchor: "ForBusinessMain"
            },
            center: {
                num: 2,
                name: "Virtual situation center",
                textBlock1: "",
                textBlock2:
                    "The Internet space monitoring service provides the Customer with the capabilities of a modern situational center in terms of monitoring the operational situation and early detection of Internet threats. There are 3 tariff plans for commercial Customers.",
                linkName1: "Tariff plans",
                link1: "/tariffsPage",
                anchor: "ForBusinessCenter"
            },
            communications: {
                num: 5,
                name: "Communications",
                textBlock1: "",
                textBlock2:
                    "Business reputation and customer trust are gained over the years, but can be destroyed instantly by an information attack by competitors. Any public action of a company can become a target for information aggression, which has become part of the harsh realities of Russian business.",
                linkName1: "More",
                link1: "/communicationsPage",
                anchor: "ForBusinessCommunications"
            },
            service: {
                num: 4,
                name: "Services and Analytics",
                textBlock1: "",
                textBlock2:
                    "Avalanche works most effectively in conjunction with our consulting services. We will not only customize the project for your information needs, but also prepare regular analytics, calculate possible risks and highlight growth points.",
                linkName1: "More",
                link1: "/analyticsPage",
                anchor: "ForBusinessService"
            },
            licence: {
                num: 3,
                name: "Licenses and Implementation",
                textBlock1: "",
                textBlock2:
                    "Implementation of the Avalanche software package on the Customer's equipment, including initial configuration and user training. Managing the monitoring in the company, without limiting the number of users and the number of objects to be monitored.",
                linkName1: "Tariff plans",
                link1: "/tariffsPage",
                anchor: "ForBusinessLicence"
            }
        }
    },
    situationCenter: {
        pageName: "Situation center",
        subMenuList: [
            {
                num: 1,
                label: "Situation center",
                anchor: "SituationCenterMain"
            },
            {
                num: 2,
                label: "About situation centers",
                anchor: "SituationCenterAbout"
            },
            {
                num: 3,
                label: "Benefits of situation centers",
                anchor: "SituationCenterBenifits"
            },
            {
                num: 4,
                label: "Outsourcing of situation center",
                anchor: "SituationCenterOutsourse"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/situationCenter/img1.jpg",
            img1Mobile: "./img/situationCenter/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Situation center",
                textBlock2:
                    "Since 2016, our situation center has been conducting comprehensive monitoring of the information space: we control the agenda of the media, social networks, messengers, information in the darkweb.The company's situation center employs more than 50 operators and analysts who provide early detection and prevention of risks and threats in the interests of federal government agencies, state corporations, large commercial companies, and the banking sector.",

                anchor: "SituationCenterMain"
            },
            about: {
                num: 2,
                name: "About situation centers",
                textBlock2:
                    "The situation center is a structure created for operational monitoring of the information environment and consolidation of information for making competent management decisions. Usually a situation center consists of three components: monitoring groups, analyst groups and rapid response groups.",

                anchor: "SituationCenterAbout"
            },
            benifits: {
                num: 3,
                name: "Benefits of situation centers",
                textBlock2:
                    "In the event of an emergency or an information attack, the reputation of the entire company may depend on the promptness of decision-making. The key task of the situation center is to warn in advance about the impending danger, to give all the necessary information about the situation, as well as to provide information resources for an adequate response.",

                anchor: "SituationCenterBenifits"
            },
            outsourse: {
                num: 4,
                name: "Outsourcing of situation center",
                textBlock2:
                    "Creating and maintaining a full–fledged situation center is a very expensive business, even for the largest companies. The cost of the situation center equipment starts from 10 million rubles, and the cost of maintenance starts from 1 million rubles per month. We own our own situation center with a total staff of more than 50 people and offer a new and unique service for the Russian market – outsourcing of the situation center.",

                anchor: "SituationCenterOutsourse"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Monitoring group",
                        text: "Controls the information space, searches for risks and threats to the company, carries out continuous monitoring of the environment and competitors."
                    },
                    {
                        num: 2,
                        name: "Analyst group",
                        text: "Conducts an assessment of identified risks and threats, forms an understanding of the situation and gives a response strategy."
                    },
                    {
                        num: 3,
                        name: "Rapid response team",
                        text: "Provides neutralization of risks and threats that have appeared in the media, social networks or messengers."
                    },
                    {
                        num: 4,
                        name: "Coordination of efforts",
                        text: "The joint efforts of the group ensure the comprehensive security of all segments of the information background."
                    }
                ]
            },
            block2: {
                cards: [
                    {
                        num: 1,
                        name: "Constant agenda control",
                        text: "Unlike the full-time PR department, the company's situation center will provide constant and round-the-clock monitoring of the information space, even at night and on weekends."
                    },
                    {
                        num: 2,
                        name: "Early warning",
                        text: "Situation center operators receive information first, as soon as it has entered the public space and has not yet been distributed. Therefore, our clients always have enough time for a preventive response to a threat. Notification of identified threats is carried out in real time."
                    },
                    {
                        num: 3,
                        name: "Developing a counteraction",
                        text: "Risk assessment, collection and analysis of data for making management decisions and developing strategies to counter threats."
                    },
                    {
                        num: 4,
                        name: "Response",
                        text: "The joint efforts of the group ensure the comprehensive security of all segments of the information background."
                    }
                ]
            },
            block3: {
                cards: [
                    {
                        num: 1,
                        name: "Optimal cost",
                        text: "The company will receive a full set of the situation center services without the creation and maintenance expenses."
                    },
                    {
                        num: 2,
                        name: "Scalability",
                        text: "The situation center can work for both small companies and large businesses conducting international activities."
                    },
                    {
                        num: 3,
                        name: "Flexibility",
                        text: "The situation center staff are immersed in the processes and problems of the industry and can work in various fields."
                    },
                    {
                        num: 4,
                        name: "Rapid deployment",
                        text: "The situation center can be deployed and configured for a new topic within a week. Gives flexibility and adaptability in decision-making."
                    }
                ]
            }
        }
    },
    communications: {
        pageName: "Communications",
        subMenuList: [
            {
                num: 1,
                label: "Communications",
                anchor: "CommunicationsMain"
            },
            {
                num: 2,
                label: "Protection from information aggression",
                anchor: "CommunicationsDefence"
            },
            {
                num: 3,
                label: "Reputation management",
                anchor: "CommunicationsReputation"
            },
            {
                num: 4,
                label: "Forming the information background",
                anchor: "CommunicationsBackground"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/communicationsPage/img1.jpg",
            img1Mobile: "./img/communicationsPage/img1Mobile.jpg",
            img2Mobile: "./img/communicationsPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Communications",
                textBlock2:
                    "Business reputation and customer trust are gained over the years, but can be destroyed instantly by an information attack by competitors. Any public action of a company can become a target for information aggression, which has become part of the harsh realities of Russian business.",

                anchor: "CommunicationsMain"
            },
            defence: {
                num: 2,
                name: "Protection from information aggression",
                textBlock1:
                    "Companies compete for the consumer not only in the market, but also in the information space. Any change in the market environment or a company's public action can lead to the start of a destructive information attack by competitors.",
                textBlock2:
                    "We provide protection and prevention of information aggression and not only monitor the information space around the clock, but also help prevent reputational losses. For each client, we develop a unique risk and threat map to understand possible targets and sources of attack, as well as help to give an adequate response to information aggression in the media, social networks and messengers.",

                anchor: "CommunicationsDefence"
            },
            reputation: {
                num: 3,
                name: "Reputation management",
                textBlock2:
                    "Assistance in restoring business reputation in the media, removing or blocking illegal information, as well as providing assistance in protecting interests.",

                anchor: "CommunicationsReputation"
            },
            background: {
                num: 4,
                name: "Forming the information background",
                textBlock2:
                    "Prevention is easier and cheaper than fixing. Forming a positive information background in relation to the company will dramatically reduce the costs associated with information warfare. Promoting a positive information agenda is an important condition for creating a favorable information environment.",

                anchor: "CommunicationsBackground"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Time",
                        text: "The main resource during a crisis is time. PR departments and contractors most often miss the beginning of an information attack and waste time."
                    },
                    {
                        num: 2,
                        name: "Outsourcing",
                        text: "Our company is the first in Russia to provide situation center outsourcing services. This means that the information background of the company will be under constant control around the clock, seven days a week. Monitoring is carried out by qualified analysts who identify risks and threats to the business even before they enter the implementation phase."
                    },
                    {
                        num: 3,
                        name: "Reaction",
                        text: "We provide not only an early detection of information attacks, but also a strategy for solving problems and an adequate response to emerging risks and threats in the media."
                    },
                    {
                        num: 4,
                        name: "Uniqueness",
                        text: "There is no place for templates in countering the information aggression. We develop a unique methodology for protection against information threats for each case."
                    }
                ]
            },
            block2: {
                imageUrl: "../img/communicationsPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Reputation audit",
                        text: "The main product of a reputation audit is a risk and threat map that shows all the weaknesses in the company's communication strategy and positioning. The map analyzes both current and potential risks that may arise due to changes within the company or in the external environment."
                    },
                    {
                        num: 2,
                        name: "Counteraction",
                        text: "Based on the map of risks and threats, we develop a system for responding to information aggression. Materials for placement are prepared in advance, response scenarios are formed, a communication response strategy is developed and appropriate materials are created, which are then posted in the media, social networks and messengers."
                    }
                ]
            },
            block3: {
                cards: [
                    {
                        num: 1,
                        name: "Strategy and positioning",
                        text: "Developing an effective positioning strategy in the information space, which will provide the most advantageous positions for protection against information risks and threats."
                    },
                    {
                        num: 2,
                        name: "Making meaning",
                        text: "Working with the target audience. Creating meanings and associations to form the desired public opinion."
                    },
                    {
                        num: 3,
                        name: "Proactive defense",
                        text: "Predicting potential risks. Preparing effective communication positions that will mitigate the first and most painful blow."
                    },
                    {
                        num: 4,
                        name: "Agenda management",
                        text: "We help companies become centers of expertise, sources of agenda and opinions, instead of just going with the flow of the current discourse. Our speakers become carriers of unique knowledge and the center of attraction for the target audience."
                    }
                ]
            }
        }
    },
    analytics: {
        pageName: "Analytics",
        subMenuList: [
            {
                num: 1,
                label: "Analytics",
                anchor: "AnalyticsMain"
            },
            {
                num: 2,
                label: "Open source intelligence",
                anchor: "AnalyticsScouting"
            },
            {
                num: 3,
                label: "Research",
                anchor: "AnalyticsResearch"
            },
            { label: "Send a request", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/analyticsPage/img1.jpg",
            img1Mobile: "./img/analyticsPage/img1Mobile.jpg",
            img2Mobile: "./img/analyticsPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Analytics",
                textBlock1:
                    "The reason for the majority of business financial losses is poor decisions made due to lack of information and uncertainty. Our company provides intelligence and analytical support to businesses. These services will be especially useful in the complex and ambiguous realities of modern international business.",
                textBlock2:
                    "Our mission is to provide enough information to make a management decision of any level of complexity, even in the most complex and ambiguous conditions. Our analytics and research department is organized on the principle of a 'think tank', which allows us to effectively solve any client's information problems.",
                anchor: "AnalyticsMain"
            },
            scouting: {
                num: 2,
                name: "Open source intelligence",
                textBlock1:
                    "Open source intelligence, or OSINT, provides business with information even in conditions of closedness and lack of data sources. OSINT works on the principle of a case study: based on information from open sources, a complete and reliable picture of events is formed for any question, even the most complex one.",
                textBlock2:
                    "Our analysts possess a universal technology for working with information, which allows us to collect data and clarify the situation in the most difficult cases. An analysis of the information field is carried out to understand the specific situation and form a reliable forecast of its development.",

                anchor: "AnalyticsScouting"
            },
            research: {
                num: 3,
                name: "Research",
                textBlock2:
                    "Our team conducts not only desk research, but also field research. We conduct quantitative and qualitative research in any region of the country, both in the field of economics and marketing, and in the socio-political plane.",

                anchor: "AnalyticsResearch"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Sources",
                        text: "During more than 15 years of work, we have accumulated a large number of information sources: we are subscribed to all the largest databases and information sources."
                    },
                    {
                        num: 2,
                        name: "Unique skills",
                        text: "Our analysts undergo special training according to Andrei Masalovich’s programs, so we can obtain unique information from open sources."
                    },
                    {
                        num: 3,
                        name: "Expertise",
                        text: "We collaborate with more than two hundred experts in various fields of knowledge, from public relations, law and economics to robotics and space aviation. Our customers can be sure that the information they receive is confirmed by experts of the highest level."
                    },
                    {
                        num: 4,
                        name: "Team",
                        text: "We have assembled a team of unique political technologists, PR specialists, journalists and analysts. Each of them has not only gone through their own path of professional development, but also received special knowledge within the company. The synthesis of experience and unique skills allows us to take on tasks of any complexity."
                    }
                ]
            },
            block2: {
                imageUrl: "../img/analyticsPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Qualitative research",
                        text: "We specialize in conducting focus group research and conducting expert interviews. This allows our clients to form an effective communication and marketing policy, as well as adjust it in a timely manner."
                    },
                    {
                        num: 2,
                        name: "Quantitative research",
                        text: "Based on the map of risks and threats, a system for responding to information aggression is developed. Response scenarios are formed in advance, a communication response strategy is developed."
                    }
                ]
            }
        }
    },
    safety: {
        pageName: "Security",
        subMenuList: [
            {
                num: 1,
                label: "Security",
                anchor: "SafetyMain"
            },
            {
                num: 2,
                label: "Economic security",
                anchor: "SafetyEconomic"
            },
            {
                num: 3,
                label: "Legal support",
                anchor: "SafetySupport"
            },
            {
                num: 4,
                label: "Cybersecurity",
                anchor: "SafetyCyber"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/safetyPage/img1.jpg",
            img1Mobile: "./img/safetyPage/img1Mobile.jpg",
            img2Mobile: "./img/safetyPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Security",
                textBlock1:
                    "The reason for the majority of business financial losses is poor decisions made due to lack of information and uncertainty. Our company provides intelligence and analytical support to businesses. These services will be especially useful in the complex and ambiguous realities of modern international business.",
                textBlock2:
                    "Our mission is to provide enough information to make a management decision of any level of complexity, even in the most complex and ambiguous conditions. Our analytics and research department is organized on the principle of a 'think tank', which allows us to effectively solve any client's information problems.",
                anchor: "SafetyMain"
            },
            economic: {
                num: 2,
                name: "Economic security",
                textBlock2:
                    "In compliance with the laws of the Russian Federation, our company ensures the economic security of the company through comprehensive due diligence in relation to transactions, partners, employees and candidates of the company.",
                anchor: "SafetyEconomic"
            },
            cyber: {
                num: 4,
                name: "Cybersecurity",
                textBlock2:
                    "For more than 15 years, we have been developing and implementing security solutions for federal government agencies, state corporations, large commercial companies, and the banking sector.",

                anchor: "SafetyCyber"
            },
            support: {
                num: 3,
                name: "Legal support",
                textBlock2:
                    "Reliable legal support on a wide range of economic issues, from corporate conflicts to bankruptcy and arbitration management. This allows us not only to provide clients with information for making competent management decisions, but also to protect their interests in disputes and court cases at all stages.",
                anchor: "SafetySupport"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Veryfying counterparties",
                        text: "An exhaustive amount of information regarding the company's likely counterparties to make the best decision. Work is carried out on both Russian and foreign counterparties. Including information from social networks and numerous other sources of information."
                    },
                    {
                        num: 2,
                        name: "Veryfying applicants",
                        text: "The reputation of potential employers is checked, threats and risks that may emanate from a potential employee are determined."
                    },
                    {
                        num: 3,
                        name: "Proactive defense",
                        text: "Among the company's analysts are former employees of law enforcement and special structures who have extensive experience in investigating economic crimes and other illegal actions."
                    },
                    {
                        num: 4,
                        name: "Research",
                        text: "Solving almost any issue: from searching for assets abroad, to analyzing the activities of offshore companies. We know where to find information about a Chinese company, about the latest activity of a businessman from Latin America, or assess the risks of a company entering the Southeast Asian market."
                    }
                ]
            },
            block2: {
                cards: [
                    {
                        num: 1,
                        name: "Vulnerability search",
                        text: "Unique research using our own software that reveals the vulnerabilities of information resources, sites, applications. Not only finding problems, but also developing solutions to eliminate them."
                    },
                    {
                        num: 2,
                        name: "Software development",
                        text: "Development of special software solutions to ensure infrastructure security."
                    },
                    {
                        num: 3,
                        name: "Development of analytical services",
                        text: "Development of tools for deep analysis of social networks: posts, dynamics and activity of the audience. Analysis of website traffic and search traffic."
                    },
                    {
                        num: 4,
                        name: "Data precessing",
                        text: "Collecting and processing large amounts of data (Big Data), as well as developing our own solutions to optimize working with large amounts of information."
                    }
                ]
            },
            block3: {
                imageUrl: "../img/safetyPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Protection of business reputation",
                        text: "Masalovich & Partners lawyers will help restore business reputation in the event of slander in the media, remove or block illegal information, and assist in protecting the client's interests in the event of an information attack."
                    },
                    {
                        num: 2,
                        name: "Corporate disputes",
                        text: "We will not only provide the most up-to-date information on the situation related to a corporate dispute, but we will also be able to provide legal advice and, if necessary, represent the interests of the customer in the courts of the Russian Federation."
                    }
                ]
            }
        }
    },
    learning: {
        pageName: "Courses",
        subMenuList: [
            {
                num: 1,
                label: "Competitive intelligence",
                anchor: "LearningService"
            },
            {
                num: 2,
                label: "Corporate trainings",
                anchor: "LearningCorporate"
            },
            { label: "Send a request", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/learningPage/img1.jpg",
            img1Mobile: "./img/learningPage/img1Mobile.jpg"
        },
        blocks: {
            service: {
                num: 1,
                name: "Competitive intelligence",
                textBlock1:
                    "In today's world, information is a key resource for the successful development of any company. Open Source Intelligence (OSINT) plays a critical role in collecting and analyzing a variety of data. With the availability of open information, open source intelligence facilitates the process of data analysis and forecasting development of any situation.",
                textBlock2:
                    "We'll show you how OSINT can help your business make the most of information, improve strategic decision-making and preserve privacy, and detect threats and risks associated with competitors' activities. Our methodologies will allow you to quickly identify threats and opportunities for your company, strengthen your position in the market and reach new heights. ",
                linkName1: "",
                linkName2: "",
                anchor: "LearningService"
            },
            corporate: {
                num: 2,
                name: "Corporate trainings",
                textBlock1:
                    "The quality development of employees is one of the key success factors for any enterprise. That is why corporate trainings are an integral part of an effective business strategy. ",
                textBlock2:
                    "Our trainings will help you gain practical knowledge in the field of OSINT and provide leadership in the information environment for continuous business development.",
                linkName1: "",
                linkName2: "",
                anchor: "LearningCorporate"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Base level",
                        text: "The basics of Open Source Intelligence (OSINT) and web search."
                    },
                    {
                        num: 2,
                        name: "Middle level",
                        text: "Effective OSINT tools for browsing the Internet and social networks, ensuring your own security."
                    },
                    {
                        num: 3,
                        name: "Advanced level",
                        text: "IoT search, WireShark, Honeypot discovery, and other advanced OSINT tools."
                    }
                ]
            }
        }
    },
    audience: {
        pageName: "Audience Analysis",
        subMenuList: [
            {
                num: 1,
                label: "Collecting statistics",
                anchor: "AudienceStatistic"
            },
            {
                num: 2,
                label: "Tools",
                anchor: "AudienceTools"
            },
            {
                num: 3,
                label: "Capabilities",
                anchor: "AudiencePossible"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/audiencePage/img1.jpg",
            img1Mobile: "./img/audiencePage/img1Mobile.jpg"
        },
        blocks: {
            helper: {
                num: 1,
                name: "Collecting statistics",
                textBlock1:
                    "Avalanche helps to quickly collect and process large amounts of data from social media. An indispensable tool for professionals of various profiles - from PR and marketing to corporate intelligence. Target audience analysis, subscriber growth, their activity and much more..",
                textBlock2:
                    "Analysis of open data in social media is carried out. It allows to detect information waves and attacks of fake accounts (botnets). Efficiency of obtaining data, transparency and objectivity of information is ensured.",
                anchor: "AudienceStatistic"
            },
            tools: {
                num: 2,
                name: "Tools",
                textBlock1: "",
                textBlock2: "",
                anchor: "AudienceTools"
            },
            possibilities: {
                num: 3,
                name: "Capabilities",
                textBlock1:
                    "The user tells the system which groups and pages are of interest, and after a period of data collection and processing, they receive the first results. Data analysis is carried out for the period of the last year. The system processes several key parameters: the number of users, posts, likes, comments and views.",
                textBlock2:
                    "The system aggregates the information and displays it in a convenient interactive form.\n\n To find out about the currently supported networks, please write to us.",
                linkName1: "",
                linkName2: "",
                anchor: "AudiencePossible"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "Covert surveillance of the activity of specific threat sources."
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Analysis of interest and assessment of the reaction of the target audience in global networks in respect to certain problems and topics."
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Social tension analysis."
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Monitoring the activity of communities in social media."
                    }
                ]
            }
        }
    },
    scanner: {
        pageName: "Vulnerability scan",
        subMenuList: [
            {
                num: 1,
                label: "Modern threats",
                anchor: "ScannerPageDanger"
            },
            {
                num: 2,
                label: "Protection control",
                anchor: "ScannerPageControl"
            },
            {
                num: 3,
                label: "Capabilities",
                anchor: "ScannerPagePossibilities"
            },
            {
                num: 4,
                label: "Benefits",
                anchor: "ScannerPageBenifits"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/scannerPage/img1.jpg",
            img1Mobile: "./img/scannerPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Modern threats",
                textBlock1:
                    "Today, web resources are integral elements of business processes. Numerous studies show that more than 40% of resources contain critical vulnerabilities. Problems in the security of web resources are problems in the business processes of an organization. They can lead to:",
                textBlock2:
                    "• disclosure of confidential information;\n• decrease in profits due to disruption of business processes;\n• negative impact on the company's reputation;\n• obtaining unauthorized access to personal customer data;\n• penetration of violators into internal corporate information systems.",
                anchor: "ScannerPageDanger"
            },
            control: {
                num: 2,
                name: "Protection control",
                textBlock1:
                    "Modern attackers discover and exploit more and more vulnerabilities on web resources that contain sensitive data. Software implementation errors, untimely software updates, outdated passwords, and much more will lead to serious vulnerabilities in Internet projects.",
                textBlock2:
                    "A web scanner is a set of solutions aimed at providing security control functions for an information system built on the basis of web technologies. A web scanner allows you to implement functions of detecting, preventing and responding to unauthorized activity attempts by remote and internal users of computer networks.",
                anchor: "ScannerPageControl"
            },
            possibilities: {
                num: 3,
                name: "Capabilities",
                textBlock1: "",
                textBlock2: "",
                anchor: "ScannerPagePossibilities"
            },
            unnamed: {
                textBlock:
                    "Website security control methods take into account modern threats, company business risks and regulatory requirements."
            },
            benifits: {
                num: 4,
                name: "Benefits",
                textBlock1: "",
                textBlock2: "",
                anchor: "ScannerPageBenifits"
            }
        },
        cards: {
            block1: [
                {
                    num: 1,
                    name: "Architecture vulnerabilities",
                    text: "This type includes vulnerabilities made when creating web applications. A comprehensive check is carried out for the introduction of malicious code from the outside, obtaining unauthorized access, the presence of viruses. The check is carried out at different levels, from the interface (XSS / CS-RF vulnerabilities), to the database (SQL/NoSQL injections, executable code injections, etc.), the correct processing of user data, the presence of protection against bots."
                },
                {
                    num: 2,
                    name: "File and directory vulnerabilities",
                    text: "The system is being checked for files and folders open for public access, including those without direct links. Detecting potential sources of leaks of confidential information and allowing you to control the appearance of unwanted data in the search engine index. Checking if 'sensitive' data is being indexed by search engines."
                },
                {
                    num: 3,
                    name: "Configuration Scanners",
                    text: "The configuration of the information system is being analyzed. The presence of errors/weak points in the configuration of the software and hardware complex (DNS, web server configuration, etc.) is determined."
                },
                {
                    num: 4,
                    name: "Administration errors",
                    text: "In the process of installing and operating web applications, many common mistakes are made that can have very unfortunate consequences. These include the use of generic passwords, the presence of third-party administration tools, and the presence of third-party source code."
                },
                {
                    num: 5,
                    name: "Negative social phenomena",
                    text: "Searching for various kinds of negative information about the website: posts on security forums about a discovered vulnerability, requests for a website hack or DoS attack, disclosure of information about the protection mechanisms. If the website is built on a typical CMS, then the user receives notifications about detected problems in this system. "
                },
                {
                    num: 6,
                    name: "Report generation",
                    text: "The results obtained by the scanners in the form of a single, visual report."
                }
            ],
            block1Mobile: {
                cards: [
                    {
                        num: 1,
                        name: "Architecture vulnerabilities",
                        text: "This type includes vulnerabilities made when creating web applications. A comprehensive check is carried out for the introduction of malicious code from the outside, obtaining unauthorized access, the presence of viruses."
                    },
                    {
                        num: 2,
                        name: "File and directory vulnerabilities",
                        text: "The system is being checked for files and folders open for public access, including those without direct links."
                    },
                    {
                        num: 3,
                        name: "Configuration Scanners",
                        text: "The configuration of the information system is being analyzed. The presence of errors/weak points in the configuration of the software and hardware complex (DNS, web server configuration, etc.) is determined."
                    },
                    {
                        num: 4,
                        name: "Administration errors",
                        text: "In the process of installing and operating web applications, many common mistakes are made that can have very unfortunate consequences. These include the use of generic passwords, the presence of third-party administration tools, and the presence of third-party source code."
                    },
                    {
                        num: 5,
                        name: "Negative social phenomena",
                        text: "Searching for various kinds of negative information about the website: posts on security forums about a discovered vulnerability, requests for a website hack or DoS attack, disclosure of information about the protection mechanisms."
                    },
                    {
                        num: 6,
                        name: "Report generation",
                        text: "The results obtained by the scanners in the form of a single, visual report."
                    }
                ]
            },
            block2: [
                {
                    num: 1,
                    name: "",
                    text: "Constant monitoring of the organization's website security level in the 24/7/365 mode",
                    imgLink: "./img/scannerPage/small1.jpg"
                },
                {
                    num: 2,
                    name: "",
                    text: "Notification of responsible persons in case of detection of critical vulnerabilities",
                    imgLink: "./img/scannerPage/small2.jpg"
                },
                {
                    num: 3,
                    name: "",
                    text: "Regular weekly reports on the current security level",
                    imgLink: "./img/scannerPage/small3.jpg"
                },
                {
                    num: 4,
                    name: "",
                    text: "Recommendations for fixing detected vulnerabilities and assistance in their implementation",
                    imgLink: "./img/scannerPage/small4.jpg"
                },
                {
                    num: 5,
                    name: "",
                    text: "Reducing the cost of implementing information security requirements",
                    imgLink: "./img/scannerPage/small5.jpg"
                },
                {
                    num: 6,
                    name: "",
                    text: "Does not put additional strain on the organization's computing resources",
                    imgLink: "./img/scannerPage/small6.jpg"
                }
            ],
            block2Mobile: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "Constant monitoring of the organization's website security level in the 24/7/365 mode",
                        imgLink: "./img/scannerPage/small1.jpg"
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Notification of responsible persons in case of detection of critical vulnerabilities",
                        imgLink: "./img/scannerPage/small2.jpg"
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Regular weekly reports on the current security level",
                        imgLink: "./img/scannerPage/small3.jpg"
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Recommendations for fixing detected vulnerabilities and assistance in their implementation",
                        imgLink: "./img/scannerPage/small4.jpg"
                    },
                    {
                        num: 5,
                        name: "",
                        text: "Reducing the cost of implementing information security requirements",
                        imgLink: "./img/scannerPage/small5.jpg"
                    },
                    {
                        num: 6,
                        name: "",
                        text: "Does not put additional strain on the organization's computing resources",
                        imgLink: "./img/scannerPage/small6.jpg"
                    }
                ]
            }
        }
    },
    aboutUs: {
        pageName: "About us",
        subMenuList: [
            {
                num: 1,
                label: "Andrey Igorevich Masalovich",
                anchor: "AboutUsPageMain"
            },
            {
                num: 2,
                label: "Our mission",
                anchor: "AboutUsPageMission"
            },
            {
                num: 3,
                label: "Activities",
                anchor: "AboutUsPageDirection"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/aboutUsPage/img1.jpg",
            img1Mobile: "./img/aboutUsPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Andrey Igorevich Masalovich",
                textBlock1:
                    "For the first time, a product called Avalanche appeared on the Russian market in 2003. Its creator is a pioneer in the competitive intelligence market, retired FAPSI Lieutenant Colonel Andrey Masalovich. For almost 20 years, the system has been used by law enforcement agencies, federal and regional authorities to control public sentiment and combat terrorist threats.",
                textBlock2:
                    "Today, analytical systems built on the basis of the Avalanche technology platform are actively used by commercial companies to monitor Russian and foreign markets, partners and competitors.",
                linkName1: "",
                linkName2: "",
                anchor: "AboutUsPageMain"
            },
            education: {
                name: "Education",
                textBlock1: "",
                textBlock2:
                    "2005 – course “Corporate Governance”, York University (Toronto, Canada)\n1994 – course“Conversion & Privatization”, University of Los-Angeles (USA)\n1992 – special course King’s School of English, (London, Great Britain)\n1989 – postgraduate student at MAI, PhD in Physics and Mathematics\n1984 – Moscow Aviation Institute, faculty of Applied Mathematics"
            },
            expert: {
                name: "Competitive Intelligence Expert",
                textBlock1: "",
                textBlock2:
                    "President of the Inforus Consortium\nPhD in Physics and Mathematics\nLaureate of the Russian Academy of Sciences Scholarship 'Outstanding Scientist of Russia' – 1993\n IPCC Award 'Best Work in Computer Journalism in Russia'-1995\nTop Pros Award (ICC, category 'Business' and 'Person') – 2002"
            },
            unnamed: {
                name: "",
                textBlock1:
                    "Author of more than 100 publications, the main results of his work are patented in the United States. He has been repeatedly published in the journals 'Questions of Radioelectronics', PC Week, PC World, 'Intercomputer', 'Softmarket', 'Securities Market' and others. In 1995 received the award of the international association of journalists IPCC 'Best work in computer journalism in Russia' for his cycle of articles on fuzzy logic. Participant and member of the Organizing Committee of many international conferences. In 1995 received gratitude from the EuroPar Steering Committee for his personal contributions to the organization of the EuroPar-95 conference (Stockholm). Participant and member of the Organizing Committee of many international conferences. Head of more than 20 successful international projects. Since 1998O, oganized the development of a set of analytical programs for trading companies and holdings. The Supermarket Management program became the winner of the Business Soft'98 competition. In 2000, he created the InterRusSoft company to carry out custom Internet projects in the global market.",
                textBlock2:
                    "Carried out a number of successful Internet projects, including www.trubka.ru, www.rusbiotech.ru, www.procurezone.com. The projects received several awards from Internet competitions: 'Jury Recognition' (Internet-2001), diploma 'Gold site - 2001', etc. In 2001, he became the president of the Inforus Consortium, which united more than 80 leading IT companies in Russia. Expert of RFBR (1995-2000), INTAS (1998), UN ITC (since 2002), APEC (since 2003). Member of the jury of the BIT competitions (since 2004) and the Runet Prize (since 2005). Developer and chief designer of Big Data analysis technology - Avalanche.\n\nBig data analysis technologies are of great relevance, as in business as well as the public sector."
            },
            mission: {
                num: 2,
                name: "Our mission",
                textBlock1: "",
                textBlock2:
                    "The Masalovich & Partners group of companies was founded by Andrey Masalovich, a well-known expert in the field of information security, in 2020, but the history of Masalovich & Partners dates back to 2003, when the Inforus Consortium was founded and the first version of the Avalanche monitoring system was created. For more than 17 years, our expertise and developments have helped Russian companies and government authorities to successfully repel communication and information threats. Our portfolio includes many large IT projects and experience in information and analytical support for federal and regional government authorities and their top officials, state corporations and commercial companies, Russian military industrial sector.",
                anchor: "AboutUsPageMission"
            },
            direction: {
                num: 3,
                name: "Activities",
                textBlock1: "",
                textBlock2:
                    "Our team specializes in providing comprehensive security based on the collection and analysis of open source intelligence, including communications, economic and information security.\n\nAlso, one of the main activities of the organization is the development of corporate software.",
                anchor: "AboutUsPageDirection"
            }
        }
    },
    demonstration: {
        pageName: "Demo",
        subMenuList: [
            {
                num: 1,
                label: "Demo",
                anchor: "DemonstrationPageMain"
            },
            {
                num: 2,
                label: "Public projects",
                anchor: "DemonstrationPublicProjects"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/demonstrationPage/img1.jpg"
        },
        blocks: {
            PublicProjects: {
                num: 2,
                name: "Public projects",
                textBlock1:
                    "To demonstrate the work of Avalanche, we pre-configured several public projects. The system aggregates information from thousands of open sources, filters it by various topics and presents it in the form of news feeds. It's free.",
                textBlock2:
                    "Based on the processed information, the system analyzes the interest of the target audience in global networks in certain problems and warns of emerging threats and risks.",
                linkName1: "",
                linkName2: "",
                anchor: "DemonstrationPublicProjects"
            },
            iframe: {
                textBlock:
                    "Supervisor screen (default mode). 8 main topics for monitoring information on the subject of Covid 19. Monitoring the situation on the Internet by federal districts of the Russian Federation.",

                anchor: "DemonstrationPublicProjects"
            }
        },
        cards: {
            block1: [
                {
                    num: 1,
                    name: "Political leaders",
                    text: "Mentions of key world political leaders in foreign media.",
                    linkName: "Go to page",
                    externalLink: "https://leaders.avl.team/"
                },
                {
                    num: 2,
                    name: "Ministries",
                    text: "Agenda of the Russian ministries and departments in the domestic media.",
                    linkName: "Go to page",
                    externalLink: "https://minister.avl.team/"
                },
                {
                    num: 3,
                    name: "Covid-19",
                    text: "Mentions of the coronavirus infection in the regional media of Russia, divided into federal districts.",
                    linkName: "Go to page",
                    externalLink: "https://covid19.avl.team/"
                },
                {
                    num: 4,
                    name: "Search for air tickets",
                    text: "Collecting information about cheap flights and promotions of various global airlines.",
                    linkName: "Go to page",
                    externalLink: "https://lowcost.avl.team/"
                },
                {
                    num: 5,
                    name: "Mobilization news",
                    text: "Most important information about the mobilization campaign and volunteer missions.",
                    linkName: "Go to page",
                    externalLink: "https://vstavai.avl.team/"
                },
                {
                    num: 6,
                    name: "OSINT&Cybersec",
                    text: "News from the world of intelligence and cybersecurity, monitoring leaks and finding vulnerabilities.",
                    linkName: "Go to page",
                    externalLink: "https://osint.avl.team/"
                }
            ]
        }
    },
    contacts: {
        pageName: "Contacts",
        subMenuList: "",
        blocks: {
            contacts: {
                num: "",
                name: "How to find us:",
                textBlock1: "",
                textBlock2: (
                    <>
                        <div className="contacts-line-back bborder">
                            <span>1.</span>
                            <span>
                                Moscow, street 2 Ostankinskaya, 6, office 103
                            </span>
                        </div>
                        {/* <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span>+7 (499) 393-00-15</span>
                        </div> */}
                        <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span>For general inquiries: info@avl.team</span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>3.</span>
                            <span>Sales department: sales@avl.team</span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>4.</span>
                            <span>Support support@avl.team</span>
                        </div>
                    </>
                )
            },
            contactsTablet: {
                num: "",
                name: "How to find us:",
                textBlock1: "",
                textBlock2: (
                    <>
                        <div className="contacts-line-back bborder">
                            <span>1.</span>
                            <span className="text-right pl-15">
                                Moscow, street 2 Ostankinskaya, 6, office 103
                            </span>
                        </div>
                        {/* <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span className="text-right">
                                +7 (499) 393-00-15
                            </span>
                        </div> */}
                        <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span className="text-right">
                                For general inquiries: info@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>3.</span>
                            <span className="text-right">
                                Sales department: sales@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>4.</span>
                            <span className="text-right">
                                Support support@avl.team
                            </span>
                        </div>
                    </>
                )
            }
        }
    },
    cookies: {
        pageName: "How cookies work",
        subMenuList: [
            {
                num: 1,
                label: "Cookie Policy",
                anchor: "CookiesPagePolitics"
            },
            {
                num: 2,
                label: "What are cookies?",
                anchor: "CookiesPageWhatItIs"
            },
            {
                num: 3,
                label: "How do we use cookies?",
                anchor: "CookiesPageWhatWeUse"
            },
            {
                num: 4,
                label: "What types of cookies do we use?",
                anchor: "CookiesPageWhatTypes"
            },
            {
                num: 4,
                label: "Can I change my cookie settings?",
                anchor: "CookiesPageCanChange"
            }
        ],
        imageUrls: {
            img1: "./img/cookiesPage/img1.jpg",
            img1Mobile: "./img/cookiesPage/img1Mobile.jpg"
        },
        blocks: {
            politics: {
                num: 1,
                name: "Cookie Policy",
                textBlock1:
                    "This Cookie Policy explains what cookies are, how and what types of cookies we use, and how to manage your cookie preferences. For more information about how we use, store and protect your personal information, please see our Privacy Policy.",
                textBlock2:
                    "You can change or withdraw your consent to the use of cookies on our website at any time.",
                anchor: "CookiesPagePolitics"
            },
            whatItIs: {
                num: 2,
                name: "What are cookies?",
                textBlock2:
                    "Cookies are small text files that are used to store small pieces of information. They are stored on your device when a website is loaded in your browser. Cookies help us make the website work properly, make it more secure, improve user experience, understand how the website works and analyze what is not working and where improvement is needed.",
                anchor: "CookiesPageWhatItIs"
            },
            whatWeUse: {
                num: 3,
                name: "How do we use cookies?",
                textBlock2:
                    "Like most online services, our website uses first-party and third-party cookies for several purposes. Some of them are mainly necessary for the website to function properly and do not collect personal data. Third-party cookies used on our website are designed to understand how the website works, how you interact with our website, to ensure the security of our services, to provide relevant advertising, etc.",

                anchor: "CookiesPageWhatWeUse"
            },
            whatTypes: {
                num: 4,
                name: "What types of cookies do we use?",

                anchor: "CookiesPageWhatTypes"
            },
            canChange: {
                num: 5,
                name: "Can I change my cookie settings?",
                textBlock1:
                    "If you decide to change your settings later, you can click on the 'Privacy & Cookie Policy' tab on your screen. This will display the consent notice again, allowing you to change your preferences or withdraw your consent entirely.",
                textBlock2:
                    "In addition, different browsers provide different ways to block and delete the cookies used by websites. You can change your browser settings to block/delete cookies.",
                anchor: "CookiesPageCanChange"
            },
            cookies: {
                analytics: {
                    num: "",
                    name: "cookielawinfo-checkbox-analytics",
                    textBlock2:
                        "This cookie is set by the GDPR Cookie Consent plugin. The cookie is used to store the user's consent to the use of cookies in the Analytics category."
                },
                functional: {
                    num: "",
                    name: "cookielawinfo-checkbox-functional",
                    textBlock2:
                        "The cookie is set by the GDPR cookie consent to record the user's consent to the use of cookies in the Functional category."
                },
                necessary: {
                    num: "",
                    name: "cookielawinfo-checkbox-necessary",
                    textBlock2:
                        "These cookies are set by the GDPR Cookie Consent plugin. The cookies are used to store the user's consent to the use of cookies in the Necessary category."
                },
                others: {
                    num: "",
                    name: "cookielawinfo-checkbox-others",
                    textBlock2:
                        "This cookie is set by the GDPR Cookie Consent plugin. The cookie is used to store the user's consent to the use of cookies in the Other category."
                },
                performance: {
                    num: "",
                    name: "cookielawinfo-checkbox-performance",
                    textBlock2:
                        "This cookie is set by the GDPR Cookie Consent plugin. The cookie is used to store the user's consent to the use of cookies in the Performance category."
                },
                policy: {
                    num: "",
                    name: "viewed_cookie_policy",
                    textBlock2:
                        "The cookie is set by the GDPR Cookie Consent plugin and is used to store whether the user has given consent to the use of cookies. It does not store any personal data."
                }
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Basic",
                        text: "Some cookies are necessary for you to enjoy all the features of our site. They allow us to maintain user sessions and prevent any security risks and do not collect or store any personal information."
                    },
                    {
                        num: 2,
                        name: "Statistical",
                        text: "These cookies store such information as the number of visitors to the website, the number of unique visitors, as well as information about which pages of the website were visited, the source of the visit, etc. This data helps us understand and analyze how well the website is performing and where it needs improvement."
                    },
                    {
                        num: 3,
                        name: "Functional",
                        text: "These are cookies that enable certain non-essential features on our website. These features include embedding content such as videos or sharing website content on social media platforms."
                    },
                    {
                        num: 4,
                        name: "Preferences",
                        text: "These cookies help us save your settings and browsing preferences, such as your language settings, so that you have a better experience on future visits to the website."
                    }
                ]
            }
        }
    },
    conditions: {
        pageName: "Terms of Use",
        subMenuList: "",
        imageUrls: {
            img1: "./img/useConditionsPage/img1.jpg",
            img1Mobile: "./img/useConditionsPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "",
                textBlock1:
                    "The service and its components developed by Masalovich & Partners Group of Companies are designed for the tasks of Internet monitoring, as well as processing and analyzing open data from the Internet. We emphasize that we use only open data that is freely available on the Internet.",
                textBlock2: (
                    <div
                        style={{ display: "inline-block", marginBottom: "2px" }}
                    >
                        {`If you use our Service in any way, you thereby confirm your agreement to comply with the following rules (all conditions specified below apply both to the Service as a whole and to all its components separately, but do not exclude additional conditions of the`}
                        <Link
                            to="/publicOfertaPage"
                            className="ml-5 "
                        >
                            <span className="bborder">public offer</span>
                        </Link>
                        {`):`}
                    </div>
                ),
                anchor: ""
            },
            support: {
                name: "Terms of Technical Support",
                line1: "As part of Technical Support, the Copyright Holder and/or its Partners undertake to provide the following services:\n\r\u0020\u0020\u0020\u2022 updating new versions of both the Service as a whole and all its components as they become available; n\r\u0020\u0020\u0020\u2022organizing consultations and eliminating incidents (by e-mail - support@avl.team and through a special feedback form)",
                line2_1:
                    "Hours for registering applications for service in the technical support system (and at the official e-mail address)",
                line2_2: "24/7",
                line3_1: "Hours for eliminating critical incidents",
                line3_2: "From 10:00 (10AM) to 20:00 (8PM) Moscow time",
                line4_1:
                    "Hours for resolving other claims and processing information requests",
                line4_2:
                    "From 10:00 (10AM) to 20:00 (8PM) Moscow time (except weekends and holidays)",
                line5_1: "Request response time",
                line5_2: "4 hours (response time includes only business hours)"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "If you purchase access to the Service, you have the right to use the Service on the number of computers/mobile devices specified in the order."
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Masalovich & Partners Group of Companies guarantees the operation of the Service in accordance with the conditions described in the user manual."
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Masalovich & Partners Group of Companies does not guarantee the operation of the Service in case of violation of the conditions described in the operation manual, as well as in case of violation by the User of the terms of use of the Service."
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Masalovich & Partners Group of Companies is not responsible for malfunctions associated with the use of the Service or the impossibility of using the Service in case of non-compliance of the User's computer, operating systems and related software with system requirements."
                    },
                    {
                        num: 5,
                        name: "",
                        text: "Masalovich & Partners Group of Companiesis not responsible for the User's utilization of the Service for malicious purposes. At the same time, Masalovich & Partners Group of Companies reserves the right to monitor and analyze the User's activity (in an impersonal form) in order to prevent the use of the Service for illegal purposes violating the laws of the Russian Federation."
                    },
                    {
                        num: 6,
                        name: "",
                        text: "The User undertakes not to use the Service to check the security of sites without the consent of their owners. The User is responsible, as established by the legislation of the Russian Federation, for the security checks performed and all possible consequences of such checks."
                    },
                    {
                        num: 7,
                        name: "",
                        text: "It is forbidden to perform reverse engineering, as well as decompiling and/or modifying the source code of any of our products."
                    },
                    {
                        num: 8,
                        name: "",
                        text: "For copyright infringement, the violator bears civil, administrative or criminal liability in accordance with the legislation of the Russian Federation."
                    },
                    {
                        num: 9,
                        name: "",
                        text: "After activating the account (with the exception of versions intended for evaluation purposes), the User acquires the right to receive Technical Support from Masalovich & Partners during the term of the license."
                    }
                ]
            }
        }
    },
    personalData: {
        pageName: "Personal Data Processing Agreement",
        subMenuList: "",
        imageUrls: {
            img1: "./img/personalDataAgreementPage/img1.jpg",
            img1Mobile: "./img/personalDataAgreementPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Personal Data Processing Agreement",
                textBlock1: "",
                textBlock2: "",
                anchor: ""
            }
        }
    },
    confidentionalPolitic: {
        pageName: "Privacy Policy",
        subMenuList: "",
        imageUrls: {
            img1: "./img/confidentionalPoliticPage/img1.jpg",
            img1Mobile: "./img/confidentionalPoliticPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Privacy policy regarding the processing of personal data",
                textBlock1: "",
                textBlock2: "",
                anchor: ""
            }
        }
    },
    publicOferta: {
        pageName: "Public offer",
        subMenuList: "",
        imageUrls: {
            img1: "./img/publicOfertaPage/img1.jpg",
            img1Mobile: "./img/publicOfertaPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Service agreement (public offer)",
                textBlock1: "",
                textBlock2: (
                    <>
                        <span style={{ fontWeight: "bold" }}>
                            "Date of placement on the website: January 01, 2023
                        </span>
                        <br />
                        <p>
                            LAVINA PULSE LLC proposes to conclude this Agreement
                            for the provision of Services for garanting the
                            right to use a simple (non-exclusive) product
                            license. The proposal is addressed an indefinite
                            circle of capable individuals, legal entities,
                            individual entrepreneurs and is a public offer in
                            accordance with paragraph 2 of Art. 437 of the Civil
                            Code of the Russian Federation.
                        </p>
                    </>
                ),
                anchor: ""
            },
            mainTablet: {
                num: "",
                name: "Service agreement (public offer)",
                textBlock1: "",
                textBlock2: (
                    <>
                        <span style={{ fontWeight: "bold", width: "20vw" }}>
                            "Date of placement on the website: January 01, 2023
                        </span>
                        <br />
                        <p>
                            LAVINA PULSE LLC proposes to conclude this Agreement
                            for the provision of Services for garanting the
                            right to use a simple (non-exclusive) product
                            license. The proposal is addressed an indefinite
                            circle of capable individuals, legal entities,
                            individual entrepreneurs and is a public offer in
                            accordance with paragraph 2 of Art. 437 of the Civil
                            Code of the Russian Federation.
                        </p>
                    </>
                ),
                anchor: ""
            }
        }
    },
    tariffs: {
        pageName: "Tariff Plans",
        subMenuList: [
            {
                num: 1,
                label: "Virtual situation center",
                anchor: "TariffsPageCenter"
            },
            {
                num: 2,
                label: "Licenses and Implementation",
                anchor: "TariffsPageLicence"
            },
            { label: "Send a request", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/tariffsPage/img1.jpg",
            img1Mobile: "./img/tariffsPage/img1Mobile.jpg",
            img2: "./img/tariffsPage/img2.jpg",
            img2Mobile: "./img/tariffsPage/img2Mobile.jpg"
        },
        blocks: {
            center: {
                num: 1,
                name: "Virtual situation center",
                textBlock1: "",
                textBlock2:
                    "The Internet space monitoring service provides the Customer with the capabilities of a modern situational center in terms of monitoring the operational situation and early detection of Internet threats. There are 3 tariff plans for commercial Customers.",
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageCenter"
            },
            licence: {
                num: 2,
                name: "Licenses and Implementation",
                textBlock1:
                    "When implementing the system on the Customer's equipment, individual software modifications are possible in accordance with the Customer's requirements.",
                textBlock2: (
                    <span
                        style={{
                            fontSize: 30 + "px",
                            paddingLeft: 20 + "px",
                            lineHeight: 1.2,
                            position: "relative",
                            top: -5 + "px"
                        }}
                    >
                        The cost of one server license without modifications is
                        15 million ₽.
                    </span>
                ),
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageLicence"
            },
            licenceTablet: {
                num: 2,
                name: "Licenses and Implementation",
                textBlock1:
                    "When implementing the system on the Customer's equipment, individual software modifications are possible in accordance with the Customer's requirements.",
                textBlock2: (
                    <span className="fs-20-20">
                        The cost of one server license without modifications is
                        15 million ₽.
                    </span>
                ),
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageLicence"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Authorship",
                        text: "The complex is based on the original domestic technology of Internet monitoring and construction of distributed databases."
                    },
                    {
                        num: 2,
                        name: "Deep data analysis",
                        text: "An expanded set of modern algorithms for big data processing, including express analysis, forecasting, modeling and much more."
                    },
                    {
                        num: 3,
                        name: "Flexibility and adaptability",
                        text: "Supports integration with state, departmental and specialized databases. Ability to integrate external data sources."
                    },
                    {
                        num: 4,
                        name: "Open source",
                        text: "All components of the complex were developed in Russia and are based on open source software products."
                    }
                ]
            }
        },
        table: {
            top: {
                column1: {
                    name: "Standard",
                    price: "from 150 thousand ₽ / month"
                },
                column2: {
                    name: "Optimum",
                    price: "from 300 thousand ₽ / month"
                },
                column3: {
                    name: "Premium",
                    price: "from 500 thousand ₽ / month"
                }
            },
            info: [
                {
                    name: "Project in Avalanche",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Private channel in Telegram",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Monthly analytical report",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Analytical support",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Weekly analytical review",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Weekly news picks",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Analytical reviews",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "(up to 3)",
                    premiumValueExtrainfo: "(up to 5)"
                },
                {
                    name: "Private channel in Telegram with online broadcast of key news",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Daily news review",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Monitoring the activity of competitors\n\r (no more than 5 companies)",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Development and update of the risk and threat map",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Reputation vulnerabilities search",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Additional work to be agreed",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                }
            ]
        }
    },
    question: {
        pageName: "Ask a question",
        subMenuList: ""
    },
    request: {
        pageName: "Send a request",
        subMenuList: ""
    },
    publications: {
        pageName: "Publications",
        subMenuList: "",
        imageUrls: {
            img1: "./img/publicationsPage/img1.jpg",
            img1Mobile: "./img/publicationsPage/img1Mobile.jpg"
        }
    },
    publicActivity: {
        pageName: "Public activity",
        subMenuList: [
            {
                num: 1,
                label: "Publications",
                anchor: "publicActivityPageMain"
            },
            {
                num: 2,
                label: "Book «CyberDed Knows» («КиберДед знает»)",
                anchor: "publicActivityPageBook"
            },
            {
                num: 3,
                label: "Telegram channels",
                anchor: "publicActivityPageTG"
            },
            {
                num: 4,
                label: "Social",
                anchor: "publicActivityPageSocial"
            },
            { label: "Ask a question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/publicActivityPage/img1.jpg",
            img1Mobile: "./img/publicActivityPage/img1Mobile.jpg",
            img2Mobile: "./img/publicActivityPage/img2Mobile.jpg",
            middle1: "./img/publicActivityPage/middle1.jpg",
            middle2: "./img/publicActivityPage/middle2.jpg",
            middle3: "./img/publicActivityPage/middle3.jpg"
        },
        blocks: {
            publications: {
                num: 1,
                name: "Publications",
                textBlock1: "",
                textBlock2: "",
                linkName1: "View all",
                link1: "/publicationsPage",
                linkName2: "",
                anchor: "publicActivityPageMain"
            },
            cyberDad: {
                num: 2,
                name: `Book\r\n«CyberDed Knows» («КиберДед знает»)`,
                textBlock1: "",
                textBlock1Img: "./img/publicActivityPage/small1.jpg",
                imgLink: "./img/publicActivityPage/small1.jpg",
                textBlock2:
                    "Andrei Masalovich, an entrepreneur, Internet intelligence officer and author of the CyberDed YouTube channel, is convinced that any failure is always a cosmic kick to search for opportunities. The main thing is to stop dragging one's feet and moping about. The lower the point from which you start, the more crushing the victory will be. Having passed the nineties and the aughts, having earned real money on crises, the author will teach you the rules of antifragility in Russian reality.",
                linkName1: "Buy",
                link1: "https://eksmo.ru/book/belyy-pisets-10-sovetov-ot-kiberdeda-kak-priyti-k-uspekhu-v-rossii-ITD1193720/",
                linkName2: "",
                anchor: "publicActivityPageBook"
            },
            tg: {
                num: 3,
                name: "Telegram channels",
                textBlock1: "",
                textBlock2: "",
                linkName1: "",
                link1: "",
                linkName2: "",
                anchor: "publicActivityPageTG"
            },
            social: {
                num: 4,
                name: "Social",
                textBlock1: "",
                textBlock2: "",
                linkName1: "",
                link1: "",
                linkName2: "",
                anchor: "publicActivityPageSocial"
            }
        },
        cardsBlock: {
            cards: [
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle1.jpg"
                },
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle2.jpg"
                },
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle3.jpg"
                }
            ]
        },
        telegram: {
            channel1: "Masalovich & Partners",
            channel1Link: "https://t.me/maspartners",
            channel2: "Mycroft Intelligence",
            channel2Link: "https://t.me/mycroftintel"
        },
        social: {
            channel1: "Dzen CyberDed",
            channel1Link: "https://dzen.ru/kiberded",
            channel2: "VK Masalovich & Partners",
            channel2Link: "https://vk.com/@mycroftintel-bspltn",
            channel3: "Dzen Mycroft Intelligence",
            channel3Link: "https://dzen.ru/mycroftintel"
        }
    }
};
export default content;
