import React from "react";
import { useLanguage } from "../../../hooks/useLang";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
// import agreements from "../agreementsTextEn";
import AgreementsBlockTablet from "./agreementsBlockTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"


const PersonalDataAgreementPageTablet = () => {
    const { content, agreements } = useLanguage();
    const pageName = content.personalData.pageName;
    const subMenuList = content.personalData.subMenuList;
    const imageUrls = content.personalData.imageUrls;
    const blocks = content.personalData.blocks;
    const personalDataAgreement = agreements.personalData;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
                // heihtForName={true}
                withoutHeight={true}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.main}
                nameSize={"fs-44"}
                customNameWidthPersonalDataAgreementPage={true}
            />
            <AgreementsBlockTablet blockContent={personalDataAgreement} />

            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default PersonalDataAgreementPageTablet;
