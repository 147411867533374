import React from "react";
import { useLanguage } from "../../../hooks/useLang";
import CardsWithArrowsAdaptiveTablet from "../cardsBlocks/cardsWithArrowsAdaptiveTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import SendRequest from ".";
// import content from "../textContent";
import ContactUsTablet from "../../footerTablet/contactUsTablet";
import SiteMapTablet from "../../footerTablet/siteMapTablet"

const AnalyticsPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.analytics.pageName;
    const subMenuList = content.analytics.subMenuList;
    const imageUrls = content.analytics.imageUrls;
    const blocks = content.analytics.blocks;
    const cards = content.analytics.cards;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.main} />
            <HorizontalBlockAdaptiveTablet blockContent={blocks.scouting} />

            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block1}
                arrowsDirection="left"
                topBorderCard={true}
                topBorderBlock={true}// topBottom
                bottomBorderCard = {false}
                customHeightAnalitycsPage={true}
            />
            <HorizontalBlockAdaptiveTablet 
               blockContent={blocks.research}
               bottomBorder={true}
            
            
            />
            <CardsWithArrowsAdaptiveTablet
                blockContent={cards.block2}
                arrowsDirection="right"
                topBorderbBlock={true}
                topBorderCard={true}
                customHeightAnalitycsPage={true}
                
            />
            {/* <SendRequest /> */}
            <ContactUsTablet/>
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default AnalyticsPageTablet;
