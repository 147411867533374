import React from "react";
import { useLanguage } from "../../hooks/useLang";

import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";

import HorizontalBlockUnnamedMobile from "../pageBlocksMobile/horizontalBlockUnnamedMobile";
import TariffsBlockMobile from "../pageBlocksMobile/tariffsBlockMobile";

const TariffsPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.tariffs.pageName;
    const imageUrls = content.tariffs.imageUrls;
    const blocks = content.tariffs.blocks;
    const cards = content.tariffs.cards;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.center}
                noLink={true}
                tripleLineName={true}
                customHeight={150}
                bottomBorder={true}
                dropdownDisable={true}
            />
            <TariffsBlockMobile />
            <LargeImageMobile imageUrl={imageUrls.img2Mobile} />
            <HorizontalBlockUnnamedMobile
                blockContent={blocks.licence}
                fulltype={true}
                bottomBorder={false}
                topBorder={true}
                nameFontSize={32}
                rightArrow={false}
                secondTextBlockFontSize="20"
            />
            <CardsSwipeMobile
                blockContent={cards.block1}
                topBorderbBlock={true}
                bottomBorderBlock={true}
            />
        </div>
    );
};

export default TariffsPageMobile;
