import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const HorizontalBlockAdaptive = ({
    blockContent,
    bottomBorder = false,
    bottomPadding = false,
    nameSize = "",
    notAnyPoints = false,
    onlyName = false
}) => {
    const [largeName, setLargeName] = useState(false);
    const nameHeight = useRef(null);
    const text1Height = useRef(null);
    const text2Height = useRef(null);
    const compareHeight = (nameHeight, text1Height, text2Height) => {
        // console.log(blockContent.name, nameHeight, text1Height, text2Height);
        return nameHeight >= text1Height && nameHeight >= text2Height
            ? true
            : false;
    };
    useEffect(() => {
        let res = compareHeight(
            nameHeight.current.getBoundingClientRect().height,
            text1Height.current.getBoundingClientRect().height,
            text2Height.current.getBoundingClientRect().height
        );
        setLargeName(res);
        // eslint-disable-next-line
    }, [nameHeight, text1Height, text2Height]);
    return (
        <div
            id={blockContent.anchor}
            className={`horizontalBlockAdaptive smt pt-60 ${
                largeName ? "pb-40" : "pb-60"
            } ${bottomBorder ? " bborder" : " "} ${
                bottomPadding ? "pb-60" : ""
            }`}
        >
            <div
                className={`horizontalBlockAdaptive-name  ${
                    !blockContent.num &&
                    !blockContent.textBlock &&
                    !blockContent.textBlock1 &&
                    !blockContent.textBlock2
                        ? "w-100"
                        : ""
                }`}
            >
                {!notAnyPoints ? (
                    blockContent.num ? (
                        <span className="horizontalBlockAdaptive-name-num fs-16 ">
                            {blockContent.num}.
                        </span>
                    ) : (
                        <div
                            className="horizontalBlockAdaptive-left-arrow imgBack "
                            style={
                                !blockContent.num &&
                                !blockContent.textBlock1 &&
                                !blockContent.textBlock2
                                    ? { top: 25 + "%" }
                                    : {}
                            }
                        ></div>
                    )
                ) : (
                    <></>
                )}

                <div
                    className={`horizontalBlockAdaptive-name-name    ${
                        blockContent.num
                            ? "fs-64"
                            : nameSize
                            ? nameSize
                            : "fs-40 w-100"
                    } ${blockContent.textBlock1Img ? " w-100 " : ""} ${
                        onlyName ? " w-100 " : ""
                    }`}
                    style={
                        !blockContent.num
                            ? !blockContent.textBlock1 &&
                              !blockContent.textBlock2
                                ? largeName
                                    ? {}
                                    : { top: -15 + "px" }
                                : { top: -20 + "px" }
                            : { top: -10 + "px" }
                    }
                    ref={nameHeight}
                >
                    {blockContent.name && blockContent.name}
                </div>
            </div>
            <div
                className="horizontalBlockAdaptive-textBlock fs-16 "
                ref={text1Height}
            >
                {blockContent.textBlock1 && blockContent.textBlock1}
                {blockContent.textBlock1Img && (
                    <img
                        src={blockContent.textBlock1Img}
                        alt="error"
                        style={{ position: "absolute", right: 20 + "px" }}
                    />
                )}
            </div>

            <div
                className="
            horizontalBlockAdaptive-textBlock-right fs-16  "
                ref={text2Height}
            >
                {blockContent.textBlock2 && blockContent.textBlock2}
                {blockContent.linkName1 && (
                    <Link
                        to={{
                            pathname: blockContent.link1
                                ? blockContent.link1
                                : "/"
                        }}
                        target={
                            blockContent.link1.includes(".")
                                ? "_blank"
                                : "_self"
                        }
                        className={`horizontalBlockAdaptive-textBlock-link ${
                            blockContent.textBlock2 && "mt-60"
                        } fs-32 cp`}
                    >
                        <span className="horizontalBlockAdaptive-textBlock-link-name ">
                            {blockContent.linkName1}
                        </span>
                        <div className="horizontalBlockAdaptive-textBlock-link-arrow imgBack "></div>
                    </Link>
                )}
                {blockContent.linkName2 && (
                    <Link
                        to={blockContent.link2 ? blockContent.link2 : "/"}
                        className="horizontalBlockAdaptive-textBlock-link mt-60 fs-32 cp"
                    >
                        <span className="horizontalBlockAdaptive-textBlock-link-name ">
                            {blockContent.linkName2}
                        </span>
                        <div className="horizontalBlockAdaptive-textBlock-link-arrow imgBack "></div>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default HorizontalBlockAdaptive;
