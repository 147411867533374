import React from "react";
import { useLanguage } from "../hooks/useLang";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const AboutUsPage = () => {
    const { content } = useLanguage();
    const pageName = content.aboutUs.pageName;
    const subMenuList = content.aboutUs.subMenuList;
    const imageUrls = content.aboutUs.imageUrls;
    const blocks = content.aboutUs.blocks;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.main} />
            <HorizontalBlockAdaptive blockContent={blocks.education} />
            <HorizontalBlockAdaptive blockContent={blocks.expert} />
            <HorizontalBlockAdaptive blockContent={blocks.unnamed} />
            <HorizontalBlockAdaptive blockContent={blocks.mission} />
            <HorizontalBlockAdaptive blockContent={blocks.direction} />
        </div>
    );
};

export default AboutUsPage;
