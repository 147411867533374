import React from "react";
import { useLanguage } from "../../../hooks/useLang";


import CardsAdaptiveBlockTablet from "../cardsBlocks/cardsAdaptiveBlockTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
import SiteMapTablet from "../../footerTablet/siteMapTablet"


const UseConditionsPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.conditions.pageName;
    const subMenuList = content.conditions.subMenuList;
    const imageUrls = content.conditions.imageUrls;
    const blocks = content.conditions.blocks;
    const support = blocks.support;
    const cards = content.conditions.cards;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
                // heihtForName={true}
                withoutHeight={true}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet
                blockContent={blocks.main}
            />
            <CardsAdaptiveBlockTablet
                blockContent={cards.block1.cards}
                topBorderbBlock={true}
                paddingTopBlock={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                topBorderCard={true}
                customHeightUseConditionsPage={true}
            />
            <div className="useConditionsSupportBlock tborder pb-80 bborder">
                <div className="useConditionsSupportBlock-left-arrow mt-60  imgBack "></div>
                <div className="useConditionsSupportBlock-name-back ">
                    <div className="useConditionsSupportBlock-name mt-40 fs-40-44">
                        {support.name}
                    </div>
                </div>
                <div className="useConditionsSupportBlock-textBlock-back pt-40">
                    <div className="useConditionsSupportBlock-textBlock fs-14 bborder">
                        {support.line1}
                    </div>
                    <div className="useConditionsSupportBlock-textBlock-halfs-back bborder">
                        <div className="useConditionsSupportBlock-textBlock-half fs-14">
                            {support.line2_1}
                        </div>
                        <div className="useConditionsSupportBlock-textBlock-half fs-14 pl-15">
                            {support.line2_2}
                        </div>
                    </div>
                    <div className="useConditionsSupportBlock-textBlock-halfs-back fs-14 bborder">
                        <div className="useConditionsSupportBlock-textBlock-half">
                            {support.line3_1}
                        </div>
                        <div className="useConditionsSupportBlock-textBlock-half pl-15">
                            {support.line3_2}
                        </div>
                    </div>
                    <div className="useConditionsSupportBlock-textBlock-halfs-back fs-14 bborder">
                        <div className="useConditionsSupportBlock-textBlock-half">
                            {support.line4_1}
                        </div>
                        <div className="useConditionsSupportBlock-textBlock-half pl-15">
                            {support.line4_2}
                        </div>
                    </div>
                    <div className="useConditionsSupportBlock-textBlock-halfs-back fs-14 bborder">
                        <div className="useConditionsSupportBlock-textBlock-half">
                            {support.line5_1}
                        </div>
                        <div className="useConditionsSupportBlock-textBlock-half pl-15">
                            {support.line5_2}
                        </div>
                    </div>
                </div>
            </div>
        <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default UseConditionsPageTablet;
