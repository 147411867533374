import React from "react";
import { useLanguage } from "../hooks/useLang";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const ForBusinessPage = () => {
    const { content } = useLanguage();
    const pageName = content.forBusiness.pageName;
    const subMenuList = content.forBusiness.subMenuList;
    const imageUrls = content.forBusiness.imageUrls;
    const blocks = content.forBusiness.blocks;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.main} />
            <HorizontalBlockAdaptive blockContent={blocks.center} />
            <HorizontalBlockAdaptive
                blockContent={blocks.licence}
                bottomBorder={true}
            />
            <LargeImage imageUrl={imageUrls.img2} />
            <HorizontalBlockAdaptive blockContent={blocks.service} />
            <HorizontalBlockAdaptive blockContent={blocks.communications} />
        </div>
    );
};

export default ForBusinessPage;
