import React from "react";
import { useLocation } from "react-router";
import ContactUsMobile from "./contactUsMobile";
import SiteMapMobile from "./siteMapMobile";

const FooterMobile = () => {
    const { pathname } = useLocation();
    return (
        <div>
            {pathname !== `/documentation-avl-3.0/` && (
                <footer>
                    {pathname !== "/cookiesPage" &&
                        pathname !== "/useConditionsPage" &&
                        pathname !== "/personalDataAgreementPage" &&
                        pathname !== "/confidentionalPoliticPage" &&
                        pathname !== "/publicOfertaPage" &&
                        pathname !== "/learningPage" &&
                        pathname !== "/analyticsPage" &&
                        pathname !== "/tariffsPage" &&
                        pathname !== "/contactUsPage" &&
                        pathname !== "/contactsPage" &&
                        pathname !== "/publicationsPage" && <ContactUsMobile />}
                    <SiteMapMobile />
                </footer>
            )}
        </div>
    );
};

export default FooterMobile;
