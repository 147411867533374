import React, { useEffect, useState } from "react";
import { useLanguage } from "../hooks/useLang";
import { captchaNum, random } from "../utils/captcha";

const CaptchaNum = ({ setAnswer }) => {
    const [correctAnswer, setCorrectAnswer] = useState();
    const [question, setQuestion] = useState();
    const [answers, setAnswers] = useState();
    const [wrongAnserCounter, setCounter] = useState(0);
    const [hideTimer, setHideTimer] = useState(true);
    const { currentLang, interfaceNames } = useLanguage();

    const taskStyle = () => {
        return {
            fontStyle: "italic",
            textDecoration: "line-through",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 220 + "px",
            height: 60 + "px",
            fontSize: 24 + "px"
        };
    };

    const timer = () => {
        const nameEl = document.querySelector(".captcha-timer");
        let time = 11;
        const countdown = setInterval(() => {
            if (nameEl && time > 0) {
                time -= 1;
                // console.log(time);
                nameEl.innerHTML = time;
            } else if (time < 1) {
                // console.log(time);
                clearInterval(countdown);
                setHideTimer(true);
                setCounter(0);
                prepareCaptcha();
            }
        }, 1000);
    };
    const prepareCaptcha = () => {
        const { task, result } = captchaNum(currentLang);
        let answerArray = [];
        for (let i = 0; i < 6; i++) {
            let randomNum = random(1, 30);
            for (let j = 0; j < answerArray.length; j++) {
                if (randomNum === answerArray[j]) {
                    randomNum = random(1, 30);
                    j = 0;
                }
            }
            answerArray[i] =
                randomNum !== correctAnswer ? randomNum : random(1, 30);
        }
        answerArray[random(0, 6)] = result;
        setAnswers(answerArray);
        setCorrectAnswer(result);
        setQuestion(task);
        // keyboardPageName(task, ".captcha-task");
        // console.log(task, result);
    };

    useEffect(() => {
        prepareCaptcha();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        prepareCaptcha();
        // eslint-disable-next-line
    }, [currentLang]);
    const handleClick = (answer) => {
        if (answer === correctAnswer) {
            setAnswer(true);
        } else {
            setCounter(wrongAnserCounter + 1);
            prepareCaptcha();
        }
    };
    useEffect(() => {
        // console.log("wrongAnserCounter", wrongAnserCounter);
        if (wrongAnserCounter > 2) {
            setHideTimer(false);
        }
    }, [wrongAnserCounter]);
    useEffect(() => {
        if (!hideTimer) {
            // console.log("timer is work");

            timer();
        }
        // eslint-disable-next-line
    }, [hideTimer]);
    return (
        <div className="captcha-back mt-10">
            <>
                <div className="captcha-description">
                    {interfaceNames.captcha}
                </div>
                <div style={taskStyle()}>{question}</div>
                <div className="captcha-answers-back ">
                    {answers &&
                        answers.map((answer, index) => (
                            <div
                                key={index}
                                className={`captcha-answer-back cp mr-20 ${
                                    index === 0 || index === 3 ? "ml-15" : ""
                                }`}
                                onClick={() => handleClick(answer)}
                            >
                                {answer}
                            </div>
                        ))}
                </div>
            </>
            {!hideTimer && <div className="captcha-timer">{}</div>}
        </div>
    );
};

export default CaptchaNum;
