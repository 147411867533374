import React from "react";

const LargeImageMobile = ({
    imageUrl,
    topBorder = false,
    bottomBorder = false
}) => {
    return (
        <>
            <img
                src={imageUrl}
                alt="error"
                className={`imageLargeBlock ${
                    topBorder ? "pt-20 tborder" : "mt-20"
                } ${bottomBorder ? "pb-20 bborder" : "mb-20"}`}
            />
        </>
    );
};

export default LargeImageMobile;
