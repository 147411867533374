import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// const random = () => Math.floor(Math.random() * 1000);

const CardsAdaptiveBlockTablet = ({
    blockContent,
    twoCardsInLine = false,
    bottomBorderBlock = false,
    topBorderbBlock = false,
    bottomBorderCard = false,
    topBorderCard = false,
    paddingTopBlock = false,
    marginTopNum = false,
    marginTopName = false,
    marginTopText = false,
    marginTopImg = false,
    marginTopLink = true,
    customHeightUseConditionsPage = false,
    customHeightScannerPageTablet = false,
    customMarginBottom = false,
    customHeightCookiePageTablet = false,
    customWidthCookiesPage = false,
    customMarginCookiepage = false,
    customHeightPlatformCards = false,
    borderImg = false,
    titleScannerColumn = false,
    heightMainPageForCardtext = false
}) => {
    // const [tempClass, setTempClass] = useState();
    // const [nameHeight, setNameHeight] = useState();
    // // const [cardHeight, setCardHeight] = useState();
    // const [textHeight, setTextHeight] = useState();
    // useEffect(() => {
    //     setTempClass(`cardsWithArrowsAdaptive${random()}`);
    // }, []);
    // const getHeightName = () => {
    //     let maxHeightName = Object.values(
    //         document.querySelectorAll(`.${tempClass}name`)
    //     ).map((el) => el.offsetHeight);
    //     setNameHeight(Math.max.apply(null, maxHeightName));
    // };
    // const getHeightText = () => {
    //     let maxHeightText = Object.values(
    //         document.querySelectorAll(`.${tempClass}text`)
    //     ).map((el) => el.offsetHeight);
    //     setTextHeight(Math.max.apply(null, maxHeightText));
    // };
    // const setHeight = () => {
    //     const maxHeightNameSet = Object.values(
    //         document.querySelectorAll(`.${tempClass}name`)
    //     );
    //     maxHeightNameSet.map((el) => (el.style.height = nameHeight + "px"));
    //     const maxHeightTextSet = Object.values(
    //         document.querySelectorAll(`.${tempClass}text`)
    //     );
    //     maxHeightTextSet.map((el) => (el.style.height = textHeight + "px"));
    // };

    // setTimeout(() => {
    //     getHeightName();
    //     getHeightText();
    //     setHeight();
    // }, 200);

    const tempClass = "temp";
    const wrapperRef = useRef(null);
    useEffect(() => {
        if (!wrapperRef?.current) {
            return;
        }
        setTimeout(() => {
            const maxHeightName = Object.values(
                wrapperRef.current.querySelectorAll(`.${tempClass}name`)
            ).map((el) => el.offsetHeight);
            const nameHeight = Math.max.apply(null, maxHeightName);

            const maxHeightText = Object.values(
                wrapperRef.current.querySelectorAll(`.${tempClass}text`)
            ).map((el) => el.offsetHeight);
            const textHeight = Math.max.apply(null, maxHeightText);

            Object.values(
                wrapperRef.current.querySelectorAll(`.${tempClass}name`)
            ).forEach((el) => (el.style.height = nameHeight + "px"));

            Object.values(
                wrapperRef.current.querySelectorAll(`.${tempClass}text`)
            ).forEach((el) => (el.style.height = textHeight + "px"));
        }, 200);
    }, [wrapperRef]);

    // const tempClass=`cardsAdaptive${obj.current}`
    // console.log("Lucky",tempClass)
    // const [tempClass, setTempClass] = useState();
    // const [nameHeight, setNameHeight] = useState();
    // const [cardHeight, setCardHeight] = useState();
    // const [textHeight, setTextHeight] = useState();
    // useEffect(() => {
    //     setTempClass(`cardsAdaptive${random()}`);
    //     console.log("-------------------------------");
    // }, []);
    // console.log("Lucky==============================", tempClass);

    return (
        <>
            <div
                ref={wrapperRef}
                className={`cardsAdaptiveBlockTablet mb-40 ${
                    bottomBorderBlock ? "bborder" : ""
                }
                    ${topBorderbBlock ? "tborder" : ""}
                    ${paddingTopBlock ? "pt-40" : ""}
                    ${customMarginBottom && "mb-80-important"}
                    ${customWidthCookiesPage && "w-925 ml-83"}
                    `}
            >
                {blockContent.map((card, index) => (
                    <div
                        className={`cardsAdaptiveBlockTablet-card
                         ${bottomBorderCard ? "bborder" : ""}
                         ${topBorderCard ? "tborder" : ""}
                         ${
                             !twoCardsInLine && topBorderCard && index > 2
                                 ? "mt-40" // mt-60
                                 : ""
                         }
                         ${
                             twoCardsInLine && topBorderCard && index > 1
                                 ? "mt-40" //mt-60
                                 : ""
                         }
                          ${
                              customMarginCookiepage &&
                              twoCardsInLine &&
                              index % 2 === 0 &&
                              "ml-important"
                          }

                        ${tempClass}card`}
                        style={
                            twoCardsInLine
                                ? index % 2 === 0
                                    ? {
                                          width: 40 + "%",
                                          marginLeft: 135 + "px"
                                      }
                                    : { width: 40 + "%" }
                                : { width: 27 + "%" }
                        }
                        key={Math.random()}
                    >
                        {card.num && (
                            <div
                                // className={`sixCardsAdaptive-card-num fs-64 ${
                                //     !topBorder && index < 3 ? " " : "mt-60"
                                // } ${bottomBorder ? "mt-60" : ""}`}
                                className={`cardsAdaptiveBlockTablet-card-num fs-48 ${
                                    marginTopNum && "mt-40"
                                } `}
                            >
                                0{card.num}
                            </div>
                        )}
                        {card.name && (
                            <div
                                className={`cardsAdaptiveBlockTablet-card-name  fs-28
                                ${marginTopName ? "mt-40" : ""}
                                ${
                                    titleScannerColumn
                                        ? "titleScanner-column-name"
                                        : ""
                                }

                                ${tempClass}name`}
                            >
                                {card.name}
                            </div>
                        )}
                        {card.text && (
                            <div
                                className={`cardsAdaptiveBlockTablet-card-text
                                    ${
                                        customHeightUseConditionsPage &&
                                        index < 3
                                            ? "h-auto"
                                            : ""
                                    }
                                    ${
                                        customHeightUseConditionsPage &&
                                        index >= 3 &&
                                        index < 6
                                            ? "h-auto"
                                            : ""
                                    }
                                    ${
                                        customHeightUseConditionsPage &&
                                        index >= 6 &&
                                        index <= blockContent.length - 1
                                            ? "h-auto"
                                            : ""
                                    }
                                    ${
                                        customHeightScannerPageTablet &&
                                        index < 3
                                            ? "h-auto"
                                            : ""
                                    }
                                    ${
                                        customHeightCookiePageTablet &&
                                        index < 2
                                            ? "h-auto"
                                            : ""
                                    }
                                    ${
                                        customHeightCookiePageTablet &&
                                        index >= 2 &&
                                        index <= blockContent.length - 1
                                            ? "h-auto"
                                            : ""
                                    }
                                    ${
                                        customHeightPlatformCards && index < 3
                                            ? "h-auto"
                                            : ""
                                    }
                                    ${
                                        customHeightPlatformCards && index >= 3
                                            ? "h-auto"
                                            : ""
                                    }
                                    ${
                                        heightMainPageForCardtext
                                            ? "height-forCardText"
                                            : ""
                                    }
                                ${marginTopText && " mt-40 "} ${
                                    card.imgLink ? "fs-14" : "fs-14 " /*fs-16 */
                                }    ${tempClass}text`}
                                // style={{ height: textHeight + "px" }}
                            >
                                {card.text}
                            </div>
                        )}
                        {card.linkName && (
                            <Link
                                to={
                                    card.externalLink
                                        ? {
                                              pathname: card.externalLink
                                          }
                                        : card.link
                                }
                                target={card.externalLink && "_blank"}
                                className={`cardsAdaptiveBlock-textBlock-link fs-32  ${
                                    ////////найти
                                    marginTopLink && "mt-60"
                                }`}
                            >
                                <span className="cardsAdaptiveBlock-textBlock-link-name cp">
                                    {card.linkName}
                                </span>
                                <div className="cardsAdaptiveBlock-textBlock-link-arrow imgBack cp"></div>
                            </Link>
                        )}
                        {card.imgLink && (
                            <img
                                src={card.imgLink}
                                alt="error"
                                className={`cardsAdaptiveBlockTablet-img ${
                                    marginTopImg && " mt-40"
                                }`}
                            />
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default CardsAdaptiveBlockTablet;
