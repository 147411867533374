import React, { useEffect, useState } from "react";

import DropdownMenuTablet from "./tablet/dropdownMenuTablet";
import FooterHorizontalBlockTablet from "./tablet/footerTablet/footerHorizontalBlockTablet";
import MainPageTablet from "./tablet/pagesTablet/mainPageTablet"; //
import MenuBarTablet from "./tablet/menuBarTablet"; //
import { setToDarkTheme, setToLightTheme } from "./utils/darkTheme";
import PlatformPageTablet from "./tablet/pageBlocksTablet/pages/platformPageTablet";
import SituationCenterPageTablet from "./tablet/pageBlocksTablet/pages/situationCenterPageTablet";
import CommunicationsPageTablet from "./tablet/pageBlocksTablet/pages/communicationsPageTablet";
import AnalyticsPageTablet from "./tablet/pageBlocksTablet/pages/analyticsPageTablet";
import SafetyPageTablet from "./tablet/pageBlocksTablet/pages/safetyPageTablet";
import LearningPageTablet from "./tablet/pageBlocksTablet/pages/learningPageTablet";
import AudiencePageTablet from "./tablet/pageBlocksTablet/pages/audiencePageTablet";
import ForBusinessPageTablet from "./tablet/pageBlocksTablet/pages/forBusinessPageTablet";
import ScannerPageTablet from "./tablet/pageBlocksTablet/pages/scannerPageTablet";
import AboutUsPageTablet from "./tablet/pageBlocksTablet/pages/aboutUsPageTablet";
// import { Route, Switch, Redirect } from "react-router-dom";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

// import { Link } from "react-router-dom";
import SubscribePageTablet from "./tablet/pageBlocksTablet/pages/subscribePageTablet";
// import DemonstrationPageTablet from "./tablet/pageBlocksTablet/pages/demonstrationPageTablet";
import ContactsPageTablet from "./tablet/pageBlocksTablet/pages/contactsPageTablet";
import ContactUsPageTablet from "./tablet/pagesTablet/ContactUsPageTablet";
import CookiesPageTablet from "./tablet/pageBlocksTablet/pages/cookiesPageTablet";
import UseConditionsPageTablet from "./tablet/pageBlocksTablet/pages/useConditionsPageTablet";
import PersonalDataAgreementPageTablet from "./tablet/pageBlocksTablet/pages/personalDataAgreementPageTablet";
import ConfidentialPoliticPageTablet from "./tablet/pageBlocksTablet/pages/confidentialPoliticPageTablet";
import PublicOfertaPageTablet from "./tablet/pageBlocksTablet/pages/publicOfertaPageTablet";
import TariffsPageTablet from "./tablet/pageBlocksTablet/pages/tariffsPageTablet";
// import QuestionPageTablet from "./tablet/pageBlocksTablet/pages/questionPageTablet";
// import RequestPage from "./pages/requestPage";
import PublicationsPageTablet from "./tablet/pageBlocksTablet/pages/publicationsPageTablet";
import PublicActivityPageTablet from "./tablet/pageBlocksTablet/pages/publicActivityPageTablet";
import { useLanguage } from "./hooks/useLang";
import DocsTablet from "./tablet/pageBlocksTablet/pages/docsTablet";
import { getCookie, setCookie } from "./utils/cookieHandler";
import CookiesPopupTablet from "./tablet/pageBlocksTablet/pages/cookiesPopupTablet";
const allowLocs = [
    `/documentation-avl-3.0/`,
    "/platformPage",
    "/questionPage",
    "/requestPage",
    "/forBusinessPage",
    "/safetyPage",
    "/publicationsPage",
    "/publicActivityPage",
    "/aboutUsPage",
    "/subscribePage",
    "/learningPage",
    "/situationCenterPage",
    "/audiencePage",
    "/scannerPage",
    "/communicationsPage",
    "/analyticsPage",
    // "/demonstrationPage",
    "/useConditionsPage",
    "/cookiesPage",
    "/publicOfertaPage",
    "/personalDataAgreementPage",
    "/confidentionalPoliticPage",
    "/tariffsPage",
    "/contactsPage",
    "/contactsPageMobile", //   contactsPageTablet
    "/"
];

const AllBackTablet = () => {
    const [cookie, setHideCookie] = useState(false);
    useEffect(() => {
        const isCookie = localStorage.getItem("isCookie");
        if (isCookie) {
            setHideCookie(true);
        }
        // console.log("isCookie", isCookie);
    }, []);
    const handleCookie = () => {
        setHideCookie(true);
        localStorage.setItem("isCookie", true);
    };
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
    const [darkTheme, setDarkTheme] = useState();
    const [language, setLanguage] = useState();

    const { handleCurrentLang } = useLanguage();
    const currentLocation = useLocation();
    const history = useHistory();

    useEffect(() => {
        const returnFunc = () => {
            history.push("/");
            history.goForward();
        };
        // console.log("currentLocation", currentLocation.pathname);

        allowLocs.includes(currentLocation.pathname)
            ? console.log("")
            : returnFunc();
        // eslint-disable-next-line
    }, [currentLocation]);

    const handeDropDownMenu = (state) => {
        if (typeof state === "undefined") {
            setDropdownMenuOpen(!dropdownMenuOpen);
        } else {
            setDropdownMenuOpen(state);
        }
    };

    const handleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLanguage = () => {
        const lang = localStorage.getItem("lang");
        // console.log("lang", lang);
        if (lang === null || lang === "ru") {
            localStorage.setItem("lang", "en");
            setLanguage("en");
            handleCurrentLang("en");
        }
        if (lang === "en") {
            localStorage.setItem("lang", "ru");
            setLanguage("ru");
            handleCurrentLang("ru");
        }
    };
    useEffect(() => {
        handleCurrentLang(language);
        // eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        // console.log("darkTheme", darkTheme);
        if (darkTheme !== undefined) {
            darkTheme ? setToDarkTheme() : setToLightTheme();
        }
    }, [darkTheme]);
    useEffect(() => {
        const currentTheme = getCookie(`avl4site_theme`);
        console.log("currentTheme", currentTheme);
        if (!currentTheme) {
            setCookie("avl4site_theme", "light", {
                expires: new Date("2025-01-01 00:00:01"),
                domain: "avl.team",
                samesite: "lax"
            });
        }
        if (currentTheme === "dark") {
            setToDarkTheme();
        }
        if (currentTheme === "light") {
            setToLightTheme();
        }
    }, []);
    // useEffect(() => {
    //     console.log("dropdownMenuOpen in AllBack", dropdownMenuOpen);
    // }, [dropdownMenuOpen]);
    // console.log(document.documentElement.clientWidth);
    return (
        <div
            className={`allBackTablet ${language ? " " : " "}`}
            // style={{ transform: "scale(0.75)" }}
        >
            <MenuBarTablet
                handeDropDownMenu={handeDropDownMenu}
                dropdownMenuOpen={dropdownMenuOpen}
                handleTheme={handleTheme}
            />
            <DropdownMenuTablet
                dropdownMenuOpen={dropdownMenuOpen}
                handeDropDownMenu={handeDropDownMenu}
            />

            {/* <FooterHorizontalBlockTablet
              handleTheme={handleTheme}
            /> */}
            <Switch>
                <Route
                    exact
                    strict
                    path={`/documentation-avl-3.0/`}
                    component={DocsTablet}
                />
                <Route
                    path="/platformPage"
                    component={PlatformPageTablet}
                />

                {/* <Route
                    path="/questionPage"
                    component={QuestionPageTablet}
                /> */}
                {/* <Route
                    path="/requestPage"
                    component={RequestPage}
                /> */}
                <Route
                    path="/forBusinessPage"
                    component={ForBusinessPageTablet}
                />

                <Route
                    path="/contactsPageMobile"
                    component={ContactUsPageTablet} // добавил route
                />
                <Route
                    path="/safetyPage"
                    component={SafetyPageTablet}
                />
                <Route
                    path="/publicationsPage"
                    component={PublicationsPageTablet}
                />
                <Route
                    path="/publicActivityPage"
                    component={PublicActivityPageTablet}
                />
                <Route
                    path="/aboutUsPage"
                    component={AboutUsPageTablet}
                />
                <Route
                    path="/subscribePage"
                    component={SubscribePageTablet}
                />
                <Route
                    path="/learningPage"
                    component={LearningPageTablet}
                />
                <Route
                    path="/situationCenterPage"
                    component={SituationCenterPageTablet}
                />
                <Route
                    path="/audiencePage"
                    component={AudiencePageTablet}
                />
                <Route
                    path="/scannerPage"
                    component={ScannerPageTablet}
                />
                <Route
                    path="/communicationsPage"
                    component={CommunicationsPageTablet}
                />
                <Route
                    path="/analyticsPage"
                    component={AnalyticsPageTablet}
                />
                {/* <Route
                    path="/demonstrationPage"
                    component={DemonstrationPageTablet}
                /> */}
                <Route
                    path="/useConditionsPage"
                    component={UseConditionsPageTablet}
                />
                <Route
                    path="/cookiesPage"
                    component={CookiesPageTablet}
                />
                <Route
                    path="/publicOfertaPage"
                    component={PublicOfertaPageTablet}
                />
                <Route
                    path="/personalDataAgreementPage"
                    component={PersonalDataAgreementPageTablet}
                />
                <Route
                    path="/confidentionalPoliticPage"
                    component={ConfidentialPoliticPageTablet}
                />
                <Route
                    path="/tariffsPage"
                    component={TariffsPageTablet}
                />
                <Route path="/contactsPage">
                    <ContactsPageTablet darkTheme={darkTheme} />
                </Route>
                <Route
                    exact
                    strict
                    path="/"
                    component={MainPageTablet}
                />
                {/* <Redirect to="/" /> */}
                {/* <Route
                    path="/*"
                    component={<Redirect to="/contactsPage" />}
                /> */}
                {/* <Redirect path="/">
                    <MainPage />
                </Redirect> */}
                {/* <SafetyPage /> */}
            </Switch>
            <FooterHorizontalBlockTablet
                handleTheme={handleTheme}
                handleLanguage={handleLanguage}
            />

            {!cookie && <CookiesPopupTablet handleCookie={handleCookie} />}
        </div>
    );
};

export default AllBackTablet;
