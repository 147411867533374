import React from "react";
import { useLanguage } from "../hooks/useLang";

import CardsAdaptiveBlock from "../pageBlocks/cardsBlocks/cardsAdaptiveBlock";
import HorizontalBlockAdaptive from "../pageBlocks/horizontalBlockAdaptive";
import LargeImage from "../pageBlocks/largeImage";
import PageHead from "../pageBlocks/pageHead/pageHead";
// import content from "../textContent";

const CookiesPage = () => {
    const { content } = useLanguage();
    const pageName = content.cookies.pageName;
    const subMenuList = content.cookies.subMenuList;
    const imageUrls = content.cookies.imageUrls;
    const blocks = content.cookies.blocks;
    const cookiesBlocks = content.cookies.blocks.cookies;
    const cards = content.cookies.cards;
    return (
        <div className="mainPage">
            <PageHead
                pageName={pageName}
                subMenuList={subMenuList}
            />
            <LargeImage imageUrl={imageUrls.img1} />
            <HorizontalBlockAdaptive blockContent={blocks.politics} />
            <HorizontalBlockAdaptive blockContent={blocks.whatItIs} />
            <HorizontalBlockAdaptive blockContent={blocks.whatWeUse} />
            <HorizontalBlockAdaptive blockContent={blocks.whatTypes} />
            <CardsAdaptiveBlock
                blockContent={cards.block1.cards}
                twoCardsInLine={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                topBorderCard={true}
                paddingBottomBlock={true}
            />
            <HorizontalBlockAdaptive blockContent={cookiesBlocks.analytics} />
            <HorizontalBlockAdaptive blockContent={cookiesBlocks.functional} />
            <HorizontalBlockAdaptive blockContent={cookiesBlocks.necessary} />
            <HorizontalBlockAdaptive blockContent={cookiesBlocks.others} />
            <HorizontalBlockAdaptive blockContent={cookiesBlocks.performance} />
            <HorizontalBlockAdaptive blockContent={cookiesBlocks.policy} />

            <HorizontalBlockAdaptive blockContent={blocks.canChange} />
        </div>
    );
};

export default CookiesPage;
