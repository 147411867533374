import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { randomId } from "../../../utils/randomId";
import { Link } from "react-router-dom";

const CardsSwipeMobile = ({
    blockContent,
    arrowsDirection = "right",
    bottomBorderBlock = false,
    topBorderbBlock = false,
    customMinTextHeight = "",
    customMinNameHeight = "",
    customNameFontSize = "",
    externalLink = false,
    topBorderCard = true
}) => {
    const [tempClass, setTempClass] = useState();
    const [nameHeight, setNameHeight] = useState();
    const [cardHeight, setCardHeight] = useState();
    const [textHeight, setTextHeight] = useState();
    const [swipePosition, setPosition] = useState(38);
    const [pointPosition, setPoint] = useState(0);
    const [curSlide, setSlide] = useState(1);
    const handlers = useSwipeable({
        onSwipedLeft: (event) => {
            if (curSlide < blockContent.cards.length) {
                swipeRight();

                setSlide((prev) => prev + 1);
                setPoint((prev) => prev + 15);
            }
            if (curSlide === blockContent.cards.length) {
                setPosition(38);
                setPoint(0);
                setSlide(1);
            }
        },
        onSwipedRight: (event) => {
            if (curSlide !== 1) {
                swipeLeft();
                setSlide((prev) => prev - 1);
                setPoint((prev) => prev - 15);
            }
        }
    });
    const cardsBack = useRef(null);
    const cardItem = useRef();
    const cardName = useRef();
    const cardText = useRef();
    const textRef = cardText.current;
    const nameRef = cardName.current;

    const swiper = cardsBack.current;
    const currentCard = cardItem.current;
    useEffect(() => {
        if (swiper) {
            setTimeout(() => {
                setCardHeight(swiper.getBoundingClientRect().height);
            }, 200);
        }
    }, [swiper]);
    const swipeRight = () => {
        setPosition(
            (prev) => prev - currentCard.getBoundingClientRect().width - 96
        );
    };
    const swipeLeft = () => {
        setPosition(
            (prev) => prev + currentCard.getBoundingClientRect().width + 96
        );
    };
    useEffect(() => {
        setTempClass(`cardsWithArrowsAdaptive${randomId()}`);
    }, []);
    const getHeightName = () => {
        if (blockContent.cards.length) {
            // console.log("getHeightName is working");
            let maxHeightName = Object.values(
                document.querySelectorAll(`.${tempClass}name`)
            ).map((el) => el.offsetHeight);
            // console.log("maxHeightName in getHeight", maxHeightName);
            // console.log(
            //     "maxHeightName apply in getHeight",
            //     Math.max.apply(null, maxHeightName)
            // );

            setNameHeight(Math.max.apply(null, maxHeightName));
        }
    };
    const getHeightText = () => {
        if (blockContent.cards.length) {
            let maxHeightText = Object.values(
                document.querySelectorAll(`.${tempClass}text`)
            ).map((el) => el.offsetHeight);
            // console.log("maxHeightText in getHeight", maxHeightText);
            // console.log(
            //     "maxHeightText apply in getHeight",
            //     Math.max.apply(null, maxHeightText)
            // );

            setTextHeight(Math.max.apply(null, maxHeightText) + 25);
        }
    };
    // useEffect(() => {
    //     console.log("Name Height State", nameHeight);
    //     console.log("Text Height State", textHeight);
    // }, [textHeight, nameHeight]);
    // const setNameHeight = () => {
    //     const maxHeightNameSet = Object.values(
    //         document.querySelectorAll(`.${tempClass}name`)
    //     );
    //     maxHeightNameSet.map((el) => (el.style.height = nameHeight + "px"));
    //     const maxHeightText = Object.values(
    //         document.querySelectorAll(`.${tempClass}text`)
    //     );
    //     maxHeightText.map((el) => (el.style.height = textHeight + 20 + "px"));
    //     setTextHeight(Math.max.apply(null, maxHeightText));
    // };
    // const setTextHeight = () => {
    //     const maxHeightNameSet = Object.values(
    //         document.querySelectorAll(`.${tempClass}name`)
    //     );
    //     maxHeightNameSet.map((el) => (el.style.height = nameHeight + "px"));
    //     const maxHeightText = Object.values(
    //         document.querySelectorAll(`.${tempClass}text`)
    //     );
    //     maxHeightText.map((el) => (el.style.height = textHeight + 20 + "px"));
    //     setTextHeight(Math.max.apply(null, maxHeightText));
    // };

    useEffect(() => {
        if (textRef) {
            getHeightText();
        }
        if (nameRef) {
            getHeightName();
        }
        // if (blockContent.cards[0].imgLink || blockContent.cards[0]) {
        // getHeightName();
        // getHeightText();
        // setHeight();
        // console.log(
        //     "maxHeightText",
        //     Object.values(
        //         document.querySelectorAll(`.${tempClass}name`)
        //     ).map((el) => el.offsetHeight)
        // );
        // getHeightName();
        // getHeightText();
        // setHeight();
        // setTimeout(() => {
        //     getHeightName();
        //     getHeightText();
        //     setHeight();
        // }, 300);
        // }

        // eslint-disable-next-line
    }, [textRef, nameRef]);

    const arrowDirection = (direction) => {
        if (direction === "right") {
            return "cardsSwipeMobile-arrows-rightDirection imgBack";
        }
    };
    const blockProps = (direction, index) => {
        if (direction === "right") {
            return index !== blockContent.cards.length - 1
                ? "cardsSwipeMobile-cards-card-back mr-48 ml-48"
                : "cardsSwipeMobile-cards-card-back ml-48 ";
        }
    };
    const imgProps = (direction) => {
        if (direction === "right") {
            return "cardsSwipeMobile-image  mb-40 ";
        }
    };
    return (
        <div
            className={`cardsSwipeMobile mb-2 ${
                topBorderbBlock ? " tborder" : ""
            } `}
            style={
                cardHeight && {
                    height: `${cardHeight}px`
                }
            }
        >
            <div
                className="cardsSwipeMobile-arrows-back pt-40 pb-40  "
                style={{ height: `${cardHeight}px` }}
            >
                <div className="cardsSwipeMobile-arrows-border-back rborder pr-20">
                    <div className="cardsWithArrowsAdaptive-arrows">
                        <div className={arrowDirection(arrowsDirection)}></div>
                        <div
                            className={`${arrowDirection(
                                arrowsDirection
                            )} mt-15`}
                        ></div>
                        <div
                            className={`${arrowDirection(
                                arrowsDirection
                            )} mt-15`}
                        ></div>
                    </div>
                    <div className="cardsWithArrowsAdaptive-arrows">
                        <div className={arrowDirection(arrowsDirection)}></div>
                        <div
                            className={`${arrowDirection(
                                arrowsDirection
                            )} mt-15`}
                        ></div>
                        <div
                            className={`${arrowDirection(
                                arrowsDirection
                            )} mt-15`}
                        ></div>
                    </div>
                </div>
            </div>
            <div
                {...handlers}
                className="cardsSwipeMobile-cards-back-wrapper "
                style={
                    cardHeight && {
                        height: `${cardHeight}px`
                    }
                }
            >
                <div
                    ref={cardsBack}
                    className={`cardsSwipeMobile-cards-back pt-40 pb-40 `}
                    style={{ left: swipePosition }}
                >
                    {blockContent.cards.length &&
                        blockContent.cards.map((card, index) => (
                            <div
                                ref={cardItem}
                                className={blockProps(arrowsDirection, index)}
                                key={index}
                            >
                                <div
                                    className={`cardsSwipeMobile-cards-card ${
                                        topBorderCard ? "tborder" : ""
                                    } `}
                                >
                                    {card.num && (
                                        <div className="cardsWithArrowsAdaptive-cards-card-num fs-48 mt-40">
                                            0{card.num}
                                        </div>
                                    )}
                                    {card.name &&
                                    index === blockContent.cards.length - 1 ? (
                                        <div
                                            ref={cardName}
                                            className={`cardsWithArrowsAdaptive-cards-card-name fs-${
                                                customNameFontSize
                                                    ? customNameFontSize
                                                    : "40"
                                            } mt-40 ${tempClass}name`}
                                            style={
                                                customMinNameHeight
                                                    ? {
                                                          height: `${customMinNameHeight}px`
                                                      }
                                                    : {
                                                          height: `${nameHeight}px`
                                                      }
                                            }
                                        >
                                            {card.name}
                                        </div>
                                    ) : card.name &&
                                      index < blockContent.cards.length - 1 ? (
                                        <div
                                            className={`cardsWithArrowsAdaptive-cards-card-name fs-${
                                                customNameFontSize
                                                    ? customNameFontSize
                                                    : "40"
                                            } mt-40 ${tempClass}name`}
                                            style={
                                                customMinNameHeight
                                                    ? {
                                                          height: `${customMinNameHeight}px`
                                                      }
                                                    : {
                                                          height: `${nameHeight}px`
                                                      }
                                            }
                                        >
                                            {card.name}
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    {card.text &&
                                    index === blockContent.cards.length - 1 ? (
                                        <div
                                            ref={cardText}
                                            className={`cardsWithArrowsAdaptive-cards-card-text fs-14 mt-40 ${tempClass}text `}
                                            style={
                                                customMinTextHeight
                                                    ? {
                                                          height: `${customMinTextHeight}px`
                                                      }
                                                    : {
                                                          height: `${textHeight}px`
                                                      }
                                            }
                                        >
                                            {card.text}
                                        </div>
                                    ) : card.text &&
                                      index < blockContent.cards.length - 1 ? (
                                        <div
                                            className={`cardsWithArrowsAdaptive-cards-card-text fs-14 mt-40 ${tempClass}text `}
                                            style={
                                                customMinTextHeight
                                                    ? {
                                                          height: `${customMinTextHeight}px`
                                                      }
                                                    : {
                                                          height: `${textHeight}px`
                                                      }
                                            }
                                        >
                                            {card.text}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {card.imgLink && (
                                        <img
                                            src={card.imgLink}
                                            alt="Loading Error"
                                            className={imgProps(
                                                arrowsDirection
                                            )}
                                            // align="left"
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
                <div className="cardsSwipeMobile-cardnum-indicators ">
                    <div className="cardsSwipeMobile-cardnum-indicators-items-back">
                        {blockContent.cards.length &&
                            blockContent.cards.map((item, index) => (
                                <div
                                    className="cardsSwipeMobile-cardnum-indicators-item border"
                                    style={
                                        index !== 0
                                            ? {
                                                  marginLeft: `5px`
                                              }
                                            : {}
                                    }
                                    key={randomId()}
                                >
                                    <div
                                        className="cardsSwipeMobile-cardnum-indicators-backBlock"
                                        style={
                                            index === 0
                                                ? {
                                                      left: `${pointPosition}px`
                                                  }
                                                : {
                                                      left: `${
                                                          pointPosition -
                                                          index * 15
                                                      }px`
                                                  }
                                        }
                                    ></div>
                                </div>
                            ))}
                    </div>
                </div>
                {externalLink && (
                    <Link
                        to={{
                            pathname:
                                blockContent.cards[curSlide - 1].externalLink
                        }}
                        target="_blank"
                        className="cardsSwipeMobile-externalLink imgBack"
                    ></Link>
                )}
            </div>
            <div
                className={`cardsSwipeMobile-bottomBorderLine ${
                    bottomBorderBlock ? " bborder" : ""
                }`}
                style={{}}
            ></div>
        </div>
    );
};

export default CardsSwipeMobile;
