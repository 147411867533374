import React from "react";

const PageHeadSubMenu = ({ subMenuList, openedSubMenu, handleSubMenu }) => {
    // const handleHash = () => {
    //     window.location = window.location.origin;
    //     console.log(window.location);
    // };
    const moveToElement = (id) => {
        document.querySelector(`#${id}`).scrollIntoView();
    };
    return (
        <div
            className="pageHeadSubMenu open-close "
            style={
                !openedSubMenu
                    ? {
                          transform: "scaleY(0)"
                      }
                    : {
                          transform: "scaleY(1)",
                          zIndex: 300
                      }
            }
        >
            {subMenuList.map((item, index) => (
                <div
                    className={
                        index === 0
                            ? "pageHeadSubMenu-point fs-16  bborder"
                            : "pageHeadSubMenu-point fs-16 bborder"
                    }
                    key={index}
                >
                    <div
                        className="pageHeadSubMenu-point-name cp"
                        onClick={() => handleSubMenu()}
                    >
                        <span>
                            {item.num ? (
                                item.num + "."
                            ) : (
                                <div className="dropdownMenu-point-left-arrow imgBack"></div>
                            )}
                        </span>
                        <div
                            // href={`#${item.anchor}`}
                            onClick={() => moveToElement(item.anchor)}
                        >
                            {item.label}
                        </div>
                    </div>

                    {/* <hr className="pageHeadSubMenu-point-hr" /> */}
                </div>
            ))}
        </div>
    );
};

export default PageHeadSubMenu;
