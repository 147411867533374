import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import cn from "classnames"; // classnames
// const random = () => Math.floor(Math.random() * 1000);

const CardsWithArrowsAdaptiveTablet = ({
    blockContent,
    arrowsDirection,
    bottomBorderBlock = false,
    topBorderBlock = false,
    withImage,
    customHeightCommunicationsPageA = false,
    customHeightCommunicationsPageB = false,
    customHeightSituationCenterA = false,
    customHeightSituationCenterB = false,
    customHeightAnalitycsPage = false,
    customHeight = false,
    customWidth = true,
    bottomBorderCard = false,
    topBorderCard = false,
    marginTopNum = false,
    marginTopName = false,
    marginTopText = false,
    marginTopImg = false,
    marginTopLink = false,
    customLinkArrowMarginTop = false,
    publicHeightSizeForAdaptive = false,
    communicationsHeightSizeForName = false,
    tariffsHeightForColumn = false,
    safetyHeight = false,
    tariffsHeightText = false
}) => {
    // const [tempClass, setTempClass] = useState();
    // const [nameHeight, setNameHeight] = useState();
    // // const [cardHeight, setCardHeight] = useState();
    // const [textHeight, setTextHeight] = useState();
    // useEffect(() => {
    //     setTempClass(`cardsWithArrowsAdaptive${random()}`);
    // }, []);
    // const getHeightName = () => {
    //     let maxHeightName = Object.values(
    //         document.querySelectorAll(`.${tempClass}name`)
    //     ).map((el) => el.offsetHeight);
    //     setNameHeight(Math.max.apply(null, maxHeightName));
    // };
    // const getHeightText = () => {
    //     let maxHeightText = Object.values(
    //         document.querySelectorAll(`.${tempClass}text`)
    //     ).map((el) => el.offsetHeight);
    //     setTextHeight(Math.max.apply(null, maxHeightText));
    // };
    // const setHeight = () => {
    //     const maxHeightNameSet = Object.values(
    //         document.querySelectorAll(`.${tempClass}name`)
    //     );
    //     maxHeightNameSet.map((el) => (el.style.height = nameHeight + "px"));
    //     const maxHeightTextSet = Object.values(
    //         document.querySelectorAll(`.${tempClass}text`)
    //     );
    //     maxHeightTextSet.map((el) => (el.style.height = textHeight + "px"));
    // };

    // setTimeout(() => {
    //     getHeightName();
    //     getHeightText();
    //     setHeight();
    // }, 200);

    const tempClass = "temp";
    const wrapperRef = useRef(null);
    useEffect(() => {
        if (!wrapperRef?.current) {
            return;
        }
        setTimeout(() => {
            const maxHeightName = Object.values(
                wrapperRef.current.querySelectorAll(`.${tempClass}name`)
            ).map((el) => el.offsetHeight);
            const nameHeight = Math.max.apply(null, maxHeightName);

            const maxHeightText = Object.values(
                wrapperRef.current.querySelectorAll(`.${tempClass}text`)
            ).map((el) => el.offsetHeight);
            const textHeight = Math.max.apply(null, maxHeightText);

            Object.values(
                wrapperRef.current.querySelectorAll(`.${tempClass}name`)
            ).forEach((el) => (el.style.height = nameHeight + "px"));

            Object.values(
                wrapperRef.current.querySelectorAll(`.${tempClass}text`)
            ).forEach((el) => (el.style.height = textHeight + "px"));
        }, 200);
    }, [wrapperRef]);

    console.log("============ temp");
    const arrowDirection = (direction) => {
        if (direction === "right") {
            return "cardsWithArrowsAdaptiveTablet-arrows-rightDirection imgBack";
        } else if (direction === "left") {
            return "cardsWithArrowsAdaptiveTablet-arrows-leftDirection imgBack";
        }
    };
    const blockProps = (direction, index) => {
        if (direction === "right") {
            return index !== blockContent.cards.length - 1
                ? "cardsWithArrowsAdaptiveTablet-cards-card-back lborder" //ml-45 pl-85
                : withImage
                ? "rborder"
                : "cardsWithArrowsAdaptiveTablet-cards-card-back lborder"; //pl-85
        } else if (direction === "left") {
            return index !== blockContent.cards.length - 1
                ? "cardsWithArrowsAdaptiveTablet-cards-card-back  rborder"
                : "cardsWithArrowsAdaptiveTablet-cards-card-back  rborder"; //mr-45 pr-85
        }
    };
    const imgProps = (direction) => {
        if (direction === "right") {
            return "cardsWithArrowsAdaptiveTablet-image mt-40 mb-40 lborder pl-62 "; //  mt-60 mb-60 ml-85 pl-85 lborder
        } else if (direction === "left") {
            return "cardsWithArrowsAdaptiveTablet-image mt-40 mb-60 ml-45 mr-45 pr-62 rborder"; //pr-85
        }
    };
    return (
        <div
            ref={wrapperRef}
            className={`cardsWithArrowsAdaptiveTablet
             ${topBorderBlock ? "tborder" : ""}
             ${bottomBorderBlock ? " bborder" : ""} `}
        >
            {arrowsDirection === "right" && (
                <div className="cardsWithArrowsAdaptiveTablet-arrows-back pt-40 pb-40">
                    {blockContent.cards[0].name ? (
                        <>
                            <div className="cardsWithArrowsAdaptive-arrows">
                                <div
                                    className={arrowDirection(arrowsDirection)}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-15`}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-15`}
                                ></div>
                            </div>
                            <div className="cardsWithArrowsAdaptive-arrows">
                                <div
                                    className={arrowDirection(arrowsDirection)}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-15`}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-15`}
                                ></div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={arrowDirection(arrowsDirection)}
                            ></div>
                            <div
                                className={`${arrowDirection(
                                    arrowsDirection
                                )} mt-15`}
                            ></div>
                            <div
                                className={`${arrowDirection(
                                    arrowsDirection
                                )} mt-15`}
                            ></div>
                        </>
                    )}
                </div>
            )}
            <div
                className={`cardsWithArrowsAdaptiveTablet-cards-back  pt-40 pb-40 `}
            >
                {" "}
                {/* mw-347*/}
                {blockContent.cards.map((card, index) => (
                    <div // classnames
                        className={cn(
                            blockProps(arrowsDirection, index),
                            index === 0 &&
                                arrowsDirection === "right" &&
                                "ml-20",
                            index === blockContent.cards.length - 1 &&
                                arrowsDirection === "left" &&
                                "mr-20",
                            index === blockContent.cards.length - 1 &&
                                arrowsDirection === "right" &&
                                withImage &&
                                "mr-62"
                        )}
                        key={index}
                    >
                        <div // classnames
                            className={cn(
                                `cardsWithArrowsAdaptiveTablet-cards-card pl-62 pr-63`,
                                index === blockContent.cards.length - 1 &&
                                    arrowsDirection === "right" &&
                                    !withImage &&
                                    "pl-62",
                                index === 0 &&
                                    arrowsDirection === "left" &&
                                    "pl"
                            )}
                        >
                            {card.num && (
                                <div
                                    className={`cardsWithArrowsAdaptive-cards-card-num ${
                                        topBorderCard ? "tborder" : ""
                                    } fs-48 pt-40`}
                                >
                                    0{card.num}
                                </div>
                            )}
                            {card.name && (
                                <div
                                    className={`cardsWithArrowsAdaptive-cards-card-name fs-28 mt-40
                                     ${customWidth ? "w-190" : ""}
                                     ${
                                         publicHeightSizeForAdaptive
                                             ? "height-adaptive-column"
                                             : ""
                                     }
                                     ${
                                         communicationsHeightSizeForName
                                             ? "height-column-name"
                                             : ""
                                     }
                                     ${
                                         tariffsHeightForColumn
                                             ? "height-column-title"
                                             : ""
                                     }
                                       ${tempClass}name `}
                                >
                                    {card.name}
                                </div>
                            )}

                            <div
                                className={`cardsWithArrowsAdaptive-cards-card-text fs-14 mt-40

                                ${customWidth && "w-190"}

                                ${customHeight ? "h-auto" : ""}
                                ${customHeightAnalitycsPage ? "h-auto" : ""}
                                ${
                                    customHeightCommunicationsPageB
                                        ? "h-auto"
                                        : ""
                                }
                                ${
                                    customHeightCommunicationsPageA
                                        ? "h-auto"
                                        : ""
                                }
                                ${customHeightSituationCenterA ? "h-auto" : ""}
                                ${customHeightSituationCenterB ? "h-auto" : ""}
                                ${safetyHeight ? "h-auto" : ""}
                                ${tariffsHeightText ? "h-auto" : ""}

                                ${tempClass}text `}
                            >
                                {card.text}
                            </div>
                            {card.linkName && (
                                <Link
                                    to={{
                                        pathname: card.externalLink
                                            ? card.externalLink
                                            : "/"
                                    }}
                                    target="_blank"
                                    className="cardsWithArrowsAdaptive-textBlock-link  fs-32 cp "
                                >
                                    <span className="cardsWithArrowsAdaptive-textBlock-link-name fs-20 pt-10 ">
                                        {card.linkName}
                                    </span>
                                    <div
                                        className={`cardsWithArrowsAdaptiveTablet-textBlock-link-arrow imgBack ${
                                            customLinkArrowMarginTop && "mt-15"
                                        }`}
                                    ></div>
                                </Link>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {blockContent.imageUrl && (
                <img
                    src={blockContent.imageUrl}
                    alt="Loading Error"
                    className={imgProps(arrowsDirection)}
                    // align="left"
                />
            )}
            {arrowsDirection === "left" && (
                <div className="cardsWithArrowsAdaptiveTablet-arrows-back pt-40 pb-40">
                    {blockContent.cards[0].name ? (
                        <>
                            <div className="cardsWithArrowsAdaptive-arrows">
                                <div
                                    className={arrowDirection(arrowsDirection)}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-15`}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-15`}
                                ></div>
                            </div>
                            <div className="cardsWithArrowsAdaptive-arrows">
                                <div
                                    className={arrowDirection(arrowsDirection)}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-15`}
                                ></div>
                                <div
                                    className={`${arrowDirection(
                                        arrowsDirection
                                    )} mt-15`}
                                ></div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={arrowDirection(arrowsDirection)}
                            ></div>
                            <div
                                className={`${arrowDirection(
                                    arrowsDirection
                                )} mt-15`}
                            ></div>
                            <div
                                className={`${arrowDirection(
                                    arrowsDirection
                                )} mt-15`}
                            ></div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CardsWithArrowsAdaptiveTablet;
