import React, { useContext, useState, useEffect } from "react";
import interfaceRu from "../text/interface/interfaceRu";
import interfaceFr from "../text/interface/interfaceFr";
import interfaceEn from "../text/interface/interfaceEn";
import interfaceEs from "../text/interface/interfaceEs";
import contentRu from "../text/content/textContent";
import contentEn from "../text/content/textContentEn";
import contentFr from "../text/content/textContentFr";
import contentEs from "../text/content/textContentEs";
import agreementsTextRu from "../text/agreements/agreementsTextRu";
import agreementsTextEn from "../text/agreements/agreementsTextEn";
import agreementsTextFr from "../text/agreements/agreementsTextFr";
import agreementsTextEs from "../text/agreements/agreementsTextEs";

const languages = ["ru", "en", "fr", "es"];
const LangContext = React.createContext();

export const useLanguage = () => {
    return useContext(LangContext);
};

export const LanguageProvider = ({ children }) => {
    const [langList] = useState(languages);
    const [currentLang, setCurrentLang] = useState();
    const [content, setContent] = useState(
        currentLang === "en"
            ? contentEn
            : currentLang === "fr"
            ? contentFr
            : currentLang === "es"
            ? contentEs
            : contentRu
    );
    const [agreements, setAgreements] = useState(
        currentLang === "en"
            ? agreementsTextEn
            : currentLang === "fr"
            ? agreementsTextFr
            : currentLang === "es"
            ? agreementsTextEs
            : agreementsTextRu
    );

    const [interfaceNames, setInterfaceNames] = useState(
        currentLang === "en"
            ? interfaceEn
            : currentLang === "fr"
            ? interfaceFr
            : currentLang === "es"
            ? interfaceEs
            : interfaceRu
    );

    function handleCurrentLang(lang) {
        setCurrentLang(lang);
        // localStorage.setItem("lang", lang);
    }
    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang === "undefined" || !lang) {
            setCurrentLang("ru");
        } else {
            setCurrentLang(lang);
        }
        // console.log("lang", lang);
        // console.log(lang === "undefined");
        // const lang = localStorage.getItem("lang");
        // console.log("localStorage lang Bool", localStorage.getItem("lang"));
        // if (!langList.includes(localStorage.getItem("lang"))) {
        //     console.log("!!!undefined!!!!");
        //     console.log(
        //         "!!!undefined!!!!",
        //         langList.includes(localStorage.getItem("lang"))
        //     );
        //     setCurrentLang("ru");
        // }
        // else if (localStorage.getItem("lang") === undefined) {
        //     console.log("!!! undefined!!!!");
        // }
        // setCurrentLang(lang !== undefined ? lang : "ru");
    }, []);

    useEffect(() => {
        // localStorage.setItem("lang", currentLang);
        switch (currentLang) {
            // case "undefined":
            //     // localStorage.setItem("lang", "ru");
            //     setContent(contentRu);
            //     setAgreements(agreementsTextRu);
            //     break;

            case "ru":
                setContent(contentRu);
                setAgreements(agreementsTextRu);
                setInterfaceNames(interfaceRu);
                localStorage.setItem("lang", currentLang);
                break;
            case "en":
                setContent(contentEn);
                setAgreements(agreementsTextEn);
                // поменять на англ интерфейс
                setInterfaceNames(interfaceEn);
                localStorage.setItem("lang", currentLang);
                break;

            case "fr":
                setContent(contentFr);
                setAgreements(agreementsTextFr);
                // поменять на франц интерфейс
                setInterfaceNames(interfaceFr);
                localStorage.setItem("lang", currentLang);
                break;
            case "es":
                // поменять все файлики на испанские
                setContent(contentEs);
                setAgreements(agreementsTextEs);
                setInterfaceNames(interfaceEs);
                localStorage.setItem("lang", currentLang);
                break;
            default:
                break;
        }
        // if (currentLang === undefined || currentLang === "ru") {
        //     setContent(contentRu);
        //     setAgreements(agreementsTextRu);
        // }
        // if (currentLang === "en") {
        //     setContent(contentEn);
        //     setAgreements(agreementsTextEn);
        // }
    }, [currentLang]);
    // useEffect(() => {
    //     console.log("currentLang", currentLang);
    //     console.log(
    //         "localStorage.getItem(lang) in useEffect",
    //         localStorage.getItem("lang")
    //     );
    // }, [currentLang]);

    return (
        <LangContext.Provider
            value={{
                handleCurrentLang,
                content,
                agreements,
                currentLang,
                langList,
                interfaceNames
            }}
        >
            {children}
        </LangContext.Provider>
    );
};
