import React from "react";
import { useLanguage } from "../../../hooks/useLang";

import CardsAdaptiveBlockTablet from "../cardsBlocks/cardsAdaptiveBlockTablet";
import HorizontalBlockAdaptiveTablet from "../horizontalBlockAdaptiveTablet";
import LargeImageTablet from "../LargeImageTablet";
import PageHeadTablet from "../../pageHeadTablet/pageHeadTablet";
// import content from "../textContent";
import SiteMapTablet from "../../footerTablet/siteMapTablet"

const CookiesPageTablet = () => {
    const { content } = useLanguage();
    const pageName = content.cookies.pageName;
    const subMenuList = content.cookies.subMenuList;
    const imageUrls = content.cookies.imageUrls;
    const blocks = content.cookies.blocks;
    const cookiesBlocks = content.cookies.blocks.cookies;
    const cards = content.cookies.cards;
    return (
        <div className="mainPageTablet">
            <PageHeadTablet
                pageName={pageName}
                subMenuList={subMenuList}
                withoutHeightSituationCenter={true}
            />
            <LargeImageTablet imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockAdaptiveTablet
             blockContent={blocks.politics} 
             customNameWidthCookies={true}/>
            <HorizontalBlockAdaptiveTablet 
             blockContent={blocks.whatItIs}
             customNameWidthCookies={true} />
            <HorizontalBlockAdaptiveTablet 
             blockContent={blocks.whatWeUse} 
             customNameWidthCookies={true}/>
            <HorizontalBlockAdaptiveTablet 
            blockContent={blocks.whatTypes} 
             customNameWidthCookiesWhatTypes={true}
            />
            <CardsAdaptiveBlockTablet
                blockContent={cards.block1.cards}
                twoCardsInLine={true}
                marginTopNum={true}
                marginTopName={true}
                marginTopText={true}
                topBorderCard={true}
                customMarginBottom={true}
                customHeightCookiePageTablet={true}
                customWidthCookiesPage={true}
                customMarginCookiepage={true}
            />
            <HorizontalBlockAdaptiveTablet blockContent={cookiesBlocks.analytics} 
             customHeightCookiePageTablet={true}
            //  customWidth={true}
             contactsWidth={true}
             customNameMaxWidth={true}
             />
            <HorizontalBlockAdaptiveTablet blockContent={cookiesBlocks.functional}
             customHeightCookiePageTablet={true}
             contactsWidth={true}
            //  customWidth={true}
            customNameMaxWidth={true}
             /> 
            <HorizontalBlockAdaptiveTablet blockContent={cookiesBlocks.necessary}
               customHeightCookiePageTablet={true}
            //    customWidth={true}
               contactsWidth={true}
               customNameMaxWidth={true}
               />
            <HorizontalBlockAdaptiveTablet blockContent={cookiesBlocks.others} 
               customHeightCookiePageTablet={true}
            //    customWidth={true}
               contactsWidth={true}
               customNameMaxWidth={true}
               />
            <HorizontalBlockAdaptiveTablet blockContent={cookiesBlocks.performance} 
             customHeightCookiePageTablet={true}
            // customWidth={true}
             contactsWidth={true}// закомментил   чтобы 720-1190 нормально показывал
            customNameMaxWidth={true}
             />
            <HorizontalBlockAdaptiveTablet blockContent={cookiesBlocks.policy}
               customHeightCookiePageTablet={true}
               contactsWidth={true}
            //  customWidth={true}
            //    customNameMaxWidth={true}////////////////////закомментил
               />
            
            <HorizontalBlockAdaptiveTablet blockContent={blocks.canChange} 
              customNameWidth={true}
              customWidth={true}
              contactsWidth={true}
              customLeft={true}
            //   titileTabletForwidth={true}
            />
            <SiteMapTablet/>
            {/* <FooterHorizontalBlockTablet/> */}
        </div>
    );
};

export default CookiesPageTablet;
