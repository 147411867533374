import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import CardsSwipeMobile from "../pageBlocksMobile/cardsBlocks/cardsSwipeMobile";

const SafetyPageMobile = () => {
    const { content, currentLang } = useLanguage();
    const pageName = content.safety.pageName;
    const imageUrls = content.safety.imageUrls;
    const blocks = content.safety.blocks;
    const cards = content.safety.cards;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                customHeight={window.innerWidth < 360 ? 340 : 300}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.economic}
                noLink={true}
                customHeight={150}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block1}
                topBorderbBlock={true}
                customMinTextHeight={
                    currentLang === "fr" || currentLang === "es" ? "200" : ""
                }
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.support}
                noLink={true}
                customHeight={window.innerWidth < 360 ? 210 : 180}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block3}
                topBorderbBlock={true}
            />
            <LargeImageMobile
                imageUrl={imageUrls.img2Mobile}
                topBorder={true}
            />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.cyber}
                noLink={true}
                customNameLine="10"
                customHeight={150}
                dropdownDisable={true}
            />
            <CardsSwipeMobile
                blockContent={cards.block2}
                topBorderbBlock={true}
                customMinTextHeight={currentLang === "es" ? "200" : ""}
            />
        </div>
    );
};

export default SafetyPageMobile;
