import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";
import AgreementsBlockMobile from "../pageBlocksMobile/agreementsBlockMobile";

const PersonalDataAgreementPageMobile = () => {
    const { content, agreements } = useLanguage();
    const pageName = content.personalData.pageName;
    const imageUrls = content.personalData.imageUrls;
    const blocks = content.personalData.blocks;
    const personalDataAgreement = agreements.personalData;
    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                bottomBorder={true}
                customNameLine="80"
                customNameWidth="85"
                customHeight={50}
                dropdownDisable={true}
                rightArrow={true}
            />
            <AgreementsBlockMobile blockContent={personalDataAgreement} />
        </div>
    );
};

export default PersonalDataAgreementPageMobile;
