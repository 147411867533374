import React from "react";
import { useLanguage } from "../../hooks/useLang";
import PageHeadMobile from "../pageBlocksMobile/pageHead/pageHeadMobile";
import LargeImageMobile from "../pageBlocksMobile/largeImageMobile";
import HorizontalBlockDropdownMobile from "../pageBlocksMobile/horizontalBlockDropdownMobile";

const UseConditionsPageMobile = () => {
    const { content } = useLanguage();
    const pageName = content.conditions.pageName;
    const imageUrls = content.conditions.imageUrls;
    const blocks = content.conditions.blocks;
    const support = blocks.support;
    const cards = content.conditions.cards;

    return (
        <div className="mainPage">
            <PageHeadMobile pageName={pageName} />
            <LargeImageMobile imageUrl={imageUrls.img1Mobile} />
            <HorizontalBlockDropdownMobile
                blockContent={blocks.main}
                noLink={true}
                noName={true}
                bottomBorder={true}
                customHeight={270}
                dropdownDisable={true}
            />
            <div className="useConditionsPageMobile-cards-back mt-40 graytborder">
                {cards.block1.cards.map((item, index) => (
                    <div
                        className="useConditionsPageMobile-cards-card graybborder pt-40 pb-40"
                        key={index}
                    >
                        <div className="useConditionsPageMobile-cards-card-num fs-48">
                            {`0${item.num}`}
                        </div>
                        <div className="useConditionsPageMobile-cards-card-tex mt-40 fs-14">
                            {item.text}
                        </div>
                    </div>
                ))}
            </div>
            <div className="useConditionsMobile-SupportBlock tborder bborder mb-30">
                <div className="useConditionsMobile-SupportBlock-name-back ">
                    <div className="useConditionsMobile-SupportBlock-name mt-40 fs-32">
                        {support.name}
                    </div>
                    <div className="useConditionsMobile-SupportBlock-arrow m imgBack "></div>
                </div>
                <div className="useConditionsMobile-SupportBlock-textBlock-back pt-40 ">
                    <div className="useConditionsMobile-SupportBlock-textBlock fs-14 bborder">
                        {support.line1}
                    </div>
                    <div className="useConditionsMobile-SupportBlock-textBlock-halfs-back bborder">
                        <div className="useConditionsMobile-SupportBlock-textBlock-half fs-14">
                            {support.line2_1}
                        </div>
                        <div className="useConditionsMobile-SupportBlock-textBlock-half taend fs-14">
                            {support.line2_2}
                        </div>
                    </div>
                    <div className="useConditionsMobile-SupportBlock-textBlock-halfs-back bborder">
                        <div className="useConditionsMobile-SupportBlock-textBlock-half fs-14">
                            {support.line3_1}
                        </div>
                        <div className="useConditionsMobile-SupportBlock-textBlock-half taend fs-14">
                            {support.line3_2}
                        </div>
                    </div>
                    <div className="useConditionsMobile-SupportBlock-textBlock-halfs-back bborder">
                        <div className="useConditionsMobile-SupportBlock-textBlock-half fs-14">
                            {support.line4_1}
                        </div>
                        <div className="useConditionsMobile-SupportBlock-textBlock-half taend fs-14">
                            {support.line4_2}
                        </div>
                    </div>
                    <div className="useConditionsMobile-SupportBlock-textBlock-halfs-back ">
                        <div className="useConditionsMobile-SupportBlock-textBlock-half fs-14">
                            {support.line5_1}
                        </div>
                        <div className="useConditionsMobile-SupportBlock-textBlock-half taend fs-14">
                            {support.line5_2}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UseConditionsPageMobile;
